import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import './SkeletonCard.css'; // The new CSS/SCSS file with the styles

const SkeletonCard = ({ type, cardClassName, contentHeight, contentWidth, shouldPulse }) => {
  // Determine the pulse class based on whether pulsing is enabled
  const pulseClass = shouldPulse ? 'skeleton-pulse' : '';

  // Render the skeleton card with the pulsing animation applied to the entire card
  return (
    <Card className={`skeleton-card ${pulseClass} ${cardClassName}`}>
      <Card.Content style={{ height: contentHeight || '160px', width: contentWidth || '100%' }}>
      </Card.Content>
    </Card>
  );
};

SkeletonCard.propTypes = {
  type: PropTypes.oneOf(['balance', 'usage', 'billPrediction', 'default']),
  cardClassName: PropTypes.string,
  contentHeight: PropTypes.string,
  contentWidth: PropTypes.string,
  shouldPulse: PropTypes.bool,
};

SkeletonCard.defaultProps = {
  type: 'default',
  cardClassName: '',
  contentHeight: '160px',
  contentWidth: '100%',
  shouldPulse: true,
};

export default SkeletonCard;