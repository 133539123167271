import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '32px',
  width = '32px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={color} d="M14.252 0.000919662C14.0591 -0.00583509 13.8668 0.0246701 13.6855 0.0907634C5.83761 2.95828 0 10.4681 0 19.329C0 30.6293 9.18994 39.8193 20.4902 39.8193C29.3512 39.8193 36.861 33.9817 39.7285 26.1337C39.8355 25.8396 39.8479 25.5195 39.7639 25.218C39.6799 24.9165 39.5037 24.6489 39.26 24.4526C39.0163 24.2562 38.7173 24.141 38.4048 24.1231C38.0924 24.1051 37.7822 24.1853 37.5176 24.3525C35.0477 25.9168 32.1414 26.8193 29 26.8193C20.1516 26.8193 13 19.6677 13 10.8193C13 7.67791 13.9025 4.77161 15.4668 2.3017C15.6078 2.07861 15.6875 1.82227 15.6978 1.55855C15.708 1.29483 15.6486 1.03306 15.5253 0.799673C15.4021 0.566287 15.2195 0.369542 14.9959 0.229299C14.7723 0.0890557 14.5157 0.0102786 14.252 0.000919662ZM11.0137 4.92475C10.3983 6.79063 10 8.75037 10 10.8193C10 21.2909 18.5284 29.8193 29 29.8193C31.0689 29.8193 33.0287 29.421 34.8945 28.8056C31.754 33.5377 26.533 36.8193 20.4902 36.8193C10.8105 36.8193 3 29.0087 3 19.329C3 13.2863 6.28156 8.0653 11.0137 4.92475Z" />
    </svg>
  );
}