import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupHR from '../components/SignupHR/SignupHR.js';
import { useNavigate, useParams } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import Property from "../../../components/Property/Property.js";
import { withCustomer } from "../../../contexts/Customer.js";
import { useEffect } from "react";
import SignupButton from "../components/SignupButton/SignupButton.js";
import { useSignup } from "../../../contexts/Signup.js";
import EmbeddedVideo from "../../../components/EmbeddedVideo/EmbeddedVideo.js";
import AdvancedMeterFAQ from "../components/FAQs/AdvancedMeterFAQ.js";
import Analytics from "../../../utils/Analytics.js";


export default withCustomer(({ customer }) => {

	const navigate = useNavigate();
	useScrollToTop();

	const signup = useSignup();

	const { premisesId } = useParams();
	const [premises, setPremises] = useState(null);

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	
	useEffect(() => {
		if (customer.allPremises && customer.allPremises.length > 0 && premisesId) {
			let premisesFound = false;
			customer.allPremises.forEach((premises) => {
				if (premises.identifier === premisesId) {
					premisesFound =true;
					setPremises(premises);
				}
			})
			if (!premisesFound) navigate('/signup/property');
		}
	}, [customer.allPremises, premisesId])


	function submit(payload) {

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		signup.set({ bookingFormData: null });

		setTimeout(() => {
			Analytics.event({ category: "Signup", action: "CompleteMeterExchange" });
			navigate("/signup/property/" + premisesId + "/meter-exchange-booking");
		}, 1);

	}

	return (		
		<Layout
			layout={SignupFormLayout}
			journey={{ message: "Advanced Meter Required", journeyName: "signup", currentStep: 5 }}
			whitePage
			accountPicker={false}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				{ premises && <>
					<Property
						key={premises.identifier}
						premises={premises}
						advancedMeterRequired={!premises.hasAdvancedMeter}
						advancedMeter={premises.hasAdvancedMeter}
					/>
					<SignupHR light />
				</>}

				<p
					style={{
						lineHeight: 2,
					}}
				>
					You have selected a property that does not have an advanced meter. In order to connect you to aurora+ we need to install one. For most people getting their meter exchanged is free of charge. However if things like rewiring, asbestos removal, or meter panel upgrades are required there may be costs involved. Our team will keep you informed throughout the process and let you know if extra work is required before it is completed.
				</p>


				<SignupButton>Continue</SignupButton>

			</Form>

			<SignupHR spaced />

			<p>What is an advanced meter?</p>
			<EmbeddedVideo
				src="https://player.vimeo.com/video/840733665?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
				width="640"
				height="360"
				title="test"
				allowFullScreen
				allow="autoplay; fullscreen; picture-in-picture"
				frameBorder="0"
			/>				

			<AdvancedMeterFAQ />


			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

})


