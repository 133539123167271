import { Button, Modal } from "semantic-ui-react";
import { withTheme } from "../../../../contexts/Theme";

export const CONNECTIVITY_ERROR = "Could not contact Aurora Energy. Please try again later."
export const GENERIC_ERROR = "Something went wrong."
export const WRONG_ACC_NUMBER = "Account number incorrect, please try again."
export const DUPLICATE_ERROR = "We have already received a request for aurora+ at this property."


export default withTheme(({ open, onClose, message, ...props }) => {

	const { theme } = props.theme;

	const title = props.title || "Oops!"
	const buttonLabel = props.buttonLabel || "OK"

	return (
		<Modal
			name="signup/error"
			size="tiny"
			open={open}
			onClose={onClose}
		>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ color: '#4a4a4a' }}>
					{ message }
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					style={{ backgroundColor: theme.primary.backgroundColor }}
					secondary
					fluid
					onClick={onClose}
				>{buttonLabel}</Button>
			</Modal.Actions>
		</Modal>
	);

})