import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { useLoginReturn } from '../../contexts/LoginReturn';
import { withContexts } from '../../contexts';

const ProtectedRoute = withContexts(({ auth, forgetLocation, children }) => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  const navigate = useNavigate();
  const loginReturn = useLoginReturn();

  useEffect(() => {
    if (!isAuthenticated) {
		// forgetLocation will dump the user back to our login page rather than a ms->loginReturn flow
		if (forgetLocation) {
			loginReturn.clear();
		} else {
      		loginReturn.set(location.pathname)
		}
      	navigate('/login');
    }
  }, [])


  return isAuthenticated ? children : null
});

export default ProtectedRoute;
