import { Card } from "semantic-ui-react"
import './SolidCard.css'

export const SolidCardGroup = ({ children, exit, enter, onExited, ...props }) => {
	delete props["in"]; // Clean up some inherited props from semantic parent

	return <div className="solid-card-group" {...props}>
		{children}
	</div>

	
}

export const SolidCard = ({ children, className, noContentPadding, ...props }) => {
	
	return <Card className={"solid-card" + (className ? " " + className : "") + (noContentPadding ? " no-content-padding" : "")} {...props}>
		{children}
	</Card>

	
}

export const SolidCardHeader = ({ children, className, ...props }) => {
	
	return <header className={"solid-card-header" + (className ? " " + className : "")} {...props}>
		{children}
	</header>
	
}

export const SolidCardContent = ({ children, className, ...props }) => {
	
	return <div className={"solid-card-content" + (className ? " " + className : "")} {...props}>
		{children}
	</div>
	
}

export const SolidCardFooter = ({ children, className, ...props }) => {
	
	return <div className={"solid-card-footer" + (className ? " " + className : "")} {...props}>
		{children}
	</div>
	
}

