import { useEffect, useRef } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'
import { useAuth } from './contexts/Auth'
import Analytics from './utils/Analytics'
import MobileAppComms from './utils/MobileAppComms'

const AppRouterContainer = ({ children, style, whitePage }) => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const navigationType = useNavigationType()
  const prevPathname = useRef(location.pathname)

  useEffect(() => {
    MobileAppComms.postMessage(
      JSON.stringify({
        CAN_EXIT_APPLICATION: ['POP', 'REPLACE'].includes(navigationType)
      })
    )
  }, [navigationType])

  useEffect(() => {
    const prevPath = prevPathname.current
    prevPathname.current = location.pathname

    if (prevPath !== location.pathname) {
      Analytics.pageview(location.pathname)
    }
  }, [location])

  return (
    <div
      style={style}
      className={`app-wrapper ${isAuthenticated ? 'is-authenticated' : ''} ${whitePage ? 'white-page no-attribution' : ''} ${style.className ? style.className : ''}`}
    >
      {children}
    </div>
  );
};

export default AppRouterContainer
