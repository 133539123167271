import React from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import Slider from "react-slick";

import Responsive from "../../components/Responsive/Responsive";
import { withCustomer } from "../../contexts/Customer";
import { withTheme } from "../../contexts/Theme";
import Modal from "./../../components/Modal";

// desktop images
import DesktopAutoPayment_Img from "./assets/Desktop-Payment.png"; // 2c (autopayment)
import DesktopPostpayHomepage_Img from "./assets/Desktop-PostpayHomepage.png"; // 1b
import DesktopPostpayPayment_Img from "./assets/Desktop-PostpayPayment.png"; // 2b
import DesktopPostpayUsageWeek_Img from "./assets/Desktop-PostpayUsageWeek.png"; // 3@
import DesktopKeyInsightsAndEnergyTips from "./assets/Desktop-KeyInsightsAndEnergyTips.png"; // 5b
import DesktopAuroraInsights from "./assets/Desktop-AuroraInsights.png";
import DesktopPostpaySwitch from "./assets/Desktop-PostpaySwitch.png";
import DesktopPrepaySwitch from "./assets/Desktop-PrepaySwitch.png";
import DesktopPostpaySwitchWithAuroraInsights from "./assets/Desktop-PostpaySwitchWithAuroraInsights.png";
import DesktopPrepaySwitchWithAuroraInsights from "./assets/Desktop-PrepaySwitchWithAuroraInsights.png";

// mobile images
import MobileAutoPayment_Img from "./assets/Mobile-Payment.png"; // 2a
import MobilePostpayHomepage_Img from "./assets/Mobile-PostpayHomepage.png"; // 1
import MobilePostpayPayment_Img from "./assets/Mobile-PostpayPayment.png"; // 2
import MobilePostpaySwitch_Img from "./assets/Mobile-PostpaySwitch.png"; // 6
import MobilePrepaySwitch_Img from "./assets/Mobile-PrepaySwitch.png"; // 6a
import MobileViewUsage_Img from "./assets/Mobile-ViewUsage.png"; // 3#
import MobileKeyInsightsAndEnergyTips from "./assets/Mobile-KeyInsightsAndEnergyTips.png"; // 5b
import MobileAuroraInsights from "./assets/Mobile-AuroraInsights.png";

// old images
import DesktopSolarExport from "./assets/onboard-3-desktop-solar.png";
import MobileSolarExport from "./assets/onboard-3-solar.png";
import DesktopBalance from "./assets/onboard-1-desktop.png";
import MobileBalance from "./assets/onboard-1.png";
import DesktopNotifications from "./assets/onboard-4-desktop.png";
import MobileNotifications from "./assets/onboard-4.png";

// smb-specific:
import DesktopSwitchProductType from "./assets/onboard-6-desktop.png";
import MobileSwitchProductType from "./assets/onboard-6.png";
import DesktopBalanceSMB from "./assets/onboard-7-desktop.png";
import MobileBalanceSMB from "./assets/onboard-7.png";
import DesktopPaymentSMB from "./assets/onboard-8-desktop.png";
import MobilePaymentSMB from "./assets/onboard-8.png";
import DesktopUsageSMB from "./assets/onboard-9-desktop.png";
import MobileUsageSMB from "./assets/onboard-9.png";
import DesktopPredictABill from "./assets/onboard-10-desktop.png";
import MobilePredictABill from "./assets/onboard-10.png";

const Slides = {
  residential: {
    PAYG: [
      {
        title: "View balance",
        description:
          "The home screen is where you can view your account balance. We estimate a real-time balance based on your recent usage, along with the number of prepaid days remaining before you need to top up.",
        image: {
          mobile: MobileBalance,
          desktop: DesktopBalance
        }
      },
      {
        title: "Convenient payment options",
        description:
          "When you want to top up your account balance, you can make in-app payments with your saved credit or debit card, or setup an auto payment to automatically make a preset payment when your balance reaches a minimum amount you set.",
        image: {
          mobile: MobileAutoPayment_Img,
          desktop: DesktopAutoPayment_Img
        }
      },
      {
        title: "View usage",
        description:
          "Check on your usage with our easy to understand graphs. Usage is shown in dollars and kWh based on your energy plan’s rates, and you can also view your usage by day, week, month, quarter and year.",
        image: {
          mobile: MobileViewUsage_Img,
          desktop: DesktopPostpayUsageWeek_Img
        }
      },
      {
        title: "View solar export",
        description:
          "If you have solar, you’ll also see the solar power you’ve exported back to the grid. This is the power you receive a rebate for. You can view your exported solar in kWh or dollar views.",
        image: {
          mobile: MobileSolarExport,
          desktop: DesktopSolarExport
        }
      },
      {
        title: "An easy reminder",
        description:
          "You will receive notifications to help you manage your account balance.",
        image: {
          mobile: MobileNotifications,
          desktop: DesktopNotifications
        }
      }
    ],
    POSTPAID: [
      {
        title: "View bill information",
        description:
          "The home screen shows you details of any payments due. You can also get a predicted amount for your next bill and when it will be issued.",
        image: {
          mobile: MobilePostpayHomepage_Img,
          desktop: DesktopPostpayHomepage_Img
        }
      },
      {
        title: "Convenient payment options",
        description:
          "When you have a payment due, you can make in-app payments with your credit or debit card, or setup direct debit instructions to automatically pay any bill amounts due.",
        image: {
          mobile: MobilePostpayPayment_Img,
          desktop: DesktopPostpayPayment_Img
        }
      },
      {
        title: "View usage",
        description:
          "Check on your usage with our easy to understand graphs. Usage is shown in dollars and kWh based on your energy plan’s rates, and you can also view your usage by day, week, month, quarter and year.",
        image: {
          mobile: MobileViewUsage_Img,
          desktop: DesktopPostpayUsageWeek_Img
        }
      },
      {
        title: "View solar export",
        description:
          "If you have solar, you’ll also see the solar power you’ve exported back to the grid. This is the power you receive a rebate for. You can view your exported solar in kWh or dollar views.",
        image: {
          mobile: MobileSolarExport,
          desktop: DesktopSolarExport
        }
      }
    ]
  },
  business: {
    POSTPAID: [
      {
        title: "Switch between Residential and Business",
        description:
          "If you have eligible aurora+ products, use single sign up and an easy transition between Residential and Business products.",
        image: {
          mobile: MobileSwitchProductType,
          desktop: DesktopSwitchProductType
        }
      },
      {
        title: "View balance",
        description:
          "The home screen is where you can make payments when they are due and see when your next bill is due.",
        image: {
          mobile: MobileBalanceSMB,
          desktop: DesktopBalanceSMB
        }
      },
      {
        title: "Payment",
        description:
          "When your bill has been issued, payments in the app can be made with your credit or debit card.",
        image: {
          mobile: MobilePaymentSMB,
          desktop: DesktopPaymentSMB
        }
      },
      {
        title: "View usage",
        description: "You can view your usage in kWh or dollar views.",
        image: {
          mobile: MobileUsageSMB,
          desktop: DesktopUsageSMB
        }
      },
      {
        title: "Predict-a-bill",
        description:
          "You can see an estimated bill amount for your next bill based on your current usage during the current billing period.",
        image: {
          mobile: MobilePredictABill,
          desktop: DesktopPredictABill
        }
      }
    ]
  }
};

const AURORA_INSIGHTS_SCREENS = {
  residential: {
    PAYG: {
      enabled: [
        {
          title: "aurora+ insights",
          description:
            "Get personalised energy usage insights, useful energy tips, and even see how your usage compares to similar households in your suburb.",
          dependsOn: "auroraInsights",
          image: {
            mobile: MobileAuroraInsights,
            desktop: DesktopAuroraInsights
          }
        },
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePrepaySwitch_Img,
            desktop: DesktopPrepaySwitchWithAuroraInsights
          }
        }
      ],
      disabled: [
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePrepaySwitch_Img,
            desktop: DesktopPrepaySwitch
          }
        }
      ]
    },
    POSTPAID: {
      enabled: [
        {
          title: "aurora+ insights",
          description:
            "Get personalised energy usage insights, useful energy tips, and even see how your usage compares to similar households in your suburb.",
          dependsOn: "auroraInsights",
          image: {
            mobile: MobileAuroraInsights,
            desktop: DesktopAuroraInsights
          }
        },
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePostpaySwitch_Img,
            desktop: DesktopPostpaySwitchWithAuroraInsights
          }
        }
      ],
      disabled: [
        {
          title: "View Key Insights and Energy Tips",
          description:
            "Know how you’re tracking compared to previous weeks with Key Insights, and learn how you can lower your power use with helpful energy tips.",
          image: {
            mobile: MobileKeyInsightsAndEnergyTips,
            desktop: DesktopKeyInsightsAndEnergyTips
          }
        },
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePostpaySwitch_Img,
            desktop: DesktopPostpaySwitch
          }
        }
      ]
    }
  },
  business: {
    PAYG: {
      enabled: [],
      disabled: []
    },
    POSTPAID: {
      enabled: [],
      disabled: []
    }
  }
};

export class AppDemo extends React.Component {
  state = {
    activeSlide: 0,
    skipModalOpen: false
  };

  constructor() {
    super();

    this.settings = {
      dots: true,
      infinite: false,
      arrows: false,
      adaptiveHeight: true,
      useCSS: false,
      lazyLoad: "progressive",
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      onInit: () => {
        this.setState({ activeSlide: 0 });
      }
    };
  }

  skip = e => {
    e.preventDefault();

    this.setState({ skipModalOpen: true });
  };

  next = (e, max) => {
    if (this.state.activeSlide === max) {
      this.closeModal(e);
      return;
    }

    this.slider.slickNext();
  };

  closeModal = e => {
    e.preventDefault();

    this.setState({ skipModalOpen: false });
    this.props.closeModal();
  };

  renderDemoScreens(screens) {
    // const { pageConfig, customerPremise } = this.props.customer;

    // if (!pageConfig || !customerPremise) return null;

    // // get business or residential slides, filtered by billing type (PAYG or POSTPAID)
    // const screens = Slides[pageConfig.productName][customerPremise.billingType];

    return screens.map(i => (
      <div key={"demoscreen" + i.title}>
        <h4>{i.title}</h4>
        <p style={{ fontSize: "0.9em" }}>{i.description}</p>

        <Responsive lessThan="computer">
          <Image centered src={i.image.mobile} />
        </Responsive>
        <Responsive greaterThan="tablet">
          <Image centered src={i.image.desktop} />
        </Responsive>
      </div>
    ));
  }

  render() {
    const {
      modalOpen,
      theme: { theme },
      customer: { pageConfig, customerPremise, hasLoaded }
    } = this.props;

    if (
      !modalOpen ||
      !hasLoaded ||
      !pageConfig ||
      !pageConfig.productName ||
      !customerPremise
    )
      return null;

    // get business or residential slides, filtered by billing type (PAYG or POSTPAID)
    const auroraInsightsEnabled = pageConfig.functionality.auroraInsights
      ? "enabled"
      : "disabled";

    const screens = [
      ...Slides[pageConfig.productName][customerPremise.billingType],
      ...AURORA_INSIGHTS_SCREENS[pageConfig.productName][
        customerPremise.billingType
      ][auroraInsightsEnabled]
    ];

    return (
      <div>
        <Modal
          name="app-demo"
          basic
          open={this.props.modalOpen}
          style={{ backgroundImage: theme.background }}
          className="themed-modal"
        >
          <Grid
            centered
            padded
            style={{
              alignItems: "center",
              height: "100%"
            }}
          >
            <Grid.Column mobile={15} computer={15} textAlign="center">
              <Slider ref={slider => (this.slider = slider)} {...this.settings}>
                {this.renderDemoScreens(screens)}
              </Slider>

              <div style={{ paddingTop: "2.2em" }}>
                <Button
                  style={{
                    marginBottom: ".5em",
                    backgroundColor: theme.primary.backgroundColor
                  }}
                  fluid
                  secondary
                  onClick={e => this.next(e, screens.length - 1)}
                >
                  {this.state.activeSlide === screens.length - 1
                    ? "Done"
                    : "Next"}
                </Button>
                <p>
                  <a href="/skip-demo" onClick={this.skip}>
                    <small
                      style={{
                        color: theme.name === "business" ? "black" : "white"
                      }}
                    >
                      Skip Tour
                    </small>
                  </a>
                </p>
              </div>
            </Grid.Column>
          </Grid>
        </Modal>

        <Modal
          name="app-demo/skip"
          size="mini"
          onActionClick={this.closeModal}
          open={this.state.skipModalOpen}
        >
          <Modal.Header>Skip Tour</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              You can watch the demo again at any time by tapping "Take the Tour
              Again" in the main navigation.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.closeModal}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withCustomer(withTheme(AppDemo));
