import React from 'react';
import { Label } from 'semantic-ui-react';

import InfoIcon from '../../../components/Icons/InfoIcon';
import { tariffSearch, solarCheck } from '../../../utils/Tariffs'

const UsageLegend = props => {
  const { openModal, solar, tariffs, mobile } = props;

  let tariffList = []

  for (let key in tariffs) {
	let tariff = tariffs[key];

	// DPS-211 Remove grandfathered T140 from the summary
	if ((tariff === "T140") && (tariffs.indexOf("T110") >= 0)) {
		continue;
	}

	if (!solar) {
      tariffList.push(tariff);
    } else {
      if (solarCheck(tariff)) {		
		tariffList.push(tariff);
      }
    }
  }

  return (
    <div className="chart-legends">
      {tariffList.length > 0 && tariffList.map((item) => (
        <Label size="large" key={item}>
          <Label empty circular style={{backgroundColor:tariffSearch(item).color}}/>
          <Label.Detail>{mobile ? tariffSearch(item).DisplayNameMobile : tariffSearch(item).DisplayName}</Label.Detail>
          <Label.Detail>
            <InfoIcon
              style={{
              marginTop: '-4px'
            }}
              onClick={() => openModal(item)}/>
          </Label.Detail>
        </Label>
      ))}
    </div>
  );
}

export default UsageLegend;
