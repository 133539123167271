import React from 'react'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import {
  List,
  Label,
  Button,
  Icon,
  Grid,
  Dimmer,
  Loader,
  Transition
} from 'semantic-ui-react';

import PaymentAccount from './../../model/PaymentAccount';
import { withCustomer } from '../../contexts/Customer'
import { withTheme } from '../../contexts/Theme';


export class WestpacAccountList extends React.Component {

  state = {
    loading: true,
    selectedAccount: null,
    isShowingOtherCards: false,
    accounts: []
  }

  fetch(customerID) {
    return axios
      .get(process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/' + customerID)
      .then(({data}) => {
        let accounts = data.map(x => new PaymentAccount(x))
        accounts.sort((a, b) => {
          return (b.default
            ? 1
            : 0) - (a.default
            ? 1
            : 0)
        })
        var selectedAccount = accounts.find(d => d.default);
        this.setState({accounts, loading: false})

        if (this.props.selectDefaultAccount) {
          this.selectAccount(selectedAccount)
        }

        if (this.props.onLoaded) {
          this
            .props
            .onLoaded(accounts)
        }
        return accounts;
      })
      .catch(reason => {

        if (this.props.onError) {
          this
            .props
            .onError(reason)
        }
        this.setState({loading: false})
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customer.customer !== this.props.customer.customer) {
      this.fetch(this.props.customer.customer.customerID)
    }
  }

  componentDidMount() {

    let {customer} = this.props.customer

    if (customer) {
      this.fetch(customer.customerID)
    }

  }

  selectAccount(account) {
    this.setState({selectedAccount: account})

    if (this.props.onSelect) {
      this
        .props
        .onSelect(account)
    }
  }
  onEditClicked = (account, ev) => {
    if (this.props.onEdit) {
      ev && ev.preventDefault();
      this
        .props
        .onEdit(account)
    }
  }

  renderCard = (account) => {
    const { theme } = this.props.theme;
    let active = this.props.isFocused && this.state.selectedAccount === account;

    return (
      <List.Item
        disabled={this.props.disabled}
        key={account.accountToken}
        value={account.accountToken}
        onClick={(ev, {value}) => {
        this.props.selectable && this.selectAccount(account)
      }}>

        <List.Header
          style={{
            marginBottom: '0.75em',
            fontWeight: 'bold',
            backgroundColor: theme.tertiary.backgroundColor
        }}>
          <span style={{minWidth: '10%', overflowWrap: 'break-word'}}>{account.cardholderName}</span>
          {account.default &&
            <Label style={{
              marginLeft: '1em',
              color: 'white',
              backgroundColor: theme.tertiary.backgroundColor
            }}>Primary</Label>
          }
        </List.Header>

        <Grid
          columns={2}
          verticalAlign="bottom"
          style={{
          marginBottom: '0.25em'
        }}>
          <Grid.Column>
            <List.Header style={{
              fontWeight: 'bold'
            }}>
              <List.Icon
                name={`cc ${account
                .cardScheme
                .toLowerCase()}`}/> {account.cardNumber}
            </List.Header>
          </Grid.Column>

          <Grid.Column>
            <NavLink
              to={"/accountSettings/payments/" + account.accountToken}
              onClick={(ev) => this.onEditClicked(account, ev)}
              style={{
              float: 'right',
              margin: '0 0 0 1em'
            }}>
              Edit
            </NavLink>
          </Grid.Column>
        </Grid>

        {this.props.selectable && (
          <Button
            as='a'
            disabled={this.props.disabled}
            basic={!active}
            color={active
            ? "black"
            : undefined}
            fluid
            icon
            labelPosition="left"
            style={{
            marginBottom: '1em'
          }}>
            {active && <Icon name='check'/>}
            Use this card
          </Button>
        )}

      </List.Item>
    )
  }

  renderFirstCard = () => {
    let {accounts} = this.state;

    if (accounts.length === 0) 
      return null;
    
    return this.renderCard(accounts[0]);
  }

  renderOtherCards = () => {
    let {accounts, isShowingOtherCards} = this.state;
    let {showAllCards} = this.props;

    if (accounts.length <= 1) 
      return null;
    
    return (
      <Transition.Group animation="fade down" duration={400}>
        {(showAllCards || isShowingOtherCards)
          ? accounts.slice(1).map(this.renderCard)
          : null}
      </Transition.Group>
    );
  }

  render() {
    // props.showAllCards overrides the card hiding feature, needed for 'Manage
    // Payment Details' screen
    const {isFocused, canAddAnotherCard, showAllCards} = this.props;
    const {isShowingOtherCards, accounts} = this.state;

    const showAddAnotherCardButton = isFocused && canAddAnotherCard && (isShowingOtherCards || accounts.length === 1);
    const showUseAnotherCardButton = !isShowingOtherCards && accounts.length > 1;

    return (
      <List className="accounts-list">
        <Dimmer active={this.state.loading} inverted>
          <Loader/>
        </Dimmer>

        {this.renderFirstCard()}
        {this.renderOtherCards()}

        {(!showAllCards && showUseAnotherCardButton) && (
          <Button fluid basic onClick={() => this.setState({isShowingOtherCards: true})}>Use another card</Button>
        )}

        {this.props.children}

        {showAddAnotherCardButton && (
          <Button
            type="button"
            basic
            primary
            fluid
            disabled={this.props.disabled}
            onClick={this.props.addAnotherCardClicked}>
            Add another card
          </Button>
        )}

      </List>
    )
  }
}

export default withCustomer(withTheme(WestpacAccountList))
