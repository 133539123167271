import React, { useMemo } from 'react';
import SignupResidentialIcon from '../Icons/signup/SignupResidentialIcon';
import TickIcon from '../Icons/TickIcon';
import EnterIcon from '../Icons/EnterIcon';
import WarningIcon from '../Icons/WarningIcon';
import './Property.css';


export default ({ premises, advancedMeter, advancedMeterRequired, inProgress, completed, enterPrompt, hasExpander, isExpanded }) => {

	const addressLines = useMemo(() => {
		return premises ? premises.addressLines : [];
	}, [premises]);


	return <div className="property-display">

		<SignupResidentialIcon color="#979797" />
		<div className="property-display-address">
			{ addressLines && addressLines.map((line, index) => {
				return <React.Fragment key={index}>
					{index > 0 ? <br /> : null}
					{line}
				</React.Fragment>
			})}
		</div>

		{ hasExpander ?
			<div className="property-display-status">
				<i className="expander icon" />
			</div>
		: enterPrompt ? 
			<div className="property-display-status"
				style={{
					color: "#0060AE",
				}}
			>
				<EnterIcon color="#0060AE"/>
				<span>Log in to aurora+</span>
			</div>
		: inProgress ? 	
			<div className="property-display-status"
				style={{
					color: "#005FAE",
				}}
			>
				<TickIcon color="#005FAE"/>
				<span>Request in progress</span>
			</div>
		: completed ?
			<div className="property-display-status">
				<TickIcon color="#40CC3D" width={40} height={40} inverted />
			</div>			
		: advancedMeter ?		
			<div className="property-display-status"
				style={{
					color: "#40CC3D",
				}}
			>
				<TickIcon color="#40CC3D"/>
				<span>Advanced meter</span>
			</div>
		: advancedMeterRequired ?
			<div className="property-display-status"
				style={{
					color: "#F7902B",
				}}
			>
				<WarningIcon color="#F7902B"/>
				<span>Advanced meter required</span>
			</div>
		: null }
		
	</div>

}