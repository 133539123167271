import TickIcon from '../Icons/TickIcon';
import './IconSteps.css';

export default ({ icon, title, text, narrow, active, complete, ...props }) => {
	const Icon = icon;

	return <div className={"icon-step" + (narrow ? " narrow" : "") + (active ? " active" : "") + (complete ? " complete" : "")}>		
		<div className="icon-step-icon">
			{ Icon && (
				complete ? <TickIcon color="#40CC3D" inverted />
				: <Icon /> 
			)}
		</div>
		<div>
			<p className="icon-step-title">{ title }</p>
			<p>{ text }</p>
		</div>
	</div>
}