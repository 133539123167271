import React from "react";
import axios from "axios";

import { withContexts } from "../../contexts";

import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, List, Loader, Modal } from "semantic-ui-react";
import { withTheme } from "../../contexts/Theme";
import { useExternalLink } from "../../components/ExternalLink";
import { useLoginReturn } from "../../contexts/LoginReturn";


export default withContexts(withTheme(({ auth, app, theme }) => {

	const [genericModalOpen, setGenericModalOpen] = useState(false);
	const [duplicateErrorModalOpen, setDuplicateErrorModalOpen] = useState(false);
	const [canSubmit, setCanSubmit] = useState(true);
	const [submittingToken, setSubmittingToken] = useState(false);

	const loginReturn = useLoginReturn();

	const navigate = useNavigate();
	const externalLink = useExternalLink();

	const {
		accessToken,
		msalToken,
		remember,
		msalForgotPassword,
		msalCancelled,
		msalLoggedOut,
		msalResetComplete,
		msalMaxRetries,
		msalError,
	 } = auth;

	// If stuck on this page with nothing happening, redirect to login after 15s
	useEffect(() => {
		if (msalMaxRetries || msalError) return;

		const timer = setTimeout(() => {
			if (!genericModalOpen && !duplicateErrorModalOpen) {
				auth.clearMsalFlags();
				navigate('/login');		
			}
		}, 15000);

		return () => {
			clearInterval(timer);
		}
	}, [msalMaxRetries, msalError, genericModalOpen, duplicateErrorModalOpen]);

	useEffect(() => {
		// msalLoggedOut should be handled by postLogoutRedirectUri anyway
		if (msalLoggedOut || msalCancelled) {
			auth.clearMsalFlags();
			navigate('/login');		
		}
	}, [msalLoggedOut, msalCancelled]);

	useEffect(() => {

		if (sessionStorage.getItem("msal.account.keys") !== null) {
			sessionStorage.removeItem("msal.account.keys");
		}

		if (msalForgotPassword) {
			auth.initiateForgotPassword();
		}

	}, [msalForgotPassword]);

	useEffect(() => {
		if (msalResetComplete) {
			auth.initiateLogin();
		}
	}, [msalResetComplete]);

	useEffect(() => {		
		if (msalToken && !accessToken && !submittingToken) {
			// Received back from MS but not logged in via API yet

			setSubmittingToken(true);



			const endpoint = process.env.REACT_APP_API_BASE_URI + "identity/LoginToken";

			const payload = {
				token: msalToken,
			}

			let { deviceInfo, isApp } = app;
			if (deviceInfo) payload.deviceInfo = deviceInfo;
		
			//setState({ canSubmit: false, formState: FORM_STATE.LOADING });
		
			axios
			  .post(endpoint, payload, { ignoreGlobalErrors: true })
			  .then(response => {
				//setState({ formState: FORM_STATE.DONE_LOADING });
				//setState({ loggingIn: false });
		
				if (response.data && response.data.accessToken) {
				  auth.updateToken(
					response.data.accessToken,
					isApp ? true : remember,
					false
				  )
				}
		
				//Analytics.event({ category: "User", action: "Successful Login" });
		
				if (!isApp) {
					
				  //Analytics.event({
					//category: "Checkbox",
					//action: "Stay Logged In",
					//value: payload.remember ? 1 : 0
				  //});
				  
				}
		
				setTimeout(() => {
					app.loginUser();
					
					setSubmittingToken(false);


					if (loginReturn.returnUrl) {
						var url = loginReturn.returnUrl;
						loginReturn.clear()
						navigate(url);
					}

				}, 1)
				
			  })			  
			  .catch(error => {
				//if (error.response?.data?.Message === "You have entered an invalid username or password") {
					//this.openInvalidModal();

				switch (error.response ? error.response.status : error.code) {
					case 409:
						setDuplicateErrorModalOpen(true);
						break;
					default:
						setGenericModalOpen(true);
						break;
				}
			  });
			  



		}
	}, [accessToken, msalToken, remember, submittingToken]);



	function handleCloseErrorModal() {
		setGenericModalOpen(false);
		setDuplicateErrorModalOpen(false);
		auth.clearMsalFlags();
		navigate('/login');
	}

	
	return (<>

		{ genericModalOpen ? (

			<Modal
				name="login/generic"
				size="tiny"
				open={true}
				onClose={handleCloseErrorModal}
				>
				<Modal.Header>Oh No! Something went wrong:</Modal.Header>
				<Modal.Content>
					<Modal.Description style={{ color: '#4a4a4a' }}>
						Please try again.
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button color="orange" fluid onClick={handleCloseErrorModal}>
						OK
					</Button>
				</Modal.Actions>
			</Modal>

		) : duplicateErrorModalOpen ? (

			<Modal
				name="signup/error"
				size="tiny"
				open={true}
				onClose={null}
			>
				<Modal.Header>Account Error</Modal.Header>
				<Modal.Content>
					<Modal.Description style={{ color: '#4a4a4a' }}>
						Your email has been found on more than one account. Please contact us to resolve this problem.
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button color="orange" fluid
						onClick={() => {
							externalLink('https://www.auroraenergy.com.au/residential/contact/general-enquiries-form?utm_source=sms&utm_medium=sms&utm_campaign=app_error&utm_id=app_email&referrer=app_duplicate_email')
							handleCloseErrorModal();
						}}
					>Contact Us</Button>
				</Modal.Actions>
			</Modal>

		) : msalMaxRetries ? (

			<Modal
				name="login/max-retries"
				size="tiny"
				open={true}
				onClose={handleCloseErrorModal}
			>
				<Modal.Header>Oh No! Something went wrong:</Modal.Header>
				<Modal.Content>
					<Modal.Description style={{ textAlign: "left" }}>
						You entered an incorrect verification code too many times. Please try again.
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button color="orange" fluid onClick={handleCloseErrorModal}>
						OK
					</Button>
				</Modal.Actions>
			</Modal>
  

		) : msalError ? (

			<Modal
				name="login/failure"
				size="small"
				open={true}
				onClose={handleCloseErrorModal}
			>
				<Modal.Header>Oh No! Something went wrong:</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<p>
								Sorry, we could not identify these details. This could be
								because:
							</p>
							<List ordered>
								<List.Item>
									You may have entered your email address and/or password
									incorrectly. Please check these details and log in again.
								</List.Item>
								<List.Item>
									You need to be registered to use this product. For an easy set
									up, you can call us on 1300 132 030 to find out if you're
									eligible.
								</List.Item>
							</List>
						</Modal.Description>
					</Modal.Content>
				<Modal.Actions>
					<Button color="orange" fluid onClick={handleCloseErrorModal}>
						OK
					</Button>
				</Modal.Actions>
			</Modal>

		) : (

			<Loader active />

		)}

	</>)

}));

