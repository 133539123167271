import { useCallback, useEffect, useState } from "react";
import { withRouter } from "../../utils/useRouter"
import { useNavigate } from "react-router";
import { withApp } from "../../contexts/App";
import Device from "../../utils/Device";
import {
	Button,
	Image,
	Container,
} from "semantic-ui-react";
import Layout from "../../layouts/Layout";
import BareLayout from "../../layouts/BareLayout";
//import logo from "../../static/logo-blue.svg";
import logo from "../../static/aurora_plus_logo_blue2.svg";
import deeplinkGraphic from "../../static/deeplink.svg";
import "./DeepLinkLandingPage.css"
import { NavLink } from "react-router-dom";
import BACKGROUND_IMAGE from '../../static/backgrounds/bg-deeplink.jpg';
import ExternalLink from "../../components/ExternalLink";


export default withRouter(withApp(({ router, app }) => {

	
	const navigate = useNavigate();

	const path = router.location.pathname;
	let deeplink = path.replace("/launch", "");
	if (deeplink.length === 0) deeplink = "/";
	const launchLink = "auroraplus://redirect" + deeplink;



    const isAndroid = Device.isAndroidDevice();
    const isApple = Device.isAppleDevice();


	const attemptLaunch = useCallback(() => {

		try {
			if (isApple || isAndroid) {
				document.location = launchLink;
			} else {
				navigate(deeplink);
			}
		} catch (err) {
		}

	}, [deeplink]);


	useEffect(() => {
		if (BACKGROUND_IMAGE) app.setBackgroundImage(BACKGROUND_IMAGE, false);
  
		attemptLaunch();

		return () => {
			app.setBackgroundImage(null);
		}

	}, [attemptLaunch]);




	return <>
		<Layout
			className="deeplink-launch-page"
			hideBackButton
			requiresCustomer={false}
			accountPicker={false}
			layout={BareLayout}
			noContainer
			noHeader
			noFooter
		>

			<div class="launch-box">

				<div className="deeplink-graphic">
					<Image src={deeplinkGraphic} alt="Aurora Energy logo" />
				</div>		

				<h1>Choose how you want to continue</h1>

				<Button
					className="launch-button"
					fluid
					secondary
					size="large"
					style={{  fontWeight: '400' }}
					onClick={attemptLaunch}					
				>
					Open in the aurora+ app
				</Button>

				<Button
					as={NavLink}
					className="continue-in-browser"
					fluid
					inverted
					size="large"
					style={{  fontWeight: '400' }}
					to={deeplink}
				>
					Continue in browser
				</Button>

				<div class="learn-more">
					Don't have the aurora+ app?
					<ExternalLink href="https://www.auroraenergy.com.au/aurora-plus/join-aurora-plus">Learn more about aurora+</ExternalLink>
				</div>

				<div className="logo">
					<Image src={logo} alt="Aurora Energy logo" />
				</div>		
			</div>


		</Layout>
	</>
	
}));



