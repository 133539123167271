import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) {
  return (
    <svg 
        id="SolarIcon" 
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M283.65,387.11A103.27,103.27,0,1,1,386.94,283.85,103.38,103.38,0,0,1,283.65,387.11m0-241c-76,0-137.78,61.81-137.78,137.78s61.81,137.77,137.78,137.77,137.78-61.81,137.78-137.77S359.62,146.07,283.65,146.07" transform="translate(0 0)" />
    <path d="M283.66,454a17.25,17.25,0,0,0-17.25,17.25v78.39a17.25,17.25,0,0,0,34.5,0V471.29A17.25,17.25,0,0,0,283.66,454" transform="translate(0 0)" />
    <path d="M549.68,266H471.29a17.26,17.26,0,1,0,0,34.51h78.39a17.26,17.26,0,0,0,0-34.51" transform="translate(0 0)" />
    <path d="M283.26,112.89a17.25,17.25,0,0,0,17.25-17.25V17.25a17.25,17.25,0,1,0-34.49,0V95.64a17.25,17.25,0,0,0,17.24,17.25" transform="translate(0 0)" />
    <path d="M112.88,283.66a17.25,17.25,0,0,0-17.25-17.25H17.25a17.25,17.25,0,0,0,0,34.5H95.63a17.25,17.25,0,0,0,17.25-17.25" transform="translate(0 0)" />
    <path d="M392.48,437.4a17.25,17.25,0,1,0-29.87,17.25l28,48.56A17.25,17.25,0,1,0,420.52,486Z" transform="translate(0 0)" />
    <path d="M446,206.64a17.15,17.15,0,0,0,8.61-2.31l48.57-28A17.26,17.26,0,0,0,486,146.41l-48.57,28a17.25,17.25,0,0,0,8.64,32.2" transform="translate(0 0)" />
    <path d="M174.44,129.53a17.24,17.24,0,0,0,14.95,8.63,17,17,0,0,0,8.61-2.32,17.24,17.24,0,0,0,6.31-23.56l-28-48.56A17.25,17.25,0,1,0,146.41,81Z" transform="translate(0 0)" />
    <path d="M112.26,362.62l-48.55,28A17.26,17.26,0,0,0,81,420.53l48.54-28a17.26,17.26,0,0,0-17.25-29.89" transform="translate(0 0)" />
    <path d="M503.41,390.31l-48.56-28a17.25,17.25,0,1,0-17.26,29.88l48.57,28a17.25,17.25,0,0,0,17.25-29.88" transform="translate(0 0)" />
    <path d="M368.58,135.66a17.28,17.28,0,0,0,23.56-6.31l28-48.57a17.25,17.25,0,1,0-29.87-17.26l-28.05,48.57a17.26,17.26,0,0,0,6.32,23.57" transform="translate(0 0)" />
    <path d="M63.52,176.62l48.55,28a17.25,17.25,0,0,0,17.26-29.88l-48.55-28a17.24,17.24,0,1,0-17.26,29.86" transform="translate(0 0)" />
    <path d="M198.34,431.3a17.27,17.27,0,0,0-23.56,6.32l-28,48.55a17.25,17.25,0,1,0,29.88,17.25l28-48.54a17.27,17.27,0,0,0-6.31-23.58" transform="translate(0 0)" />
  </g>
</svg>

)
}