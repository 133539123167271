import React from 'react';
import {
    Header,
    Grid
  } from 'semantic-ui-react';

import './SolarSystemSize.css'
import { withTheme } from '../../../contexts/Theme';

export default withTheme(function SolarSystemSize(props) {

	var { theme } = props.theme;

    return (
        <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Grid padded={"horizontally"}>
                    <Grid.Column mobile={9} tablet={10} computer={10}>
                        <Header as='h4' className="SolarSystemSizeHeader" textAlign="left">System Size</Header>
                    </Grid.Column>
                    <Grid.Column mobile={7} tablet={6} computer={6}>
                        <p className="SolarSystemTableDescription">Expected Solar production per day</p>
                    </Grid.Column>
                </Grid>
                <Grid padded={"horizontally"} className="SolarSystemSizeGrid">
                    <Grid.Row columns={2} >
                        <Grid.Column mobile={9} tablet={10} computer={10} className="SystemSize">10 kW Solar System</Grid.Column>
                        <Grid.Column mobile={7} tablet={6} computer={6} className="SystemKwhSize">28.7 - 33 kWh</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} >
                        <Grid.Column mobile={9} tablet={10} computer={10} className="SystemSize">7 kW Solar System</Grid.Column>
                        <Grid.Column mobile={7} tablet={6} computer={6} className="SystemKwhSize">20 - 23 kWh</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} >
                        <Grid.Column mobile={9} tablet={10} computer={10} className="SystemSize">5 kW Solar System</Grid.Column>
                        <Grid.Column mobile={7} tablet={6} computer={6} className="SystemKwhSize">14.4 - 16.5 kWh</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} >
                        <Grid.Column mobile={9} tablet={10} computer={10} className="SystemSize">4 kW Solar System</Grid.Column>
                        <Grid.Column mobile={7} tablet={6} computer={6} className="SystemKwhSize">11.5 - 13.2 kWh</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} >
                        <Grid.Column mobile={9} tablet={10} computer={10} className="SystemSize">3 kW Solar System</Grid.Column>
                        <Grid.Column mobile={7} tablet={6} computer={6} className="SystemKwhSize">8.6 - 10 kWh</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} >
                        <Grid.Column mobile={9} tablet={10} computer={10} className="SystemSize">2 kW Solar System</Grid.Column>
                        <Grid.Column mobile={7} tablet={6} computer={6} className="SystemKwhSize">5.7 - 6.6 kWh</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} className="SolarSystemSizeConditions">
                <p className="SolarSystemSizeConditionsText">*Based on Tasmania year round average of 3.8 - 4.4 hours of peak sun per day (data from Bureau of Meteorology)</p>
                <p className="SolarSystemSizeConditionsText">*Data obtained from www.solarchoice.net.au</p>
            </Grid.Column>
        </Grid>
    )
})