import React from 'react';

export default function Icon({
    color = 'currentColor',
    height = '60px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle fill='none' cx="30" cy="30" r="26.5" stroke={color} strokeWidth="2" />
      <path fill={color} d="M22.5 40L21.7929 40.7071C21.9886 40.9028 22.2567 41.0087 22.5333 40.9994C22.8099 40.9902 23.0703 40.8668 23.2526 40.6585L22.5 40ZM40.7526 20.6585C41.1163 20.2429 41.0741 19.6111 40.6585 19.2474C40.2429 18.8837 39.6111 18.9259 39.2474 19.3415L40.7526 20.6585ZM18.2071 34.2929C17.8166 33.9024 17.1834 33.9024 16.7929 34.2929C16.4024 34.6834 16.4024 35.3166 16.7929 35.7071L18.2071 34.2929ZM23.2526 40.6585L40.7526 20.6585L39.2474 19.3415L21.7474 39.3415L23.2526 40.6585ZM23.2071 39.2929L18.2071 34.2929L16.7929 35.7071L21.7929 40.7071L23.2071 39.2929Z" />
    </svg>
)
}
