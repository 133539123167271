import React from "react";
import { axisBottom, axisLeft, axisRight, axisTop } from "d3-axis";
import { select } from "d3-selection";

const Axis = (props) => {
  const {
    name,
    orientation,
    scale,
    tickFormat,
    position,
    length,
    textOffset,
    className,
    dynamicStrokeWidth,
    contentHeight,
    dataMax,
	axisColor } = props;
  
  // reposition the entire axis (by default it's centered)
  const axisTransform = {
    transform: position
      ? `translate(${position.x},${position.y})`
      : ''
  };

  // the orientation of the tick marks can be easily changed via props
  // i.e. the direction the tick marks go and where text labels will be positioned
  let axisFunc;
  switch (orientation) {
    case 'left':
      axisFunc = axisLeft(scale);
      break;
    case 'right':
      axisFunc = axisRight(scale);
      break;
    case 'top':
      axisFunc = axisTop(scale);
      break;
    case 'bottom':
    default:
      axisFunc = axisBottom(scale);
      break;
  }

  // calculate tick spacings and sizes
  if (dataMax) {
    // this limits the number of ticks to 5 or 10 ticks per axis

    // +1 because we don't count the zero value
    var ticks = [props.ticks + 1]; 
    var yPosition = 5 - props.ticks;

    // need to account for -5 to 5, and 0 to 5 scenarios
    var segmentCount = (dataMax.min + dataMax.max) / props.ticks;

    for (var c = 0; c <= props.ticks; c++) {
      ticks[c] = yPosition * segmentCount;
      yPosition++;
    }
    
    axisFunc.tickValues(ticks);
  }
  else {
    // this "suggests" to D3 to generate this many ticks
    // often random numbers of ticks will appear =/
    axisFunc.ticks(props.ticks);
  }

  if (length > 0)
    axisFunc.tickSize(-length);

  // if custom styling is required for tick marks
  if (tickFormat)
    axisFunc.tickFormat(tickFormat);

  // apply axis calculations and create the actual ticks and labels
  var axisTicks =
    select("." + name)
      .call(axisFunc)
      .selectAll(".tick")
      .attr("class", "tick");

  // add class to every second axis line/text
  axisTicks
    .filter((d, i) => i & 1)
    .attr("class", "tick odd")
	.select('line')

  // make axis lines take up all available space
  if (dynamicStrokeWidth) {
    axisTicks
      .select('line') // background lines
      .style('stroke-width', contentHeight / (axisTicks.size() - 1));
  }

  // apply text offsets if we want to move the text, but not the "tick" visuals
  if (textOffset) {
    axisTicks
      .selectAll("text")
      .attr("x", textOffset.x)
      .attr("y", textOffset.y)
  }

  // Apply text coloring from theme
  axisTicks
	 .selectAll("text")
  	.style("fill", axisColor);

  // console.log(axisTransform);
  return (
    <g className={`axis ${name} ${className}`} {...axisTransform}>
      {props.children}
    </g>
  )
}

const AxisLabel = (props) => {
  const { name, position, text, visible, color } = props;
  
  if (!visible) return null;

  // reposition the label
  const labelTransform = {
    transform: position
      ? `translate(${position.x},${position.y})`
      : ''
  };

  // console.log(labelTransform);

  return (
    <text className={`axis-label ${name}`} {...labelTransform} style={{fill: color}}>
      {text}
    </text>
  );
}

export { Axis, AxisLabel };
