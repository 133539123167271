import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon" 
        className="weather-icon" 
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M354.59,155.51a13.25,13.25,0,0,0-13.19-6.45L229.75,163.89,280.17,20A13.25,13.25,0,0,0,257.25,7.41L67.19,249.29a13.26,13.26,0,0,0,12.17,21.33l101.23-13.45L147.34,402.05a13.25,13.25,0,0,0,7.33,15,13.25,13.25,0,0,0,16.17-4L353.73,170.16A13.25,13.25,0,0,0,354.59,155.51ZM187.08,347.37,210.7,244.48A13.25,13.25,0,0,0,196,228.38l-87.88,11.68L228.76,86.57l-31.15,88.92A13.24,13.24,0,0,0,211.86,193l101.66-13.5Z" transform="translate(-64.36 -2.35)" />
  </g>
</svg>

)
}