
import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        aria-hidden="true"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }}
        focusable="false" 
        data-prefix="fas" 
        data-icon="info" 
        className="svg-inline--fa fa-info fa-w-6" 
        role="img" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 512 612">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path fill={color} d="M500.26,271.91a114.28,114.28,0,0,0-113.3,0,112.17,112.17,0,0,0-56.61,97V439c0,41.24-33.87,74.78-75.51,74.78s-75.5-33.54-75.5-74.78V387.57h4.71c36.44,0,66.08-29.31,66.08-65.34V285.85l2.84-1.22a112.84,112.84,0,0,0,49.42-41.27,111.1,111.1,0,0,0,18.52-61.26V135.4a18.39,18.39,0,0,0-5.51-13.16A18.88,18.88,0,0,0,302,116.77H250.13V18.64a18.88,18.88,0,0,0-37.76,0v98.13H108.55V18.64a18.88,18.88,0,0,0-37.76,0v98.13H18.88A18.78,18.78,0,0,0,0,135.4v46.71a111,111,0,0,0,18.53,61.25,112.76,112.76,0,0,0,49.41,41.27l2.85,1.22v36.38c0,36,29.64,65.34,66.07,65.34h4.72V439a112.18,112.18,0,0,0,56.61,97,114.28,114.28,0,0,0,113.3,0,112.19,112.19,0,0,0,56.62-97V368.93c0-41.23,33.87-74.77,75.5-74.77s75.51,33.54,75.51,74.77V439a18.88,18.88,0,0,0,37.76,0V368.93A112.17,112.17,0,0,0,500.26,271.91Zm-462.5-89.8V154h245.4v28.07c0,41.23-33.88,74.78-75.51,74.78H113.26C71.63,256.89,37.76,223.34,37.76,182.11Zm99.1,168.19a28.22,28.22,0,0,1-28.31-28.07V294.16H212.37v28.07a27.76,27.76,0,0,1-8.32,19.86,28.25,28.25,0,0,1-20,8.21Z" />
                </g>
            </g>
    </svg>
)
}