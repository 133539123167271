import './PowerHoursBanner.css';
import PowerHoursIcon from "../Icons/PowerHoursIcon";
import PowerHoursBannerLogo from "../Icons/PowerHoursBannerLogo";
import { NavLink, useNavigate } from 'react-router-dom';
import Analytics from '../../utils/Analytics';
import { useRef } from 'react';
import { useTheme } from '../../contexts/Theme';

export const PowerHoursBanner = ({ showLink, ...props }) => {
  const { theme } = useTheme(); 
  const navigate = useNavigate();

  return (
    <div
      className="power-hours-banner"
      onClick={() => {
        Analytics.event({
          category: "Power Hours",
          action: "Invitation Banner Click",
        });
        navigate("/powerhours");
      }}
      {...props}
	  style={{
        backgroundColor: theme.powerHoursBannerBackground.backgroundColor || "#f5f5f5", 
      }}
    >
      <div className="power-hours-icon-container"
	  >
        <PowerHoursBannerLogo />
      </div>
      {showLink && (
        <div className="power-hours-offer-button">View New Offer</div>
      )}
    </div>
  );
};