import React, { useState } from "react";
import Layout from "../../../../layouts/Layout.js";
import FullWidthLayout from "../../../../layouts/FullWidthLayout.js";
import { Form } from 'formsy-semantic-ui-react';
import SignupButton from "../../components/SignupButton/SignupButton.js";
import useScrollToTop from "../../../../utils/useScrollToTop.js";
import { useSwipeable } from "react-swipeable";
import { classNames } from '../../../../utils/classNames.js';
import plans from './SelectPlan/Plans.js';
import Plan from "./SelectPlan/Plan.js";
import "./SelectPlan/SelectPlan.css";
import SignupFullWidthLayout from "../../../../layouts/SignupFullWidthLayout.js";
import { EqualHeight } from 'react-equal-height'
import Responsive from "../../../../components/Responsive/Responsive.js";


export default ({ initialFormData, journey, tariffData, onSubmit }) => {

	useScrollToTop();

	const [canSubmit, setCanSubmit] = useState(plans.filter(plan => plan.id === initialFormData.tariff).length > 0);

	const [viewPlan, setViewPlan] = useState(0);
	const [selectedPlan, setSelectedPlan] = useState(initialFormData.tariff);


	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => setViewPlan(1),
		onSwipedRight: () => setViewPlan(0)
	});


	function submit(payload) {
		payload = {
			selectedTariff: selectedPlan
		}
		onSubmit(payload);
	}


	const planCards = (
		<div className="plan-wrapper">
			<div
				{...swipeHandlers}
				className={classNames((viewPlan === 1) && 'transition', 'plan-container')}
			>
				{plans.map((props) => (
					<Plan
						{...props}
						key={props.id}
						currentPlan={tariffData && tariffData.tariff}
						selectedPlan={selectedPlan}
						selectPlan={() => {
							setSelectedPlan(props.id);
							setCanSubmit(true);
						}}
					/>
				))}
			</div>
		</div>				
	)

	return <Layout
		layout={SignupFullWidthLayout}
		journey={journey}
		whitePage
		accountPicker={false}
	>
		<Form
			noValidate
			onValidSubmit={submit}
		>

			<p>You have the option of 2 plans, please select:</p>
			
			<EqualHeight updateOnChange={[tariffData && tariffData.tariff]}>
				<Responsive lessThan="computer" className="plans-mobile">
					{ planCards }
				</Responsive>
				<Responsive greaterThan="tablet" className="plans-desktop">
					{ planCards }
				</Responsive>
			</EqualHeight>

			<SignupButton disabled={!canSubmit} style={{
				maxWidth: '400px',
			}}>Continue</SignupButton>

		</Form>
	</Layout>	


}

