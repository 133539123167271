import React from 'react';
import { withCustomer } from '../../contexts/Customer';
import { Button, Image } from 'semantic-ui-react';

import Responsive from "../../components/Responsive/Responsive";
import ExternalLink from '../../components/ExternalLink';

import infographicDesktop from './assets/noproduct_infographic_desktop.svg';
import infographicMobile from './assets/noproduct_infographic_mobile.svg';
import logo from '../../static/aurora_plus_logo.svg'
import './NoProduct.css';

class NoProduct extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.interval = setInterval(() => window.location.reload(false), 900000);
  }

  renderInfographic() {
    return (
      <div className="infographic-container">
        <Responsive lessThan="computer">
          <img className="infographic-image" src={infographicMobile} alt="infographic" />
        </Responsive>
        <Responsive greaterThan="tablet">
          <img className="infographic-image" src={infographicDesktop} alt="infographic" />
        </Responsive>

        <div className="infographic-bottom-text">
          <p>
            If you have any questions, please contact us at <ExternalLink href="https://www.auroraenergy.com.au/">auroraenergy.com.au</ExternalLink>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { logoutUser } = this.props;

    return (
      <div className="interim-container">
        <div className="logout-button-container">
          <Button
            className="logout-button"
            onClick={logoutUser}>
            Logout
          </Button>
        </div>

        <h2 className="header">Welcome to</h2>
        <Image className="logo" src={logo} alt="Aurora+ logo"/>

        <div className="description-text">
          <p>Sit back, good things are happening. We're busy switching things on in the background.</p>
          <p>Below you can see where you are in the process.</p>
        </div>

        {this.renderInfographic()}

      </div>
    )
  }
}

export default withCustomer(NoProduct);
