import React, { useState, useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import './Greeting.css';

export default ({ customerName }) => {
  const [cachedName, setCachedName] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    // Function to check and update the name
    const checkAndUpdateName = () => {
      if (customerName) {
        // Update state and sessionStorage with the new customerName
        setCachedName(customerName);
        sessionStorage.setItem('customerName', customerName);
      } else {
        // Attempt to retrieve the name from sessionStorage
        const storedName = sessionStorage.getItem('customerName');
        if (storedName) {
          setCachedName(storedName);
        }
      }
      setIsVerified(true); // Name check is complete
    };

    checkAndUpdateName();
  }, [customerName]);

  // Only render the greeting after the name check is complete
  if (!isVerified) {
    return null; // Render nothing until the name is verified
  }

  return (
    <Header className="greeting">
      Hello
      <br />
      {cachedName}
    </Header>
  );
};