import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

// This is the instrumentationKey for the orange environment, 
//const instrumentationKey = '51e5a579-e532-49a3-aff6-da67507816e8';

// This will disable app insights for local/dev environments. The WFE release pipeline task of "Change App Environment" has a script that injects the PROD key into instrumentationKey
const instrumentationKey = 'disabledInstrumentationKey';


const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

if(!appInsights.config.instrumentationKey.startsWith("disabled"))
{	
	appInsights.loadAppInsights();
	appInsights.trackPageView();
}

export { reactPlugin, appInsights };

