import moment from "moment";

function addBusinessDays(m, daysToAdd) {
    var cnt = 0;
    var newDate = moment(m);
    while (cnt < daysToAdd) {
        newDate = newDate.add('days', 1);
        if (newDate.weekday() != moment().day("Sunday").weekday() && newDate.weekday() != moment().day("Saturday").weekday()) {
            cnt = cnt + 1;
        }
    }
 
    return newDate;
}


export {
	addBusinessDays
}