import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Providers } from './contexts';
import { getAccessToken } from './contexts/Auth';
import App from './App';
import axios from 'axios';
import './utils/Analytics';
import Log from './utils/Log';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './AuthConfig';

axios.defaults.headers.common['authorization'] = getAccessToken();

const {NODE_ENV} = process.env

if (NODE_ENV === 'development') {
    localStorage.setItem('debug', 'app:*');
    Log.info('process.env', process.env)
}


export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<MsalProvider instance={msalInstance}>
		<Providers>
			<App />
		</Providers>
	</MsalProvider>
);

