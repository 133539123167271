import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { withRouter } from '../../utils/useRouter';
import { withCustomer } from '../../contexts/Customer';
import { withTheme } from '../../contexts/Theme';
import HomeIcon from '../Icons/HomeIcon';
import PowerHoursIcon from '../Icons/PowerHoursIcon';
import UsageIcon from '../Icons/UsageIcon';
import PredictIcon from '../Icons/PredictIcon';
import PayNowIcon from '../Icons/PayNowIcon';
import { PRODUCT_TYPE } from '../../utils/Types';
import './Navigation.css';

export class Navigation extends React.Component {
  
  renderMenuItems = () => {
    const { theme } = this.props.theme;
    const { pageConfig } = this.props.customer;
    const isBusiness = pageConfig && pageConfig.productName === PRODUCT_TYPE.BUSINESS;
    
    const residentialItems = [
      { icon: <HomeIcon />, url: "/", key: "home" },
      { icon: <UsageIcon />, url: "/usage", key: "usage" },
      { icon: <PowerHoursIcon />, url: "/powerhours", key: "powerhours" },
      { icon: <PredictIcon />, url: "/billprediction", key: "billprediction" },
      { icon: <PayNowIcon />, url: "/payment", key: "payment" } 
    ];

    const businessItems = [
      { icon: <HomeIcon />, url: "/", key: "home" },
      { icon: <UsageIcon />, url: "/usage", key: "usage" },
      { icon: <PredictIcon />, url: "/billprediction", key: "billprediction" },
      { icon: <PayNowIcon />, url: "/payment", key: "payment" } 
    ];

    const itemsToRender = isBusiness ? businessItems : residentialItems;

    return itemsToRender.map(item => (
      <Menu.Item 
        key={item.key} 
        as={NavLink} 
        to={item.url}
        style={{ color: window.location.pathname === item.url ? theme.navigation.active.iconColor : theme.navigation.iconColor }}
        activestyle={{ 
          backgroundColor: theme.navigation.active.background, 
          color: theme.navigation.active.iconColor 
        }}
      >
        {React.cloneElement(item.icon, {
          style: { color: window.location.pathname === item.url ? theme.navigation.active.iconColor : theme.navigation.iconColor }
        })}
      </Menu.Item>
    ));
  }

  render() {
    const { theme } = this.props.theme;
    const isResidential = this.props.customer.pageConfig.productName === PRODUCT_TYPE.RESIDENTIAL;

    return (
      <Menu
        fluid
        widths={isResidential ? 5 : 4}
        fixed="bottom"
        className="mobile-navigation"
        style={{ backgroundColor: theme.navigation.background, color: theme.navigation.color }}
      >
        {this.renderMenuItems()}
      </Menu>
    );
  }
}

export default withRouter(withCustomer(withTheme(Navigation)));
