import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupHR from '../components/SignupHR/SignupHR.js';
import { useNavigate, useParams } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import SignupTip from "../components/SignupTip/SignupTip.js";
import SignupTipButton from "../components/SignupTip/SignupTipButton.js";
import Property from "../../../components/Property/Property.js";
import { withCustomer } from "../../../contexts/Customer.js";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import SignupButton from "../components/SignupButton/SignupButton.js";
import SignupEstimation from "../components/SignupEstimation/SignupEstimation.js";
import { useExternalLink } from "../../../components/ExternalLink.js";
import SignupInProgress from "../components/SignupInProgress/SignupInProgress.js";


export default withCustomer(({ customer }) => {

	const navigate = useNavigate();
	const externalLink = useExternalLink();
	useScrollToTop();

	let { premisesId } = useParams();

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	const [premises, setPremises] = useState(null);
	
	useEffect(() => {
		if (customer.allPremises && customer.allPremises.length > 0 && premisesId) {
			let premisesFound = false;
			customer.allPremises.forEach((premises) => {
				if (premises.identifier === premisesId) {
					premisesFound =true;
					setPremises(premises);
				}
			})
			if (!premisesFound) navigate('/signup/property');
		}
	}, [customer.allPremises, premisesId])


	return (		
		<Layout
			layout={SignupFormLayout}
			journey={{ message: "Signup complete", journeyName: "signup", currentStep: 6 }}
			whitePage
			accountPicker={false}
			hideBackButton={true}
		>
			<Form
				noValidate
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				{ premises && <>
					<Property
						key={premises.identifier}
						premises={premises}
						completed
					/>
					<SignupHR light spaceBelow/>

					<SignupInProgress premises={premises} customer={customer.customer} />

				</>}


				<SignupButton as={Link} to="/signup/property">
					Sign up another property
				</SignupButton>

			</Form>

			{ (premises && !premises.inAppSignupLifeSupport
				&& customer.customer && !customer.customer.lifeSupport) && (
				<SignupTip>
					<p>If you haven’t heard anything a week past this date, please call us on 1300 13 2003 or</p>
					<SignupTipButton onClick={() => {
						externalLink('https://www.auroraenergy.com.au/general-enquiries-form');
					}}>Contact us here</SignupTipButton>
				</SignupTip>
			)}

			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

})


