import React, { useMemo } from "react";
import useScrollToTop from "../../../../utils/useScrollToTop.js";
import { useNavigate, useParams, useLocation } from "react-router";
import { FORM_STATE } from "../../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR, DUPLICATE_ERROR } from "../../components/SignupErrorModal/SignupErrorModal.js";
import { withCustomer } from "../../../../contexts/Customer.js";
import { useEffect } from "react";
import { useNavigationType, useSearchParams } from 'react-router-dom';
import { useSignup } from "../../../../contexts/Signup.js";
import LifeSupportPage from "./LifeSupportPage.js";
import SelectPlanPage from "./SelectPlanPage.js";
import AccessIssuesPage from "./AccessIssuesPage.js";
import ContactDetailsPage from "./ContactDetailsPage.js";
import { ActivatePropertyData } from "../ActivatePropertyHelper.js";
import { useTariff } from "../../../../contexts/Tariff.js";
import Analytics from "../../../../utils/Analytics.js";
	

export default withCustomer(({ customer }) => {

	const navigate = useNavigate();
	useScrollToTop();

	const navType = useNavigationType();
	const [searchParams, setSearchParams] = useSearchParams();

	const signup = useSignup();

	let { premisesId, step } = useParams();
	step = parseInt(step);
	let location = useLocation();
	

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(true);
	const [errorModal, setErrorModal] = useState(null);

	const [premises, setPremises] = useState(null);

	const tariffData = useTariff(premises);


	const[readyToSubmit, setReadyToSubmit] = useState(false);


	useScrollToTop()
  
	function showPage(page, replace) {
		setCanSubmit(true);
		setFormState(FORM_STATE.DEFAULT);

		let parts = location.pathname.split("/");

		if (parts.length === 0) {
			navigate('/signup/property');
			return;
		}

		const lastPart = parts[parts.length - 1];
		if (!isNaN(lastPart) && (lastPart !== "advanced-meter-booking")) parts.pop();
		navigate(parts.join("/") + "/" + page, { replace: replace });
	}


	let bookingFormData = useMemo(() => {
		return {
			tariff: null,
			life_support: null,
			type_of_life_support: null,
			mailing_address_differs: null,
			mailing_address: null,
			issues_accessing_property: null,
			dog: null,
			panel_inside: null,
			locked_gate: null,
			other: null,
			other_access_issue_description: null,
			contact_title_on_the_day: null,
			contact_first_name_on_the_day: null,
			contact_last_name_on_the_day: null,
			contact_number_on_the_day: null,
			...signup.bookingFormData
		}
	}, [signup.bookingFormData]);

	function updateFormData(data) {
		signup.set({
			bookingFormData: {
				...bookingFormData,
				...data
			}
		});
	}

	useEffect(() => {
		if (readyToSubmit) {			
			submit();
			setReadyToSubmit(false);
		}
	}, [readyToSubmit])

	useEffect(() => {
		if (!step) {
			showPage(1, true);
			return;
		}
	}, [step]);
	

	useEffect(() => {
		if (customer.allPremises && customer.allPremises.length > 0 && premisesId) {
			let premisesFound = false;
			customer.allPremises.forEach((premises) => {
				if (premises.identifier === premisesId) {
					premisesFound =true;
					setPremises(premises);
				}
			})
			if (!premisesFound) navigate('/signup/property');
		}
	}, [customer.allPremises, premisesId])

	

	const submit = (payload) => {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "customers/premise/activate";

		if (!premises) return;


		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);


		const data = bookingFormData;

		// Validate first

		//if (!data.selectedTariff) {
		//	return showPage(1);
		//}

		if (!data.life_support || (data.life_support === "yes" && !data.type_of_life_support)) {
			//return showPage(2);
			return showPage(1);
		}

		if (
			!data.issues_accessing_property
			|| (
				data.issues_accessing_property === "yes"
				&& !(data.dog || data.panel_inside || data.locked_gate || data.other)
			)
			|| (
				data.issues_accessing_property === "yes"
				&& data.other
				&& !data.other_access_issue_description
			)
		) {
			//return showPage(3);
			return showPage(2);
		}

		if (!data.use_current_contact_details
			|| (data.use_current_contact_details === "no"
				&& (
					!data.contact_first_name_on_the_day
					|| !data.contact_last_name_on_the_day
					|| !data.contact_number_on_the_day
					|| !data.contact_title_on_the_day
				)
			)
		) {
			//return showPage(4);
			return showPage(3);
		}


		const activatePropertyData = new ActivatePropertyData(false, customer, premises, tariffData, data, signup);


		// Do submission
		axios
			.post(endpoint,
				activatePropertyData.getPayload()
			, { ignoreGlobalErrors: true})
			.then((response) => {
				setFormState(FORM_STATE.DONE_LOADING);
				
				customer.refresh();

				Analytics.event({ category: "Signup", action: "FormSubmittedBasic" });

				navigate("/signup/property/" + premises.identifier + "/complete");
				
			})
			.catch((error) => {

				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				//Analytics.event({ category: "User", action: "Failed " });

				switch(error.response ? error.response.status : error.code) {
					case 404:
						navigate('/signup/external/residential');
						break;
					case 409:
						setErrorModal(DUPLICATE_ERROR);
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;

				}
				

			})

	}	




	//let journey = (step === 1) ? { message: "Select a plan", journeyName: "signup", journeyName: "signup", currentStep: 3 }
	//: { message: "Advanced Meter Booking", journeyName: "signup", journeyName: "signup", currentStep: 4 }
	let journey = { message: "Advanced Meter Booking", journeyName: "signup", journeyName: "signup", currentStep: 4 }

	let page = null;
	switch(step) {
		//case 1:	page = <SelectPlanPage initialFormData={bookingFormData} journey={journey} tariffData={tariffData} onSubmit={(payload) => {updateFormData(payload); showPage(step + 1)}} />; break;
		case 1:	page = <LifeSupportPage initialFormData={bookingFormData} journey={journey} onSubmit={(payload) => {updateFormData(payload); showPage(step + 1)}} />; break;
		case 2:	page = <AccessIssuesPage initialFormData={bookingFormData} journey={journey} onSubmit={(payload) => {updateFormData(payload); showPage(step + 1)}} />; break;
		case 3:	page = <ContactDetailsPage initialFormData={bookingFormData} journey={journey} onSubmit={(payload) => {
			updateFormData(payload);
			setReadyToSubmit(true);
		}} formState={formState} canSubmitAll={canSubmit && customer.hasLoaded} />; break;
	}

	return <>
		{ page }

		<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>
	</>


})


