import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    count
}) {
  return (
    <React.Fragment>
      <svg
        height={height}
        width={width}
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M0 18.7216V16.8862H2.27597V8.70006C2.27597 7.16439 2.73177 5.79851 3.64337 4.60241C4.55499 3.4063 5.7511 2.64306 7.2317 2.31267V1.52342C7.2317 1.08904 7.38619 0.726537 7.69515 0.435911C8.00411 0.145304 8.37579 0 8.81019 0C9.24458 0 9.61626 0.145304 9.92523 0.435911C10.2342 0.726537 10.3887 1.08904 10.3887 1.52342V2.31267C11.8693 2.64306 13.0684 3.4063 13.9862 4.60241C14.9039 5.79851 15.3628 7.16439 15.3628 8.70006V16.8862H17.6204V18.7216H0ZM8.81019 22.0255C8.21673 22.0255 7.70127 21.8098 7.26381 21.3785C6.82636 20.9471 6.60764 20.4286 6.60764 19.8229H11.0127C11.0127 20.4286 10.7971 20.9471 10.3657 21.3785C9.9344 21.8098 9.41589 22.0255 8.81019 22.0255ZM4.11141 16.8862H13.5273V8.70006C13.5273 7.39076 13.0715 6.27418 12.1599 5.35034C11.2483 4.4265 10.1378 3.96458 8.82855 3.96458C7.51925 3.96458 6.40573 4.4265 5.488 5.35034C4.57028 6.27418 4.11141 7.39076 4.11141 8.70006V16.8862Z" />
      </svg>
      {count !== 0 && <span className="count">{count}</span>}
    </React.Fragment>
)
}
