
import React from 'react';

import './Property.css';
import Property from './Property';
import { Link } from 'react-router-dom';

export default ({ onClick, to, ...props }) => {	
	return onClick ? (
		<Link
			className="property-link"
			to={to}
			onClick={onClick}
		>
			<Property {...props} />
		</Link>
	) : (
		<div className="property-link">
			<Property {...props} />
		</div>
	);
}

