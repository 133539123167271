import React, { useState } from 'react'

import { Radio } from 'semantic-ui-react'

import SolarHeaderInfoIcon from '../../../components/Icons/InfoSolidFA'

import './SolarHeader.css'

function SolarHeader(props) {

    const [title] = useState("Solar")

    return (
        <div className="SolarHeader">
            <div className="SolarHeaderToggle">
                <div className={`SolarHeaderToggleTitle ${props.toggleVal ? "checked" : ""}`} onClick={() => props.toggle()}>{title}</div>
                <Radio 
                    toggle 
                    className="SolarToggle"
                    checked={props.toggleVal} 
                    onClick={() => props.toggle()}
                />
            </div>
            <div className="SolarHeaderInfo" onClick={() => props.solarModal()}>
                <div className="SolarHeaderInfoIcon">
                    <SolarHeaderInfoIcon />
                </div>
                <div className="SolarHeaderInfoText">
                    <p>Learn how Solar impacts your bill</p>
                </div>
            </div>
        </div>
    )

}

export default SolarHeader