import Urls from './Urls';

const moment = require('moment');

const COLORS = {
  peakColor: "#0060ae",
  offPeakColor: "#70cde2",
  shoulderColor: "#A1B0C4",
  solarColor: "#faa63d"
}

const TYPES = {
  flat: "Flat",
  timeOfUse: "TimeOfUse",
  stepped: "Stepped",
  solar: "Solar",
}

// TO ALL FUTURE DEVS, MAKE SURE TARIFFS ARE IN NUMBERED ORDER

const TARIFFS = (() => {

  let isDaylightSaving = moment(new Date()).isDST();
  // Update appropriate modal contents for T93PEAK, T93OFFPEAK, T94PEAK, T94OFFPEAK, T94SHOULDER based on daylight saving time
  const t93PeakModal = isDaylightSaving
    ? {
      Template: 'list',
      Content: [
        'Use less in Peak times* to save money:',
        'Monday to Friday – 8 am to 11 am and 5 pm to 10 pm'
      ],
      Disclaimer: '* These times will change when daylight savings ends.'
    }
    : {
      Template: 'list',
      Content: [
        'Use less in Peak times* to save money:',
        'Monday to Friday – 7 am to 10 am and 4 pm to 9 pm'
      ],
      Disclaimer: '* These times will change when daylight savings begins.'
    };

  const t93OffPeakModal = isDaylightSaving
    ? {
      Template: 'list',
      Title: 'Off-Peak',
      Content: [
        'Try to do more in Off-Peak times* to save money:',
        'Monday to Friday – 11 am to 5 pm and 10 pm to 8 am',
        'All weekend.'
      ],
      Disclaimer: '* These times will change when daylight savings ends.'
    }
    : {
      Template: 'list',
      Title: 'Off-Peak',
      Content: [
        'Try to do more in Off-Peak times* to save money:',
        'Monday to Friday – 10 am to 4 pm and 9 pm to 7 am',
        'All weekend.'
      ],
      Disclaimer: '* These times will change when daylight savings begins.'
    };

  const t94PeakModal =  isDaylightSaving
    ? {
      Template: "list",
      Content: [
        "Use less in Peak times* to save money:",
        "Monday to Friday – 8 am to 11 pm"
      ],
      Disclaimer: "*These times will change when daylight savings ends.",
      Link: Urls.BUSINESS_USAGE_LEARN_MORE,
    }
    : {
      Template: "list",
      Content: [
        "Use less in Peak times* to save money:",
        "Monday to Friday – 7 am to 10 pm"
      ],
      Disclaimer: "*These times will change when daylight savings begins.",
      Link: Urls.BUSINESS_USAGE_LEARN_MORE,
    };

  const t94OffPeakModal =  isDaylightSaving
    ? {
      Template: "list",
      Title: "Off-Peak",
      Content: [
        "Try to do more in Off-Peak times* to save money:",
        "Monday to Sunday – 11 pm to 8 am"
      ],
      Disclaimer: "*These times will change when daylight savings ends.",
      Link: Urls.BUSINESS_USAGE_LEARN_MORE,
    }
    : {
      Template: "list",
      Title: "Off-Peak",
      Content: [
        "Try to do more in Off-Peak times* to save money:",
        "Monday to Sunday – 10 pm to 7 am"
      ],
      Disclaimer: "*These times will change when daylight savings begins.",
      Link: Urls.BUSINESS_USAGE_LEARN_MORE,
    };

  const t94ShoulderModal =  isDaylightSaving
    ? {
      Template: "list",
      Title: "Shoulder",
      Content: [
        "Try to do more in Shoulder times* to save money:",
        "Saturday to Sunday – 8 am to 11 pm"
      ],
      Disclaimer: "*These times will change when daylight savings ends.",
      Link: Urls.BUSINESS_USAGE_LEARN_MORE,
    }
    : {
      Template: "list",
      Title: "Shoulder",
      Content: [
        "Try to do more in Shoulder times* to save money:",
        "Saturday to Sunday – 7 am to 10 pm"
      ],
      Disclaimer: "*These times will change when daylight savings begins.",
      Link: Urls.BUSINESS_USAGE_LEARN_MORE,
    };
  return {
    T22: {
      Type: TYPES.stepped,
      CustomerSegment: "Business",
      TariffName: "Tariff 22",
      MarketName: "TAS22",
      DisplayName: "Light & Power",
      DisplayNameMobile: "L/P",
      color: COLORS.peakColor,
      Modal: {
        Template: "paragraph", // see Utils/Modals.js for a list of templates
        Content: [
          "This is the energy you are using for lighting and general power, including heating and hot water. It is charged at a flat rate throughout both day and night, with the rate reducing after the first 500 kWh you use each quarter."
        ],
        Link: Urls.BUSINESS_PRODUCTS
      }
    },
    T31: {
      Type: TYPES.flat,
      CustomerSegment: "Residential",
      TariffName: "Tariff 31",
      MarketName: "TAS31",
      DisplayName: "Light & Power",
      DisplayNameMobile: "L/P",
      color: COLORS.peakColor,
      Modal: {
        Template: "paragraph", // see Utils/Modals.js for a list of templates
        Content: [
          "This is the energy you are using for lighting and general power. It is charged at a flat rate throughout both day and night."
        ]
      }
    },
    T41: {
      Type: TYPES.flat,
      CustomerSegment: "Residential",
      TariffName: "Tariff 41",
      MarketName: "TAS41",
      DisplayName: "Heating & Hot Water",
      DisplayNameMobile: "H/HW",
      color: COLORS.offPeakColor,
      Modal: {
        Template: "paragraph",
        Content: [
          "This is the energy you are using for heating and/or hot water. It is charged at a flat rate throughout both day and night."
        ]
      }
    },
    T93PEAK: {
      Type: TYPES.timeOfUse,
      CustomerSegment: "Residential",
      TariffName: "Tariff 93",
      MarketName: "TAS93",
      DisplayName: "Peak",
      DisplayNameMobile: "Peak",
      color: COLORS.peakColor,
      Modal: {
        ...t93PeakModal
      }
    },
    T93OFFPEAK: {
      Type: TYPES.timeOfUse,
      CustomerSegment: "Residential",
      TariffName: "Tariff 93",
      MarketName: "TAS93",
      DisplayName: "Off Peak",
      DisplayNameMobile: "Off Peak",
      color: COLORS.offPeakColor,
      Modal: {
        ...t93OffPeakModal
      }
    },
    T110: {
      Type: TYPES.solar,
      CustomerSegment: "Residential",
      TariffName: "Tariff 110",
      MarketName: "TAS110",
      DisplayName: "Solar Export",
      DisplayNameMobile: "S/E",
      isSolar: true,
      color: COLORS.solarColor,
      Modal: {
        Template: "paragraph",
        KilowattHourUsage: [
          "This is the excess solar energy that you have not used that you have sold back to Aurora."
        ],
        DollarValueUsage: [
          "This is the credit you have earned from selling your excess energy back to Aurora."
        ]
      }
    },
    T140: {
      Type: TYPES.solar,
      CustomerSegment: "Residential",
      TariffName: "Tariff 140",
      MarketName: "TAS140",
      DisplayName: "Solar Export",
      DisplayNameMobile: "S/E",
      isSolar: true,
      color: COLORS.solarColor,
      Modal: {
        Template: "paragraph",
        KilowattHourUsage: [
          "This is the excess solar energy that you have not used that you have sold back to Aurora."
        ],
        DollarValueUsage: [
          "This is the credit you have earned from selling your excess energy back to Aurora."
        ],
        Link: Urls.SOLAR_LEARN_MORE_LINK,
      }
    },
    T160: {
      Type: TYPES.solar,
      CustomerSegment: "Residential",
      TariffName: "Tariff 160",
      MarketName: "TAS160",
      DisplayName: "Solar",
      DisplayNameMobile: "Solar",
      isSolar: true,
      color: COLORS.solarColor,
      Modal: {
        Template: "paragraph",
        KilowattHourUsage: [
          "This is the excess solar energy that you have not used that you have sold back to Aurora."
        ],
        DollarValueUsage: [
          "This is the credit you have earned from selling your excess energy back to Aurora."
        ],
        Link: Urls.SOLAR_LEARN_MORE_LINK,
      }
    },
    T120: {
      Type: TYPES.solar,
      CustomerSegment: "Business",
      TariffName: "Tariff 120",
      MarketName: "TAS120",
      DisplayName: "Solar Export",
      DisplayNameMobile: "S/E",
      isSolar: true,
      color: COLORS.solarColor,
      Modal: {
        Template: "paragraph",
        KilowattHourUsage: [
          "This is the excess solar energy that you have not used that you have sold back to Aurora."
        ],
        DollarValueUsage: [
          "This is the credit you have earned from selling your excess energy back to Aurora."
        ]
      }
    },
    T150: {
      Type: TYPES.solar,
      CustomerSegment: "Business",
      TariffName: "Tariff 150",
      MarketName: "TAS150",
      DisplayName: "Solar Export",
      DisplayNameMobile: "S/E",
      isSolar: true,
      color: COLORS.solarColor,
      Modal: {
        Template: "paragraph",
        KilowattHourUsage: [
          "This is the excess solar energy that you have not used that you have sold back to Aurora."
        ],
        DollarValueUsage: [
          "This is the credit you have earned from selling your excess energy back to Aurora."
        ]
      }
    },
    T94PEAK: {
      Type: TYPES.timeOfUse,
      CustomerSegment: "Business",
      TariffName: "Tariff 94",
      MarketName: "TAS94",
      DisplayName: "Peak",
      DisplayNameMobile: "Peak",
      color: COLORS.peakColor,
      Modal: {
        ...t94PeakModal
      }
    },
    T94OFFPEAK: {
      Type: TYPES.timeOfUse,
      CustomerSegment: "Business",
      TariffName: "Tariff 94",
      MarketName: "TAS94",
      DisplayName: "Off Peak",
      DisplayNameMobile: "Off Peak",
      color: COLORS.offPeakColor,
      Modal: {
        ...t94OffPeakModal
      }
    },
    T94SHOULDER: {
      Type: TYPES.timeOfUse,
      CustomerSegment: "Business",
      TariffName: "Tariff 94",
      MarketName: "TAS94",
      DisplayName: "Shoulder",
      DisplayNameMobile: "Shoulder",
      color: COLORS.shoulderColor,
      Modal: {
        ...t94ShoulderModal
      }
    }
  };
})();

const catchAll = (tarrif) => {
  return {
    CustomerSegment: "",
    TariffName: tarrif,
    MarketName: tarrif,
    DisplayName: tarrif,
    DisplayNameMobile: tarrif,
    color: "#FF69B4"
  }
}

const tariffSearch = (tariff) => {
  if (TARIFFS[tariff]) {
    return TARIFFS[tariff]
  } else {
    return catchAll(tariff)
  }
}

const solarCheck = (tariff) => {
  return tariffSearch(tariff).isSolar
}

const nonSolarTariffTypes = (tariffTypes) => {
  return tariffTypes.filter(tariff => !solarCheck(tariff));
}

export {
  COLORS,
  TYPES,
  TARIFFS,
  solarCheck,
  tariffSearch,
  nonSolarTariffTypes,
}
