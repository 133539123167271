import React, { useMemo } from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import Property from "../../../components/Property/Property.js";
import { useState } from "react";
import { Accordion, Loader } from "semantic-ui-react";
import { withCustomer } from "../../../contexts/Customer.js";
import useClassifiedPremises from "../../../hooks/useClassifiedPremises.js";
import SignupEstimation from "../components/SignupEstimation/SignupEstimation.js";
import './PendingInAppSignup.css';
import { Link } from "react-router-dom";
import SignupTip from "../components/SignupTip/SignupTip.js";
import SignupTipButton from "../components/SignupTip/SignupTipButton.js";
import { useExternalLink } from "../../../components/ExternalLink.js";
import SignupInProgress from "../components/SignupInProgress/SignupInProgress.js";
import { useEffect } from "react";
import Analytics from "../../../utils/Analytics.js";


export default withCustomer(({ customer }) => {

	const externalLink = useExternalLink();
	useScrollToTop();

	const [isLoading, setIsLoading] = useState(false);

	const [signedUpPremises, inProgressPremises, notSignedUpPremises] = useClassifiedPremises(customer.allPremises);

	useEffect(() => {
		Analytics.event({ category: "Signup", action: "PendingRequests" });
	}, [])
	
	const panels = useMemo(() => {
		const hasMultiple = (inProgressPremises.length > 1);

		return inProgressPremises ?
			inProgressPremises.map((premises, index) => {
				const entry = {
					key: "premises-" + (index + 1),
					title: (
						<Accordion.Title>
							<Property
								premises={premises}
								hasExpander={hasMultiple}
								advancedMeterRequired={!premises.hasAdvancedMeter}
							/>
						</Accordion.Title>
					),
					content: (
						<Accordion.Content>	
							<SignupInProgress premises={premises} customer={customer.customer} />
						</Accordion.Content>
					),
				}
				if (!hasMultiple) entry.active = true;
				return entry;
			})
		: null;
	}, [inProgressPremises]);


	return (
		<Layout
			whitePage
			hideBackButton
			pageTitle={null}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Pending requests" }}
			onPullDownRefresh={() => {
				setIsLoading(true);
				customer
					.refresh()
					.finally(() => {
						setIsLoading(false);
					})
	  
			}}
		>

			{ isLoading ?
				<Loader
					inverted
					active={isLoading}
					size="medium"
					className="home-loader"
				/>
			:
				<>
					<Accordion className="property-accordion" panels={panels} />

					<SignupButton as={Link} to="/signup/property">
						Sign up another property
					</SignupButton>

					<SignupTip>
						<p>If you haven’t heard anything a week past this date, please call us on 1300 13 2003 or</p>
						<SignupTipButton onClick={() => {
							externalLink('https://www.auroraenergy.com.au/general-enquiries-form');
						}}>Contact us here</SignupTipButton>
					</SignupTip>

				</>
			}

		</Layout>
	);

});
