import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon" 
        className="weather-icon" 
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color} transform={`translate(0,25)`}>
    <path d="M77,87.63a100.3,100.3,0,0,0,24.63-3.14c.35-.06.69-.13,1-.22a79.29,79.29,0,0,0,15-5.92c6.84-3.32,12.24-5.94,25.8-5.94s18.95,2.62,25.79,5.94a79.68,79.68,0,0,0,15,5.92,9,9,0,0,0,1,.21A99.48,99.48,0,0,0,210,87.63a100.38,100.38,0,0,0,24.63-3.14c.34-.06.69-.13,1-.22a79.68,79.68,0,0,0,15-5.92c6.84-3.32,12.24-5.94,25.79-5.94s19,2.62,25.8,5.94a79.29,79.29,0,0,0,15,5.92,9,9,0,0,0,1,.21A99.56,99.56,0,0,0,343,87.63c5.83,0,10.56-5.4,10.56-12.07S348.79,63.49,343,63.49a79.71,79.71,0,0,1-20.51-2.7,9,9,0,0,0-1.34-.31,64.65,64.65,0,0,1-10.58-4.35c-7.59-3.69-16.2-7.87-34-7.87s-26.45,4.18-34,7.86a63.4,63.4,0,0,1-10.59,4.36,9.93,9.93,0,0,0-1.31.3A79.5,79.5,0,0,1,210,63.49a79.61,79.61,0,0,1-20.51-2.7,9.39,9.39,0,0,0-1.35-.31,63.78,63.78,0,0,1-10.58-4.36c-7.59-3.68-16.19-7.86-34-7.86s-26.45,4.18-34.05,7.86a62.71,62.71,0,0,1-10.59,4.36,10.3,10.3,0,0,0-1.31.3A79.4,79.4,0,0,1,77,63.49c-5.82,0-10.55,5.41-10.55,12.07S71.21,87.63,77,87.63Z" transform="translate(0 -48.26)" />
    <path d="M77,124a82.52,82.52,0,0,1,17.52,2c1.81.41,2.93.73,3,.77a9.35,9.35,0,0,0,1.25.28,63.91,63.91,0,0,1,10.62,4.37c7.6,3.68,16.2,7.86,34.05,7.86s26.45-4.18,34-7.86A64.5,64.5,0,0,1,188.19,127a10.38,10.38,0,0,0,1.23-.27c.15-.05,1.28-.37,2.57-.69.17,0,.57-.11.73-.14A81.63,81.63,0,0,1,210,124a82.6,82.6,0,0,1,17.52,2c1.81.41,2.92.73,3,.77a10.74,10.74,0,0,0,1.25.28,64.38,64.38,0,0,1,10.63,4.37c7.59,3.68,16.19,7.86,34,7.86s26.45-4.18,34-7.86A64.14,64.14,0,0,1,321.16,127a10.89,10.89,0,0,0,1.23-.28c.15,0,1.27-.36,2.56-.68.17,0,.57-.11.74-.14A81.52,81.52,0,0,1,343,124c5.83,0,10.56-5.4,10.56-12.07S348.79,99.89,343,99.89a101.08,101.08,0,0,0-21.54,2.39c-1.32.3-2.38.57-3.13.77-.33,0-.66.12-1,.2a80,80,0,0,0-15,5.92c-6.83,3.33-12.24,6-25.79,6s-19-2.62-25.8-6a79.29,79.29,0,0,0-15-5.92c-.33-.08-.67-.15-1-.2-.78-.21-1.93-.5-3.43-.84a98.28,98.28,0,0,0-42.77.07c-1.31.3-2.37.57-3.12.77-.33,0-.66.12-1,.2a79.68,79.68,0,0,0-15,5.92c-6.84,3.33-12.24,6-25.79,6s-19-2.62-25.8-6a79.83,79.83,0,0,0-15-5.92c-.34-.08-.67-.15-1-.2-.78-.21-1.93-.5-3.42-.84A100.84,100.84,0,0,0,77,99.89c-5.82,0-10.55,5.4-10.55,12.07S71.21,124,77,124Z" transform="translate(0 -48.26)" />
    <path d="M10.55,205.16A99.64,99.64,0,0,0,35.18,202c.35,0,.69-.13,1-.21a79.83,79.83,0,0,0,15-5.92c6.83-3.32,12.24-5.94,25.79-5.94s19,2.62,25.8,5.94a79.83,79.83,0,0,0,15,5.92c.35.08.69.16,1,.21a97.91,97.91,0,0,0,49.27,0c.34,0,.69-.13,1-.21a79.56,79.56,0,0,0,15-5.92c6.84-3.32,12.24-5.94,25.8-5.94s19,2.62,25.8,5.94a79.56,79.56,0,0,0,15,5.92c.34.08.68.16,1,.21a97.87,97.87,0,0,0,49.26,0c.34,0,.69-.13,1-.21a79.83,79.83,0,0,0,15-5.92c6.84-3.32,12.24-5.94,25.79-5.94s19,2.62,25.81,5.94a79.43,79.43,0,0,0,15,5.92c.35.08.69.16,1,.21a99.59,99.59,0,0,0,24.64,3.15c5.82,0,10.55-5.4,10.55-12.07S415.27,181,409.45,181a79.25,79.25,0,0,1-20.51-2.71,9.34,9.34,0,0,0-1.35-.3A65.54,65.54,0,0,1,377,173.66c-7.59-3.69-16.19-7.86-34.05-7.86s-26.45,4.17-34,7.86A65.66,65.66,0,0,1,298.33,178a9.89,9.89,0,0,0-1.31.29,78.94,78.94,0,0,1-41,0,9.34,9.34,0,0,0-1.35-.3A65.92,65.92,0,0,1,244,173.66c-7.59-3.69-16.19-7.86-34-7.86S183.55,170,176,173.66A65.54,65.54,0,0,1,165.37,178a10.4,10.4,0,0,0-1.32.29,78.94,78.94,0,0,1-41.05,0,8.93,8.93,0,0,0-1.34-.3,65.54,65.54,0,0,1-10.58-4.35c-7.59-3.69-16.2-7.86-34-7.86S50.58,170,43,173.66A64.77,64.77,0,0,1,32.4,178a9.89,9.89,0,0,0-1.31.29A79.14,79.14,0,0,1,10.55,181C4.73,181,0,186.42,0,193.09S4.73,205.16,10.55,205.16Z" transform="translate(0 -48.26)" />
    <path d="M409.45,216.13a99.56,99.56,0,0,0-24.68,3.16,8.68,8.68,0,0,0-1,.2,79.43,79.43,0,0,0-15,5.92c-6.84,3.32-12.25,5.94-25.81,5.94s-18.95-2.62-25.79-5.94a79.83,79.83,0,0,0-15-5.92c-.33-.08-.65-.15-1-.2-.79-.21-1.92-.5-3.37-.82a98.27,98.27,0,0,0-46,.81c-.33.06-.66.12-1,.21a79.56,79.56,0,0,0-15,5.92c-6.84,3.32-12.24,5.94-25.8,5.94s-19-2.62-25.8-5.94a79.56,79.56,0,0,0-15-5.92c-.32-.08-.65-.15-1-.2a98.15,98.15,0,0,0-49.37,0c-.33.06-.66.12-1,.21a79.83,79.83,0,0,0-15,5.92c-6.84,3.32-12.24,5.94-25.8,5.94s-19-2.62-25.79-5.94a79.83,79.83,0,0,0-15-5.92c-.33-.08-.65-.15-1-.2a99.51,99.51,0,0,0-24.69-3.16C4.73,216.13,0,221.53,0,228.2s4.73,12.07,10.55,12.07A79.54,79.54,0,0,1,31.07,243a9.78,9.78,0,0,0,1.3.3A64.62,64.62,0,0,1,43,247.63c7.59,3.69,16.19,7.86,34,7.86s26.46-4.17,34.05-7.86a65,65,0,0,1,10.64-4.37A9.49,9.49,0,0,0,123,243,79.26,79.26,0,0,1,164,243a9.64,9.64,0,0,0,1.29.3A63.9,63.9,0,0,1,176,247.63c7.6,3.69,16.2,7.86,34.05,7.86s26.45-4.17,34-7.86a65.38,65.38,0,0,1,10.64-4.37,9.49,9.49,0,0,0,1.26-.28,79.4,79.4,0,0,1,20.54-2.71,82.37,82.37,0,0,1,17.4,1.92c1.86.41,3,.74,3.13.78a8.06,8.06,0,0,0,1.29.3,65.14,65.14,0,0,1,10.62,4.36c7.59,3.69,16.2,7.86,34,7.86s26.46-4.17,34.05-7.86a65,65,0,0,1,10.64-4.37,9.49,9.49,0,0,0,1.26-.28,79.31,79.31,0,0,1,20.54-2.71c5.82,0,10.55-5.4,10.55-12.07S415.27,216.13,409.45,216.13Z" transform="translate(0 -48.26)" />
    <path d="M343,295.93a79.44,79.44,0,0,1-20.51-2.71,10.36,10.36,0,0,0-1.34-.3,65.54,65.54,0,0,1-10.58-4.35c-7.59-3.69-16.2-7.87-34-7.87s-26.45,4.18-34,7.86a64.26,64.26,0,0,1-10.59,4.36,11.78,11.78,0,0,0-1.31.29,78.94,78.94,0,0,1-41,0,10.89,10.89,0,0,0-1.35-.3,64.65,64.65,0,0,1-10.58-4.36c-7.59-3.68-16.19-7.86-34-7.86s-26.45,4.18-34.05,7.86a63.55,63.55,0,0,1-10.59,4.36,12.3,12.3,0,0,0-1.31.29A79.14,79.14,0,0,1,77,295.93c-5.82,0-10.55,5.4-10.55,12.07S71.21,320.07,77,320.07a99.64,99.64,0,0,0,24.63-3.15,9.58,9.58,0,0,0,1-.21,79.91,79.91,0,0,0,15-5.93c6.84-3.32,12.24-5.94,25.8-5.94s18.95,2.62,25.79,5.94a80.32,80.32,0,0,0,15,5.93,9,9,0,0,0,1,.21,97.87,97.87,0,0,0,49.26,0,9.4,9.4,0,0,0,1-.21,80.32,80.32,0,0,0,15-5.93c6.84-3.32,12.24-5.94,25.79-5.94s19,2.62,25.8,5.94a79.91,79.91,0,0,0,15,5.93,9,9,0,0,0,1,.21A99.56,99.56,0,0,0,343,320.07c5.83,0,10.56-5.4,10.56-12.07S348.79,295.93,343,295.93Z" transform="translate(0 -48.26)" />
    <path d="M343,332.32a98.49,98.49,0,0,0-20.76,2.24c-.17,0-.58.11-.75.15-1.35.3-2.42.58-3.18.78a8.81,8.81,0,0,0-1,.2,80,80,0,0,0-15,5.92c-6.83,3.32-12.24,5.94-25.79,5.94s-19-2.62-25.79-5.94a79.68,79.68,0,0,0-15-5.92,9.07,9.07,0,0,0-1-.21c-.78-.21-1.93-.5-3.43-.83A100.51,100.51,0,0,0,210,332.32a98.4,98.4,0,0,0-20.76,2.24c-.18,0-.59.11-.76.15-1.34.3-2.41.58-3.17.78a6.69,6.69,0,0,0-1,.2,79.68,79.68,0,0,0-15,5.92c-6.84,3.32-12.24,5.94-25.79,5.94s-19-2.62-25.79-5.94a80,80,0,0,0-15-5.92,7.1,7.1,0,0,0-1-.2c-.8-.22-2-.51-3.45-.84A100.73,100.73,0,0,0,77,332.32c-5.82,0-10.55,5.4-10.55,12.07S71.21,356.46,77,356.46a82.33,82.33,0,0,1,17.55,2c1.76.39,2.85.71,3,.75a10,10,0,0,0,1.29.3,64,64,0,0,1,10.63,4.36c7.59,3.69,16.19,7.86,34,7.86s26.45-4.17,34-7.86a64.26,64.26,0,0,1,10.65-4.36,11.44,11.44,0,0,0,1.26-.29c.15-.05,1.26-.37,3.26-.82a79.41,79.41,0,0,1,34.8.06c1.81.41,2.92.72,3,.76a9.54,9.54,0,0,0,1.26.29,65.26,65.26,0,0,1,10.63,4.36c7.58,3.69,16.19,7.86,34,7.86s26.45-4.17,34-7.86a64.5,64.5,0,0,1,10.64-4.36,11.11,11.11,0,0,0,1.27-.29c.15-.05,1.26-.37,3.26-.82a81.52,81.52,0,0,1,17.27-1.9c5.83,0,10.56-5.4,10.56-12.07S348.79,332.32,343,332.32Z" transform="translate(0 -48.26)" />
  </g>
</svg>

)
}