import React, {Component} from 'react';
import {Button, Header} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import {FORM_STATE} from '../../utils/FormState';
// import moment from 'moment-timezone';
import ValidatedInput from '../../components/Forms/ValidatedInput';
import ValidatedDropdown from '../../components/Forms/ValidatedDropdown';
// import ConsentFormHelper from './ConsentFormHelper';
import MultipleAddressesModal from './MultipleAddressesModal.js';
import Analytics from '../../utils/Analytics';
import {
  ID_TYPES,
  // PHONE_TYPES,
  // MESSAGE_ALREADY_SUBMITTED,
  MESSAGE_UNABLE_TO_IDENTIFY,
  // FAILURE_MESSAGE_NO_PAYG_PREMISES,
  // CONTACT_STRING_INCORRECT,
  PAGE_NUMBERS,
} from './constants';

export default class IdentityPage extends Component {

  state = {
    idTypeOptions: [],
    canSubmit: false,
    formState: FORM_STATE.DEFAULT,
    identificationErrorMessage: MESSAGE_UNABLE_TO_IDENTIFY,

    // If identification fails, the fields used to identify the customer are marked
    // as invalid using this.
    markIdentificationFieldValid: true,

    // If we hit the maximum number of validation attempts, we send the user to the
    // callback page.
    customerValidationFailCount: 0,
    hidingLicenseId: false,
    licenseIdHeight: null
  }

  unknownLicenseId = () => {
     var hide = !this.state.hidingLicenseId
     this.setState({hidingLicenseId: hide})

     if(hide) {
       this.props.context.typeOfIDNumber = "N/A"
       this.setState({licenseIdHeight: 0, canSubmit: true})
     } else {
       this.props.context.typeOfIDNumber = ""
       this.setState({licenseIdHeight: null, typeOfIDNumber: "", canSubmit: false})
     }

     this.props.saveGlobalState({customerIdIsUnknown: hide})

  }


  /*
  componentDidMount() {
    this.dataHelper = new ConsentFormHelper()
  }
  */

  submit = () => {
    this.setState({formState: FORM_STATE.LOADING})
    this
      .validateCustomer()
      .then(res => {
        this.setState({formState: FORM_STATE.DONE_LOADING})
        if (res) {
          this
            .props
            .next();
        }
      })
  }

  /**
   * Attempt to validate the customer using the information provided.
   *
   * If they are successfully validated, and have only one address,
   * proceed to the next screen.
   *
   * If they are successfully validated, and have multiple addresses,
   * give them a list of their addresses to select from.
   *
   * If they are unsuccessfully validated enough times, proceed to
   * the callback screen.
   */
  validateCustomer = () => {

    return new Promise(resolve => {

      /*
       * CCB validation is now skipped due to defaulting to the 'dumb' form.
       * Commented out code to keep as similar as previous form
       */

      /*
      this
        .dataHelper
        .validateCustomer(this.props.context.lastName, moment(this.props.context.dateOfBirth, 'DD/MM/YYYY').format("YYYY-MM-DD"), this.props.context.typeOfID, this.props.context.typeOfIDNumber)
        .then(res => {

          if (res && !res.Message) {

            Analytics.event({
              category: 'New Consent Form',
              action: `Validate License Details - Success`,
              label: `Attempt: ${this.state.customerValidationFailCount + 1}`
            })

            let apaygProperties = res.Premises;
            let apaygUnconsentedProperties = res
              .Premises
              .filter(premise => {
                return premise.HasExplicitInformedConsent === false
              });

            // User has no properties that are/were APAYG
            if (apaygProperties === undefined || apaygProperties.length <= 0) {
              Analytics.event({category: 'New Consent Form', action: `Validate License Details - Success (No APAYG Properties)`})
              this
                .props
                .saveGlobalState({currentStep: 7, failureMessage: FAILURE_MESSAGE_NO_PAYG_PREMISES, contactMessage: CONTACT_STRING_INCORRECT});
              resolve(false);
              return;
            }

            if (!res.CustomerID) {
              Analytics.event({category: 'New Consent Form', action: `Validate License Details - Success (Already Submitted Consent)`})
              this.setState({markIdentificationFieldValid: false, identificationErrorMessage: MESSAGE_ALREADY_SUBMITTED});
              resolve(false);
              return;
            }

            // Add a blank phone number field
            res
              .PhoneNumbers
              .push({PhoneType: PHONE_TYPES[0].value, PhoneNumber: ''});

            this
              .props
              .saveGlobalState({
                customerIsUnverified: false,
                customerID: res.CustomerID,
                title: res.Title,
                firstName: res.FirstName,
                lastName: res.LastName,
                givenNames: res.GivenNames,
                email: res.EmailAddress,
                phoneNumbers: res.PhoneNumbers,
                address: (apaygUnconsentedProperties.length > 0)
                  ? apaygUnconsentedProperties[0].Address
                  : '',
                premise: (apaygUnconsentedProperties.length > 0)
                  ? apaygUnconsentedProperties[0]
                  : ''
              });

            // User has multiple Premises, and hasn't entered a property ID, we offer them a
            // list of Premises to select from.
            if (this.props.context.propertyID === '' && apaygUnconsentedProperties.length > 1) {
              this
                .props
                .saveGlobalState({
                  addressSelection: res
                    .Premises
                    .filter(premise => {
                      return premise.HasExplicitInformedConsent === false
                    }),
                  multipleAddresses: true
                })
              resolve(false);
              return;
            }

            resolve(true);

          } else {

            // Unable to validate customer.

            this.setState({
              customerValidationFailCount: this.state.customerValidationFailCount + 1,
              markIdentificationFieldValid: false,
              customerIsUnverified: true,
              identificationErrorMessage: MESSAGE_UNABLE_TO_IDENTIFY
            })

            */

            this.props.saveGlobalState({ 
              customerIsUnverified: true,
              unverifiedCustomerId: this.props.context.typeOfIDNumber
            })

            Analytics.event({category: 'New Consent Form', action: `Validate License Details - Failure`, label: `ID Type: ${this.props.context.typeOfID}`})

            /*if (this.state.customerValidationFailCount >= MAX_VALIDATION_ATTEMPTS) {
              this
                .props
                .saveGlobalState({currentStep: 7, failureMessage: FAILURE_MESSAGE_UNABLE_TO_IDENTIFY, contactMessage: CONTACT_STRING});
            }*/

            resolve(true);
     /*
          }
        })
     */
    })
  }

  // One of the fields used to identify the customer has been modified. Stop
  // marking them as invalid.
  identifierChanged = () => {
    this.setState({markIdentificationFieldValid: true})
  }

  // Called from within multiple addresses modal. Used to update Address on Global
  // state.
  addressChanged = (address) => {
    let newPremise = this
      .props
      .context
      .addressSelection
      .find(premise => {
        return premise.Address === address
      })
    this
      .props
      .saveGlobalState({address: address, premise: newPremise})
  }

  // Called from within multiple addresses modal. Used to close the modal and
  // progress the form.
  addressSaved = () => {
    this
      .props
      .saveGlobalState({multipleAddresses: false});
    this
      .props
      .next();
  }

  onFormValid = () => {
    this.setState({canSubmit: true})
  }
  onFormInvalid = () => {
    this.setState({canSubmit: false})
  }
  onChangeType = (e) => {
    this
      .props
      .saveGlobalState({typeOfID: e.target.value})
  }

  render() {
    const {currentStep} = this.props.context;
    const displayTypeIDPlaceholderText = ID_TYPES.find(idType => {
      return idType.key === this.props.context.typeOfID
    });

    if (currentStep !== PAGE_NUMBERS.IDENTITY) {
      return null;
    }

    return (

      <Form
        onChange={this.props.change}
        noValidate
        onValidSubmit={this.submit}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}
        loading={this.state.formState === FORM_STATE.LOADING}>

        <Header textAlign="center" size="large">
          Your details
        </Header>

        <p>Please choose and provide one form of identification</p>

        <ValidatedDropdown
          options={this.props.context.idTypeOptions}
          value={this.props.context.typeOfID}
          hideErrorMessage
          onChange={this.identifierChanged}
          validations={{
            "isParameterTrue": this.state.markIdentificationFieldValid
          }}
          name="typeOfID"/>


        {/* ID Number */}
        <ValidatedInput
          style={{
            overflow: 'hidden',
            height: this.state.licenseIdHeight
          }}
          required
          validations={{
            "maxLength": 100,
            "isParameterTrue": this.state.markIdentificationFieldValid
          }}
          validationErrors={{
            isParameterTrue: this.state.identificationErrorMessage
          }}
          name="typeOfIDNumber"
          placeholder={displayTypeIDPlaceholderText.text}
          value={this.props.context.typeOfIDNumber}
          onChange={this.identifierChanged}
          errorAlign={'left'}/>

        {/* Multiple Addresses Modal */}
        <MultipleAddressesModal
          change={this.props.change}
          next={this.props.next}
          context={this.props.context}
          modalOpen={this.props.context.multipleAddresses}
          addressChanged={this.addressChanged}
          addressSaved={this.addressSaved}/> {/* 'Next' Button */}
        <Form.Field>
          <Button fluid type="submit" secondary disabled={!this.state.canSubmit}>Next</Button>
        </Form.Field>

        {/* 'Previous' Button */}
        <Button fluid basic onClick={this.props.prev}>Previous</Button>

      </Form>
    );
  }
}
