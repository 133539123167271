import React from 'react';
import axios from 'axios';
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Segment
} from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';

import Responsive from "../../components/Responsive/Responsive";
import _ from 'lodash';
import { FORM_STATE } from '../../utils/FormState';
import ValidatedInput from '../../components/Forms/ValidatedInput';
import ValidatedNumberFormatInput from '../../components/Forms/ValidatedNumberFormatInput';
import Layout from '../../layouts/Layout';
import { withRouter } from '../../utils/useRouter';
import { withApp } from '../../contexts/App';
import { withCustomer } from '../../contexts/Customer'
import { withTheme } from '../../contexts/Theme';
import ValidatedDropdown from '../../components/Forms/ValidatedDropdown';
import Modal from './../../components/Modal';
import Log from '../../utils/Log';
import Analytics from '../../utils/Analytics';

export class AccountDetailsEdit extends React.Component {

  contact = {
    unitType: '',
    unitNumber: '',
    streetNumber: '',
    floorNumber:'',
    streetName: '',
    streetType: '',
    suburb: '',
    postcode: '', 
    state: '',
    mobilePhoneNumber: '',
    homePhoneNumber: ''
  }
  prevMobilePhoneNumber = null;
  prevHomePhoneNumber = null;

  state = {
    modalConfirmationOpen: false,
    submitStateFieldErrors: {},
    submitStateFailMessage: '',
    formState: FORM_STATE.DEFAULT,
    fields: this.contact,
    processing: true,
    errors: {},
    editAddress: false,
    editPhoneNums: false
  }
 

  states = [
    {
      'value': '',
      'text': 'Select'
    }
  ]

  streetTypes = [
    {
      'value': '',
      'text': 'Select'
    }
  ]

  unitTypes = [
    {
      'value': '',
      'text': 'Select'
    }
  ]

  componentDidMount = () => {
    var pathName = window.location.href;
    if(pathName.includes("editAddress")){
      this.state.editAddress = true;
    }else{
      this.state.editPhoneNums = true;
    }

    if (this.props.customer && this.props.customer.customerPremise) {
      this.getContactDetails();
    }
  }

  componentDidUpdate = (nextProps) => {
    var pathName = window.location.href;
    if(pathName.includes("editAddress")){
      this.state.editAddress = true;
    }else{
      this.state.editPhoneNums = true;
    }

    if (this.props.customer && this.props.customer.customerPremise !== nextProps.customer.customerPremise) {
      this.getContactDetails();
    }
  }

  formatData = (contactData) => {
	  
	if (this.props.customer.isFromHubCX){
      return {
		...this.contact,
        mobilePhoneNumber: contactData.PhoneNumbers.MobilePhoneNumber,
        homePhoneNumber: contactData.PhoneNumbers.HomePhoneNumber
      }
    }
	
    return {
      displayName: contactData.PostalAddress.DisplayName,
      unitType: contactData.PostalAddress.UnitType,
      unitNumber: contactData.PostalAddress.UnitNumber,
      floorNumber:contactData.PostalAddress.FloorNumber,
      streetNumber: contactData.PostalAddress.StreetNumber,
      streetName: contactData.PostalAddress.StreetName,
      streetType: contactData.PostalAddress.StreetType,
      suburb: contactData.PostalAddress.Suburb,
      postcode: contactData.PostalAddress.Postcode,
      state: contactData.PostalAddress.State,
      mobilePhoneNumber: contactData.PhoneNumbers.MobilePhoneNumber,
      homePhoneNumber: contactData.PhoneNumbers.HomePhoneNumber
    }
  }

  getContactDetails = () => {
    const accountID = this.props.customer && this.props.customer.customerPremise && this.props.customer.customerPremise.parentAccountID;

    const getContact = () => {
      return axios.get(process.env.REACT_APP_API_BASE_URI + 'premises/contact?accountID=' + accountID);
    }

    const getContactDropdowns = () => {
      return axios.get(process.env.REACT_APP_API_BASE_URI + 'premises/contact/dropdowns');
    }

    this.setState({processing: true});
    axios.all([getContact(), getContactDropdowns()]).then(axios.spread((contact, dropdowns) => {
      this
        .streetTypes
        .push(...dropdowns.data.StreetTypes);
      this
        .unitTypes
        .push(...dropdowns.data.UnitTypes);
      this
        .states
        .push(...dropdowns.data.States);

      this.prevMobilePhoneNumber = contact.data.PhoneNumbers.MobilePhoneNumber;
      this.prevHomePhoneNumber = contact.data.PhoneNumbers.HomePhoneNumber;
      let fields = this.formatData(contact.data)
      this.setState({fields, mobilePhoneNumber: fields.mobilePhoneNumber, processing: false});
    })).catch((error) => {
      Log.log('error ', error);
    });
  }

  isEmptyOrNoChangePhoneNumber = (currentPhone, previousPhone) => {
    return (currentPhone === previousPhone) || (currentPhone === '' && previousPhone === null);
  }

  toBackendObject = (contactData) => {
    const homePhoneNumber = this.isEmptyOrNoChangePhoneNumber(contactData.homePhoneNumber, this.prevHomePhoneNumber)
      ? null
      : contactData
        .homePhoneNumber
        .replace(/^(.+) (.+) (.+)$/g, '$1$2-$3');
    const mobilePhoneNumber = this.isEmptyOrNoChangePhoneNumber(contactData.mobilePhoneNumber, this.prevMobilePhoneNumber)
      ? null
      : contactData
        .mobilePhoneNumber
        .replace(/^(.+) (.+) (.+)$/g, '$1-$2-$3');

    return {
      PostalAddress: this.state.editAddress ? {
        unitType: contactData.unitType,
        unitNumber: contactData.unitNumber,
        floorNumber:contactData.floorNumber,
        streetNumber: contactData.streetNumber,
        streetName: contactData.streetName,
        streetType: contactData.streetType,
        suburb: contactData.suburb,
        postcode: contactData.postcode,
        state: contactData.state
      } : null,
      PhoneNumbers: this.state.editPhoneNums ? {
        mobilePhoneNumber,
        homePhoneNumber
      } : null
    }
  }

  submit = (fields) => {
    const accountID = this.props.customer && this.props.customer.customerPremise && this.props.customer.customerPremise.parentAccountID;
    const endpoint = process.env.REACT_APP_API_BASE_URI + 'premises/contact?accountID=' + accountID;
    const payload = this.toBackendObject(fields);

    this.setState({processing: true});
    
    Analytics.event({category: 'Account', action: 'Account Details updated'})

    axios
      .post(endpoint, payload)
      .then(() => {
        this.openModal();
        this.setState({submitStateFailMessage: null, processing: false});
      })
      .catch((error) => {
        this.openModal();

		try {	// These mappings don't match the names of inputs
			this.refs.accountForm.updateInputsWithError(
			_.mapValues(error.response.data.ModelState, function (obj) { return obj[0]; }),
			true,
			);
		} catch(e) { } 

        this.setState({
          processing: false,
          submitStateFailMessage: (error.response.data && error.response.data.Message)
            ? error.response.data.Message
            : error.message || 'Oh No! Something went wrong'
        });
      });
  }

  openModal = () => {
    this.setState({modalConfirmationOpen: true});
  }

  closeModal = () => {
    if (this.state.submitStateFailMessage) {
      this.setState({modalConfirmationOpen: false});
    } else {
      this
        .props
        .router
        .navigate('/accountSettings');
    }
  }

  submitBtnText = () => {
    switch (this.state.formState) {
      case FORM_STATE.LOADING:
        return 'Saving Details';
      case FORM_STATE.DONE_LOADING:
        return 'Details Saved';
      default:
        return 'Save Details';
    }
  }

  onFormValid = () => {
    this.setState({canSubmit: true});
  }

  onFormInvalid = () => {
    this.setState({canSubmit: false});
  }

  render() {
    const { formState, submitStateFailMessage } = this.state;
    const { theme } = this.props.theme;
    const { pageConfig } = this.props.customer;

    return (
      <Layout pageTitle="Edit Contact Details">

        <Modal
          name={`account-details/edit/${submitStateFailMessage
          ? 'failure'
          : 'success'}`}
          size="small"
          open={this.state.modalConfirmationOpen}>
          <Modal.Header>{(submitStateFailMessage)
              ? 'Failed'
              : 'Contact Details Saved'}</Modal.Header>
          <Modal.Content>
            <Modal.Description>{(submitStateFailMessage)
                ? submitStateFailMessage
                : 'Your contact detail changes have been saved.'}</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              fluid
              secondary
              style={{ backgroundColor: theme.primary.backgroundColor }}
              onClick={this.closeModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Form
          ref="accountForm"
          noValidate
          onValidSubmit={this.submit}
          onValid={this.onFormValid}
          onInvalid={this.onFormInvalid}>
          <Responsive
            greaterThan="mobile"
            as={Header}
            textAlign="center"
            size="large"
            style={{
            marginBottom: '1.2em'
          }}>
            Edit Contact Details
          </Responsive>
          <Segment.Group>
            <Dimmer inverted active={this.state.processing}><Loader/></Dimmer>
            <Segment hidden ={!this.state.editAddress}>
              <p>
                <b>Postal Address Details</b>
              </p>
              <Form.Group widths='equal'>
                <ValidatedDropdown
                  search
                  selection
                  fluid
                  options={this.unitTypes}
                  label="Flat/Unit Type"
                  name="unitType"
                  placeholder="Select"
                  value={this.state.fields.unitType}/>
                <ValidatedInput
                  validations="maxLength:60"
                  value={this.state.fields.unitNumber}
                  //hideErrorMessage
                  fluid
                  name="unitNumber"
                  label="Flat/Unit No."/>

                  
              </Form.Group>
              <Form.Group widths='equal'>
              <ValidatedInput
                  validations="maxLength:60"
                  value={this.state.fields.floorNumber}
                  //hideErrorMessage
                  fluid
                  name="floorNumber"
                  label="Floor/Level No."/>

                <ValidatedInput
                  validations="maxLength:60"
                  value={this.state.fields.streetNumber}
                  //hideErrorMessage
                  fluid
                  name="streetNumber"
                  label="Street Number"/>
                </Form.Group>
                <Form.Group widths='equal'>
                  
                <ValidatedInput
                  validations="maxLength:60"
                  value={this.state.fields.streetName}
                  required = {this.state.editAddress ? true : false}
                  fluid
                  name="streetName"
                  label="Street Name"/>
              </Form.Group>
              {this.state.canSubmit === false && <small
                style={{
                textAlign: 'right',
                color: 'red',
                'padding': '0',
                'marginTop': '-1em',
                display: 'block'
              }}>Please provide a complete postal address.</small>}

              <ValidatedDropdown
                search
                selection
                options={this.streetTypes}
                label="Street Type"
                name="streetType"
                placeholder="Select"
                //hideErrorMessage
                value={this.state.fields.streetType}/>
              <ValidatedInput
                value={this.state.fields.suburb}
                validations="maxLength:60"
                fluid
                required = {this.state.editAddress ? true : false}
                name="suburb"
                label="Suburb"/>
              <Form.Group widths='equal'>
                <ValidatedInput
                  value={this.state.fields.postcode}
                  validations="maxLength:60"
                  required = {this.state.editAddress ? true : false}
                  fluid
                  autoComplete="postal-code"
                  name="postcode"
                  label="Postcode"/>
                <ValidatedDropdown
                  options={this.states}
                  name="state"
                  label="State"
                  placeholder="Select"
                  required = {this.state.editAddress ? true : false}
                  value={this.state.fields.state}/>
              </Form.Group>
            </Segment>
            <Segment hidden ={!this.state.editPhoneNums}>
              <p>
                <b>Phone Details</b>
              </p>
              <ValidatedNumberFormatInput
                //onValueChange={({value}) => this.setState({mobilePhoneNumber: value})}
                value={this.state.fields.mobilePhoneNumber}
                type="tel"
                hideErrorMessage
                noErrorProp
                format="#### ### ###"
                mask="_"
                name="mobilePhoneNumber"
                label="Mobile Number"/> {(!this.state.mobilePhoneNumber)
                ? <p>
                    <small>Please supply a mobile number to receive SMS notifications.</small>
                  </p>
                : null
}
              <ValidatedNumberFormatInput
                //onValueChange={({value}) => this.setState({homePhoneNumber: value})}
                value={this.state.fields.homePhoneNumber}
                type="tel"
                hideErrorMessage
                noErrorProp
                format="(##) #### ####"
                mask="_"
                name="homePhoneNumber"
                label={pageConfig && pageConfig.productName === 'business' ? 'Work Phone Number' : 'Home Phone Number'}/>
            </Segment>
          </Segment.Group>
          <Form.Field disabled={this.state.processing}>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              type="submit"
              fluid
              secondary 
              loading={formState === FORM_STATE.LOADING}
              className={(formState === FORM_STATE.DONE_LOADING
              ? 'done-loading'
              : '')}>
              {this.submitBtnText()}
            </Button>
          </Form.Field>
          <Button
            disabled={this.state.processing}
            onClick={e => this
            .props
            .router
            .navigate('/accountSettings')}
            fluid
            basic>Cancel</Button>
        </Form>
      </Layout>
    )
  }
}

export default withRouter(withApp(withCustomer(withTheme(AccountDetailsEdit))));
