import { useCallback, useContext, useEffect, useRef } from 'react';
import { AppContext, withApp } from '../contexts/App';
import MobileAppComms from '../utils/MobileAppComms';

export default withApp(({
	app,
	children,
	href,
	to,
	target,
	rel,
	eventLabel,
	onClick,
	...rest
}) => {

	let { isApp } = app;

	let url = href || to;

    return (
		<a
			onClick={(event) => {
				if (isApp) {
					event.preventDefault();
					MobileAppComms.postMessage(
						JSON.stringify({ OPEN_EXTERNAL_LINK: url })
					);
				}
				if (!!onClick) onClick();
			}}
			href={url}
			target={target || '_blank'}
			rel={rel || 'noopener noreferrer'}
			{...rest}
		>
			{children}
		</a>
    )
});



export function useExternalLink() {
  
	let { isApp } = useContext(AppContext)

	let activeRef = useRef(false);
	useEffect(() => {
	  activeRef.current = true;
	});
  
	let externalLink = useCallback((url) => {  
		if (!activeRef.current) return;

		/* TODO: check events are registered for anything that uses this
		ReactGA.outboundLink({ label: to }, () => {
			window.open(to, '_blank');		
		});	
		*/
		
		if (isApp) {
			MobileAppComms.postMessage(
				JSON.stringify({ OPEN_EXTERNAL_LINK: url })
			);
		} else {
			window.open(url, '_blank');
		}

	}, []);
  
	return externalLink;
  }


// TODO: Remove once app is refactored to functional components
export const withExternalLink = (Component) => (props) => {
	const externalLink = useExternalLink()
	
	return (
	  <Component
		{...props}
		externalLink={externalLink}
	  />
	)
}