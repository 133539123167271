import { RadioGroup } from "formsy-semantic-ui-react";

import './Property.css';

export default ({...props}) => {
	
	return <div className="property-radio-group">
		<RadioGroup {...props} /> 
	</div>

}