
const getPresentKeys = (keys, data) => {
	let presentKeys = [];
	for (let i = 0; i < keys.length; i++) {
		let key = keys[i];
		if (data[key] > 0) presentKeys.push(key);
	}
	return presentKeys;
}

const XaxisHandler = (key, type, isPeak, props, isSolar, data, timeRange) => {

  if (isSolar) {
	return props.xScale0.bandwidth() * 0.5
  } else {
	let unitData = data[props.unitOfMeasurement];
	if (unitData) {
		let nonSolarKeys = props.nonSolarKeys || props.keys;	
		let presentKeys = getPresentKeys(nonSolarKeys, data[props.unitOfMeasurement]);
		let numBars = presentKeys.length;
		if (numBars === 0) return 1;
		let index = presentKeys.indexOf(key);
		return props.xScale0.bandwidth() * (index + 0.5) / numBars;
	} else {
		return 1;
	}
  }

}

// Width Modification handler 

const WidthHandler = (key, type, isPeak, props, isSolar, data, timeRange) => {
  
  if (isSolar) {
	return props.xScale0.bandwidth() * 0.4;
  } else {
	let unitData = data[props.unitOfMeasurement];
	if (unitData) {
		let nonSolarKeys = props.nonSolarKeys || props.keys;	
		let presentKeys = getPresentKeys(nonSolarKeys, data[props.unitOfMeasurement]);
		let numBars = presentKeys.length;
		if (numBars === 0) return 1;
		return props.xScale0.bandwidth() * Math.min(0.4, 1.2 / numBars); // Just in case we get 4 bars above the line somehow
	} else {
		return 1;
	}
  }

}

export {
  XaxisHandler,
  WidthHandler
}
