export default ({ src, width, height, style, ...props }) => {

	const aspect = height / width;



	return <div
		className="embedded-video"
		style={{
			position: 'relative',
			paddingBottom: (aspect * 100) + '%',
			height: 0,
			overflow: 'hidden',
			maxWidth: '100%',
			...style
		}}
	>
		<iframe
			src={src}
			width={width}
			height={height}
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				border: 'none',
				outline: 'none',
				background: '#D9D9D9',
			}}
			{...props}
		/>
	</div>

}

