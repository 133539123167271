import React from "react";

import { withContexts } from "../../contexts";

import { useEffect } from "react";
import { Loader } from "semantic-ui-react";


export default withContexts(({ auth }) => {

	useEffect(() => {
		auth.initiateResetMfaPhone();
	});

	return (
		<Loader active />
	)

});

