import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
        height={height}
        width={width}
        viewBox="0 0 40 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="icon">
        <g transform="translate(4, 4)">
			<path fill={color} d="M2.5 5C1.673 5 1 5.673 1 6.5V24.5C1 25.327 1.673 26 2.5 26H29.5C30.327 26 31 25.327 31 24.5V6.5C31 5.673 30.327 5 29.5 5H2.5ZM2.5 6H29.5C29.7755 6 30 6.2245 30 6.5V10.1562L16.6143 17.1748C16.2233 17.3513 15.7747 17.352 15.4102 17.1895L2 10.1963V6.5C2 6.2245 2.2245 6 2.5 6ZM30 11.2852V24.5C30 24.7755 29.7755 25 29.5 25H2.5C2.2245 25 2 24.7755 2 24.5V11.3242L14.9746 18.0889C15.2961 18.2329 15.6408 18.3057 15.9893 18.3057C16.3468 18.3057 16.7087 18.2287 17.0527 18.0732L30 11.2852ZM3.5 22C3.224 22 3 22.224 3 22.5V23.5C3 23.776 3.224 24 3.5 24C3.776 24 4 23.776 4 23.5V22.5C4 22.224 3.776 22 3.5 22ZM6 22C5.724 22 5.5 22.224 5.5 22.5V23.5C5.5 23.776 5.724 24 6 24C6.276 24 6.5 23.776 6.5 23.5V22.5C6.5 22.224 6.276 22 6 22ZM8.5 22C8.224 22 8 22.224 8 22.5V23.5C8 23.776 8.224 24 8.5 24C8.776 24 9 23.776 9 23.5V22.5C9 22.224 8.776 22 8.5 22ZM11 22C10.724 22 10.5 22.224 10.5 22.5V23.5C10.5 23.776 10.724 24 11 24C11.276 24 11.5 23.776 11.5 23.5V22.5C11.5 22.224 11.276 22 11 22ZM13.5 22C13.224 22 13 22.224 13 22.5V23.5C13 23.776 13.224 24 13.5 24C13.776 24 14 23.776 14 23.5V22.5C14 22.224 13.776 22 13.5 22ZM16 22C15.724 22 15.5 22.224 15.5 22.5V23.5C15.5 23.776 15.724 24 16 24C16.276 24 16.5 23.776 16.5 23.5V22.5C16.5 22.224 16.276 22 16 22ZM18.5 22C18.224 22 18 22.224 18 22.5V23.5C18 23.776 18.224 24 18.5 24C18.776 24 19 23.776 19 23.5V22.5C19 22.224 18.776 22 18.5 22ZM21 22C20.724 22 20.5 22.224 20.5 22.5V23.5C20.5 23.776 20.724 24 21 24C21.276 24 21.5 23.776 21.5 23.5V22.5C21.5 22.224 21.276 22 21 22ZM23.5 22C23.224 22 23 22.224 23 22.5V23.5C23 23.776 23.224 24 23.5 24C23.776 24 24 23.776 24 23.5V22.5C24 22.224 23.776 22 23.5 22ZM26 22C25.724 22 25.5 22.224 25.5 22.5V23.5C25.5 23.776 25.724 24 26 24C26.276 24 26.5 23.776 26.5 23.5V22.5C26.5 22.224 26.276 22 26 22ZM28.5 22C28.224 22 28 22.224 28 22.5V23.5C28 23.776 28.224 24 28.5 24C28.776 24 29 23.776 29 23.5V22.5C29 22.224 28.776 22 28.5 22Z" />
		</g>
    </svg>
)
}


