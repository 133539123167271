export default class BankDetails {

  constructor(data) {
    this.bankName = data.BankName;
    this.branchNumber = data.BranchNumber;
    this.accountHolderName = data.AccountHolderName;
    this.bankAccountNumber = data.BankAccountNumber;
    this.paymentMethod = "DirectDebit";
  }
}
