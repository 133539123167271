import React from 'react'

import {List, Checkbox} from 'semantic-ui-react';
import WestpacAccountList from './../WestpacAccountList/WestpacAccountList';
import WestpacPayment from './../WestpacPayment/WestpacPayment';
import PaymentAccount from './../../model/PaymentAccount';

export default class PaymentMethod extends React.Component {
  state = {
    activeIndex: 0,
    formVisible: false,
    formSelected: false,
    accounts: false,
    selectedAccount: false,
    savePaymentInformation: false,
    processing: false
  }

  westpacPayment = null

  handleClick = (e, titleProps) => {
    const {index} = titleProps
    const {activeIndex} = this.state
    const newIndex = activeIndex === index
      ? -1
      : index

    this.setState({activeIndex: newIndex})
  }

  get canAddAnotherCard() {
    const { restrictedNavigation } = this.props;
    if (restrictedNavigation) return false;
    return (!this.state.accounts || this.state.accounts.length < 4)
  }

  get isAdhocPayment() {
    return this.state.formSelected;
  }
  get savePaymentInformation() {
    return this.state.savePaymentInformation;
  }

  async getPaymentAccount() {
    let {formSelected, selectedAccount} = this.state
    let form = this.westpacPayment

    return new Promise((resolve, reject) => {
      if (formSelected) {
        form.submit((err, token) => {
          if (err) {
            reject(err)
          } else {
            let paymentAccount = new PaymentAccount(token)
            resolve(paymentAccount)
          }
        })
      } else if (selectedAccount) {
        resolve(selectedAccount)
      } else {
        reject()
      }
    })

  }

  render() {
    const { formVisible, formSelected } = this.state

    return (
      <WestpacAccountList
        selectable
        selectDefaultAccount
        isFocused={!formSelected}
        canAddAnotherCard={this.canAddAnotherCard}
        addAnotherCardClicked={x => this.setState({formVisible: true, formSelected: true})}
        onSelect={x => {
          this.setState({formSelected: false, selectedAccount: x});
          this.props.onSelect && this
            .props
            .onSelect(x)
        }}
        onLoaded={accounts => {
          this.setState({
            accounts,
            formSelected: accounts.length === 0,
            formVisible: accounts.length === 0
          })
        }}
        onError={x => {
        this.setState({formSelected: true, formVisible: true})
      }}>
        {formVisible && <List.Item
          active={formSelected && this.state.accounts && this.state.accounts.length > 0}>

          <WestpacPayment
            basic
            ref={cmp => this.westpacPayment = cmp}
            disabled={this.props.disabled}
            customer={this.props.customer}
            onClick={x => {
              this.setState({formSelected: true})
            }}
            onChange={x => {
              this.setState({formSelected: true})
            }}
            onFocused={x => {
              this.setState({formSelected: true})
            }}
            onError={this.props.onError}>
          
            {this.canAddAnotherCard && <Checkbox
              label="Save Payment Details"
              onChange={(e, data) => this.setState({savePaymentInformation: data.checked})}
              checked={this.state.savePaymentInformation}/>}
          </WestpacPayment>
        </List.Item>}
      </WestpacAccountList>
    );
  }
}
