import React from 'react';

import { Navigate } from 'react-router-dom';
import { withApp} from '../contexts/App'
import { withRouter } from '../utils/useRouter.js'
/*
 * This is a redirect screen which replaces the token
 * with a token thats passed into the url
 */
export class AdminLogin extends React.Component {
  componentDidMount() {
    const { app, router } = this.props;
	if (app.loginUser && !app.isMirroring) {
    	app.loginUser(router.params.token);
    	app.set({isMirroring: true})
	}
  }
  componentDidUpdate() {
    const { app, router } = this.props;
	if (app.loginUser && !app.isMirroring) {
		app.loginUser(router.params.token);
		app.set({isMirroring: true})
	}
  }

  render() {
    const { app, router } = this.props;

	return app.isMirroring ?
		<Navigate to='/'/>
		: null

  }
}

export default withRouter(withApp(AdminLogin));
