import React from "react";
import MediaQuery from 'react-responsive';

export default function Responsive({ as, children, lessThan, greaterThan, ...props }) {
  const Component = as || 'div'
  let mq = { };
  if (greaterThan) {
	switch(greaterThan) {
		case "mobile": mq.minWidth = 768; break;
		case "tablet": mq.minWidth = 992; break;
		case "computer": mq.minWidth = 1200; break;
	}
  }
  if (lessThan) {
	switch(lessThan) {
		case "mobile": mq.maxWidth = 319; break;
		case "tablet": mq.maxWidth = 767; break;
		case "computer": mq.maxWidth = 991; break;
	}
  }

	return (    
	  <MediaQuery {...mq}>
		<Component {...props}>
			{children}
		</Component>
	  </MediaQuery>
  )
}

