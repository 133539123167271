import React, {Component} from 'react';

import {Button, Header, Radio} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import {FORM_STATE} from '../../utils/FormState';
import ValidatedInput from '../../components/Forms/ValidatedInput';
import ValidatedDropdown from '../../components/Forms/ValidatedDropdown';
import ValidatedInputWithSelect from '../../components/Forms/ValidatedInputWithSelect';
import {PHONE_TYPES_SPECIAL_INSTRUCTIONS, STATE_PROVINCES, MAILING_ADDRESS_TYPES, PHONE_REGEX, PAGE_NUMBERS} from './constants';
import ConsentFormHelper from './ConsentFormHelper';

export default class ResidenceQuestions extends Component {

  state = {
    streetTypes: [],
    showStreetAddress: true,
    showStreetType: true,
    showUnitNumber: true,
    numberText: 'Street Number'
  }

  submit = () => {
    this
      .props
      .next()
  }

  componentDidMount() {
    this.dataHelper = new ConsentFormHelper()
    this
      .dataHelper
      .getStreetTypes()
      .then(res => {
        this.setState({streetTypes: res.StreetTypes})
      })
  }

  updateHasInternalSwitches = (e, {value}) => {
    if (value === true) {
      this
        .props
        .saveGlobalState({hasSpecialInstructions: true})
    }
    this
      .props
      .saveGlobalState({hasInternalSwitches: value})
  }

  updateHasSpecialInstructions = (e, {value}) => {
    this
      .props
      .saveGlobalState({hasSpecialInstructions: value})
  }
  updateHasDog = (e, {value}) => {
    this
      .props
      .saveGlobalState({hasDog: value})
  }
  updateUseAddressForPostedCorrespondence = (e, {value}) => {
    this
      .props
      .saveGlobalState({useAddressForPostedCorrespondence: value})
  }
  updateReceiveBillsElectronically = (e, {value}) => {
    this
      .props
      .saveGlobalState({receiveBillsElectronically: value})
  }

  updatedPhoneNumber = (e, i) => {
    let phoneNumber = this.props.context.specialInstructionsPhone;
    let updatedPhoneNumber = {
      ...phoneNumber,
      PhoneNumber: e.target.value
    }
    this
      .props
      .saveGlobalState({specialInstructionsPhone: updatedPhoneNumber})
  }

  updatedPhoneType = (e, i) => {
    let newPhoneType = PHONE_TYPES_SPECIAL_INSTRUCTIONS.find(phoneType => {
      return phoneType.text === e.target.textContent
    })
    if (newPhoneType !== undefined) {
      let phoneNumber = this.props.context.specialInstructionsPhone;
      let updatedPhoneNumber = {
        ...phoneNumber,
        PhoneType: newPhoneType.value
      }
      this
        .props
        .saveGlobalState({specialInstructionsPhone: updatedPhoneNumber})
    }
  }

  updateMailingAddressType = (e) => {
    let mailingAddressType = MAILING_ADDRESS_TYPES.find((addressType) => {
      return addressType.text === e.target.textContent
    })
    if (mailingAddressType !== undefined) {
      this.setState({showStreetAddress: mailingAddressType.showStreetAddress, showStreetType: mailingAddressType.showStreetType, showUnitNumber: mailingAddressType.showUnitNumber, numberText: mailingAddressType.numberText})
    }
  }

  canSubmit = () => {
    // The user must supply a phone number if they have special access instructions.
    if (this.props.context.hasSpecialInstructions && !this.props.context.specialInstructionsPhone.PhoneNumber.match(PHONE_REGEX)) {
      return false
    }

    // If the user needs to provide a separate mailing address, check to make sure
    // all fields that are required for the selected address type are entered.
    if (!this.props.context.useAddressForPostedCorrespondence) {

      if (this.state.showStreetAddress) {
        if (this.props.context.mailingStreetName === '' || this.props.context.mailingStreetType === '') 
          return false;
        }
      
      if (this.state.showStreetType) {
        if (this.props.context.mailingStreetType === '') 
          return false;
        }
      
      if (this.props.context.mailingStreetNumber === '' || this.props.context.mailingSuburb === '' || this.props.context.mailingPostcode === '' || this.props.context.mailingState === '') {
        return false;
      }
    }
    return true;
  }

  render() {
    const context = this.props.context;

    const {
      address,
      currentStep,
      mailingAddressType,
      mailingUnitNumber,
      mailingStreetNumber,
      mailingStreetName,
      mailingStreetType,
      mailingSuburb,
      mailingPostcode,
      mailingState
    } = context;

    if (currentStep !== PAGE_NUMBERS.RESIDENCE_QUESTIONS) {
      return null
    }

    return (
      <Form
        noValidate
        onChange={this.props.change}
        onValidSubmit={this.submit}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}
        style={{
        marginBottom: '5px'
      }}>

        <Header textAlign="center" size="large">Your property details</Header>
        <p>{address}</p>

        {/* Panel of Switches */}
        <Form.Group style={{
          textAlign: 'left'
        }}>
          <Form.Field style={{
            width: '194px'
          }}>Is there a panel of switches inside your property?</Form.Field>
          <Radio
            style={{
            marginRight: '10px'
          }}
            name="hasInternalSwitches"
            value={true}
            checked={context.hasInternalSwitches === true}
            onChange={this.updateHasInternalSwitches}
            label={'Yes'}/>
          <Radio
            style={{
            marginLeft: '10px'
          }}
            name="hasInternalSwitches"
            value={false}
            checked={context.hasInternalSwitches === false}
            onChange={this.updateHasInternalSwitches}
            label={'No'}/>
        </Form.Group>

        {/* Meter Access */}
        <Form.Group style={{
          textAlign: 'left'
        }}>
          <Form.Field style={{
            width: '194px'
          }}>
            In the case of trouble accessing your meter, please provide a contact number.</Form.Field>
          <Radio
            disabled={context.hasInternalSwitches === true}
            style={{
            marginRight: '10px'
          }}
            name="meterSpecialInstructions"
            value={true}
            checked={context.hasSpecialInstructions === true}
            onChange={this.updateHasSpecialInstructions}
            label={'Yes'}/>
          <Radio
            disabled={context.hasInternalSwitches === true}
            style={{
            marginLeft: '10px'
          }}
            name="meterSpecialInstructions"
            value={false}
            checked={context.hasSpecialInstructions === false}
            onChange={this.updateHasSpecialInstructions}
            label={'No'}/>
        </Form.Group>

        {/* Meter Access Number */}
        {context.hasSpecialInstructions === true && (
          <div style={{
            marginBottom: '20px'
          }}>
            <ValidatedInputWithSelect
              required
              fluid
              hideErrorMessage
              name='phone'
              dropdownName='phoneType'
              placeholder='Phone Number'
              value={this.props.context.specialInstructionsPhone.PhoneNumber}
              defaultValue={this.props.context.specialInstructionsPhone.PhoneType}
              validations={{
              "validPhoneNumberOrEmpty": true
            }}
              onChange={e => this.updatedPhoneNumber(e)}
              onLabelChange={e => this.updatedPhoneType(e)}
              options={PHONE_TYPES_SPECIAL_INSTRUCTIONS}
              labelPosition='right'/>
          </div>

        )}

        {/* Dog at Property */}
        <Form.Group style={{
          textAlign: 'left'
        }}>
          <Form.Field style={{
            width: '194px'
          }}>Is there a dog at this property?</Form.Field>
          <Radio
            style={{
            marginRight: '10px'
          }}
            name="hasDog"
            value={true}
            checked={context.hasDog === true}
            onChange={this.updateHasDog}
            label={'Yes'}/>
          <Radio
            style={{
            marginLeft: '10px'
          }}
            name="hasDog"
            value={false}
            checked={context.hasDog === false}
            onChange={this.updateHasDog}
            label={'No'}/>
        </Form.Group>

        {/* Same mailing address */}
        <Form.Group style={{
          textAlign: 'left'
        }}>
          <Form.Field style={{
            width: '194px'
          }}>Is the above address the same as your mailing address?</Form.Field>
          <Radio
            style={{
            marginRight: '10px'
          }}
            name="postedCorrespondence"
            value={true}
            checked={context.useAddressForPostedCorrespondence === true}
            onChange={this.updateUseAddressForPostedCorrespondence}
            label={'Yes'}/>
          <Radio
            style={{
            marginLeft: '10px'
          }}
            name="postedCorrespondence"
            value={false}
            checked={context.useAddressForPostedCorrespondence === false}
            onChange={this.updateUseAddressForPostedCorrespondence}
            label={'No'}/>
        </Form.Group>

        {/* Separate mailing address */}
        {context.useAddressForPostedCorrespondence === false && (
          <div style={{
            marginBottom: '20px'
          }}>

            <ValidatedDropdown
              hideErrorMessage
              name="mailingAddressType"
              placeholder="Mailing Address Type"
              options={MAILING_ADDRESS_TYPES}
              value={mailingAddressType}
              onChange={e => this.updateMailingAddressType(e)}/> {this.state.showUnitNumber === true && (<ValidatedInput
              validations={{
              "maxLength": 100
            }}
              hideErrorMessage
              name="mailingUnitNumber"
              placeholder="Unit Number"
              value={mailingUnitNumber}/>)}

            <ValidatedInput
              validations={{
              "maxLength": 10
            }}
              hideErrorMessage
              name="mailingStreetNumber"
              placeholder={this.state.numberText}
              value={mailingStreetNumber}/> {this.state.showStreetAddress === true && (<ValidatedInput
              validations={{
              "maxLength": 100
            }}
              hideErrorMessage
              name="mailingStreetName"
              placeholder="Street Name"
              value={mailingStreetName}/>)}

            {this.state.showStreetType === true && (<ValidatedDropdown
              hideErrorMessage
              name="mailingStreetType"
              placeholder="Street Type"
              options={this.state.streetTypes}
              value={mailingStreetType}/>)}

            <ValidatedInput
              validations={{
              "maxLength": 100
            }}
              hideErrorMessage
              name="mailingSuburb"
              placeholder="Suburb"
              value={mailingSuburb}/>
            <ValidatedInput
              validations={{
              "maxLength": 100
            }}
              hideErrorMessage
              name="mailingPostcode"
              placeholder="Postcode"
              value={mailingPostcode}/>
            <ValidatedDropdown
              hideErrorMessage
              name="mailingState"
              placeholder="State"
              options={STATE_PROVINCES}
              value={mailingState}/>
          </div>
        )}

        <Form.Field>
          <Button
            fluid
            type="submit"
            secondary
            loading={this.state.formState === FORM_STATE.LOADING}
            disabled={!this.canSubmit()}>Next</Button>
        </Form.Field>

        <Button fluid basic onClick={this.props.prev}>Previous</Button>
      </Form>
    );
  }
}
