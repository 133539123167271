
import React from 'react';

import './Property.css';
import Property from './Property';

export default ({ name, value, onChange, style, ...props }) => {
	const id = name + "-" + value + "-input";
	return <div className="property-radio" style={style}>
		<input id={id} name={name} type="radio" value={value} onChange={
			(e) => {
				if (onChange) onChange(e, { value: value, checked: true });
			}
		}/>
		<label htmlFor={id}>
			<Property {...props} />
		</label>
	</div>
}

