import React from 'react';
import axios from 'axios';
import { Header, Segment, Image, Button } from 'semantic-ui-react';
import { withRouter } from '../utils/useRouter';
import { NavLink } from 'react-router-dom';
import { FORM_STATE } from '../utils/FormState'
import ValidatedInput from '../components/Forms/ValidatedInput';
import { Form } from 'formsy-semantic-ui-react';
import Layout from '../layouts/Layout';
import LoggedOutLayout from '../layouts/LoggedOutLayout';
import logoAPAYG from '../static/aurora_plus_logo.svg';
import Modal from './../components/Modal';

export class SetEmail extends React.Component {
  state = {
    formState: FORM_STATE.DEFAULT,
    submitFailMessage: '',
    modalSubmitOpen: false,
    forgotPassword: false
  }

  componentDidMount = () => {
    this.setState({
      forgotPassword: this.props.router.params.forgotPassword !== 'false'
    });
  }

  isSubmitDisabled = () => {
    return !this.state.canSubmit
  }

  closeModal = () => {
    this.setState({modalSubmitOpen: false});
  }

  submit = (fields) => {

    const endpoint = process.env.REACT_APP_API_BASE_URI + 'identity/SendPasswordReset';

    this.setState({formState: FORM_STATE.LOADING});
    axios
      .post(endpoint, {username: fields.email})
      .then(() => {
        this.setState({formState: FORM_STATE.DONE_LOADING, modalSubmitOpen: false});
      })
      .catch((error) => {
        const errorMsg = (error.response && error.response.data)
          ? error.response.data.Message
          : error.message;

        this.setState({formState: FORM_STATE.DEFAULT, modalSubmitOpen: true, submitFailMessage: errorMsg});
      });
  }

  goToLogin = () => {
    this.props.router.navigate('/login');
  }

  onFormValid = () => {
    this.setState({canSubmit: true});
  }

  onFormInvalid = () => {
    this.setState({canSubmit: false});
  }

  render() {
    const {forgotPassword, formState, modalSubmitOpen, submitFailMessage} = this.state;

    return (
      <Layout
        accountPicker={false}
        layout={LoggedOutLayout}
        className="logged-out"
      >
        <div className="ui form">
          <Modal
            name="confirm-email/failure"
            size="small"
            open={modalSubmitOpen}
            onClose={this.closeModal}>
            <Modal.Header>Confirm Email Failed</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>{submitFailMessage}</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button fluid color="orange" onClick={this.closeModal}>OK</Button>
            </Modal.Actions>
          </Modal>
          <Segment
            basic
            style={{
            marginBottom: '2rem',
            paddingLeft: '2.5em',
            paddingRight: '2.5em'
          }}>
            <NavLink to="/"><Image centered size="medium" src={logoAPAYG}/></NavLink>
          </Segment>
          <Header
            textAlign="center"
            inverted
            size="small"
            style={{
              marginTop: 0,
			  color: '#4a4a4a',
            }}
		  >
            {(forgotPassword)
              ? 'Forgot Password'
              : 'Reset Password'
}
          </Header>
          {formState === FORM_STATE.DONE_LOADING
            ? (
              <div style={{
                color: '#4a4a4a'
              }}>
                <p>If you have an aurora+ account, you will receive an email with a reset
                  password link.</p>
                <Button fluid color="orange" onClick={this.goToLogin}>Back to Login</Button>
              </div>
            )
            : (
              <Form
                noValidate
                onValidSubmit={this.submit}
                onValid={this.onFormValid}
                onInvalid={this.onFormInvalid}
                style={{
                color: '#4a4a4a'
              }}>
                <p style={{
                  textAlign: 'center'
                }}>
                    Please confirm your email address to reset your password.
                </p>
                <ValidatedInput
                  required
                  validations="isEmail"
                  validationError="You must enter a valid email address"
                  type="email"
                  fluid
                  name="email"
                  placeholder="Email Address"/>
                <ValidatedInput
                  required
                  validations={{
                  isEmail: true,
                  equalsField: 'email'
                }}
                  validationError="The confirmation email must match"
                  type="email"
                  fluid
                  name="email_confirmation"
                  placeholder="Confirm Email Address"/>

                <Form.Field>
                  <Button
                    type="submit"
                    fluid
                    secondary
                    disabled={this.isSubmitDisabled()}
                    loading={formState === FORM_STATE.LOADING}>
                    Send Email
                  </Button>
                </Form.Field>
                <Form.Field>
                  <Button
                    fluid
                    //inverted
                    size="small"
                    onClick={() => this.props.router.navigate('/')}>
                    Back to Login
                  </Button>
                </Form.Field>
              </Form>
            )
}
        </div>
      </Layout>
    )
  }

}

export default withRouter(SetEmail)