import React from 'react';

import {Grid} from 'semantic-ui-react'

let columnContainer = {
  mobile: 16,
  tablet: 16,
  computer: 16
}

export default({
  children,
  dimmer,
  ...rest
}) => (
  <Grid centered {...rest} className="layout-full-width">
    <Grid.Column {...columnContainer} className="main-container">
      {dimmer}
      {children}
    </Grid.Column>
  </Grid>
)