import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '24px',
  width = '24px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path fill={color} d="M20 0C8.97207 0 0 8.97207 0 20C0 31.0279 8.97207 40 20 40C31.0279 40 40 31.0279 40 20C40 8.97207 31.0279 0 20 0ZM20 3C29.4066 3 37 10.5934 37 20C37 29.4066 29.4066 37 20 37C10.5934 37 3 29.4066 3 20C3 10.5934 10.5934 3 20 3ZM19.9766 9.97852C19.5791 9.98473 19.2003 10.1485 18.9235 10.4337C18.6466 10.719 18.4943 11.1025 18.5 11.5V18.5H11.5C11.3012 18.4972 11.1039 18.5339 10.9194 18.608C10.735 18.6822 10.5671 18.7922 10.4255 18.9318C10.284 19.0713 10.1716 19.2376 10.0949 19.421C10.0181 19.6044 9.97864 19.8012 9.97864 20C9.97864 20.1988 10.0181 20.3956 10.0949 20.579C10.1716 20.7624 10.284 20.9287 10.4255 21.0682C10.5671 21.2078 10.735 21.3178 10.9194 21.392C11.1039 21.4661 11.3012 21.5028 11.5 21.5H18.5V28.5C18.4972 28.6988 18.5339 28.8961 18.608 29.0806C18.6822 29.265 18.7922 29.4329 18.9318 29.5745C19.0713 29.716 19.2376 29.8284 19.421 29.9051C19.6044 29.9819 19.8012 30.0214 20 30.0214C20.1988 30.0214 20.3956 29.9819 20.579 29.9051C20.7624 29.8284 20.9287 29.716 21.0682 29.5745C21.2078 29.4329 21.3178 29.265 21.392 29.0806C21.4661 28.8961 21.5028 28.6988 21.5 28.5V21.5H28.5C28.6988 21.5028 28.8961 21.4661 29.0806 21.392C29.265 21.3178 29.4329 21.2078 29.5745 21.0682C29.716 20.9287 29.8284 20.7624 29.9051 20.579C29.9819 20.3956 30.0214 20.1988 30.0214 20C30.0214 19.8012 29.9819 19.6044 29.9051 19.421C29.8284 19.2376 29.716 19.0713 29.5745 18.9318C29.4329 18.7922 29.265 18.6822 29.0806 18.608C28.8961 18.5339 28.6988 18.4972 28.5 18.5H21.5V11.5C21.5029 11.2992 21.4654 11.0999 21.3899 10.9139C21.3143 10.7279 21.2022 10.5589 21.0601 10.417C20.918 10.2751 20.7489 10.1632 20.5628 10.0879C20.3767 10.0126 20.1773 9.97538 19.9766 9.97852Z" />
    </svg>
  );
}
