import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';

export default(props) => (
  <Grid
    className={'logged-out-home-layout ' + props.outerGridClasses}
    verticalAlign='middle'
    centered
>
    <Grid.Column mobile={16} tablet={8} computer={6} textAlign="center">
      <Segment basic vertical>
        {props.children}
      </Segment>
    </Grid.Column>
  </Grid>
)
