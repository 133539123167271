export const passwordThresholds = {
  WEAK: 2,
  STRONG: 3,
  EXCELLENT: 4,
}

export function getPasswordStrengthScore(score) {
  if (score <= passwordThresholds.WEAK) {
    return 'Weak';
  } else if (score <= passwordThresholds.STRONG) {
    return 'Strong';
  } else if (score <= passwordThresholds.EXCELLENT) {
    return 'Excellent';
  }
}

const containsLowercase = password => (/[a-z]/.test(password))
const containsUppercase = password => (/[A-Z]/.test(password))
const containsNumerical = password => (/[0-9]/.test(password))
const containsSpecial = password => (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/.test(password))

export function passwordStrength(password) {
  let strengthScore = 0;

  if (containsLowercase(password)) {
    strengthScore++;
  }

  if (containsUppercase(password)) {
    strengthScore++;
  }

  if (containsNumerical(password)) {
    strengthScore++;
  }

  if (containsSpecial(password)) {
    strengthScore++;
  }

  return strengthScore;
}

export function getPasswordValidationsPassed(password = "") {
  let validations = [];

  if (containsLowercase(password)) {
    validations.push("lowercase");
  }

  if (containsUppercase(password)) {
    validations.push("uppercase");
  }

  if (containsNumerical(password)) {
    validations.push("numerical");
  }

  if (containsSpecial(password)) {
    validations.push("special");
  }

  return validations;
}
