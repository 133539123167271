import React, { useRef, useState, useMemo } from "react";
import Layout from "../../../../layouts/Layout.js";
import SignupFormLayout from "../../../../layouts/SignupFormLayout.js";
import { Form } from 'formsy-semantic-ui-react';
import SignupButton from "../../components/SignupButton/SignupButton.js";
import IconRadioGroup from "../../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../../components/IconRadio/IconRadio.js";
import ValidatedInput from "../../../../components/Forms/ValidatedInput.js";
import useScrollToTop from "../../../../utils/useScrollToTop.js";
import ValidatedDropdown from "../../../../components/Forms/ValidatedDropdown.js";
import Titles from "../../../../utils/Titles.js";
import ToS from "../../../../utils/ToS/ToS.js";
import { Segment } from "semantic-ui-react";
import { FORM_STATE } from "../../../../utils/FormState.js";
import { withCustomer } from "../../../../contexts/Customer.js";

export default withCustomer(({ initialFormData, journey, customer, onSubmit, formState, canSubmitAll }) => {

	useScrollToTop();

	const [canSubmit, setCanSubmit] = useState(false);

	const formRef = useRef();
	const [formData, setFormData] = useState(initialFormData);

	const { firstName, lastName, mobileNumber } = customer.hasLoaded ? customer.customer : { };

	const userName = [firstName, lastName].join(" ");
	const userPhone = mobileNumber;

	function submit(payload) {
		payload = {
			use_current_contact_details: null,
			contact_title_on_the_day: null,
			contact_first_name_on_the_day: null,
			contact_last_name_on_the_day: null,
			contact_number_on_the_day: null,
			...payload
		}
		onSubmit(payload);
	}

	const titles = useMemo(() => {
		return Titles.map(({ code, description}) => {
			return {
				text: description,
				value: description,
			}
		})
	}, [Titles])


	return <Layout
		layout={SignupFormLayout}
		journey={journey}
		whitePage
		accountPicker={false}
	>
		<Form
			ref={formRef}
			noValidate
			onValidSubmit={submit}
			onValid={() => {setCanSubmit(true)}}
			onInvalid={() => {setCanSubmit(false)}}
			onChange={(() => {
				setFormData(formRef.current ? formRef.current.getModel() : { });
			})}
		>

			<p>
				Will the contact details on the day be:
				<br/>Name: { userName }
				<br/>Phone: { userPhone }
			</p>

			<IconRadioGroup
				name="use_current_contact_details"
				value={formData.use_current_contact_details}
				required
			>
				<IconRadio name="use_current_contact_details" value="yes" label="Yes" />
				<IconRadio name="use_current_contact_details" value="no" label="No" />
			</IconRadioGroup>
			
			{ formData.use_current_contact_details === "no" ? <>
				<ValidatedDropdown
					hideErrorMessage
					options={titles}
					defaultValue={formData.contact_title_on_the_day}
					name="contact_title_on_the_day"
					placeholder="Select Contact Title"
					required
				/>
				<ValidatedInput
					name="contact_first_name_on_the_day"
					value={formData.contact_first_name_on_the_day}
					hideErrorMessage
					type="text"
					placeholder="Enter Contact First Name"
					required
					validations={{
						"maxLength": 40,
					}}
				/>
				<ValidatedInput
					name="contact_last_name_on_the_day"
					value={formData.contact_last_name_on_the_day}
					hideErrorMessage
					type="text"
					placeholder="Enter Contact Last Name"
					required
					validations={{
						"maxLength": 40,
					}}
				/>
				<ValidatedInput
					name="contact_number_on_the_day"
					value={formData.contact_number_on_the_day}
					hideErrorMessage
					type="phone"
					placeholder="Enter Contact Phone Number"
					required
					validations={{
						"minLength": 5,
						"maxLength": 20,
					}}
				/>
			</> : null }

			<p style={{ marginBottom: 0 }}>By proceeding you are agreeing to:</p>
			<ul style={{
				paddingLeft: '1.5em',
				marginTop: 0,
				fontSize: '0.9em',
			}}>
				<li>The aurora+ Terms & Conditions</li>
				<li>Receiving monthly bills by email</li>
				<li>Installation of an advanced meter at your property</li>
				<li>Your electricity tariff(s) will remain unchanged</li>
			</ul>

			<Segment style={{
				height: '20em',
				overflow: 'auto',
				margin: '40px 0 60px',
			}}>
				{ ToS }
			</Segment>

			<SignupButton
				disabled={!canSubmit || !canSubmitAll}
				loading={formState === FORM_STATE.LOADING}
			>
				I accept, sign me up
			</SignupButton>

		</Form>
	</Layout>	


})


