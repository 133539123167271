import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import SignupHR from '../components/SignupHR/SignupHR.js';
import { useNavigate, useParams } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR, DUPLICATE_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import SignupTip from "../components/SignupTip/SignupTip.js";
import SignupTipButton from "../components/SignupTip/SignupTipButton.js";
import Property from "../../../components/Property/Property.js";
import { useMemo } from "react";
import PropertyRadioGroup from "../../../components/Property/PropertyRadioGroup.js";
import PropertyRadio from "../../../components/Property/PropertyRadio.js";
import { Segment } from "semantic-ui-react";
import { withCustomer } from "../../../contexts/Customer.js";
import { useEffect } from "react";
import ToS from "../../../utils/ToS/ToS.js";
import { useTariff } from "../../../contexts/Tariff.js";
import { withApp } from "../../../contexts/App.js";
import { ActivatePropertyData } from "./ActivatePropertyHelper.js";
import StaticHelp from "../../StaticHelp.js";
import { useSignup } from "../../../contexts/Signup.js";
import Analytics from "../../../utils/Analytics.js";


export default withApp(withCustomer(({ app, customer }) => {

	const navigate = useNavigate();
	useScrollToTop();

	let { premisesId } = useParams();

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	const [premises, setPremises] = useState(null);
	const tariffData = useTariff(premises);

	const signup = useSignup();

	useEffect(() => {
		if (tariffData && tariffData.hasIneligible) {
			Analytics.event({ category: "Signup", action: "NotEligible" });
			navigate('/signup/property/' + premisesId + '/ineligible');
		}
	}, [tariffData && tariffData.hasIneligible]);

	useEffect(() => {
		if (customer.allPremises && customer.allPremises.length > 0 && premisesId) {
			let match = customer.allPremises.find(premises => (premises.identifier === premisesId));
			if (match) {
				if (!match.hasAdvancedMeter) {
					navigate("/signup/property/" + premisesId + "/meter-exchange-required");
				} else {
					setPremises(match);
				}
			} else {
				navigate('/signup/property');
			}
		}
	}, [customer.premises, premisesId])


	const submit = (payload) => {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "customers/premise/activate";

		if (!premises) return;

		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		const activatePropertyData = new ActivatePropertyData(true, customer, premises, tariffData, payload, signup);

		axios
			.post(endpoint,
				activatePropertyData.getPayload()
			, { ignoreGlobalErrors: true})
			.then((response) => {
				setFormState(FORM_STATE.DONE_LOADING);

				customer.refresh();

				navigate("/signup/property/" + premisesId + "/complete");

				Analytics.event({ category: "Signup", action: "FormSubmittedAdvanced" });
				
			})
			.catch((error) => {

				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				//Analytics.event({ category: "User", action: "Failed " });

				switch(error.response ? error.response.status : error.code) {
					case 404:
						navigate('/signup/external/residential');
						break;
					case 409:
						setErrorModal(DUPLICATE_ERROR);
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;

				}
				

			})

	}	




				

	return (		
		<Layout
			layout={SignupFormLayout}
			journey={{ message: "Property selected", journeyName: "signup", currentStep: 5 }}
			whitePage
			accountPicker={false}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				{ premises && <>
					<Property
						key={premises.identifier}
						premises={premises}
						advancedMeterRequired={!premises.hasAdvancedMeter}
						advancedMeter={premises.hasAdvancedMeter}
					/>
					<SignupHR light />
				</>}

				{!app.isLoadingCustom && <>

					<p style={{ marginBottom: 0 }}>By proceeding you are agreeing to:</p>
					<ul style={{
						paddingLeft: '1.5em',
						marginTop: 0,
						fontSize: '0.9em',
					}}>
						<li>The aurora+ Terms & Conditions</li>
						<li>Receiving monthly bills by email</li>
						<li>Installation of an advanced meter at your property</li>
						<li>Your electricity tariff(s) will remain unchanged</li>
					</ul>

					<Segment style={{
						height: '20em',
						overflow: 'auto',
						margin: '40px 0 60px',
					}}>
						{ ToS }
					</Segment>


					<SignupButton disabled={!canSubmit} loading={formState === FORM_STATE.LOADING}>
						I accept, sign me up
					</SignupButton>
				
				</>}
			</Form>
			
			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

}))


