import React from 'react';
import axios from 'axios';
import {
  List,
  Segment,
  Image,
  Button,
  Header,
  Progress,
  Loader
} from 'semantic-ui-react';

import {Form} from 'formsy-semantic-ui-react';
import {NavLink} from 'react-router-dom';
import { withRouter } from '../utils/useRouter';
import Device from '../utils/Device';
import {FORM_STATE} from '../utils/FormState';
import ValidatedInput from '../components/Forms/ValidatedInput';
import {
  passwordStrength,
  getPasswordValidationsPassed,
  getPasswordStrengthScore,
  passwordThresholds
} from '../utils/PasswordStrength';
import logoAPAYG from '../static/aurora_plus_logo.svg';

import Layout from '../layouts/Layout';
import SetPasswordLayout from '../layouts/SetPasswordLayout';   
import Modal from './../components/Modal';
import PasswordValidationInfo from './AccountSettings/PasswordValidationInfo';
import PasswordField from '../components/Forms/PasswordField';
import { withApp } from '../contexts/App';
import { withContexts } from '../contexts';
import LoggedOutLayout from '../layouts/LoggedOutLayout';

export class SetPassword extends React.Component {

  state = {
    initialLoading: true,
    displayError: false,
    displayErrorMessage: '',
    submitFailMessage: '',
    modalSubmitOpen: false,
    formState: FORM_STATE.DEFAULT,
    isTouched: false,
    passwordStrength: '',
    passwordValidationsPassed: [],
    isMobileDevice: Device.isMobileDevice()
  }

  componentDidMount = () => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URI}identity/SetPassword?resetToken=${this.props.router.params.id}&onlyValidateToken=true`;
    const payload = {
      credentials: {}
    }

    axios
      .post(endpoint, payload, {ignoreGlobalErrors: true})
      .then(() => {
        this.setState({initialLoading: false});
      })
      .catch((error) => {
        const errorMsg = (error.response)
          ? error.response.data.Message
          : error.message;
        this.setState({
          initialLoading: false,
          displayError: true,
          displayErrorMessage: errorMsg || 'Failed to Load'
        });
      });
  }

  updatePasswordStrength = ({password}) => {
    const validationsPassed = getPasswordValidationsPassed(password);

    this.setState({
      passwordStrength: validationsPassed.length,
      passwordValidationsPassed: validationsPassed
    })
  }

  onFormChange = (fields) => {
    this.updatePasswordStrength(fields)
    this.setState({formTouched: true})
  }

  isSubmitDisabled = () => {
    return !this.state.canSubmit
  }

  closeModal = () => {
    this.setState({modalSubmitOpen: false});
  }

  submit = (fields) => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URI}identity/SetPasswordAndLogin?resetToken=${this.props.router.params.id}`;

    this.setState({formState: FORM_STATE.LOADING});

	let { isApp } = this.props.app;

    axios
      .post(endpoint, fields)
      .then((response) => {
        this.setState({formState: FORM_STATE.DONE_LOADING, modalSubmitOpen: false})

		const remember = isApp ? true : false; // Don't default browser users to remembering

        if (response.data && response.data.accessToken) {
          this.props.auth.updateToken(
            response.data.accessToken,
            remember
          )
        }

        Analytics.event({ category: "User", action: "Successful Login" });

        this.props.app.loginUser();		
      })
      .catch((error) => {
        const errorMsg = (error.response && error.response.data)
          ? error.response.data.Message
          : error.message;

        this.setState({modalSubmitOpen: true, submitFailMessage: errorMsg, formState: FORM_STATE.DEFAULT});
      });
  }

  submitBtnText = () => {
    switch (this.state.formState) {
      case FORM_STATE.LOADING:
        return 'Password Updating';
      case FORM_STATE.DONE_LOADING:
        return 'Password Updated';
      default:
        return 'Set Password';
    }
  }

  onFormValid = () => {
    this.setState({canSubmit: true});
  }

  onFormInvalid = () => {
    this.setState({canSubmit: false});
  }

  render() {
    const {
      initialLoading,
      formState,
      modalSubmitOpen,
      submitFailMessage,
      displayError,
      displayErrorMessage,
      passwordStrength,
      passwordValidationsPassed
    } = this.state;

    if (initialLoading) {
      return <Loader inverted active={true}/>;
    }

    const form = (
      <Form
        noValidate
        onValidSubmit={this.submit}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}
        onChange={this.onFormChange}
      >
        <PasswordValidationInfo
          validationsPassed={passwordValidationsPassed} />

        <PasswordField
          validations={{
          minLength: 8,
          maxLength: 256,
          passwordStrength: passwordThresholds.WEAK,
          matchRegexp: /^[A-Za-z0-9@#$%^&*\-_!+=\[\]{}|:',.?\/`~"();]+$/
        }}
          validationErrors={{
          minLength: 'The new password field must be at least 8 characters.',
          maxLength: 'The new password field may not be greater than 256 characters.',
          matchRegexp: 'The new password field must only contain letters, numbers and symbols'
        }}
          required
          autoComplete="new-password"
          fluid
          name="password"
          placeholder="Set Password"
          hideErrorMessage/>
        <PasswordField
          validations={{
          equalsField: 'password'
        }}
          validationErrors={{
          equalsField: 'The confirm new password must match.'
        }}
          required
          autoComplete="new-password"
          fluid
          name="confirmPassword"
          placeholder="Confirm Password"
          textColor = "white"
          />

        <div>
          <small>Password Strength
            <span style={{
              float: 'right'
            }}>{getPasswordStrengthScore(passwordStrength)}</span>
          </small>
          <Progress
            percent={passwordStrength * 25}
            size="small"
            error={passwordStrength <= passwordThresholds.WEAK}
            warning={passwordStrength <= passwordThresholds.STRONG}
            success={passwordStrength <= passwordThresholds.EXCELLENT}/>
        </div>

        <Button
          fluid
          secondary
          disabled={this.isSubmitDisabled()}
          type="submit"
          loading={formState === FORM_STATE.LOADING}
          style={{
          marginBottom: '1em'
        }}
          className={(formState === FORM_STATE.DONE_LOADING
          ? 'done-loading'
          : '')}>
          {this.submitBtnText()}
        </Button>

        {(formState === FORM_STATE.DONE_LOADING)
          ? <p style={{
              textAlign: 'center'
            }}>Redirecting to log in screen.</p>
          : null
}

      </Form>
    )

    return (
      <Layout hideBackButton={true} accountPicker={false} layout={LoggedOutLayout} whitePage
			>
       
        <Modal
          name="set-password/failure"
          size="small"
          open={modalSubmitOpen}
          onClose={this.closeModal}>
          <Modal.Header>Set Password Failed</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{submitFailMessage}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button fluid color="orange" onClick={this.closeModal}>OK</Button>
          </Modal.Actions>
        </Modal>
        {displayError
          ? <div>
              <p style={{
              }}>{displayErrorMessage}</p>
            </div>
          : form
}
      </Layout>
    )
  }
}

export default withContexts(withRouter(SetPassword));
