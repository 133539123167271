import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import "./SignupButton.css";

export default function SignupButton({ className, children, as, style, ...props }) {
	className = ((className && className.length) ? className + " " : "") + "signup-button";
	if (as === Link) className += " link";
	return <Button
		className={className}
		fluid
		secondary
		type="submit"
		size="large"
		style={{
			marginTop: '30px',
			fontSize: '18px',
			fontWeight: '400',
			minHeight: '52px',
			...style
		}}
		as={as}
		{...props}
	>
		{ children }
	</Button>

}