import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '24px',
  width = height,
  style = {},
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, cursor: 'pointer' }}
      {...rest}>
      <path
        d="M3 13V10C3 7.23858 5.23858 5 8 5H21M21 5L18 2M21 5L18 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M21 11V14C21 16.7614 18.7614 19 16 19L3 19M3 19L6 22M3 19L6 16"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  )
}

