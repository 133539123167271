import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon"
        className="weather-icon"  
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M325.86,81.68a117.64,117.64,0,0,0-213.22-11.2A70.35,70.35,0,0,0,100,69.34,71.69,71.69,0,0,0,30.7,159.27,58.3,58.3,0,0,0,68.28,262.16H319.71a90.34,90.34,0,0,0,6.15-180.48Zm-6.15,156.17H68.28A34,34,0,0,1,51.2,174.44l9.19-5.36-4.1-9.83a47.3,47.3,0,0,1,59.08-63L126.14,100l4.4-10.52a93.33,93.33,0,0,1,175.13,8l2.75,8.77,11.29-.38a66,66,0,1,1,0,132.07Z" transform="translate(-9.45 -7.29)" />
    <line x1="67.15" y1="312.76" x2="87.78" y2="284.46" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="96.35" y1="351.22" x2="145.04" y2="284.46" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="182.92" y1="313.53" x2="204.12" y2="284.46" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="208.52" y1="359.19" x2="263.02" y2="284.46" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="293.2" y1="325.32" x2="323" y2="284.46" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="122.02" y1="396.91" x2="161.79" y2="342.38" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  </g>
</svg>

)
}