import Log from './Log'
import axios from 'axios';
import MobileAppComms from './../utils/MobileAppComms';
import Analytics from './../utils/Analytics';

const { REACT_APP_API_BASE_URI } = process.env


export const openBill = (billId, parentAccountID, isApp, parentAccountNumber, serviceAgreementStatus) => {

  return new Promise((resolve, reject) => {

    if (!billId) {
      Log("No Bill Id to open.");
      return resolve();
    }

    // this hack is so the new tab opening works in safari but also, the window has
    // to be opened from a user click event, not the axios promise this is a browser
    // security feature... we call windowRef.close() in the catch block if there is
    // an error and throw a modal
    var windowReference;

    if (!isApp) {
      windowReference = window.open();
    }

    return axios
      .get(`${REACT_APP_API_BASE_URI}bills/${parentAccountID}/${billId}?parentAccountNumber=${parentAccountNumber}&isOldCCB=${serviceAgreementStatus === "Old_CCB"}`)
      .then(res => {

        if (!res.data.token)
          return reject();

        const pdfUrl = `${REACT_APP_API_BASE_URI}bills/Aurora_${billId}.pdf?tokenString=${encodeURIComponent(res.data.token)}`;

        //for mobile app devices we use the postMessage call
        if (isApp) {
          MobileAppComms.postMessage(JSON.stringify({ OPEN_EXTERNAL_LINK: pdfUrl }));
        } else {
          windowReference.location = pdfUrl;
        }
        Analytics.event({category: 'Transaction history', action: 'Viewed Bill PDF successful'})

        return resolve();

      }).catch(e => {
        Log("Failed to open Bill [" + billId + "]");
        if (!isApp && windowReference) {
          windowReference.close();
        }

        return reject();
      })
  });
}
