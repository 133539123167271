import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import { useNavigate } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { useSignup } from "../../../contexts/Signup.js";
import IconRadioGroup from "../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../components/IconRadio/IconRadio.js";
import SignupPhoneIcon from '../../../components/Icons/signup/SignupPhoneIcon.js';
import SignupEmailIcon from '../../../components/Icons/signup/SignupEmailIcon.js';
import { withContexts } from "../../../contexts/index.js";
import Analytics from "../../../utils/Analytics.js";
import { useLoginReturn } from "../../../contexts/LoginReturn.js";


export default withContexts(({ auth }) => {

	const navigate = useNavigate();
	useScrollToTop();

	const loginReturn = useLoginReturn();
	const signup = useSignup();
	if (!signup.maskedEmailAddress) navigate('/signup/residential');


	return (		
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Login already exists", error: true }}
		>
			<Form
				noValidate
			>
				<p>
					{signup.maskedEmailAddress} has already been used to create a login
				</p>

				<IconRadioGroup
					name="verificationType"
					formRadioGroup={true}
					required
					style={{marginTop: '30px'}}
					onChange={(event, { checked, value }) => {
						if (checked) {
							switch(value) {
								case "Try Another":
									navigate("/signup/residential/find-your-account");
									break;
								case "Login":
									loginReturn.set('/signup/property');
									auth.initiateLogin();
									break;
								case "NoLonger":
									Analytics.event({ category: "Signup", action: "ContactDetailsOutofdate" });
									navigate("/signup/external/residential");
									break;
							}
						}
					}}
				>
					<IconRadio value="Login" label={<span>Login using this email address</span>} icon={SignupEmailIcon} iconHeight={40} />
					<IconRadio value="NoLonger" label={<span>I no longer use this email address</span>} icon={SignupEmailIcon} iconHeight={40} />
					{/**
					 * APS-287 Only show Login button
					 <IconRadio value="Try Another" label={<span>Try another email address</span>} icon={SignupPhoneIcon} iconHeight={40} />
					 **/}
				</IconRadioGroup>

			</Form>

		</Layout>		
	);

})


