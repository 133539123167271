import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';

export default(props) => (
  <Grid
    className={'consent-form-layout ' + props.outerGridClasses}
    verticalAlign='middle'
    centered
    style={{
    alignItems: 'center',
    minHeight: '100vh'
  }}>
    <Grid.Column
      mobile={16}
      tablet={8}
      computer={6}
      textAlign="center"
      style={{
      paddingTop: '2.5rem'
    }}>
      <Segment basic vertical style={{
        textAlign: 'left'
      }}>
        {props.children}
      </Segment>
    </Grid.Column>
  </Grid>
)
