import moment from 'moment-timezone';
import './TimeOfUseGraph.css'
import { useMemo } from 'react';
import ClockIcon from "../Icons/ClockIcon";
import { useTheme } from '../../../src/contexts/Theme';

export default ({ timeOfUse }) => {

	const themeContext = useTheme(); 
	
	let isDaylightSaving = moment(new Date()).isDST();
	let isWeekend = [6, 0].includes(moment().day()); // 6 = Saturday, 0 = Sunday
	let tariffMatch = timeOfUse.match(/Tariff (\d+)/);
    let tariff = tariffMatch ? 'T' + tariffMatch[1] : 'T93'; // Default to 'T93' if not matched

	if (tariff === "T93" && !isWeekend) {
		var periods = useMemo(() => [
			new TimePeriod("Peak", isDaylightSaving ? 8 : 7, isDaylightSaving ? 11 : 10),
			new TimePeriod("Off-Peak", isDaylightSaving ? 11 : 10, isDaylightSaving ? 17 : 16),
			new TimePeriod("Peak", isDaylightSaving ? 17 : 16, isDaylightSaving ? 22 : 21),
			new TimePeriod("Off-Peak", isDaylightSaving ? 22 : 21, isDaylightSaving ? 24 + 8 : 24 + 7),
		], [isDaylightSaving]);
	} else if (tariff === "T93" && isWeekend) {	
		var periods = useMemo(() => [
			new TimePeriod("Off-Peak", 0, 24),
		], [isDaylightSaving]);	
	} else if (tariff === "T94" && !isWeekend) {
		var periods = useMemo(() => [
			new TimePeriod("Peak", isDaylightSaving ? 8 : 7, isDaylightSaving ? 23 : 22),
			new TimePeriod("Off-Peak", isDaylightSaving ? 23 : 22, isDaylightSaving ? 24 + 8 : 24 + 7),
		], [isDaylightSaving]);	
	} else if (tariff === "T94" && isWeekend) {
		var periods = useMemo(() => [
			new TimePeriod("Shoulder", isDaylightSaving ? 8 : 7, isDaylightSaving ? 23 : 22),
			new TimePeriod("Off-Peak", isDaylightSaving ? 23 : 22, isDaylightSaving ? 24 + 8 : 24 + 7),
		], [isDaylightSaving]);	
	} else {
		var periods = useMemo(() => [
			new TimePeriod("Peak", isDaylightSaving ? 8 : 7, isDaylightSaving ? 11 : 10),
			new TimePeriod("Off-Peak", isDaylightSaving ? 11 : 10, isDaylightSaving ? 17 : 16),
			new TimePeriod("Peak", isDaylightSaving ? 17 : 16, isDaylightSaving ? 22 : 21),
			new TimePeriod("Off-Peak", isDaylightSaving ? 22 : 21, isDaylightSaving ? 24 + 8 : 24 + 7),
		], [isDaylightSaving]);	
	}

	var now = moment(new Date());
	var nowHour = now.hour() + now.minute() / 60;

	var currentPeriodIndex = 0;
	for (var i = 1; i < periods.length; i++) {
		var period = periods[i];
		if ((nowHour >= period.startHour && nowHour <= period.endHour) ||
		  ((nowHour + 24) >= period.startHour && (nowHour + 24) <= period.endHour)) {
			currentPeriodIndex = i;
		}
	}
	var currentPeriod = periods[currentPeriodIndex];
	var beforePeriod = periods[(currentPeriodIndex - 1 + periods.length) % periods.length];
	var afterPeriod = periods[(currentPeriodIndex + 1) % periods.length];

	const hoursShown = 5.5;
	var graphStart = nowHour - hoursShown / 2;
	var graphEnd = nowHour + hoursShown / 2;

	var labels = [];
	for (var l = Math.floor(graphStart); l <= Math.ceil(graphEnd); l++) {
		var time = moment.tz("Australia/Hobart").startOf('day').hour(l).format("ha");
		labels.push(
			<span className="time-of-use-label" key={time} style={{
				left: ((l - graphStart) / (graphEnd - graphStart) * 100)  + "%"
			}}>
				{time}
			</span>
		)
	}

	var lines = [
		{ period: beforePeriod, offset: (beforePeriod.endHour > nowHour) ? -24 : 0},
		{ period: currentPeriod, offset: (currentPeriod.endHour < nowHour) ? 24 : (currentPeriod.startHour > nowHour) ? -24 : 0},
		{ period: afterPeriod, offset: (afterPeriod.startHour < nowHour) ? 24 : 0},
	].map(({ period, offset }) => {
		var left = ((period.startHour + offset - graphStart) / (graphEnd - graphStart) * 100);
		var right = ((period.endHour + offset - graphStart) / (graphEnd - graphStart) * 100);

		let background = "#000000";

		if (period.className === 'peak') {
			background = themeContext.theme.TimeOfUseGraph.peakColor;
		} else if (period.className === 'off-peak') {
			background = themeContext.theme.TimeOfUseGraph.offPeakColor;
		} else if (period.className === 'shoulder') {
			background = themeContext.theme.TimeOfUseGraph.shoulderColor;
		}

		return {
			style: {
				background: background,
				// add a small margin between
				left: (left + 0.6) + "%",
				width: (right - left - 1.2) + "%",
			},
			className: period.className,
		}
	});

	return <div className="time-of-use-graph" style={{ color: themeContext.theme.TimeOfUseGraph.color }} >
		<header>
			<h2 className={currentPeriod.className} style={{ color: themeContext.theme.TimeOfUseGraph.color }}>
				{currentPeriod.label}
				<ClockIcon height={24}/>
			</h2>
			<div>Until {currentPeriod.end.format("ha")}</div>
		</header>
		
		<div className="time-of-use-labels">
			{labels}
		</div>

		<div className="time-of-use-timeline">
			{ lines.map((line, index) => {
				return <div key={index} { ...line }></div>
			})}
		</div>

		<div className={"time-of-use-now "}></div>
	</div>
	
}

function TimePeriod(label, startHour, endHour) {
	this.label = label;
	this.className = label.toLowerCase();
	this.startHour = startHour;
	this.start = moment.tz("Australia/Hobart").startOf('day');
	this.start.hour(startHour);
	this.endHour = endHour;
	this.end = moment.tz("Australia/Hobart").startOf('day');
	this.end.hour(endHour);
}