
export const BILLING_TYPE = {
  PAYG: 'PAYG',
  POSTPAID: 'POSTPAID'
}

export const PRODUCT_TYPE = {
  RESIDENTIAL: 'residential',
  BUSINESS: 'business'
}

export const SYSTEM_TYPE = {
  CCB: 'ccb',
  HUB: 'hubcx'
}
