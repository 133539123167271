import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import SignupHR from '../components/SignupHR/SignupHR.js';
import { useNavigate, useParams } from "react-router";
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import Property from "../../../components/Property/Property.js";
import { Segment } from "semantic-ui-react";
import { withCustomer } from "../../../contexts/Customer.js";
import { useEffect } from "react";
import ToS from "../../../utils/ToS/ToS.js";
import SignupTipButton from "../components/SignupTip/SignupTipButton.js";
import SignupTip from "../components/SignupTip/SignupTip.js";
import ExternalLink from "../../../components/ExternalLink.js";
import { Link } from "react-router-dom";


export default withCustomer(({ customer }) => {

	const navigate = useNavigate();
	useScrollToTop();

	let { premisesId } = useParams();

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	const [premises, setPremises] = useState(null);

	useEffect(() => {
		if (customer.allPremises && customer.allPremises.length > 0 && premisesId) {
			let match = customer.allPremises.find(premises => (premises.identifier === premisesId));
			if (match) setPremises(match);
			else navigate('/signup/property');
		}
	}, [customer.premises, premisesId])

				

	return (		
		<Layout
			layout={SignupFormLayout}
			journey={{ message: "Property selected", journeyName: "signup", currentStep: 5 }}
			whitePage
			accountPicker={false}
		>
			{ premises && <>
				<Property
					key={premises.identifier}
					premises={premises}
					advancedMeterRequired={!premises.hasAdvancedMeter}
					advancedMeter={premises.hasAdvancedMeter}
				/>
				<SignupHR light />
			</>}


			<p>Unfortunately, the property you selected is on an energy plan that isn’t eligible for aurora+.</p>
		
			<SignupTip>
				<p>Click here to see the tariffs eligible for aurora+ and find out if your are on the best tariff for you</p>
				<ExternalLink className="ui button fluid signup-tip-button" to="https://www.auroraenergy.com.au/residential/products/electricity-plans">
					Right plan calculator
				</ExternalLink>
			</SignupTip>

			<SignupTip style={{
				marginTop: '1rem'
			}}>
				<p>Want to discuss your tariff with us? Call us on <ExternalLink href='tel:1300132003'>1300 13 2003</ExternalLink> or</p>
				<SignupTipButton onClick={() => {
					navigate('/contact/general?account=' + premisesId);
				}}>Contact us here</SignupTipButton>
			</SignupTip>

			<SignupButton as={Link} to="/signup/property">
				Sign up another property
			</SignupButton>

			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

})


