import React from 'react';
import {Form} from 'formsy-semantic-ui-react';

export default(props) => {
    const errorLabel = props.hideErrorMessage
        ? null
        : <small
            style={{
            textAlign: 'right',
            color: 'red',
            'padding': '0.25em 0',
            display: 'block'
        }}></small>
      return (<Form.Dropdown
        selection
        className={(props.hideErrorMessage
          ? 'hide-errors '
          : '') + (props.hideLabel || !props.label
            ? 'hide-labels'
            : '') + props.className}
        name={props.name}
        label={props.label}
        errorLabel={errorLabel}
        validations={props.validations}
        validationErrors={props.validationErrors}
        validationError={props.validationError || "Field is invalid"}
        required={props.required}
        autoComplete={props.autoComplete}
        fluid={props.fluid}
        disabled={props.disabled}
        onChange={props.onChange}
        icon={props.icon}
        options={props.options}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        style={props.style}
        instantValidation/>)
}
