import React from 'react'
import { transition } from 'd3-transition'
import { select } from 'd3-selection'
import { cloneDeep } from 'lodash'

export default class Bar extends React.Component{

    // This is used to stop setState running after components have been unmounted. 
    // In this componenent always make sure to check if the component is mounted before using setState
    _isMounted = true

    constructor(props) {
        super(props)

        this.barRef = React.createRef()
        this.state = {
            y: 120,
            height: 0
        }
    }

    componentDidMount() {
        let tempState = cloneDeep(this.props)
        delete tempState.targetData
        delete tempState.barClickHandler
        delete tempState.solar
        tempState.height = 0
        tempState.y = 120
        this._isMounted && this.setState({...tempState})
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.height !== this.props.height) {

            if(prevProps.solar !== this.props.solar) {
                this.props.solar ? this.setState({height: 0, y: 215}) : this.setState({height: 0, y: this.props.y})
            }

            this.transitionAnimation()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    transitionAnimation = () => {
        let el = select(this.barRef.current)
        el.transition()
            .duration(500)
            .attr("height", this.props.height)
            .attr("y", this.props.y)
            .on("end", () => 
                this._isMounted && this.setState({height: this.props.height})
                )
    }

    barClick = () => {
        this.props.barClickHandler(this.props, this.props.targetData, this.barRef)
    }

    render() {
        return (<rect {...this.state} ref={this.barRef} onClick={() => this.barClick()} />)
    }
}