import React, {Component} from 'react'
import {PAGE_NUMBERS} from './constants'

export default class FailIdentificationPage extends Component {
  render() {
    const {currentStep} = this.props.context
    if (currentStep !== PAGE_NUMBERS.IDENTIFICATION_FAIL) {
      return null
    }

    return (
      <div className='form-content'>
        <p className='h4 text-center mb-4'>
          {this.props.context.failureMessage}
        </p>
        <p>{this.props.context.contactMessage}</p>
      </div>
    )
  }
}
