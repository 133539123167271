import React from 'react';
import {Input, Dropdown} from 'formsy-semantic-ui-react';
import {withFormsy} from 'formsy-react';

export default withFormsy(({
    ...props
}) => {

    const errorLabel = <small
        style={{
        textAlign: 'right',
        color: 'red',
        'padding': '0.25em 0',
        display: 'block'
    }}></small>

    const label = <Dropdown
        name={props.dropdownName}
        defaultValue={props.defaultValue}
        options={props.options}
        style={props.labelStyle}
        onChange={props.onLabelChange}/> 

    return (<Input
        className={(props.hideErrorMessage
        ? 'hide-errors inputWithSelect '
        : 'inputWithSelect ') + (props.hideLabel || !props.label
        ? 'hide-errors field smaller-field'
        : 'field smaller-field')}
        name={props.name}
        label={label}
        labelPosition={props.labelPosition}
        errorLabel={errorLabel}
        validations={props.validations}
        validationErrors={props.validationErrors}
        type={props.type}
        onChange={props.onChange}
        icon={props.icon}
        iconPosition={props.iconPosition}
        inline={props.inline}
        value={props.value}
        placeholder={props.placeholder}
        style={props.inputStyle}
        error={props.error}
        instantValidation/>)
})
