import React from 'react';
import { Card, Statistic, Button } from 'semantic-ui-react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import InfoIcon from '../Icons/InfoIcon';
import './Prediction.css';
import { withTheme } from '../../contexts/Theme';
import { SolidCard, SolidCardContent, SolidCardHeader } from '../SolidCard/SolidCard';

const DollarValue = ({ value, plain }) => {
	value = value || 0;
	// Use toLocaleString to format the value with commas for AUIX-114
	var s = Math.abs(Number(value)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	return (
	  <>
		{plain ? "$" : <sup style={{ fontSize: '55%' }}>$</sup>}
		{s}
		{(value < 0 && !plain) && <span style={{ fontSize: '50%', lineHeight: 1 }}>CR</span>}
	  </>
	);
  };

export const PredictedAmount = withTheme((props) => {
  const { theme } = props.theme
  const noData = "We are just preparing your account and data. We'll have it ready for you soon."; 
  const noBillYet = "Bill prediction information will be available after your next bill is issued. Please check back after then."; //APAYGDEV-677
  const noBillingGroup = "Bill prediction information is not currently available. Please check back soon."; //APAYGDEV-697

  const prediction = !props.noData ? props.predictedAmount : 0;

  var useT22Modal = props.tariffs && ((props.tariffs.indexOf("T22") >= 0) || (props.tariffs.length === 0));


  //Display stub card if the customer hasn't got a bill yet or their bill has no biling group APAYGDEV677 & 697
  if (props.InvoiceID==0 || props.billNextRun == null) {
   	return (
		<SolidCard style={theme.card.default}>
			<SolidCardHeader>
				Predicted charges for your next bill	
			</SolidCardHeader>
			<SolidCardContent>
				<p style={{textAlign: 'center', maxWidth: '20em', margin: 'auto'}}>
					{props.InvoiceID==0 ? noBillYet : noBillingGroup}
				</p>
			</SolidCardContent>
		</SolidCard>	
	);
  }


  
  return (
	<SolidCard style={theme.card.default}>
		<SolidCardHeader>
			<span>Predicted charges for your next bill	</span>
			<InfoIcon height="24px" onClick={() => props.openModal(useT22Modal ? 'yourBillPredictionT22' : 'yourBillPrediction')} />
		</SolidCardHeader>
		<SolidCardContent>
			{props.noData ?
				<p style={{textAlign: 'center', maxWidth: '20em', margin: 'auto'}}>
					{noData}
				</p>				
			:
				<Statistic style={{ width: '100%' }}>
					<Statistic.Value>
						<DollarValue value={prediction}/>
					</Statistic.Value>
				</Statistic>
			}			
		</SolidCardContent>
	</SolidCard>	
  );
})

export const CurrentBillingPeriod = withTheme((props) => {
  if (props.noData) return null;

  const { theme } = props.theme;
  const progress = (props.totalDays == 0)
    ? 0
    : 1 - props.daysRemaining / props.totalDays;

  // limit where we place the "Today" marker
  const billProgressPercent = _.clamp(progress, 0.0, 1.0);
  const widthPadding = 40;
  const height = props.height || 100,
    width = props.width || 345,
    linePositionY = 40,
    curentDateWidth = 45 + 50; // half the width + adjustment for inner svg elements
  const widthTotal = width + (widthPadding * 2);

  const nextBillDue = props.billNextTo
    ? props.billNextTo
    : moment(props.startDate).add(props.totalDays, 'days');


  //Don't show the card if the customer doesn't have a bill yet or the bill doesn't have a valid Billing group assigned APAYGDEV-667 & 697
  if (props.InvoiceID == 0 || props.billNextRun == null) {
    return false    
  }

  // don't show the SVG in the 5-day waiting period, instead display a message
  if (props.daysRemaining < 1) {
    return (
		<SolidCard style={theme.card.default}>
			<SolidCardHeader>
				<span>Current billing period</span>
				<InfoIcon height="24px" onClick={() => props.openModal('currentBillingPeriod')} />
			</SolidCardHeader>
			<SolidCardContent>
				<p style={{textAlign: 'center', maxWidth: '20em', margin: 'auto'}}>
					Your bill is currently being prepared. This can take approximately 5-7 days.
				</p>
			</SolidCardContent>
		</SolidCard>
	);
  }


  return (

	<SolidCard style={theme.card.default} noContentPadding>
		<SolidCardHeader>
			<span>Current billing period</span>
			<InfoIcon height="24px" onClick={() => props.openModal('currentBillingPeriod')} />
		</SolidCardHeader>
		<SolidCardContent style={{textAlign: "center", padding: 0}}>

			<div
				className="top-section"
				style={{ borderBottom: theme.divider, padding: '2em .5em' }}
			>

				<svg
					width={widthTotal}
					height={height}
					viewBox={`0 0 ${widthTotal} ${height}`}
					fill="none"
					xmlns="http://www.w3.org/2000/svg">

					<path
						id="postDate-line"
						d={`M${widthPadding} ${height - linePositionY} L${widthPadding + width} ${height - linePositionY}`}
						stroke="#F7902B"
						strokeWidth="10"
						strokeMiterlimit="11.4737"
						strokeLinecap="round"
						strokeDasharray="2 15"></path>
					<path
						id="preDate-line"
						d={`M${widthPadding} ${height - linePositionY} L${widthPadding + (width * billProgressPercent)} ${height - linePositionY}`}
						stroke="#0060AE"
						strokeWidth="10"
						strokeMiterlimit="11.4737"
						strokeLinecap="round"></path>
					<g
						id="currentDate-group"
						transform={`translate(${widthPadding + (width * billProgressPercent) - curentDateWidth} ${-linePositionY - 17})`}
					>
						<path stroke="#F7902B" id="currentDate-line" d="M93 90 L93 112" strokeWidth="2" strokeLinecap="round"></path>
						<path fill="#F7902B" d="M58 76.1054C58 67.2107 65.2107 60 74.1054 60H111.895C120.789 60 128 67.2107 128 76.1054C128 85.0002 120.789 92.2109 111.895 92.2109H74.1054C65.2106 92.2109 58 85.0002 58 76.1054Z"></path>
						<text x="73" y="81" fill="white" style={{ fontSize: '13.3px', lineHeight: '15px' }}>
							Today
						</text>
						<circle stroke="#0060AE" id="currentDate-spot" cx="93" cy="117" r="7" fill="white" strokeWidth="2"></circle>
					</g>

					<text x={widthPadding - 5} y={height - 2} className="styledText" style={{fill: theme.color}}>
						{moment(props.startDate).format('DD MMM')}
					</text>

					<text x={widthPadding + (width - 50)} y={height - 2} className="styledText" style={{fill: theme.color}}>
						{moment(nextBillDue).format('DD MMM')}
					</text>
				</svg>
			
			</div>

			<div className="left-section" style={{ width: '50%', float: 'left', padding: '1.5em .5em' }}>
				<Statistic style={{ width: '100%' }}>
					<Statistic.Value>
						{Math.max(0, props.daysRemaining)}
					</Statistic.Value>
				</Statistic>
				<p>Days remaining</p>
			</div>

			<div className="right-section" style={{borderLeft: theme.divider}}>
				<Statistic style={{ width: '100%', whiteSpace: 'no-wrap' }}>
					<Statistic.Value>
						<DollarValue value={props.dailyCost}/>
					</Statistic.Value>
				</Statistic>
				<p>Avg $/day</p>
			</div>
		</SolidCardContent>
	</SolidCard>

  );
})

export const ChargesSoFar = withTheme((props) => {
  const { theme } = props.theme
  // don't show this in the 5-day waiting period
  if (!props.noData && props.daysRemaining < 1 || props.billNextRun == null )
    return null;

  const dateStr = props.lastUpated ? moment(props.lastUpated).format("DD/M/YYYY") : "";

  const startStr = props.billTo ? moment(props.billTo).format("DD/M/YYYY") : "";
  if (props.InvoiceID==0){
    return false
  }
  return (
    <React.Fragment>
      <Modal
        name="prediction/currentCharges"
        size="tiny"
        open={props.open}
        onClose={props.handleCloseModal}
        style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}>  
        <Modal.Header>Current charges as of today</Modal.Header>
        <Modal.Content>
          <Modal.Description style={{ textAlign: "left" }}>
          <p>This balance is based on how much we estimate you have used {props.billTo ? " (from your last Bill on " + startStr + ") ": ""}until now.</p>
          <p>This balance does not include any payments or rebates applied since your last bill and is an estimated amount only.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: theme.primary.backgroundColor }}
            secondary
            fluid
            onClick={props.handleCloseModal}>OK</Button>
        </Modal.Actions>
      </Modal>

		<SolidCard style={theme.card.hero}>
			<SolidCardHeader>
				<span>Current charges as of today</span>
				<InfoIcon height="24px" onClick={() => props.openModal()} />
			</SolidCardHeader>
			<SolidCardContent>
				<Statistic style={{ width: '100%' }} >
					<Statistic.Value>
						<DollarValue value={props.chargesSoFar}/>
					</Statistic.Value>
				</Statistic>
				{
					(props.predictedSolar !== 0) &&
					<p>
						Includes solar credit of <DollarValue value={props.solarSoFar} plain={true} />
					</p>
				}
			</SolidCardContent>
		</SolidCard>
      </React.Fragment>
  );
})
