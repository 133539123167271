import React, { useState } from 'react';
import ValidatedInput from './ValidatedInput';
import { Button, Icon } from 'semantic-ui-react'
import {
    passwordStrength,
    getPasswordValidationsPassed,
    getPasswordStrengthScore,
    passwordThresholds
} from '../../utils/PasswordStrength';

export default (props) => {
    let [showPassword, setShowPassword] = useState(false);
    let toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    let validations = { }
    let validationErrors = { }

    return (
        <ValidatedInput
            {...props}
            type={showPassword ? "text" : "password"}
            icon={<Icon name={showPassword ? "eye" : "eye slash"} link onClick={toggleShowPassword} />}
        />
    )
}
