import React from 'react';
import {Form} from 'semantic-ui-react';
import { PatternFormat } from 'react-number-format';
import { withFormsy } from 'formsy-react';

export default withFormsy(({
    noErrorProp,
    getErrorMessage,
    getErrorMessages,
    value,
    setValue,
    hasValue,
    isFormDisabled,
    isValid,
    isPristine,
    isFormSubmitted,
    isRequired,
    isValidValue,
    resetValue,
    setValidations,
    showRequired,
    showError,
    hideErrorMessage,
    innerRef,
    validationError,
    validationErrors,
    ...props
}) => {
    const errorLabel = props.hideErrorMessage
        ? null
        : <small
            style={{
            textAlign: 'right',
            color: 'red',
            'padding': '0.25em 0',
            display: 'block'
        }}></small>
        
    const valueToReturn = props.valueFormat
        ? props.valueFormat
        : 'formattedValue';

    return (
        <Form.Field required={props.required} className={props.className}>

            <label>{props.label}</label>
            <PatternFormat
                {...props}
                value={value}
                onValueChange={(values, e) => {
                    setValue(values[valueToReturn]);
                    if (props.onValueChange) {
                        props.onValueChange(values);
                    }
                }}
                customInput={props.as}
			/> {errorLabel}
        </Form.Field>
    )
})
