import { useMemo } from 'react'

const RE_DIGIT = new RegExp(/^\d+$/)

export default function OtpInput({ value, valueLength, onChange }) {
  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (RE_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }

    return items
  }, [value, valueLength])

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling

    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }

  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const inputOnChange = ({ target }, idx) => {
    let targetValue = target.value
  
    const isTargetValueDigit = RE_DIGIT.test(targetValue)

    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling

    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : ' '

    const targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue)

      if (!isTargetValueDigit) {
        return
      }
  
      focusToNextInput(target)
    } else {
      onChange(targetValue)

      target.blur()
    }
  }

  const inputOnKeyDown = (e) => {
    const { key, target } = e
    if (['ArrowRight', 'ArrowDown'].includes(key)) {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (['ArrowLeft', 'ArrowUp'].includes(key)) {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value

    if (key !== 'Backspace' || targetValue !== '') {
      return
    }

    target.setSelectionRange(0, targetValue.length)

    focusToPrevInput(target)
  }

  const inputOnFocus = ({ target }) => {
    const previousElementSibling = target.previousElementSibling

    if (previousElementSibling && previousElementSibling.value === '') {
      return previousElementSibling.focus()
    }
  
    target.setSelectionRange(0, target.value.length)
  }

  return (
    <div className="otp-group">
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="otp-input"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  )
}