import { createContext, useContext, useReducer } from 'react'

const loadedState = JSON.parse(sessionStorage.getItem("signup"));

const initialState = {
  emailAddress: null,
  dob: null,
  accountNo: null,
  maskedEmailAddress: null,
  maskedPhoneNumber: null,
  token: null,
  campaign: null,
  ...loadedState,
}

function signupReducer(state, payload) {
  const newState = { ...state, ...payload }
  sessionStorage.setItem("signup", JSON.stringify(newState));
  return newState;
}

export const SignupContext = createContext(initialState)

export function SignupProvider({ children }) {
  const [state, setState] = useReducer(signupReducer, initialState)

  const signup = {
    ...state,
    setState,
    set: (props) => {
		setState(props);
	}
  }

  return (
    <SignupContext.Provider value={signup}>
      {children}
    </SignupContext.Provider>
  )
}

export const useSignup = () => useContext(SignupContext)

export function withSignup(Component) {
  return function WrappedComponent(props) {
    return (
      <SignupContext.Consumer>
        {context => <Component {...props} signup={context} />}
      </SignupContext.Consumer>
    )
  }
}