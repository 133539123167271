import React from "react";
import axios from "axios";
import qs from "query-string";
import { Image, Dimmer, Loader } from "semantic-ui-react";
import { withRouter } from '../../utils/useRouter';
import Analytics from "../../utils/Analytics";
import { withContexts } from "../../contexts";
import MobileAppComms from "../../utils/MobileAppComms";
import Device from "../../utils/Device";
import logo from "../../static/logo.svg";

const { REACT_APP_API_BASE_URI, REACT_APP_BASE_URI } = process.env;

const STATUS_TEXT = {
  DEFAULT: "Redirecting you to aurora+ insights.",
  FAILED: `We were not able to connect to aurora+ insights.\nIf you tried to open aurora+ insights in a new browser tab or window\nplease return to aurora+ and simply click on the link or button.`
};
class Redirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      statusText: STATUS_TEXT.DEFAULT
    };
    this.getSingleUseLoginToken = this.getSingleUseLoginToken.bind(this);
  }

  async componentDidMount() {
    try {
      const {
        history,
        location,
        app: { isApp },
        customer: { setCustomerPremise }
      } = this.props;
      const { token, ...otherQueries } = await qs.parse(location.search);

      // If the token is not present, generate one and open new tabs with the new token
      if (!token) {
        const newToken = await this.getSingleUseLoginToken();

        const url =
          REACT_APP_API_BASE_URI +
          "identity/ExitAppWithToken" +
          "?" +
          `tokenString=${encodeURIComponent(newToken)}`;

        Analytics.event({
          category: "Bidgely SSO",
          action: "Exit app with token"
        });

        if (isApp) {
          MobileAppComms.postMessage(
            JSON.stringify({ OPEN_EXTERNAL_LINK: url })
          );
        } else {
          window.open(url);

          // local testing
          // window.open(
          //   "http://localhost:3000/redirect/bidgely" +
          //     "?" +
          //     `token=${encodeURIComponent(newToken)}`
          // );
        }

        await history.goBack();

        return;
      }

      // If the token is present, validate it and redirect to Bidgely endpoint
      if (token) {
        const {
          BearerToken: { accessToken },
          LastViewedPremiseId
        } = await this.validateSingleUseLoginToken(token);

        if (accessToken) {
          Analytics.event({
            category: "Bidgely SSO",
            action: "Single-use-token is valid"
          });

          this.props.auth.updateToken(accessToken)
          setCustomerPremise(LastViewedPremiseId);

          // get Bidgely endpoint
          const { SpLogin } = await this.getBidgelyEndpoints();
          const redirectURL = SpLogin + "?" + qs.stringify(otherQueries);

          // local testing
          // const redirectURL = "http://localhost:3000/saml/sso";

          Analytics.event({
            category: "Bidgely SSO",
            action: "Redirect to Bidgely"
          });

          window.location.href = redirectURL;
        }
      }
    } catch (error) {
      this.setState({
        statusText: STATUS_TEXT.FAILED,
        isLoading: false
      });
    }
  }

  getSingleUseLoginToken = async () => {
    const {
      customer: {
        customerPremise: { identifier: premiseId, parentAccountID: accountId }
      }
    } = this.props;

    const {
      data: { token }
    } = await axios.get(
      REACT_APP_API_BASE_URI +
        "identity/GenerateSingleUseLogin" +
        "?" +
        `accountId=${encodeURIComponent(accountId)}` +
        `&premiseId=${encodeURIComponent(premiseId)}`
    );

    Analytics.event({
      category: "Bidgely SSO",
      action: "Single-use-token is generated"
    });

    return token;
  };

  validateSingleUseLoginToken = async token => {
    const { data } = await axios.get(
      REACT_APP_API_BASE_URI +
        "identity/SingleUserLogin" +
        "?" +
        `tokenString=${encodeURIComponent(token)}`
    );
    return data;
  };

  getBidgelyEndpoints = async () => {
    const { data } = await axios.get(
      REACT_APP_API_BASE_URI + "identity/GetBidgelyDetails"
    );

    return data;
  };

  render() {
    const { statusText, isLoading } = this.state;

    return (
      <Dimmer className="page-samlsso" active page>
        <Image src={logo} alt="Aurora APAYG+ logo" />
        <h3 className="text">{statusText}</h3>
        {isLoading && <Loader inverted size="medium" inline />}
      </Dimmer>
    );
  }
}

export default withContexts(withRouter(Redirect));
