import SignupEstimation from "../SignupEstimation/SignupEstimation";

export default ({ premises, customer }) => {

	if (!premises || !customer) return null;

	return (!premises.hasAdvancedMeter && (premises.inAppSignupLifeSupport || customer.lifeSupport)) ? <>

		<p>It looks like you have life support at this property. Whilst you are eligible to have aurora+ at your property, we want to make sure there are no interruptions to your service.</p>
		<p>We have submitted your request and someone will be in contact to organise your meter replacement.</p>
		<p>This could take up to 5 business days.</p>

	</> : <>

		{ !premises.hasAdvancedMeter && <>
			<p>Advanced meter installation required</p>
			<p>You will be contacted by TasMetering who will organise installation of an advanced meter at your property within the next 15 business days</p>
		</> }

		<SignupEstimation premises={premises} />

		<p>You will be notified via email once your aurora+ account is ready to use.</p>

	</>

}
