import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon"
        className="weather-icon"  
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M244.57,108.3A108.94,108.94,0,0,0,156,153.92a90.5,90.5,0,0,0-10.19-.62A89.46,89.46,0,0,0,56.53,239C22.4,254.08,0,289.09,0,328.65v4.66c0,24.95,23.05,45.25,51.38,45.25l245.73.55c14.82,0,28.47-7.08,37.49-19.44a41.33,41.33,0,0,0,9.66-26.36c0-.49,0-1-.07-1.61s.07-1.22.07-1.84a49.83,49.83,0,0,0-19-39.4A108.89,108.89,0,0,0,244.57,108.3Zm0,22.66A86.23,86.23,0,0,1,309.79,273.8c-6.88-41.92-41.71-73.91-83.55-73.91l-2,0A89.33,89.33,0,0,0,180,160.11,86.14,86.14,0,0,1,244.57,131Zm71.86,215.17a23.76,23.76,0,0,1-19.32,10.33L51.38,355.9c-15.57,0-28.72-10.34-28.72-22.59v-4.66c0-32.78,19.66-61.41,48.94-71.24l8.12-2.73s-.61-11.41-.61-12a66.68,66.68,0,0,1,128-26.33l3.36,7.81,8.44-1.05a58.49,58.49,0,0,1,7.38-.52c34,0,61.73,29.35,62.15,65.6-.23,4.09-3.5,14.39-3.5,14.39l13.57.81c13,.77,23.15,12.41,23.12,27l0,2.92C321.61,338.73,316.43,346.13,316.43,346.13Z" transform="translate(0 -41.93)" />
    <path d="M138.56,120.83a11.33,11.33,0,0,0,16.54-15.49L137.6,86.66a11.33,11.33,0,0,0-16.54,15.49Z" transform="translate(0 -41.93)" />
    <path d="M409.12,255.4,384.63,248a11.33,11.33,0,1,0-6.57,21.69l24.49,7.42a11.33,11.33,0,1,0,6.57-21.68Z" transform="translate(0 -41.93)" />
    <path d="M372.71,187.45a11.33,11.33,0,0,0,11,8.75,10.9,10.9,0,0,0,2.59-.3l24.93-5.82A11.33,11.33,0,0,0,406.09,168l-24.92,5.82A11.34,11.34,0,0,0,372.71,187.45Z" transform="translate(0 -41.93)" />
    <path d="M375.79,94.39a11.33,11.33,0,0,0-16-.53l-18.67,17.5a11.33,11.33,0,1,0,15.48,16.54l18.68-17.5A11.33,11.33,0,0,0,375.79,94.39Z" transform="translate(0 -41.93)" />
    <path d="M282.73,91.42a11,11,0,0,0,3.29.5,11.33,11.33,0,0,0,10.83-8l7.43-24.49a11.33,11.33,0,1,0-21.68-6.57L275.17,77.3A11.33,11.33,0,0,0,282.73,91.42Z" transform="translate(0 -41.93)" />
    <path d="M201,80.77a11.33,11.33,0,0,0,22.06-5.16l-5.82-24.92a11.33,11.33,0,0,0-22.07,5.15Z" transform="translate(0 -41.93)" />
  </g>
</svg>

)
}