import React from 'react';
import {Link} from 'react-router-dom';

import Device from '../utils/Device';
import {Button, Segment, Grid, Header, Image} from 'semantic-ui-react';

import Layout from '../layouts/Layout';
import LoggedOutLayout from '../layouts/LoggedOutLayout';
import logo from '../static/aurora_plus_logo.svg';
import ExternalLink from './../components/ExternalLink';

import playLogo from '../static/playStoreLogo.png'
import itunesLogo from '../static/itunesStoreLogo.svg'

export const androidAppStoreUrl = "https://play.google.com/store/apps/details?id=au.com.auroraenergy.android.mobileapp";
export const appleAppStoreUrl = "https://itunes.apple.com/us/app/aurora-payg/id1425726650";

export default class AppDownload extends React.Component {

  componentDidMount() {
    this.isAndroid = !Device.isMobileDevice() || Device.isAndroidDevice();
    this.isApple = !Device.isMobileDevice() || Device.isAppleDevice();
  }

  renderAndroid() {
    return (<Image
      as={ExternalLink}
      eventLabel="Opened link to Android App Store"
      href={androidAppStoreUrl}
      src={playLogo}
      fluid/>);
  }

  renderApple() {
    return (<Image
      as={ExternalLink}
      eventLabel="Opened link to iTunes App Store"
      href={appleAppStoreUrl}
      src={itunesLogo}
      fluid/>);
  }

  render() {
    return (
      <Layout accountPicker={false} layout={LoggedOutLayout}>
        <Grid padded centered>
          <Grid.Row only='mobile tablet' stretched>
            <Segment basic>
              <Link to="/"><Image centered size="small" src={logo}/></Link>
            </Segment>
            <Segment basic style={{
              width: '100%'
            }}>
              <Header size="small" textAlign="center" inverted>
                Download the App
              </Header>
              <div>
                {(this.isAndroid)
                  ? this.renderAndroid()
                  : this.renderApple()
}
              </div>
              <p style={{
                margin: '2em 0'
              }}>
                <small>
                  <b>Or</b>
                </small>
              </p>
              <Button as={Link} size="tiny" to="/login" fluid>
                Visit Website
              </Button>
            </Segment>
          </Grid.Row>
        </Grid>
      </Layout>
    );
  }
}
