import React from 'react'
import WeatherIcon from '../components/Icons/weather'

const weatherData = {
  1: { Description: "Sunny", Icon: <WeatherIcon.Sun /> },
  2: { Description: "Cloudy", Icon: <WeatherIcon.SunCloud /> },
  3: { Description: "Overcast", Icon: <WeatherIcon.Cloud /> },
  4: { Description: "Overcast", Icon: <WeatherIcon.Cloud /> },
  5: { Description: "Overcast", Icon: <WeatherIcon.Cloud /> },
  6: { Description: "Fog", Icon: <WeatherIcon.Fog /> },
  7: { Description: "Fog", Icon: <WeatherIcon.Fog /> },
  8: { Description: "Rain", Icon: <WeatherIcon.LightRain /> },
  9: { Description: "Rain", Icon: <WeatherIcon.Rain /> },
  10: { Description: "Rain", Icon: <WeatherIcon.Rain /> },
  11: { Description: "Drizzle", Icon: <WeatherIcon.LightRain /> },
  12: { Description: "Showers", Icon: <WeatherIcon.LightRain /> },
  13: { Description: "Showers", Icon: <WeatherIcon.Rain /> },
  14: { Description: "Showers", Icon: <WeatherIcon.LightRain /> },
  15: { Description: "Showers", Icon: <WeatherIcon.LightRain /> },
  16: { Description: "Showers", Icon: <WeatherIcon.LightRain /> },
  17: { Description: "Showers", Icon: <WeatherIcon.Rain /> },
  18: { Description: "Showers", Icon: <WeatherIcon.Rain /> },
  19: { Description: "Showers", Icon: <WeatherIcon.Rain /> },
  20: { Description: "Storm", Icon: <WeatherIcon.Thunder /> },
  21: { Description: "Storm", Icon: <WeatherIcon.Thunder /> },
  22: { Description: "Storm", Icon: <WeatherIcon.Thunder /> },
  23: { Description: "Storm", Icon: <WeatherIcon.Thunder /> },
  24: { Description: "Storm", Icon: <WeatherIcon.Thunder /> },
  25: { Description: "Storm", Icon: <WeatherIcon.Thunder /> },
  26: { Description: "Snow", Icon: <WeatherIcon.Snow /> },
  27: { Description: "Snow", Icon: <WeatherIcon.Snow /> },
  28: { Description: "Snow", Icon: <WeatherIcon.Snow /> },
  29: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  30: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  31: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  32: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  33: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  34: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  35: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  36: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  37: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  38: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  39: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  40: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  41: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  42: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  43: { Description: "Snow", Icon: <WeatherIcon.SnowRain /> },
  44: { Description: "Sand Storm", Icon: <WeatherIcon.Rain /> },
  45: { Description: "Snow", Icon: <WeatherIcon.Snow /> },
  46: { Description: "Hail", Icon: <WeatherIcon.SnowRain /> },
  47: { Description: "Fog", Icon: <WeatherIcon.Fog /> },
  48: { Description: "Cloudy", Icon: <WeatherIcon.SunCloud /> },
  49: { Description: "Fog", Icon: <WeatherIcon.Fog /> },
  50: { Description: "Sunny", Icon: <WeatherIcon.Sun /> }
}

function weatherSearch(val) {
  if(val === "Null" || val === "null"){
    return weatherData[2]
  }
  
  if(val === null){
    return weatherData[2]
  }

  if(val === "none" || val === "None"){
    return weatherData[2]
  }

  return weatherData[val]
}

export {
    weatherData, 
    weatherSearch
}
