import React, { PureComponent } from "react";
import Bar from './Bar'

import {tariffSearch} from '../../utils/Tariffs'
import { XaxisHandler, WidthHandler } from './modelFunctions'

export default function DataBars (props) {

  let handleClick = (model, data, ref) => props.onClick(model, data, ref);

  let renderBars = (props) => {

    const rectProps = (data, index, key, timeRange) => {
      const tariffSettings = tariffSearch(key);
      const dataObj = data[props.unitOfMeasurement];
      const isPeak = !key.includes("OFFPEAK");
	  const hasSubstitute = data.HasSubstitutedData;

	  let keyValue = dataObj ? dataObj[key] : 0;

      if (tariffSettings.isSolar && keyValue < 0) {
        keyValue = -keyValue;
      }

      if (0 < keyValue && keyValue < (props.dataMax * .01)) {
        keyValue = props.dataMax * .01 
      }

      const keyValueYScaled = keyValue ? props.yScale(keyValue) : props.yScale(0);

      // have to check for "IsPeak" for daily views.
      // however this value only comes through for daily, not any other time period
      // Use the XaxisHandler and WidthHandler to control custom positioning for tariffs


      var contentHeight = props.contentHeight;
      if (hasSubstitute) contentHeight = props.contentHeight -2;

      const model = {
        className: "bar hasPopup",
        datatype: key,
        units: props.unitOfMeasurement,
        rx: 2, // corner radius
        ry: 2,
        y: null,
        height: props.hasSolar
          ? (props.solar
            ? (props.contentHeight) - keyValueYScaled
            : (props.contentHeight * 0.5) - keyValueYScaled)
          : props.contentHeight - keyValueYScaled,

        x: XaxisHandler(key, tariffSettings.Type, isPeak, props, tariffSettings.isSolar, data, timeRange),
        width: data ? WidthHandler(key, tariffSettings.Type, isPeak, props, tariffSettings.isSolar, data, timeRange) : null,
        key: `${index}_${key}`,
        peak: data.isPeak ? "true" : "false", // only populated for day kilowatt view
        value: keyValue,
        //APAYGDEV-349 Substituted data displays differently
        style: hasSubstitute ? {strokeWidth: "3", stroke: tariffSearch(key).color} : null,
        fill: hasSubstitute ? "transparent" : tariffSearch(key).color
      }

      model.height = model.height < 0 ? 0 : model.height

      model.y = props.hasSolar
        ? (props.solar
          ? keyValueYScaled || 0
          : (tariffSettings.isSolar
            ? props.yScale(0)
            : keyValueYScaled))
        : (keyValueYScaled || 0)
        
      return model;
    }

    // render as many bars as we need (based on keys, which are mappings of {kwH, $, Solar$})
    // these bars are all related, i.e. the same time period, showing different tariffs
    // The bars component is used to handle the animations, if animations are no longer required you can replace Bar with a simple svg rect
    return (data, index) => {

      return (
        <g key={index} className="bar-group" transform={`translate(${props.xScale0(data.StartTime)},0)`}>
          {props.keys.map(key => 
            <Bar {...rectProps(data, index, key, props.timeRange)} targetData={data} barClickHandler={handleClick} solar={props.solar} />
          )}
        </g>
      );
    };
  }

  return (
    <React.Fragment>
      <clipPath id="bar-clip">
        <rect
          y={-props.margins.bottom}
          width={props.contentWidth + props.margins.right}
          height={props.contentHeight + props.margins.bottom} />
      </clipPath>

      <g className="clippedArea" clipPath="url(#bar-clip)">
        <g className="bars" transform={`translate(${props.panX},0)`}>
          {props.data.map(renderBars(props))}
        </g>
      </g>
    </React.Fragment>
  );
}
