import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '23px',
  width = '20px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path fill={color} d="M16 0C11.5994 0 8 3.59941 8 8V12H4.5C2.0325 12 0 14.0325 0 16.5V35.5C0 37.9675 2.0325 40 4.5 40H27.5C29.9675 40 32 37.9675 32 35.5V16.5C32 14.0325 29.9675 12 27.5 12H24V8C24 3.59941 20.4006 0 16 0ZM16 3C18.7794 3 21 5.22059 21 8V12H11V8C11 5.22059 13.2206 3 16 3ZM4.5 15H27.5C28.3465 15 29 15.6535 29 16.5V35.5C29 36.3465 28.3465 37 27.5 37H4.5C3.6535 37 3 36.3465 3 35.5V16.5C3 15.6535 3.6535 15 4.5 15ZM9 24C8.46957 24 7.96086 24.2107 7.58579 24.5858C7.21071 24.9609 7 25.4696 7 26C7 26.5304 7.21071 27.0391 7.58579 27.4142C7.96086 27.7893 8.46957 28 9 28C9.53043 28 10.0391 27.7893 10.4142 27.4142C10.7893 27.0391 11 26.5304 11 26C11 25.4696 10.7893 24.9609 10.4142 24.5858C10.0391 24.2107 9.53043 24 9 24ZM16 24C15.4696 24 14.9609 24.2107 14.5858 24.5858C14.2107 24.9609 14 25.4696 14 26C14 26.5304 14.2107 27.0391 14.5858 27.4142C14.9609 27.7893 15.4696 28 16 28C16.5304 28 17.0391 27.7893 17.4142 27.4142C17.7893 27.0391 18 26.5304 18 26C18 25.4696 17.7893 24.9609 17.4142 24.5858C17.0391 24.2107 16.5304 24 16 24ZM23 24C22.4696 24 21.9609 24.2107 21.5858 24.5858C21.2107 24.9609 21 25.4696 21 26C21 26.5304 21.2107 27.0391 21.5858 27.4142C21.9609 27.7893 22.4696 28 23 28C23.5304 28 24.0391 27.7893 24.4142 27.4142C24.7893 27.0391 25 26.5304 25 26C25 25.4696 24.7893 24.9609 24.4142 24.5858C24.0391 24.2107 23.5304 24 23 24Z" />
    </svg>

  );
}