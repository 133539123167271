import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import { useSignup } from "../../../contexts/Signup.js";
import IconRadioGroup from "../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../components/IconRadio/IconRadio.js";
import SignupCheckbox from "../components/SignupCheckbox/SignupCheckbox.js";
import ValidatedInput from "../../../components/Forms/ValidatedInput.js";
import { useEffect } from "react";
import Analytics from "../../../utils/Analytics.js";


export default () => {

	const navigate = useNavigate();
	useScrollToTop();

	const signup = useSignup();
	if (!signup.token || (!signup.maskedEmailAddress && !signup.maskedPhoneNumber)) navigate('/signup/residential');

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	useEffect(() => {
		Analytics.event({ category: "Signup", action: "UpdateEmail" });
	}, [])

	
	function submit(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/edit-email/verify/send";

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);


		axios
			.post(endpoint, {
				emailAddress: payload.email,
			}, {
				headers: {
					common: {
						'authorization': 'bearer ' + signup.token
					}
				},
				ignoreGlobalErrors: true
			})
			.then(response => {
				setFormState(FORM_STATE.DONE_LOADING);

				//Analytics.event({ category: "User", action: "Successful Login" });		

				signup.set({ emailAddress: payload.email });				
				
				setTimeout(() => {
					navigate('/signup/residential/verify-update');
				}, 1);
				
			})
			.catch(error => {
				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				//Analytics.event({ category: "User", action: "Failed " });

				switch(error.response ? error.response.status : error.code) {
					case 404:
						navigate('/signup/external/residential');
						break;
					case 409:
						navigate('/signup/residential/login-exists');
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;

				}
				
			});
	}

	return (		
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Confirm your email address", journeyName: "signup", currentStep: 3 }}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				<p>
					Please enter an updated email address below:
				</p>
			
				<ValidatedInput
	            	required
					validations={{
						isEmail: true,
						maxLength: 320,
					}}
					validationErrors={{
						isEmail: "You must enter a valid email address"
					}}
					type="email"
					fluid
					name="email"
					placeholder="Enter email Address"
                />
                <ValidatedInput
					required
					validations={{
						isEmail: true,
						equalsField: 'email'
					}}
					validationError="The confirmation email must match"
					type="email"
					fluid
					name="email_confirmation"
					placeholder="Confirm Email Address"
				/>



				<SignupButton disabled={!canSubmit} loading={formState === FORM_STATE.LOADING}>
					Continue
				</SignupButton>

			</Form>

			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

}


