import React from 'react';
import axios from 'axios';
import { withCustomer } from '../../contexts/Customer';
import Premise from '../../model/Premise';
import { Button, Image } from 'semantic-ui-react';

import Responsive from "../../components/Responsive/Responsive";
import ExternalLink from '../../components/ExternalLink';
import Payment from '../Payment/Payment';

//import infographic from './assets/interim_infographic_desktop.png';
import infographicDesktop from './assets/interim_infographic_desktop.svg';
import infographicMobile from './assets/interim_infographic_mobile.svg';
import logo from '../../static/logo.svg'
import './Interim.css';

class Interim extends React.Component {

  constructor(props) {
    super(props);
    this.paymentWindow = React.createRef();
  }

  state = {
    paymentDisabled: true,
    isPaymentShown: false,
    currentPremise: null,
    loadingPremise: false,
    currentlySelectedStep: 'informing',
  }

  componentDidMount() {
    this.fetchCurrentPendingStartPremise();
    this.interval = setInterval(() => window.location.reload(false), 900000);
  }

  fetchCurrentPendingStartPremise() {
    const { customer } = this.props.customer;

	if (!customer) {
		// DPS-516 & DPS-20 if an existing hub customer is moving to a+, no customer
		// object is returned.
        this.setState({ paymentDisabled: true });
		return;
	}

    const PENDING_START_STATUS = '10';
    const url = process.env.REACT_APP_API_BASE_URI
      + `premises/${PENDING_START_STATUS}?customerID=${customer.customerID}`;

    this.setState({ loadingPremise: true });

    axios.get(url)
      .then(res => {
        let premises = res.data;
        if (!premises) return;

        premises = premises
          .map(p => new Premise(p))
          .sort((a, b) => a.serviceAgreementStatusCompare(b));

        this.setState({ currentPremise: premises[0] });
      })
      .catch(i => {
        console.error(i);

        // if for some reason we cannot get a premise, we then do not have enough information
        // to be able to make a payment, so disable payment functionality
        this.setState({ paymentDisabled: true });
      })
      .finally(() => {
        this.setState({ loadingPremise: false });
      });
  }

  onModalClose(modalNames) {
    const namesThatTriggerClose = [
      'mPaymentSuccess',
      'mPaymentFailure',
      'mPaymentSuccessCardSaveFailure',
    ];

    const closePaymentWindow = modalNames.some(name => namesThatTriggerClose.includes(name));

    if (closePaymentWindow) this.setState({ isPaymentShown: false });
  }

  renderPaymentButton() {
    const { paymentDisabled, isPaymentShown, loadingPremise } = this.state;

    if (paymentDisabled) return null;

    const text = isPaymentShown ? "Cancel" : "Make a Payment"

    return (
        <Button
          className="payment-button"
          onClick={() => {
            this.setState({ isPaymentShown: !isPaymentShown })

            // scroll to payment window, but wait until after re-render
            setTimeout(() => this.paymentWindow.current.scrollIntoView({behavior: 'smooth'}), 150);
          }}
          loading={loadingPremise}
          disabled={loadingPremise}
        >
          { text }
        </Button>
    )
  }

  renderInfographic() {
    return (
      <div className="infographic-container">
        <Responsive lessThan="computer">
          <img className="infographic-image" src={infographicMobile} alt="infographic" />
        </Responsive>
        <Responsive greaterThan="tablet">
          <img className="infographic-image" src={infographicDesktop} alt="infographic" />
        </Responsive>

        <div className="infographic-bottom-text">
          <div>
            <p>
              Check out the <ExternalLink href="https://www.auroraenergy.com.au/faqs/aurora-plus">aurora+ Help Centre</ExternalLink> while
              you wait. You'll find everything you need to make the most of aurora+.
              Take an app tour, ask a question and discover everything aurora+ has to offer
              at the <ExternalLink href="https://www.auroraenergy.com.au/faqs/aurora-plus">aurora+ Help Centre</ExternalLink>.
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isPaymentShown, currentPremise } = this.state;
    const { logoutUser } = this.props;
    let accountId = undefined;

    if (currentPremise) accountId = currentPremise.parentAccountID;

    return (
      <div className="interim-container">
        <div className="logout-button-container">
          <Button
            className="logout-button"
            onClick={logoutUser}
          >
            Logout
          </Button>
        </div>
        <Image className="logo" src={logo} alt="Aurora+ logo"/>

        <h2>Welcome to aurora+</h2>
        <p>Your product is nearly ready! There are just a couple of things we need to do first.<br/>Below you can see where you are in the process.</p>

        { this.renderInfographic() }

        { this.renderPaymentButton() }

        <div ref={this.paymentWindow}>
          {
            isPaymentShown ?
              (<Payment
                restrictedNavigation
                accountIdOverride={accountId}
                onModalClose={i => this.onModalClose(i)} />)
              : null
          }
        </div>
      </div>
    )
  }
}

export default withCustomer(Interim);
