import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '28px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 80 78"
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M10.9981 3.96592e-08C10.4691 -8.59613e-05 9.94949 0.139699 9.49192 0.405184C9.03436 0.670668 8.65512 1.05241 8.39266 1.51172L0.392656 15.5117C0.135977 15.9628 0.000687266 16.4727 2.61157e-06 16.9917C-0.000682043 17.5106 0.133262 18.0209 0.38875 18.4727C0.843904 21.6684 2.12309 24.6016 3.99812 27.043V75C3.9982 75.7956 4.3143 76.5586 4.87689 77.1212C5.43948 77.6838 6.2025 77.9999 6.99812 78H72.9981C73.7937 77.9999 74.5568 77.6838 75.1194 77.1212C75.6819 76.5586 75.998 75.7956 75.9981 75V27.0508C77.8772 24.6064 79.1571 21.6659 79.6114 18.4648C79.8649 18.0137 79.9974 17.5047 79.996 16.9873C79.9946 16.4698 79.8594 15.9615 79.6036 15.5117L71.6036 1.51172C71.3411 1.05241 70.9619 0.670668 70.5043 0.405184C70.0468 0.139699 69.5271 -8.58203e-05 68.9981 3.96592e-08H10.9981ZM12.7403 6H67.2559L73.7911 17.4336C73.0662 23.3815 68.1577 28 61.9981 28C58.6645 28 55.6804 26.6542 53.5059 24.4766C52.962 23.9324 52.2298 23.618 51.4606 23.5984C50.6914 23.5788 49.9441 23.8554 49.3731 24.3711C46.8783 26.626 43.6199 28 39.9981 28C36.3764 28 33.1179 26.626 30.6231 24.3711C30.0521 23.8554 29.3048 23.5788 28.5357 23.5984C27.7665 23.618 27.0343 23.9324 26.4903 24.4766C24.3159 26.6542 21.3318 28 17.9981 28C11.8386 28 6.93007 23.3815 6.20516 17.4336L12.7403 6ZM28.67 30.1094C31.9197 32.4018 35.7226 34 39.9981 34C44.2736 34 48.0766 32.4018 51.3262 30.1094C54.3469 32.3948 57.9333 34 61.9981 34C64.8886 34 67.6008 33.2978 69.9981 32.0664V72H61.9981V43C61.998 42.2044 61.6819 41.4414 61.1194 40.8788C60.5568 40.3162 59.7937 40.0001 58.9981 40H20.9981C20.2025 40.0001 19.4395 40.3162 18.8769 40.8788C18.3143 41.4414 17.9982 42.2044 17.9981 43V72H9.99813V32.0664C12.3957 33.2987 15.1067 34 17.9981 34C22.063 34 25.6494 32.3948 28.67 30.1094ZM23.9981 46H36.9981V72H23.9981V46ZM42.9981 46H55.9981V72H42.9981V46Z" />
      </svg>
)
}

