import React from 'react';
import {Grid} from 'semantic-ui-react'

const ColumnLayout = ({children, dimmer, column}) => (
  <Grid centered>
    {dimmer}
    <Grid.Column
      {...column}
      style={{
      padding: 0
    }}
      className="main-container">
      {children}
    </Grid.Column>
  </Grid>
)

ColumnLayout.defaultProps = {
  column: {
    mobile: 16,
    tablet: 10,
    computer: 8
  }
}

export default ColumnLayout