
import React from 'react';

export default function Icon({
    color = 'currentColor',
    height = '16px',
    width = height,
    style = {}
}) { return (
    <svg 
        aria-hidden="true"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }}
        focusable="false" 
        data-prefix="fas" 
        data-icon="info" 
        className="svg-inline--fa fa-info fa-w-6" 
        role="img" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 192 512">
            <path fill="currentColor" d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"></path>
    </svg>
)
}