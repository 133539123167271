import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '28px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 75 82"
      
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M37.3983 0.00151218C36.7319 0.0242674 36.0903 0.259521 35.5672 0.672899L5.95703 24.0005C2.19891 26.9623 0 31.488 0 36.2727V76.0432C0 78.884 2.36751 81.2515 5.20833 81.2515H26.0417C28.8825 81.2515 31.25 78.884 31.25 76.0432V55.2098C31.25 54.5952 31.677 54.1682 32.2917 54.1682H42.7083C43.323 54.1682 43.75 54.5952 43.75 55.2098V76.0432C43.75 78.884 46.1175 81.2515 48.9583 81.2515H69.7917C72.6325 81.2515 75 78.884 75 76.0432V36.2727C75 31.488 72.8011 26.9623 69.043 24.0005L39.4328 0.672899C38.8549 0.216389 38.1344 -0.0213863 37.3983 0.00151218ZM37.5 7.106L65.1774 28.9118C67.436 30.6918 68.75 33.399 68.75 36.2727V75.0015H50V55.2098C50 51.2204 46.6978 47.9182 42.7083 47.9182H32.2917C28.3022 47.9182 25 51.2204 25 55.2098V75.0015H6.25V36.2727C6.25 33.399 7.56404 30.6918 9.82259 28.9118L37.5 7.106Z" />
    </svg>
)
}
