import Cloud from './Cloud'
import Fog from './Fog'
import LightRain from './LightRain'
import Rain from './Rain'
import Snow from './Snow'
import SnowRain from './SnowRain'
import Sun from './Sun'
import SunCloud from './SunCloud'
import Thunder from './Thunder'

export default {
    Cloud,
    Fog,
    LightRain,
    Rain,
    Snow,
    SnowRain,
    Sun,
    SunCloud,
    Thunder
}
