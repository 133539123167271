import React, { Component } from "react";
import moment from 'moment-timezone';
import {cloneDeep} from 'lodash'
import {weatherSearch} from '../../utils/WeatherValuesMap'

import "./WeatherOverlay.css"

export default class WeatherOverlay extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data && this.props.timeRange === "week" && this.props.data.length <= 7) {
            this.props.weatherData ? this.mapWeatherData() : null
        }

        if (prevProps.weatherData !== this.props.weatherData) {
            this.props.weatherData ? this.mapWeatherData() : null
        }
    }

  mapWeatherData() {
        let tempState = cloneDeep(this.props)
        for (let i = 0; i < this.props.data.length; i++) {
            for (let e = 0; e < this.props.weatherData.length; e++) {
                if (moment(this.props.data[i].StartTime).isSame(this.props.weatherData[e].day, 'day')) {
                  tempState.data[i].weather = this.props.weatherData[e]
                  tempState.data[i].weather.position = this.props.xScale0(this.props.data[i].StartTime) + 15
                }
            }
        }
        this.setState({data: tempState.data})
    }

  displayIcon(val) {
        return (
            val
        )
    }

  weatherDataMap(val) {
    // negate both properties, this means round() will round down when at 0.5
    const roundHalfDown = num => -Math.round(-num);

    if (val.weather) {
        const minTemp = roundHalfDown(val.weather.min_temp);
        const maxTemp = roundHalfDown(val.weather.max_temp); 
        const symbol = val.weather.symbol; 

          return (
              <g 
                  key={val.StartTime} 
                  className="weather-axis-item" 
                  transform={`translate(${val.weather.position},0)`}
                  width={this.props.xScale1.bandwidth()}
                  height={35}>
                  <g transform={`translate(-11,-20)`}>{this.displayIcon(weatherSearch(symbol).Icon)}</g>
                  <text fill="currentColor" y="15" textAnchor="middle">{weatherSearch(symbol).Description}</text>
                  <text fill="currentColor" y="25" textAnchor="middle">{`${minTemp}°C / ${maxTemp}°C`}</text>
              </g>
          )
        } else {
            return null
        }
    }

    render () {
        const { data } = this.state;
        const { panX } = this.props;

        return (
            <g className="weather-axis" transform={`translate(${panX},-60)`}>
                {data && data.map((item) => this.weatherDataMap(item))}
            </g>
        )
    }
}
