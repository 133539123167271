import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '11px',
  width = '18px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 47 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path fill={color} d="M23.4646 10.7761L39.3854 26.697C41.1104 28.422 43.9104 28.422 45.6354 26.697C47.3604 24.972 47.3604 22.172 45.6354 20.447L26.4104 1.22197C24.7813 -0.407193 22.1438 -0.407193 20.5188 1.22197L1.29379 20.447C-0.431214 22.172 -0.431213 24.972 1.29379 26.697C3.01879 28.422 5.81878 28.422 7.54378 26.697L23.4646 10.7761Z" />
    </svg>
  );
}
