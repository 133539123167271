import React from 'react';

import Popup from 'rmc-picker/lib/Popup'
import MultiPicker from 'rmc-picker/lib/MultiPicker'
import Picker from 'rmc-picker/lib/Picker'

import { Button, Icon, } from 'semantic-ui-react';

import 'rmc-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';

import Modal from './../../../components/Modal';
import DateContextHelper from '../DateContextHelper';

const MOBILE_PICKER = {
  day: {
    title: 'Select Day',
    options: ['calculateDayOptions', 'calculateMonthOptions', 'calculateYearOptions']
  },
  week: {
    title: 'Select Week',
    options: ['calculateWeekOptions', 'calculateYearOptions']
  },
  month: {
    title: 'Select Month',
    options: ['calculateMonthOptions', 'calculateYearOptions']
  },
  quarter: {
    title: 'Select Quarter',
    options: ['calculateQuarterOptions', 'calculateYearOptions']
  },
  year: {
    title: 'Select Year',
    options: ['calculateYearOptions']
  }
}

const generateDatePickerContent = ({ data, timeRange, pickerValues, onValueChange, getClosestSelection }) => {

  const startDate = data && data.StartDate;
  const endDate = data && data.EndDate;

  if (!MOBILE_PICKER[timeRange])
    return null

  const {
    title,
    options = []
  } = MOBILE_PICKER[timeRange]

  let selectedValue = []

  const pickers = options.map((fn, i) => ( //APAYGDEV-360, onWheel and "multipicker" weren't playing nice together, so something else needed to be worked out
    <div key={fn} onWheel={(e) => {
        const newSelection = getClosestSelection(pickerValues);
        
        var d = new Date();

        if(e.deltaY < 0) // Scroll down the dates
        {
          if(pickerValues[2] == 2018 && i == 2) //don't let us scroll before 2018
          {  
            return 2018;
          }
          else
          {
            newSelection[i]--;
          }
        }
        else if (e.deltaY > 0) // scroll up the dates
        {
          if(pickerValues[2] == d.getFullYear() && i == 2) //don't let us scroll beyond the current year
          {
            return d.getFullYear();
          }
          else
          {
            newSelection[i]++;
          }
        }

        onValueChange(getClosestSelection(newSelection))
      }
    } className="rmc-picker">
      
      <Picker key={i} selectedValue={pickerValues[i]} onValueChange={(v) => {
        const newSelection = getClosestSelection(pickerValues);
        newSelection[i] = v;
        onValueChange(newSelection)
        }}>

        {DateContextHelper[fn](pickerValues, timeRange, startDate, endDate, true).map((opt, i2) => {
          if (opt.selected) {
            selectedValue[i] = opt.value
          }

          return <Picker.Item
            key={i2}
            value={opt.value}
            style={opt.header
            ? {
              float: 'left',
              textAlign: 'left',
              fontWeight: 'bold',
              width: 'auto',
              display: 'inline-block'
            }
            : {}}>{opt.text}</Picker.Item>
        })}
      </Picker>
      
      </div>
  ))

  var pickersDivStyle = //Since we aren't using the "multipicker" for desktop, we need to fix the styling of the 3 individual pickers
  {
    display: 'flex'
  };

  const picker = (
    <div style={pickersDivStyle}>
      {pickers}
    </div>
  )

  return {title, picker}
}

const MobileDatePicker = props => {
  const { content, title, onOk, data, color } = props;

  return (
    <Popup content={content} title={title} onOk={onOk}>
      <span
        style={{
          color: color
        }}>
        {data.SummaryLabel}&nbsp; {data.SummaryLabel && <Icon name="calendar alternate outline"/>}
      </span>
    </Popup>
  )
}

const DesktopDatePicker = props => {
  const { content, title, onOk, toggleVisible, visible, data, color } = props;

  return (
    <React.Fragment>
      <Modal size="tiny" name={title} open={visible}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {content}
        </Modal.Content>
        <Modal.Actions>

          <Button
            color="grey"
            onClick={() => toggleVisible(false)}>Cancel</Button>
          <Button
            color="orange"
            onClick={() => {
            onOk();
            toggleVisible(false);
          }}>Ok</Button>
        </Modal.Actions>
      </Modal>
      <span
          style={{
          cursor: 'pointer',
          color: color
        }}
        onClick={() => toggleVisible(true)}
      >
        {data.SummaryLabel}&nbsp; {data.SummaryLabel && <Icon name="calendar alternate outline"/>}
      </span>
    </React.Fragment>
  )
}

export { DesktopDatePicker, MobileDatePicker, generateDatePickerContent };
