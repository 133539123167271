import React from 'react';
import Analytics from './../../utils/Analytics';

export default function InfoIcon({
    color = 'currentColor',
    height = '24px',
    width = height,
    style = {},
    onClick,
    eventName,
    ...rest
}) {
    return (
        <svg
            onClick={e => {
            if (eventName) 
                Analytics.modalview('info/' + eventName);
            onClick && onClick(e);
        }}
            height={height}
            width={width}
            viewBox="0 0 19 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...style, cursor: 'pointer' }}
            {...rest}>
            <g
                id="Screens-v2"
                stroke="none"
                strokeWidth="1"
                fill={color}
                fillRule="nonzero">
                <g id="legend" transform="translate(-50.000000, 0.000000)" fill={color}>
                    <g id="Group-5">
                        <g
                            id="peak"
                            transform="translate(35.000000, 9.600000) rotate(90.000000) translate(-35.000000, -9.600000) translate(25.500000, -24.900000)">
                            <g id="Group" transform="translate(0.000000, 0.000000)">
                                <g id="Group-4" transform="translate(0.000000, 0.500000)">
                                    <g
                                        id="info-icon"
                                        transform="translate(9.500000, 10.000000) rotate(-90.000000) translate(-9.500000, -10.000000) translate(0.000000, 0.500000)">
                                        <g id="Group" transform="translate(0.900000, 0.500000)">
                                            <path
                                                d="M9,0.9 C13.46634,0.9 17.1,4.53366 17.1,9 C17.1,13.46634 13.46634,17.1 9,17.1 C4.53366,17.1 0.9,13.46634 0.9,9 C0.9,4.53366 4.53366,0.9 9,0.9 Z M9,0 C4.02948,0 0,4.02948 0,9 C0,13.97052 4.02948,18 9,18 C13.97052,18 18,13.97052 18,9 C18,4.02948 13.97052,0 9,0 Z"
                                                id="Shape"
                                                fillRule="nonzero"></path>
                                            <circle id="Oval" fillRule="nonzero" cx="9" cy="5.31828" r="1"></circle>
                                            <rect
                                                id="Rectangle-path"
                                                fillRule="nonzero"
                                                x="8.37"
                                                y="7.4178"
                                                width="1.26"
                                                height="6.15222"></rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
