import { AppProvider, useApp } from './App'
import { AuthProvider, useAuth } from './Auth'
import { CustomerProvider, useCustomer } from './Customer'
import { ThemeProvider, useTheme } from './Theme'
import { SignupProvider } from './Signup'
import { LoginReturnProvider } from './LoginReturn'
import { TariffProvider } from './Tariff'

export function Providers({ children }) {
  return (
    <AuthProvider>
      <AppProvider>
        <CustomerProvider>
          <ThemeProvider>
			      <SignupProvider>
			        <LoginReturnProvider>
			          <TariffProvider>
                  {children}
			          </TariffProvider>
              </LoginReturnProvider>
			      </SignupProvider>
          </ThemeProvider>
        </CustomerProvider>
      </AppProvider>
    </AuthProvider>
  )
}

export const withContexts = (Component) => (props) => {
  const app = useApp()
  const auth = useAuth()
  const customer = useCustomer()
  const theme = useTheme()

  return (
    <Component
      {...props}
      app={app}
      auth={auth}
      customer={customer}
      theme={theme}
    />
  )
}