
import React from 'react';

import './IconRadio.css';
import { Checkbox } from 'formsy-semantic-ui-react';

export default ({ label, icon, iconHeight, name, checked, onChange }) => {
	const Icon = icon;
	const id = name + "-input";
	return <Checkbox id={id} name={name} type="checkbox" checked={checked} defaultChecked={checked}
		className={"icon-checkbox" + (checked ? " checked" : "")}
		onChange={
			(e) => {
				if (onChange) {
					setTimeout(() => {
						onChange(e, { checked: e.target.checked });
					}, 0);
				}
			}
		}
		label={
			<label htmlFor={id}>
				{Icon && <div className="icon-checkbox-icon">{<Icon height={iconHeight}/>}</div>}
				<span className="icon-checkbox-label">
					<span>{label}</span>
				</span>
			</label>
		}
	/>
		
}
