import React from 'react';

export default({
    color = 'currentColor',
    height = '24px',
    width = height,
    style,
    onClick,
    ...rest
}) => {
    style = {
        ...style,
        cursor: 'pointer'
    }
    return (
        <svg
            onClick={e => {
            onClick && onClick(e);
        }}
            height={height}
            width={width}
            viewBox="0 0 19 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            {...rest}>
			<g
                id="Screens-v2"
                stroke="none"
                strokeWidth="1"
                fill={color}
                fillRule="nonzero">
				<g
					fill={color}
					id="info-icon"
					transform="translate(9.5, 9.5) rotate(45) translate(-9.5, -9.5)">
					<g id="Group">
						<circle cx="9.5" cy="9.5" r="8.9" stroke={color} strokeWidth="1.26" fill="none" />
						<rect
							id="Rectangle-path"
							fillRule="nonzero"
							x="8.87"
							y="4"
							width="1.26"
							height="11"></rect>
						<rect
							id="Rectangle-path"
							fillRule="nonzero"
							x="4"
							y="8.87"
							width="11"
							height="1.26"></rect>
					</g>
				</g>
			</g>
        </svg>
    )
}
