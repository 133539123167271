import React, {Component} from 'react'
import {Button, Header, Radio, FormGroup} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import {FORM_STATE} from '../../utils/FormState'
import ValidatedInput from '../../components/Forms/ValidatedInput'
import ValidatedInputWithSelect from '../../components/Forms/ValidatedInputWithSelect'
import {PHONE_TYPES, PAGE_NUMBERS} from './constants'

export default class ProductDetailsPage extends Component {
  state = {
    requireCustomerUpdate: false,
    phoneNumberCount: this.props.context.phoneNumbers.count,
    emptyPhoneNumberInputs: 0,
    untrackedPhoneNumber: '',
    workingPhoneNumbers: [],
    workingPhoneTypes: [],
    noPhoneNumbersListed: false,
    canSubmit: false,
    lifeSupport: '',
    formValid: false
  }

  submit = () => {
    // If no phone numbers have been entered, give feedback to all phone number
    // fields.
    if (!this.props.context.phoneNumbers.filter(phone => {
      return phone.PhoneNumber !== ''
    }).length) {
      this.setState({noPhoneNumbersListed: true})
      return
    }

    // If the user isn't living at the address being signed up
    // continue form as the 'dumb' form
    if (this.props.context.isLivingAtAddress === false) {
      this.props.saveGlobalState({ customerIsUnverified: true });
    }

    // Set the Special Instructions phone to be the first non-international phone
    // number entered by the user (or empty).
    if (this.props.context.specialInstructionsPhone === '') {
      let firstLocalPhoneNumber = this
        .props
        .context
        .phoneNumbers
        .find(phone => {
          return phone.PhoneType !== 'INT' && phone.PhoneNumber !== ''
        })

      this
        .props
        .saveGlobalState({
          specialInstructionsPhone: {
            PhoneNumber: firstLocalPhoneNumber !== undefined
              ? firstLocalPhoneNumber.PhoneNumber
              : '',
            PhoneType: firstLocalPhoneNumber !== undefined
              ? firstLocalPhoneNumber.PhoneType
              : PHONE_TYPES[0].value
          }
        })
    }

    this
      .props
      .next()
  }

  onFormValid = () => {
    this.setState({formValid: true})
  }
  onFormInvalid = () => {
    this.setState({formValid: false})
  }
  updatedEmail = () => {
    this.setState({requireCustomerUpdate: true})
  }

  // Update phone number in global state. Handle displaying of empty phone number
  // fields.
  updatedPhoneNumber = (e, i) => {
    this.setState({requireCustomerUpdate: true, noPhoneNumbersListed: false})
    let phoneNumbers = this.props.context.phoneNumbers

    // Update phone number modified by user.
    let phoneNumber = this.props.context.phoneNumbers[i]
    let updatedPhoneNumber = {
      ...phoneNumber,
      PhoneNumber: e.target.value
    }
    phoneNumbers[i] = updatedPhoneNumber

    // Add or remove empty phone number fields.
    let emptyPhoneNumbers = this
      .props
      .context
      .phoneNumbers
      .filter(value => {
        return value.hasOwnProperty('PhoneNumber') && value.PhoneNumber === ''
      })
    if (emptyPhoneNumbers.length === 0) {
      this
        .props
        .context
        .phoneNumbers
        .push({PhoneType: PHONE_TYPES[0].value, PhoneNumber: ''})
    } else if (emptyPhoneNumbers.length > 1) {
      for (let i = phoneNumbers.length - 1; i > 0; i--) {
        if (phoneNumbers[i].PhoneNumber === '') {
          phoneNumbers.splice(i, 1)
          break
        }
      }
    }
    this
      .props
      .saveGlobalState({phoneNumbers: phoneNumbers})
  }

  updatedPhoneType = (e, i) => {
    this.setState({noPhoneNumbersListed: false})
    let newPhoneType = PHONE_TYPES.find(phoneType => {
      return phoneType.text === e.target.textContent
    })

    if (newPhoneType !== undefined) {
      this.setState({requireCustomerUpdate: true})
      let phoneNumbers = this.props.context.phoneNumbers

      // Update phone number modified by user.
      let phoneNumber = this.props.context.phoneNumbers[i]
      let updatedPhoneNumber = {
        ...phoneNumber,
        PhoneType: newPhoneType.value
      }
      phoneNumbers[i] = updatedPhoneNumber
      this
        .props
        .saveGlobalState({phoneNumbers: phoneNumbers})
    }
  }

  canSubmit = () => {
    var noCurrentPhone = false
    if (!this.props.context.phoneNumbers.filter(phone => {
      return phone.PhoneNumber !== ''
    }).length) {
      noCurrentPhone = true
      return
    }

    if (this.state.lifeSupport !== '' && this.state.noPhoneNumbersListed === false && this.state.formValid === true && noCurrentPhone === false) 
      return true

    return false
  }

  updateLifeSupport = (e, {value}) => {
    this
      .props
      .saveGlobalState({hasLifeSupport: value})
    this.setState({lifeSupport: value})
  }
  updateLivingAtPremise = (e, {value}) => {
    this
      .props
      .saveGlobalState({isLivingAtAddress: value})
  }
  addressChanged = address => {
    this
      .props
      .saveGlobalState({address: address})
  }

  // One of the fields used to identify the customer has been modified. Stop
  // marking them as invalid.
  identifierChanged = () => {
    this.setState({markIdentificationFieldValid: true})
  }



  handleChange = (e, {value}) => this.setState({value})

  render() {
    const {currentStep, selectedProduct} = this.props.context
    if (currentStep !== PAGE_NUMBERS.PRODUCT_DETAILS) {
      return null
    }

    return (
      <Form
        onChange={this.props.change}
        onValidSubmit={this.submit}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}>
        <Header textAlign='center' size='large'>
          Your details
        </Header>
        
	{ !this.props.context.customerIsUnverified ? 
          <React.Fragment>
           {/* We were able to validate your identity */}
           <p> {this.props.context.title} {this.props.context.firstName}{' '} {this.props.context.lastName} </p> 
           <p>{this.props.context.address}</p>
          </React.Fragment>
        : 
          <React.Fragment>
            {/* We were unable to validate your identity */}
            {/* First Name */}
            <ValidatedInput
              required
              validations={{
                "maxLength": 100,
                "isParameterTrue": this.state.markIdentificationFieldValid
              }}
              hideErrorMessage
              name="firstName"
              placeholder="First Name"
              value={this.props.context.firstName}
              onChange={this.identifierChanged}/>

            {/* Last Name */}
            <ValidatedInput
              required
              validations={{
                "maxLength": 100,
                "isParameterTrue": this.state.markIdentificationFieldValid
              }}
              hideErrorMessage
              name="lastName"
              placeholder="Last Name"
              value={this.props.context.lastName}
              onChange={this.identifierChanged}/>


            {/* Home Address */}
            <ValidatedInput
              required
              validations={{
                "maxLength": 250,
                "isParameterTrue": this.state.markIdentificationFieldValid
              }}
              hideErrorMessage
              name="address"
              placeholder="Home Address"
              value={this.props.context.address}
              onChange={this.identifierChanged}/>

          </React.Fragment>
        } 
                
        <ValidatedInput
          required={selectedProduct.value === 'TAS_RES_PI'}
          validations={{
          maxLength: 100,
          isEmail: true
        }}
          hideErrorMessage
          name='email'
          placeholder='Email address'
          value={this.props.context.email}
          onChange={this.updatedEmail}/> {Object
          .keys(this.props.context.phoneNumbers)
          .map((item, i) => {
            return (<ValidatedInputWithSelect
              style={{ width: '17%' }}
              fluid
              name='phone'
              dropdownName='phoneType'
              key={i}
              placeholder='Phone Number'
              value={this.props.context.phoneNumbers[item].PhoneNumber}
              defaultValue={this.props.context.phoneNumbers[item].PhoneType}
              validations={this.props.context.phoneNumbers[item].PhoneType === 'INT'
              ? {
                validInternationlPhoneNumberOrEmpty: true
              }
              : {
                validPhoneNumberOrEmpty: true
              }}
              validationErrors={{
              validPhoneNumberOrEmpty: 'Phone number must be 10 digits.',
              validInternationlPhoneNumberOrEmpty: 'Phone number must be between 6 and 24 digits.'
            }}
              error={this.state.noPhoneNumbersListed}
              onChange={e => this.updatedPhoneNumber(e, i)}
              onLabelChange={e => this.updatedPhoneType(e, i)}
              options={PHONE_TYPES}
              labelPosition='right'/>)
          })}

        <FormGroup style={{
          textAlign: 'left'
        }}>
          <Form.Field style={{
            width: '194px'
          }}>
            I am currently registered as a 'life support' customer.
          </Form.Field>
          <Radio
            style={{
            marginRight: '10px'
          }}
            className='consent'
            name='lifeSupport'
            value={true}
            checked={this.props.context.hasLifeSupport === true}
            onChange={this.updateLifeSupport}
            label={'Yes'}/>
          <Radio
            style={{
            marginLeft: '10px'
          }}
            name='lifeSupport'
            value={false}
            checked={this.props.context.hasLifeSupport === false}
            onChange={this.updateLifeSupport}
            label={'No'}/>
        </FormGroup>

        <FormGroup style={{
          textAlign: 'left'
        }}>
          <Form.Field style={{
            width: '194px'
          }}>
            I am currently living at this property.
          </Form.Field>
          <Radio
            style={{
            marginRight: '10px'
          }}
            className='consent'
            name='livingAtPremise'
            value={true}
            checked={this.props.context.isLivingAtAddress === true}
            onChange={this.updateLivingAtPremise}
            label={'Yes'}/>
          <Radio
            style={{
            marginLeft: '10px'
          }}
            name='livingAtPremise'
            value={false}
            checked={this.props.context.isLivingAtAddress === false}
            onChange={this.updateLivingAtPremise}
            label={'No'}/>
        </FormGroup>

        <Form.Field>
          <Button
            fluid
            type='submit'
            secondary
            loading={this.state.formState === FORM_STATE.LOADING}
            disabled={!this.canSubmit()}>
            Next
          </Button>
        </Form.Field>
        <Button fluid basic onClick={this.props.prev}>
          Previous
        </Button>
      </Form>
    )
  }
}
