import React from 'react';
import {Grid, Image, Button} from 'semantic-ui-react';
import Slider from 'react-slick';

import Responsive from "../../components/Responsive/Responsive";
import { withTheme } from '../../contexts/Theme';
import Modal from './../../components/Modal';


export class Carousel extends React.Component {

  state = {
    activeSlide: 0,
    skipModalOpen: false
  }

  constructor(props) {
    super(props);

    this.settings = {
      dots: true,
      infinite: false,
      arrows: false,
      adaptiveHeight: true,
      useCSS: false,
      lazyLoad: "progressive",
      beforeChange: this.onSlideChange,
      className: this.props.className
    }
  }

  onSlideChange = (current, next) => {
    if (current !== next) {
      this.props.onSlideChange(next)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeSlide !== prevProps.activeSlide) {
      this.slider.slickGoTo(this.props.activeSlide);
    }
  }

  render() {
    const { theme } = this.props.theme;
    const { slides, buttons, settings } = this.props;
    
    return (
      <React.Fragment>

        <Slider
          ref={slider => (this.slider = slider)}
          {...this.settings }
        >
          {(slides && slides.length > 0) &&

            slides.map(i => (
              <div key={'carousel-slide ' + i.title}>

                {(settings && settings.showTitle) &&
                  <h4>{i.title}</h4>
                }
                {(settings && settings.showDescription) &&
                  <p style={{ fontSize: '0.9em' }}>{i.description}</p>
                }

                <Responsive lessThan="computer">
                  <Image centered src={i.image.mobile} />
                </Responsive>
                <Responsive greaterThan="tablet">
                  <Image centered src={i.image.desktop} />
                </Responsive>
              </div>
            ))

          }
        </Slider>

        {//buttons &&
          //  <div className='carousel-buttons' style={{ paddingTop: '2.2em' }}>
          //    {buttons}
          //  </div>
        }

      </React.Fragment>
    )
  }

}

export default withTheme(Carousel);
