import React from 'react';
export default function Icon({
  color = 'currentColor',
  height = '28px',
  width = height
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"> 
      <path fill={color} d="M34 0C15.2578 0 0 15.2579 0 34C0 52.7421 15.2578 68 34 68C52.7422 68 68 52.7421 68 34C68 15.2579 52.7422 0 34 0ZM34 6C49.4995 6 62 18.5005 62 34C62 49.4995 49.4995 62 34 62C18.5005 62 6 49.4995 6 34C6 18.5005 18.5005 6 34 6ZM34 12C32.48 12 31.2519 13.2339 31.2539 14.7539V17.5703C26.2719 17.9303 22.3125 22.0569 22.3125 27.1289C22.3125 32.4349 26.6315 36.7539 31.9375 36.7539H36.75C39.024 36.7539 40.875 38.601 40.875 40.875C40.875 43.149 39.024 45 36.75 45H31.3555C28.8015 45 27.2024 43.9407 26.3164 43.0547C25.2424 41.9807 23.5037 41.9807 22.4297 43.0547C21.3557 44.1287 21.3557 45.8713 22.4297 46.9453C24.6997 49.2153 27.83 50.4623 31.25 50.4883V53.25C31.25 54.768 32.48 56 34 56C35.52 56 36.75 54.768 36.75 53.25V50.5C42.056 50.5 46.3711 46.1771 46.3711 40.8711C46.3711 35.5651 42.0521 31.2461 36.7461 31.2461H31.9375C29.6635 31.2461 27.8125 29.399 27.8125 27.125C27.8125 24.851 29.6635 23 31.9375 23H36.1641C38.7426 23 39.9285 24.482 39.9414 24.4883V24.4922C40.7621 25.766 42.4612 26.126 43.7383 25.3086C45.0143 24.4846 45.3826 22.7838 44.5586 21.5078C43.7026 20.1838 40.994 17.7551 36.75 17.5391V14.75C36.75 13.232 35.52 12 34 12Z" />
      </svg>
  );
}
