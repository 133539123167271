import { Button } from 'semantic-ui-react';
import './SignupTip.css';

export default ({ children, ...props }) => {

	return <Button
		className="signup-tip-button"
		fluid
		type="button"
		{...props}
	>
		{ children }
	</Button>

}