export default [
	{ code: "MR",	description: "Mr" },
	{ code: "MRS",	description: "Mrs" },
	{ code: "MS",	description: "Ms" },
	{ code: "MI",	description: "Miss" },
	{ code: "MX",	description: "MX" },
	{ code: "DR",	description: "Dr" },
]


/* Full list
export default [
	{ code: "ARCH",	description: "Archdeacon" },
	{ code: "BARO",	description: "Baron" },
	{ code: "BISH",	description: "Bishop" },
	{ code: "BRO",	description: "Brother" },
	{ code: "CAPT",	description: "Captain" },
	{ code: "COL",	description: "Colonel" },
	{ code: "COMM",	description: "Commander" },
	{ code: "DAME",	description: "Dame" },
	{ code: "DR",	description: "Dr" },
	{ code: "FAT",	description: "Father" },
	{ code: "GEN",	description: "General" },
	{ code: "HON",	description: "Honourable" },
	{ code: "LADY",	description: "Lady" },
	{ code: "LORD",	description: "Lord" },
	{ code: "LT",	description: "Lieutenant" },
	{ code: "MAJ",	description: "Major" },
	{ code: "MGR",	description: "Manager" },
	{ code: "MI",	description: "Miss" },
	{ code: "MR",	description: "Mr" },
	{ code: "MRS",	description: "Mrs" },
	{ code: "MS",	description: "Ms" },
	{ code: "MX",	description: "MX" },
	{ code: "PAST",	description: "Pastor" },
	{ code: "PROF",	description: "Professor" },
	{ code: "REV",	description: "Reverend" },
	{ code: "SIS",	description: "Sister" },
	{ code: "SR",	description: "Sir" },
]
*/