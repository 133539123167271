import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';
import { withApp } from '../contexts/App';
import Responsive from '../components/Responsive/Responsive';
import JourneyProgress from '../components/JourneyProgress/JourneyProgress';

export default withApp(({ app, dimmer, children, ...props }) => {
	const { journey, showSideContent } = app;
	
	
	const mainContent = <Segment basic vertical>
		{ journey &&
			<Responsive greaterThan="tablet">
				<header className="signup-header">
					{ journey.message && <h1>{ journey.message }</h1> }
				</header>
			</Responsive>
		}
		{children}
	</Segment>

	const gridProps = {
		className: 'signup-form-layout in-app-signup ' + (props.outerGridClasses || ""),
		centered: true,
		style: {
			flex: '1 1 auto',
		}
	}


	return (<>
		<Responsive lessThan="computer">
			<Grid {...gridProps}>
				{dimmer}			
				<Grid.Column mobile={16} tablet={8} computer={8}>
					{mainContent}
				</Grid.Column>
			</Grid>
		</Responsive>
		
		<Responsive greaterThan="tablet">
			<Grid {...gridProps}>
				{dimmer}			
				<Grid.Column mobile={16} tablet={8} computer={8}>
					<div className="signup-desktop-column" style={{
						maxWidth: '400px',
					}}>
						{mainContent}
					</div>
				</Grid.Column>
				{ (showSideContent !== false) && journey && 
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<JourneyProgress.Aside {...journey} />
					</Grid.Column>
				}
			</Grid>
		</Responsive>
	</>)
})
