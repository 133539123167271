import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon"
        className="weather-icon" 
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color} transform={`translate(0,75)`}>
    <path d="M24.52,299.94A26.94,26.94,0,0,1,18.6,283c0-.63,0-1.29.09-2h1a40.68,40.68,0,0,0,5.66,18.36Z" transform="translate(1 -90.09)" />
    <path d="M19.18,281a41,41,0,0,0,5.73,18.59A26.62,26.62,0,0,1,19.1,283c0-.66,0-1.32.08-2m-1-.07c-.06.74-.09,1.41-.09,2a27.43,27.43,0,0,0,6,17.23l1.64-1.13A40.16,40.16,0,0,1,20.18,281Z" transform="translate(1 -90.09)" />
    <path d="M45.85,329.37a46.32,46.32,0,0,1-30.1-81.55,56.86,56.86,0,0,1,55.05-71,55.62,55.62,0,0,1,9.64.84,92.66,92.66,0,0,1,82-49.21,93.81,93.81,0,0,1,27.74,4.18,93,93,0,0,1,164,16.07A71.51,71.51,0,0,1,349,291.52H306.55a71.45,71.45,0,0,1-63.07,37.85ZM70.8,196.91a36.74,36.74,0,0,0-33.91,50.88l3.39,8.13-7.61,4.43a26.29,26.29,0,0,0-13,20.73c-.05.7-.08,1.34-.08,1.94a26.3,26.3,0,0,0,26.25,26.25H243.48a51.41,51.41,0,1,0,0-102.81l-9.24.31L232,199.51a72.85,72.85,0,0,0-136.71-6.2L91.61,202l-8.91-3.08A36.55,36.55,0,0,0,70.8,196.91ZM349,271.42a51.41,51.41,0,0,0,0-102.82l-9.24.32-2.28-7.26A72.87,72.87,0,0,0,209,140.8a93,93,0,0,1,39.7,45.74A71.67,71.67,0,0,1,315,257.86a72.6,72.6,0,0,1-1.29,13.56Z" transform="translate(1 -90.09)" />
    <path d="M268,91.09a92,92,0,0,1,85.84,58.07A71,71,0,0,1,349,291H306.25a71.06,71.06,0,0,1-62.77,37.85H45.85A45.82,45.82,0,0,1,16.31,248,56.37,56.37,0,0,1,70.8,177.31a55.13,55.13,0,0,1,9.92.9,92.2,92.2,0,0,1,81.75-49.27,93.27,93.27,0,0,1,27.95,4.27A92.21,92.21,0,0,1,268,91.09m45.11,180.83H349a51.91,51.91,0,0,0,0-103.82l-8.87.3L338,161.51A73.37,73.37,0,0,0,208.23,141,92,92,0,0,1,248.31,187a71,71,0,0,1,64.78,84.9M91.33,201.38l-8.47-2.92a36.91,36.91,0,0,0-12.06-2.05A37.24,37.24,0,0,0,36.43,248l3.22,7.73-7.23,4.21A26.88,26.88,0,0,0,19.18,281c-.05.66-.08,1.32-.08,2a26.81,26.81,0,0,0,26.75,26.75H243.48a51.91,51.91,0,1,0,0-103.81l-8.87.29-2.17-6.89a73.35,73.35,0,0,0-137.65-6.24l-3.46,8.26M268,90.09A93,93,0,0,0,190,132a94.42,94.42,0,0,0-27.53-4.09,93.15,93.15,0,0,0-82.3,49.16,55.55,55.55,0,0,0-9.37-.79,57.36,57.36,0,0,0-55.61,71.34,46.81,46.81,0,0,0,30.66,82.22H243.48A72,72,0,0,0,306.85,292H349a72,72,0,0,0,5.53-143.81A92.88,92.88,0,0,0,268,90.09Zm-58.29,50.56A72.38,72.38,0,0,1,337,161.81l2.16,6.89.23.73.76,0,8.88-.3a50.91,50.91,0,0,1,0,101.82H314.3A72,72,0,0,0,249,186.06a93.44,93.44,0,0,0-39.32-45.41Zm-117.8,62,.36-.86,3.46-8.27a72.32,72.32,0,0,1,135.78,6.16l2.16,6.89.23.73.76,0,8.88-.3a50.91,50.91,0,0,1,0,101.82H45.85A25.8,25.8,0,0,1,20.1,283c0-.59,0-1.21.08-1.9a25.78,25.78,0,0,1,12.75-20.34l7.22-4.21.76-.44-.34-.81-3.22-7.72a36.16,36.16,0,0,1,45.19-48.19L91,202.33l.89.3Z" transform="translate(1 -90.09)" />
  </g>
</svg>

)
}