import React from 'react';

export default function AuroraEnergyLogo({ color = 'currentColor', height = 54, width = 98 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 98 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.8" d="M45.1169 5.54081C44.9701 5.62045 42.5383 11.2059 43.382 18.428C43.382 18.428 49.571 11.2505 51.8081 2.60449C51.8081 2.60449 47.7423 4.11714 45.1169 5.54081Z" fill={color} />
      <path d="M44.8855 36.8395C58.2796 25.1175 60.1025 3.43099 60.4117 0C60.2895 0.0298798 57.2132 0.697889 53.2435 2.09445C48.6087 20.8096 31.9859 31.9205 23.1921 36.8595H38.2896L44.8855 36.8395Z" fill={color} />
      <path opacity="0.6" d="M52.4547 36.859H74.6258C74.6258 36.859 64.129 31.578 56.6717 22.7109C56.6717 22.7109 54.2839 28.6866 49.9375 33.9502C49.9375 33.9502 50.8886 35.2105 52.4547 36.859Z" fill={color} />
      <path d="M5.23763 49.6951V49.0775C5.07943 49.1835 4.16553 49.8894 2.79454 49.8894C1.07185 49.8894 0 48.7423 0 47.1015C0 44.0138 3.76152 43.961 4.81611 43.961H5.06217C5.06217 43.4318 5.04465 43.1846 4.79833 42.9376C4.60509 42.7436 4.21835 42.5845 3.56802 42.5845C2.42557 42.5845 1.38851 43.1492 1.26561 43.22L0.474603 41.7026C0.597504 41.5968 1.77525 40.7676 3.76152 40.7676C4.49971 40.7676 5.74728 40.8907 6.59084 41.7377C7.31176 42.4612 7.29424 43.3612 7.29424 44.4907V49.6951H5.23763ZM5.06217 45.5843H4.69269C3.16376 45.5843 2.07387 45.8489 2.07387 46.9427C2.07387 47.8425 2.82984 48.0897 3.40982 48.0897C4.37655 48.0897 4.95653 47.6131 5.06217 47.56V45.5843Z" fill={color} />
      <path d="M14.6186 49.6435V48.9898C14.4775 49.096 13.4023 49.8907 12.1683 49.8907C11.2517 49.8907 10.4762 49.608 9.91213 49.0429C8.94256 48.0716 8.9777 46.765 8.9777 45.6345V40.9551H11.3574V45.5289C11.3574 46.6592 11.4104 47.2243 11.7275 47.5422C11.8334 47.6478 12.0978 47.8596 12.6619 47.8596C13.4201 47.8596 14.1251 47.4184 14.2662 47.3128V40.9551H16.6459V49.6435H14.6186Z" fill={color} />
      <path d="M24.1326 42.7985C24.0609 42.7806 23.7204 42.7454 23.5951 42.7454C22.1257 42.7454 21.3909 43.6103 21.1222 43.9991V49.7032H18.7031V41.0148H20.7639V42.1096C20.8356 41.9859 21.9287 40.7676 23.6847 40.7676C24.3117 40.7676 24.6343 40.9791 24.6882 40.9972L24.1326 42.7985Z" fill={color} />
      <path d="M29.2653 50.0755C26.6668 50.0755 24.875 48.1779 24.875 45.4749C24.875 42.7366 26.7566 40.7676 29.4444 40.7676C32.0429 40.7676 33.8526 42.6831 33.8526 45.3319C33.8526 48.1425 31.9891 50.0755 29.2653 50.0755ZM29.337 42.7544C28.1003 42.7544 27.2941 43.8106 27.2941 45.3857C27.2941 46.9968 28.1722 48.0703 29.4266 48.0703C30.6809 48.0703 31.4335 47.0323 31.4335 45.4395C31.4335 43.8284 30.5735 42.7544 29.337 42.7544Z" fill={color} />
      <path d="M40.5916 42.7985C40.5201 42.7806 40.1794 42.7454 40.0539 42.7454C38.5847 42.7454 37.8499 43.6103 37.5812 43.9991V49.7032H35.1621V41.0148H37.2229V42.1096C37.2946 41.9859 38.3876 40.7676 40.1437 40.7676C40.7707 40.7676 41.0936 40.9791 41.1472 40.9972L40.5916 42.7985Z" fill={color} />
      <path d="M46.7592 49.6951V49.0775C46.601 49.1835 45.6871 49.8894 44.3164 49.8894C42.5938 49.8894 41.5215 48.7423 41.5215 47.1015C41.5215 44.0138 45.2831 43.961 46.3377 43.961H46.5835C46.5835 43.4318 46.566 43.1846 46.3199 42.9376C46.1267 42.7436 45.7399 42.5845 45.0899 42.5845C43.9472 42.5845 42.9102 43.1492 42.7873 43.22L41.9963 41.7026C42.1192 41.5968 43.2969 40.7676 45.2831 40.7676C46.021 40.7676 47.2688 40.8907 48.1126 41.7377C48.833 42.4612 48.8157 43.3612 48.8157 44.4907V49.6951H46.7592ZM46.5835 45.5843H46.2145C44.6854 45.5843 43.5958 45.8489 43.5958 46.9427C43.5958 47.8425 44.3515 48.0897 44.9317 48.0897C45.8981 48.0897 46.4781 47.6131 46.5835 47.56V45.5843Z" fill={color} />
      <path d="M58.5052 45.6716H52.2075C52.2075 47.8827 53.3591 49.3391 55.3748 49.3391C56.868 49.3391 57.9294 48.602 57.9294 48.602L58.2892 49.2134C58.1992 49.3027 56.9938 50.0763 55.3204 50.0763C52.8914 50.0763 51.4341 48.3142 51.4341 45.6892C51.4341 42.8851 52.9275 41.1406 55.2846 41.1406C57.1199 41.1406 58.5413 42.5609 58.5413 45.1323C58.5413 45.276 58.5234 45.4736 58.5052 45.6716ZM55.2846 41.7884C53.7191 41.7884 52.3517 42.9929 52.2436 44.9884H57.7856C57.7856 43.4604 57.0297 41.7884 55.2846 41.7884Z" fill={color} />
      <path d="M65.6586 49.8901V44.7626C65.6586 43.6327 65.6586 42.8616 65.1177 42.3241C64.7575 41.9654 64.1987 41.8219 63.5868 41.8219C62.2893 41.8219 61.0105 42.6288 60.7763 42.7725V49.8901H60.0376V41.338H60.7763V42.0731C60.9202 41.9654 62.1636 41.1406 63.6586 41.1406C64.6492 41.1406 65.2622 41.4276 65.6586 41.8219C66.3069 42.4676 66.3967 43.1307 66.3967 44.4217V49.8901H65.6586Z" fill={color} />
      <path d="M75.1509 45.6716H68.8535C68.8535 47.8827 70.0051 49.3391 72.02 49.3391C73.5137 49.3391 74.5751 48.602 74.5751 48.602L74.9352 49.2134C74.8452 49.3027 73.6396 50.0763 71.9659 50.0763C69.5372 50.0763 68.0798 48.3142 68.0798 45.6892C68.0798 42.8851 69.5733 41.1406 71.9303 41.1406C73.7654 41.1406 75.1871 42.5609 75.1871 45.1323C75.1871 45.276 75.1694 45.4736 75.1509 45.6716ZM71.9303 41.7884C70.3643 41.7884 68.9972 42.9929 68.8893 44.9884H74.4314C74.4314 43.4604 73.6757 41.7884 71.9303 41.7884Z" fill={color} />
      <path d="M80.9929 41.7225C80.85 41.705 80.6527 41.6689 80.258 41.6689C78.4299 41.6689 77.6951 43.2761 77.6052 43.4015V49.7046H76.8706V41.1873H77.6052V42.4905C77.7313 42.3123 78.5377 40.9551 80.294 40.9551C80.9391 40.9551 81.1185 41.0624 81.1724 41.0799L80.9929 41.7225Z" fill={color} />
      <path d="M87.702 52.9117C86.9546 53.6576 86.0828 53.7995 85.0511 53.7995C83.2365 53.7995 82.1514 52.9117 82.0625 52.8227L82.4362 52.2728C82.5606 52.3436 83.5748 53.1249 85.0334 53.1249C86.1719 53.1249 86.7587 52.8227 87.1856 52.3969C87.702 51.8821 87.7195 51.2603 87.7195 49.716V48.9529C87.5773 49.0411 86.5634 49.8224 85.122 49.8224C83.0582 49.8224 81.5464 48.1893 81.5464 45.6504C81.5464 43.0231 83.2897 41.1406 85.6736 41.1406C87.2571 41.1406 88.3065 41.7444 88.4666 41.8332V49.9822C88.4666 51.58 88.2888 52.3259 87.702 52.9117ZM87.7195 42.2595C87.5953 42.2061 86.8479 41.8152 85.6201 41.8152C83.5923 41.8152 82.2935 43.3955 82.2935 45.6504C82.2935 47.6919 83.3967 49.1478 85.1578 49.1478C86.5274 49.1478 87.5773 48.3134 87.7195 48.2426V42.2595Z" fill={color} />
      <path d="M94.4475 50.5482C94.0709 51.5612 93.6764 52.4316 93.1749 52.9291C92.4753 53.6224 91.6508 53.7998 91.005 53.7998C90.2168 53.7998 89.6606 53.5868 89.5889 53.5513L89.8036 52.9114C89.9298 52.9473 90.3059 53.107 90.9871 53.107C91.5609 53.107 92.1528 52.9114 92.5473 52.5207C93.1386 51.9344 93.4971 50.886 93.838 50.051L90.3243 41.3271H91.1664L94.2507 49.0381L97.0114 41.3271H97.8183L94.4475 50.5482Z" fill={color} />
    </svg>
  );
}
