import "./ToS.css";

// Ripped and converted to JSX from https://www.auroraenergy.com.au/aurora-plus/aurora-plus-terms-and-conditions
export default (
	<div className="terms-of-service">
		<h1>aurora+ terms of service</h1>

		<h2>Terms and Conditions</h2>

		<p className="justified">
			<span>
				These terms and conditions explain in detail your rights and obligations
				when using aurora+ and its features, including accessing the digital web
				(browser URL) and mobile interfaces.
			</span>
		</p>

		<p className="justified">
			<br />
			<span>
				aurora+ is available to all eligible residential and business customers on
				a Standard Retail Contract. Aurora Energy does not charge any additional
				fees for the use of aurora+ and prices for a Standard Retail Contract are
				set by the independent Tasmanian Economic Regulator.&nbsp;
			</span>
		</p>

		<p className="justified">
			<br />
			<span>
				These terms and conditions do not cover the sale or supply of energy to
				you at your premises or related matters which are covered in your Standard
				Retail Contract.
			</span>
		</p>

		<p className="justified">
			<br />
			<span>
				More information about aurora+, your Standard Retail Contract and other
				matters can be found on our website auroraenergy.com.au.
			</span>
		</p>

		<h3>
			1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The parties&nbsp;
		</h3>

		<p>
			These terms and conditions are between:
			<br />
			(a)&nbsp;&nbsp; &nbsp;Aurora Energy Pty Ltd ACN 082 464 622 (referred to as
			‘Aurora Energy’, ‘we’, ‘our’ or ‘us’); and
			<br />
			(b)&nbsp;&nbsp; &nbsp;You, the customer to whom these terms and conditions
			apply (referred to as ‘you’ or ‘your’).
		</p>

		<h3>
			2. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Use of aurora+
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;We, Aurora Energy, are the owner of aurora+ and
			operate its key features, including the web and mobile interfaces. We may,
			at our sole discretion, refuse to provide you with access to aurora+,
			including where you fail to satisfy the pre-conditions detailed in clause 3.
			<br />
			(b)&nbsp;&nbsp; &nbsp;You agree to be bound by these terms and conditions.
			Your use of aurora+ and access to its features indicates your acceptance of
			and agreement with these terms and conditions, as they exist at the time of
			use.
			<br />
			(c)&nbsp;&nbsp; &nbsp;We may amend these terms and conditions at any time.
			If we do so, we will endeavour to provide notice as soon as practicable that
			changes have been made (e.g. on our website). If you use aurora+ or access
			its features after a notice of change has been provided, you will be deemed
			to have accepted the new terms and conditions.
			<br />
			(d)&nbsp;&nbsp; &nbsp;You agree that use of aurora+ requires compatible
			devices and internet access, may require certain software or operating
			systems, may require periodic updates, and may be affected by the
			performance of these factors. You agree that meeting these requirements,
			which may change from time to time, is your responsibility.
			<br />
			(e)&nbsp;&nbsp; &nbsp;These terms and conditions do not affect your rights
			and obligations under your Standard Retail Contract.
			<br />
			(f)&nbsp;&nbsp; &nbsp;You are not required to pay Aurora Energy any separate
			fees or charges for the use of aurora+, including the web and mobile
			interfaces. &nbsp;However, you may incur charges from your mobile service
			provider for downloading and using the application. More information on
			Aurora Energy’s prices can be found on our website auroraenergy.com.au.
		</p>

		<h3>
			3. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Pre-conditions – are you
			eligible?
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;In order to be, and remain, eligible for aurora+ you
			must fulfil the following pre-conditions:
			<br />
			(i)&nbsp;&nbsp; &nbsp;be an Aurora Energy residential or small business
			customer;
			<br />
			(ii)&nbsp;&nbsp; &nbsp;have a communications-enabled advanced meter
			installed at your premises or provide consent for one to be installed; and
			<br />
			(iii)&nbsp;&nbsp; &nbsp;have a valid email address assigned to your
			individual Aurora Energy account and provide consent to receive your energy
			statement by electronic means.
			<br />
			(b)&nbsp;&nbsp; &nbsp;You must also select a tariff that is compatible with
			aurora+. The tariffs that are compatible with aurora+ may be updated from
			time to time at our discretion.
		</p>

		<h3>
			4. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Functionality and compatibility
			of aurora+
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;We may alter the features and functionality of aurora+
			and/or the content and data made available through the digital web and
			mobile interfaces at any time, with or without notice to you, and, to the
			full extent permitted by law and subject to these terms and conditions, we
			will not be liable to you or to any third party should we exercise such
			rights.
			<br />
			(b)&nbsp;&nbsp; &nbsp;Some aurora+ features and functionality may be
			administered by third parties or licensors of Aurora Energy.
			<br />
			(c)&nbsp;&nbsp; &nbsp;We are not responsible for assessing your digital
			device to ensure its compatibility with aurora+ or the digital web and
			mobile interfaces. You must take all steps required to ascertain the
			suitability of aurora+ and its features for your individual circumstances.
			<br />
			(d)&nbsp;&nbsp; &nbsp;Some of the features and functionality associated with
			aurora+ may be dependent on your circumstances, and access to some of the
			features and functionality may require you to satisfy pre-conditions in
			addition to those in clause 3.
		</p>

		<h3>
			<span>
				5. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Notifications
			</span>
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;You agree to receive aurora+ product and service
			notifications from Aurora Energy or relevant third parties (if applicable)
			via the digital web and mobile interfaces and other standard communication
			channels (e.g. email or SMS). Notifications may include, but are not limited
			to:
			<br />
			(i)&nbsp;&nbsp; &nbsp;When an energy statement is issued, due or overdue;
			<br />
			(ii)&nbsp;&nbsp; &nbsp;When a payment plan instalment is due or overdue; or
			<br />
			(iii)&nbsp;&nbsp; &nbsp;When a payment through the digital web or mobile
			interface has been successful, is pending or has failed.&nbsp;
			<br />
			(b)&nbsp;&nbsp; &nbsp;We may send notifications via a push notification,
			email or SMS. Push notifications may be sent to any compatible device on
			which you have installed and registered aurora+.
			<br />
			(c)&nbsp;&nbsp; &nbsp;From time to time you may receive notification
			messages through aurora+, including commercial electronic messages, which
			advertise, promote or offer new or existing features, products or services.
			You agree to receive such messages and that notifications and commercial
			electronic messages need not contain information about how to unsubscribe.
			However, we will honour any general preferences you make in relation to the
			receipt of marketing materials and your preferences in aurora+ concerning
			notifications and new offers.
			<br />
			(d)&nbsp;&nbsp; &nbsp;Depending on your device setting, notifications may be
			seen by other people (including authorised persons) who are able to see your
			device. Please check the notification setting on all your devices to ensure
			they are appropriate for your individual needs.
			<br />
			(e)&nbsp;&nbsp; &nbsp;We are not responsible for you failing to receive
			notifications as a result of you disabling the notifications function on
			your digital device. You acknowledge that delivery of notifications may be
			subject to the quality of your internet connection.
			<br />
			(f)&nbsp;&nbsp; &nbsp;Notifications will be sent to you and managed in
			accordance with our Privacy Policy and we reserve the right to suspend or
			discontinue notifications at any time without notice.
		</p>

		<h3>
			6. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Data accuracy
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;The account balance displayed within aurora+ may vary
			slightly to the account balance shown in your energy statement due to
			rounding. Your statement will be the reference for all amounts owing.&nbsp;
			<br />
			(b)&nbsp;&nbsp; &nbsp;We will use best endeavours to ensure information and
			other data in aurora+ through the digital web and mobile interfaces is
			current and up to date. However, we cannot always guarantee the information
			and other data is complete, current, accurate or free from errors or
			omissions, as it may be affected by a number of factors outside our control,
			including provision of data from third parties, changes in your consumption
			behaviour, telecommunication outages and estimated or substituted meter
			reads.&nbsp;
			<br />
			(c)&nbsp;&nbsp; &nbsp;We also do not guarantee the accuracy of information
			provided by third parties within aurora+ (such as the provision of weather
			forecasts, home energy reports and energy usage recommendations). When
			possible we will let you know as soon as possible of any significant data
			accuracy matters.
			<br />
			(d)&nbsp;&nbsp; &nbsp;In some cases, historical energy usage data, energy
			statements or transactions may not be available in aurora+ due to system
			constraints or other factors. If you have any questions regarding historical
			account information please contact us.
		</p>

		<h3>
			7. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Privacy and security
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;We will comply with all relevant privacy legislation
			in relation to your personal information. You can find our Privacy Policy on
			our website. If you have any questions, you can contact our privacy officer.
			<br />
			(b)&nbsp;&nbsp; &nbsp;Your credit card information will be stored securely
			by Aurora Energy’s banking partner and will not be stored in the digital web
			and mobile interfaces.
			<br />
			(c)&nbsp;&nbsp; &nbsp;You must ensure that your user name and password
			details are kept secure and confidential. We will have no responsibility for
			unauthorised use of, or access to, the digital web and mobile interfaces by
			a third party, except where such unauthorised use or access is covered by
			relevant privacy legislation. This includes, but is not limited to,
			situations where your aurora+ user name and password details are
			intentionally provided to third parties or where digital web and mobile
			interfaces remain logged in on public devices. You should take all
			reasonable steps to ensure your devices are protected and not left
			unattended and report any unauthorised use of aurora+ to Aurora Energy.
			<br />
			(d)&nbsp;&nbsp; &nbsp;To access multiple aurora+ accounts via a single sign
			on, you must use the same user name and password for all accounts. You agree
			that, where you intentionally provide your user name and password details to
			a third party, that they will have access to your aurora+ accounts.
			<br />
			(e)&nbsp;&nbsp; &nbsp;We may use your personal information and data to help
			us understand trends in customer behaviour, including how and when aurora+
			is used, to improve the performance and use of aurora+ and the quality of
			our data, or develop additional features or products that better meet
			customer needs. For more information please see our Privacy Policy.
		</p>

		<h3>
			8. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Availability
		</h3>

		<p>
			The availability of the aurora+ service (including but not limited to
			information and data) and its features may be subject to interruptions from
			time to time, including planned and unplanned outages. Further, your access
			to or ability to use it may be affected by telecommunication outages or
			other events beyond our control.
		</p>

		<h3>
			9. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Risk of viruses
		</h3>

		<p>
			While we will endeavour to control and eliminate any viruses which affect or
			are transmitted through the aurora+ service, we cannot guarantee that the
			service will at all times be free of viruses or bugs. You assume all risk
			for any loss or damage to your software, computer or mobile devices as a
			result of using the aurora+ service.
		</p>

		<h3>
			10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to aurora+
		</h3>

		<ol className="alpha-list">
			<li>
				<p>
					<span>
						You may cease using aurora+ at any time and will:
					</span>
				</p>
			</li>
		</ol>
		<ol className="roman-list">
			<li>
				<p>
					<span>
						retain your user name and password for future access to the digital
						web and mobile interfaces;
					</span>
				</p>
			</li>
			<li>
				<p>
					<span>
						continue to receive your energy statement(s) electronically and be
						billed for your account(s) in line with your current billing cycle,
						unless requested otherwise; and
					</span>
				</p>
			</li>
			<li>
				<p>
					<span>
						continue to receive account notifications as detailed in clause 5,
						unless requested otherwise.
					</span>
				</p>
			</li>
		</ol>
		<ol start="2" className="alpha-list">
			<li>
				<p>
					<span>
						If you cease using aurora+ this will not impact or affect any of your
						rights or obligations under your Standard Retail Contract with Aurora
						Energy.
					</span>
				</p>
			</li>
			<li>
				<p>
					<span>
						We may remove your access to aurora+ immediately if you breach your
						Standard Retail Contract (including a breach giving rise to
						disconnection) or a breach of these terms and conditions, including
						but not limited to, a failure to maintain compliance with the
						pre-conditions detailed in clause 3, a failure to pay your energy
						charges or unauthorised and unlawful use of the digital web and mobile
						interfaces or other aurora+ features. Reactivating your access to
						aurora+ will be at Aurora Energy’s sole discretion.
					</span>
				</p>
			</li>
		</ol>
		
		<h3>
			11. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Intellectual property
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;All intellectual property in relation to aurora+ and
			its content, including the digital web and mobile interfaces (but not
			including your personal information and/or data), belongs to Aurora Energy
			or its licensors. You obtain no interest in that intellectual property. All
			content which you access through aurora+ is protected by Australian
			copyright and other intellectual property laws. You may not do anything
			which breaches those laws or interferes with the intellectual property
			rights in the content.
			<br />
			(b)&nbsp;&nbsp; &nbsp;The Aurora Energy logo is a registered trademark of
			Aurora Energy. Other trademarks may be displayed by aurora+ from time to
			time. Nothing displayed by aurora+ should be construed as granting any
			licence or right of use of any logo, trademark or masthead displayed by
			aurora+, without the express written permission of Aurora Energy.
			<br />
			(c)&nbsp;&nbsp; &nbsp;You may download and view content or print a copy of
			material which you access through aurora+ for personal, non-commercial use
			provided you do not modify the content in any way.
			<br />
			(d)&nbsp;&nbsp; &nbsp;All rights not expressly granted under these terms and
			conditions are reserved by Aurora Energy. Unless expressly stated otherwise,
			you are not permitted to copy, or republish anything you access using
			aurora+ without our permission.
		</p>

		<h3>
			12. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Australian Consumer Law
		</h3>

		<p>
			We do not exclude any rights and remedies in respect of goods or services
			under the Australian Consumer Law in the Competition and Consumer Act 2010
			(Cth) which cannot be excluded, restricted or modified.
		</p>

		<h3>
			13. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Force majeure
		</h3>

		<p>
			If we cannot meet an obligation under these terms and conditions because of
			an event outside Aurora Energy’s control (‘a force majeure event’):
			<br />
			(a)&nbsp;&nbsp; &nbsp;the obligation is suspended to the extent it is
			affected by the force majeure event for as long as the force majeure event
			continues; and
			<br />
			(b)&nbsp;&nbsp; &nbsp;we will use our best endeavours to give prompt notice
			of that fact including full particulars of the event, an estimate of its
			likely duration, the extent to which our obligations are affected and the
			steps being taken to remove, overcome or minimise those effects.
		</p>

		<h3>
			14. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;General
		</h3>

		<p>
			(a)&nbsp;&nbsp; &nbsp;You may not use the digital web and mobile interfaces
			or other features of aurora+ for any purpose or in any way which is:
			<br />
			(i)&nbsp;&nbsp; &nbsp; Unauthorised or unlawful;
			<br />
			(ii)&nbsp;&nbsp; &nbsp;Is defamatory, harassing or threatening to any
			person;
			<br />
			(iii)&nbsp;&nbsp; &nbsp;Promotes or encourages physical or mental harm to
			any person;
			<br />
			(iv)&nbsp;&nbsp; &nbsp;Promotes violence against any person; or
			<br />
			(v)&nbsp;&nbsp; &nbsp;Threatens or promotes terrorism.
			<br />
			(b)&nbsp;&nbsp; &nbsp;These terms and conditions shall be governed by and
			interpreted in accordance with the laws of Tasmania.
			<br />
			(c)&nbsp;&nbsp; &nbsp;You agree to submit to the exclusive jurisdiction of
			the courts of Tasmania and the Commonwealth of Australia in respect of all
			matters arising out of or relating to these terms and conditions, their
			performance or subject matter.
		</p>

	</div>
);	