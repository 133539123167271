import React from 'react';
import { Icon, List } from 'semantic-ui-react';

import "./PasswordValidationInfo.css";

export default props => {
  const { validationsPassed, whiteColor } = props;

  const containsLower = validationsPassed.includes('lowercase');
  const containsUpper = validationsPassed.includes('uppercase');
  const containsNumerical = validationsPassed.includes('numerical');
  const containsSpecial = validationsPassed.includes('special');

  const renderCheckbox = (isChecked, label) => (
    <React.Fragment>
      { label }
      { <Icon name='check' className={`${isChecked ? 'checked' : ''} ${whiteColor ? 'white' : ''}`} /> }
    </React.Fragment>
  );

  return (
    <div className={`password-validation-info ${whiteColor ? 'white' : undefined}`}>
      <p>
       Password must be a minimum of 8 characters and include at least two of the following types:
      </p>
      <List as="ul">
        <List.Item as="li">
          { renderCheckbox(containsLower, 'Lowercase letters') }
        </List.Item>
        <List.Item as="li">
          { renderCheckbox(containsUpper, 'Uppercase letters') }
        </List.Item>
        <List.Item as="li">
          { renderCheckbox(containsNumerical, 'Numbers') }
        </List.Item>
        <List.Item as="li">
          { renderCheckbox(containsSpecial, 'Symbols') }
        </List.Item>
      </List>
    </div>
  )
}
