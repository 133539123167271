import Journeys from '../../utils/Journeys';
import IconStep from '../IconSteps/IconStep';
import IconSteps from '../IconSteps/IconSteps';
import TickIcon from '../Icons/TickIcon';

import './JourneyProgress.css'

const Banner = ({
	message,
	currentStep,
	journeyName,
	error,
}) => {
	
	const journeyData = Journeys[journeyName];

	return <div className={"journey-progress-banner" + (error ? " error" : "")}>
		<p>{ message }</p>

		{ (journeyData && journeyData.steps) && 
			<div className="journey-progress-steps">
				{ journeyData.steps.map((step, index) => {
					let active = ((index + 1) === currentStep);
					let complete = ((index + 1) < currentStep);
					return <div
						key={'journey-progress-step' + index}
						className={"journey-progress-step" + (active ? " active" : "") + (complete ? " complete" : "")}
					>
						{ complete && <TickIcon color="#40CC3D" inverted/> }
					</div>
				})}
			</div>
		}
	</div>

}

const Aside = ({
	message,
	currentStep,
	journeyName,
	error,
	narrow,
}) => {
	
	const journeyData = Journeys[journeyName];

	return (journeyData && journeyData.steps) ? <>
		<div className={"journey-progress-aside" + (error ? " error" : "")}>

			<IconSteps>
				{ journeyData.steps.map((step, index) => {
					return <IconStep
						key={index}
						narrow
						icon={step.icon}
						title={narrow ? step.short : step.title}
						text={narrow ? null : step.text}
						active={(index + 1) === currentStep}
						complete={(index + 1) < currentStep}
					/>
				})}
			</IconSteps>

		</div>
	</> : null

}



export default {
	Banner,
	Aside,
}


