import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';

export default(props) => (
  <Grid
    className={'logged-out-layout' + (props.outerGridClasses ? " " + props.outerGridClasses : "")}
    centered
    style={{
    	alignItems: 'center',
    	height: '100vh'
  	}}
>
    <Grid.Column mobile={16} tablet={8} computer={6} textAlign="center">
      <Segment basic vertical>
        {props.children}
      </Segment>
    </Grid.Column>
  </Grid>
)
