import { withApp } from "../../contexts/App"
import Layout from "../../layouts/Layout"
import LoggedOutLayout from "../../layouts/LoggedOutLayout";
import { androidAppStoreUrl, appleAppStoreUrl } from "../AppDownload";
import { Button, Image, Segment } from "semantic-ui-react";
import ExternalLink from "../../components/ExternalLink";

import logo from "../../static/logo-blue.svg";
import unlockAnimation from "../../static/animations/padlock-unlock.json";
import { useLottie } from "lottie-react";

export default withApp(({ app }) => {

	const { isAndroidDevice, isAppleDevice } = app;

	const { View: UnlockAnimationView } = useLottie({
		animationData: unlockAnimation,
		loop: true,
	})

	let url = null;
	if (isAppleDevice) url = appleAppStoreUrl;
	else url = androidAppStoreUrl;

	return (
		<Layout
			accountPicker={false}
			layout={LoggedOutLayout}
			noContainer={true}
			whitePage={true}
		>

			<div style={{
				maxWidth: '150px',
				margin: '20px auto 0',
			}}>
				{ UnlockAnimationView }
			</div>

			<h1 style={{
				color: '#4A4A4A',
			}}>Update Required</h1>

			<Segment
				basic
				style={{
					margin: '10px 0 0',
				}}
			>
				<p>Your aurora+ app requires an update to ensure it continues to function properly.</p>
				<p>It’ll only take a few minutes!</p>
				<p>Tap the button below to update aurora+ in your app store.</p>

				<Button 
					as={ExternalLink}
					to={url}
					secondary
					fluid
					style={{marginTop: '50px'}}
				>Update</Button>

			</Segment>


			<Image
				style={{
					maxWidth: "150px",
					margin: '60px auto 20px',
				}}
				src={logo}
			/>

		</Layout>
	)

});


