import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon"
        className="weather-icon"  
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
  <path d="M329.26,103.17A121.09,121.09,0,0,0,109.78,91.64a71.82,71.82,0,0,0-13-1.18A73.8,73.8,0,0,0,25.43,183,60,60,0,0,0,64.12,289H322.94a93,93,0,0,0,6.32-185.78Zm-6.32,160.76H64.12a35,35,0,0,1-17.59-65.28L56,193.14,51.78,183a48.68,48.68,0,0,1,60.81-64.86L123.68,122l4.52-10.83a96.07,96.07,0,0,1,180.28,8.18l2.83,9,11.63-.39a68,68,0,0,1,0,136Z" transform="translate(-3.57 -26.61)" />
  <line x1="81.96" y1="327.69" x2="82.08" y2="299.1" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  <line x1="140.6" y1="346.08" x2="140.77" y2="306.46" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  <line x1="199.01" y1="366.27" x2="199.14" y2="336.7" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  <line x1="258.08" y1="327.69" x2="258.23" y2="291.64" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  <line x1="316.7" y1="350.37" x2="316.92" y2="299.1" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  </g>
</svg>

)
}