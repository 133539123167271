import React from 'react';
import axios from 'axios';
import { Segment, Item, Button, Grid, Header } from 'semantic-ui-react';
import moment from 'moment-timezone';
import _ from 'lodash';

import Responsive from "../components/Responsive/Responsive";
import Layout from '../layouts/Layout';
import FullWidthLayout from '../layouts/FullWidthLayout';
import ColumnLayout from '../layouts/ColumnLayout';
import { withRouter } from '../utils/useRouter';
import { withApp } from '../contexts/App'
import { withCustomer } from '../contexts/Customer'
import { withTheme } from '../contexts/Theme';
import InfiniteList from './../components/InfiniteList';
import Modal from './../components/Modal';
import MobileAppComms from './../utils/MobileAppComms';
import { openBill } from './../utils/PDF';

export class TransactionHistory extends React.Component {

  state = {
    pageRows: 10,
    pageNumber: 1,
    tabChangeCount: 0,
    loading: true,
    moreToLoad: true,
    modal: {
      isOpen: false,
      title: '',
      description: ''
    }
  }

  constructor(props) {
    super(props)
    this.listBillsRef = React.createRef();
    this.listTransactionsRef = React.createRef();
  }

  componentDidMount() {
    const { router } = this.props;
    this.setState({
      currentTab: (router && router.params) ? router.params.tab : "transactions",
      tabChangeCount: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { router } = this.props;
    const { router: prevRouter } = prevProps;

    if (router && router.params && prevRouter.params && prevRouter.params.tab !== router.params.tab) {
      this.setState({
        currentTab: router.params.tab,
        tabChangeCount: this.state.tabChangeCount + 1
      });
    }
  }

  formatDate = (dateStr) => {
    return moment(dateStr).format('D MMM YYYY');
  }

  formatCurrency = (amount) => {
    const prefix = amount < 0 ? '-' : '+';
    const formattedAmount = Math.abs(amount).toLocaleString('en-AU', {
      currency: 'AUD',
      minimumFractionDigits: 2,
    });
    return `${prefix} $${formattedAmount}`;
  }

  formatDescription = (transaction) => {
    if (transaction.Type === "Original Invoice") {
      return (
        <React.Fragment>
          <span>{transaction.BillID}</span>
          <a style={{ opacity: 0.6, paddingLeft: "5px" }} onClick={() => this.billClicked(transaction.BillID)}>View PDF</a>
        </React.Fragment>
      );
    }
    return transaction.Description;
  }

  tabButtonChanged = (tabName) => {
    this.setState({
      currentTab: tabName,
      tabChangeCount: this.state.tabChangeCount + 1
    });
  }

  renderTabButton = (tabName, title) => {
    const isActive = this.state.currentTab === tabName;
    const { theme } = this.props.theme; // Get the theme from props

    return (
      <Button
        style={{
          backgroundColor: isActive ? theme.transactionHistory.tabs.active.background : theme.transactionHistory.tabs.inactive.background,
          color: isActive ? theme.transactionHistory.tabs.active.color : theme.transactionHistory.tabs.inactive.color,
        }}
        className={`tab-button ${isActive ? 'tab-button-active' : ''}`}
        onClick={() => this.tabButtonChanged(tabName)}>
        {title}
      </Button>
    );
  }

  renderCurrentTab = () => {
    const { TransactionLayout } = this;
    const { currentTab } = this.state;

    let list = null;
    let balance = null;
    let heading = '';

    if (currentTab === 'transactions') {
      list = this.renderTransactionList();
      balance = this.renderBalance();
      heading = 'Transactions';
    } else if (currentTab === 'bills') {
      list = this.renderBillSegments();
      heading = 'Bills';
    }

    return (
      <TransactionLayout>
        <Responsive greaterThan="tablet">
          <Header textAlign='center' size='large' style={{ fontWeight: 500, marginBottom: '1.5rem' }}>
            {heading}
          </Header>
        </Responsive>
         {/* Commented out the balance section for AUIX-157 (last minute UI/UX request, lightest touch possible */}
         {/* {balance} */}
        {list}
      </TransactionLayout>
    );
  }

  TransactionLayout = (props) => {
    const { theme } = this.props.theme; // Use theme for segment styling
    return (
      <Segment padded className="main-wrapper layout-default transaction-history-layout" style={theme.transactionHistory.item.default}>
        <ColumnLayout column={{ mobile: 16, tablet: 10, computer: 8 }}>
          {props.children}
        </ColumnLayout>
      </Segment>
    );
  }

  renderTransactionList = () => {
    const { customerPremise, customer } = this.props.customer;
    const { theme } = this.props.theme; // Use theme for item styling

    if (!customerPremise) return null;

    return (
      <InfiniteList
        key={customerPremise.parentAccountID + this.state.tabChangeCount}
        ref={this.listTransactionsRef}
        isSideMenuOpen={this.props.app.isSideMenuOpen}
        manualLoadMore={true}
        getData={(pageRows, pageNumber) =>
          axios
            .get(process.env.REACT_APP_API_BASE_URI + `transactions?accountId=${customerPremise.parentAccountID}&parentAccountNumber=${customerPremise.parentAccountNumber}&isOldCCB=${customerPremise.serviceAgreementStatus === "Old_CCB"}&pageRows=${pageRows}&pageNumber=${pageNumber}`)
            .then(res => {
              if (!res.data) return res.data;
              return _.filter(res.data, (obj) => obj.VisibleInStatement);
            })
            .catch()
        }
        children={({ item, ...props }) => (
          <Item key={`${item.Identifier}-${props.itemIndex}`} className="notification transaction" 
            style={props.itemIndex % 2 === 0 
              ? theme.transactionHistory.item.alternate 
              : theme.transactionHistory.item.default}>
            <Item.Content style={{ backgroundColor: "inherit", borderRadius: '5px' }}>
              <Item.Header style={{ backgroundColor: theme.transactionHistory.item.highlighted.background, lineHeight: '33px' }}>
                {item.Type === "Original Invoice" ? <div>Bill issued</div> : <div>Payment received</div>}
                {this.formatDate(item.TransactionDate)}
              </Item.Header>
              <Item.Meta className="bill-transaction">
                <div className="transaction-description" style={{ color: theme.transactionHistory.item.highlighted.color }}>
                  {this.formatDescription(item, customerPremise.parentAccountID)}
                </div>
                <div
                  className={`currency ${item.CurrentAmount >= 0 ? 'negative' : 'positive'}`}
                  style={{ color: item.CurrentAmount >= 0 ? theme.transactionHistory.item.amount.positive : theme.transactionHistory.item.amount.negative }}>
                  {this.formatCurrency(-1 * item.CurrentAmount)}
                </div>
              </Item.Meta>
            </Item.Content>
          </Item>
        )}
      />
    );
  }

  renderBalance = () => {
    const { customerPremise } = this.props.customer;
    const { theme } = this.props.theme;

    if (!customerPremise || customerPremise.hasPrePayBilling) return null;

    const currentBalance = Math.abs(customerPremise.estimatedBalance).toLocaleString('en-AU', {
      currency: 'AUD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return (
      <div style={{ textAlign: "center" }}>
        <p style={{ margin: 0, lineHeight: '40px' }}>
          Current account balance
        </p>
        <p style={{ color: theme.transactionHistory.item.highlighted.color, fontSize: '52px', lineHeight: '70px', marginBottom: '15px' }}>
          {customerPremise.estimatedBalance >= 0 ? `$${currentBalance}` : `-$${currentBalance}`}
        </p>
      </div>
    );
  }

  billClicked = (billId) => {
    const { customerPremise } = this.props.customer;
    const { isApp } = this.props.app;

    openBill(billId, customerPremise.parentAccountID, isApp, customerPremise.parentAccountNumber, customerPremise.serviceAgreementStatus).catch(() => {
      this.openModal('Oops!', `We can't retrieve your statement right now. Please try again later or contact the Aurora Energy Team by clicking on Contact Us in your App menu.`);
    });
  }

  renderBillSegments = () => {
    const { theme } = this.props.theme;
    const { customerPremise } = this.props.customer;
    if (!customerPremise) return null;
  
    const { isApp } = this.props.app;
    const parentAccountID = customerPremise.parentAccountID;
  
    // Safeguard: Ensure theme.transactionHistory and link styles are defined
    const transactionHistoryTheme = theme.transactionHistory || {};
    const linkStyles = transactionHistoryTheme.link || {
      color: '#0060AE',
      hoverColor: '#004080',
      textDecoration: 'none',
      hoverTextDecoration: 'underline',
    };
  
    return (
      <InfiniteList
        key={parentAccountID + this.state.tabChangeCount}
        ref={this.listBillsRef}
        isSideMenuOpen={this.props.app.isSideMenuOpen}
        manualLoadMore={true}
        getData={(pageRows, pageNumber) =>
          axios
            .get(process.env.REACT_APP_API_BASE_URI + `bills/${parentAccountID}?parentAccountNumber=${customerPremise.parentAccountNumber}&isOldCCB=${customerPremise.serviceAgreementStatus === "Old_CCB"}&pageNumber=${pageNumber}&pageRows=${pageRows}`)
            .then(res => {
              return res.data ? res.data.filter(doc => {
                return doc.InvoiceDocNumber && doc.StatementAvailable && !doc.InvoiceDocNumber.includes("CCB");
              }) : [];
            })
            .catch()
        }
        children={({ item, itemIndex }) => (
          <div 
            key={item.BillId} 
            className="bill-segment-item"
            style={itemIndex % 2 === 0 
              ? transactionHistoryTheme.item?.even 
              : transactionHistoryTheme.item?.odd}
          >
            <div>
              <span className="bill-segment-date">{moment(item.DateComplete).format('D MMM YYYY')}</span>
              <a
                href="#"
                className="bill-segment-view-link"
                style={{
                  color: linkStyles.color,
                  textDecoration: linkStyles.textDecoration,
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = linkStyles.hoverColor;
                  e.target.style.textDecoration = linkStyles.hoverTextDecoration;
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = linkStyles.color;
                  e.target.style.textDecoration = linkStyles.textDecoration;
                }}
                onClick={() => this.billClicked(item.BillId)}
              >
                View PDF
              </a>
            </div>
          </div>
        )}
      />
    );
  }
  
  handleCloseModal = () => {
    this.setState({
      modal: {
        isOpen: false
      }
    });
  }

  openModal = (title, description) => {
    this.setState({
      modal: {
        isOpen: true,
        description,
        title
      }
    });
  }

  render() {
    const { router, theme: { theme = {} } } = this.props;
    const { hasLoaded } = this.props.customer;

    if (!hasLoaded || !this.state.currentTab) return null;
    // Determine the pageTitle based on the currentTab
    let pageTitle = '';
    if (this.state.currentTab === 'transactions') {
    pageTitle = 'Transactions';
    } else if (this.state.currentTab === 'statements' || this.state.currentTab === 'bills') {
    pageTitle = 'Bills & transactions';
    } else {
    pageTitle = 'Transactions'; // Default to Transaction History if currentTab is not recognised
    }

    return (
      <Layout
        layout={FullWidthLayout}
        pageTitle={pageTitle}
        onPullDownRefresh={props => {
          let list = this.state.currentTab === 'transactions'
            ? this.listTransactionsRef
            : this.listBillsRef;
          list && list.current && list.current.getData(1, true);
        }}>

        <Modal
          name='bill-statement-error'
          size='tiny'
          open={this.state.modal.isOpen}
          onClose={this.handleCloseModal}>
          <Modal.Header>{this.state.modal.title}</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: 'left' }}>
              {this.state.modal.description}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary fluid onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Responsive lessThan="computer">
          <Button.Group className="tab-button-group">
            {this.renderTabButton('transactions', "Transactions")}
            {this.renderTabButton('bills', "Bills")}
          </Button.Group>
          {this.renderCurrentTab()}
        </Responsive>

        <Responsive greaterThan="tablet">
          <Grid centered>
            <Grid.Column computer={4}>
              <Button.Group className="transaction-history-desktop-tab-group">
                {this.renderTabButton('transactions', "Transactions")}
                {this.renderTabButton('bills', "Bills")}
              </Button.Group>
            </Grid.Column>
            <Grid.Column computer={12}>
              {this.renderCurrentTab()}
            </Grid.Column>
          </Grid>
        </Responsive>

      </Layout>
    );
  }
}

export default withRouter(withApp(withCustomer(withTheme(TransactionHistory))));
