import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
        height={height}
        width={width}
        viewBox="0 0 14 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="icon">
        <g id="Screens-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Hamburger-Menu"
                transform="translate(-31.000000, -195.000000)"
                fill={color}
                fillRule="nonzero"
                stroke={color}
                strokeWidth="0.3">
                <g id="account" transform="translate(32.000000, 196.000000)">
                    <path
                        d="M11.6996572,2.34259833 C9.71963118,2.34259833 8.3440278,1.74024168 7.53863881,1.23490085 C6.66379841,0.685973996 6.25120076,0.128570564 6.24754996,0.123621335 C6.19166688,0.0462383716 6.09921833,0.000246094231 6.00053181,0 C6.00027309,0 6.00001437,0 5.99975565,0 C5.90138534,0 5.80899428,0.0461563401 5.75285248,0.123019772 C5.74879924,0.128597907 5.33617284,0.68600134 4.46136119,1.23492819 C3.65600094,1.74026902 2.28036882,2.34262567 0.300371548,2.34262567 C0.134475845,2.34262567 0,2.47053998 0,2.62834107 L0,6.95043936 C0,6.95259952 2.87464396e-05,6.95481436 5.74928792e-05,6.95697453 C0.0304137331,8.21651214 0.117544192,9.35114326 0.879439827,10.4821923 C1.72694236,11.7403628 3.31406204,12.8498103 5.87413371,13.9737226 C5.91409126,13.9912773 5.95703844,14 5.99998562,14 C6.04293281,14 6.08593748,13.9912226 6.12583754,13.9737226 C8.68593796,12.8498103 10.2730289,11.7403901 11.1205602,10.4821923 C11.8824558,9.35114326 11.9695863,8.21651214 11.9999425,6.95697453 C11.9999712,6.95481436 12,6.95259952 12,6.95043936 L12,2.62831373 C12.0000287,2.47051264 11.8655529,2.34259833 11.6996572,2.34259833 Z M11.3992856,6.94718544 C11.3699643,8.1593089 11.2931538,9.16681869 10.6147666,10.1739183 C9.84531061,11.3161784 8.37725869,12.3427468 6.00001437,13.3993113 C3.6227988,12.3427741 2.15471813,11.3161784 1.38529093,10.1739183 C0.70687495,9.16684603 0.630064464,8.1593089 0.600771842,6.94718544 L0.600771842,2.90968146 C2.58597222,2.85149385 3.98365286,2.22458247 4.81959932,1.69465956 C5.38475432,1.33640105 5.77260129,0.977048783 6.00001437,0.736532689 C6.22742745,0.977048783 6.61527442,1.33640105 7.18042942,1.69465956 C8.01640463,2.22460981 9.41405653,2.85152119 11.3992856,2.90968146 L11.3992856,6.94718544 Z"
                        id="Shape"></path>
                    <path
                        d="M6.23076923,3.55555556 C4.0675897,3.55555556 2.30769231,5.25027156 2.30769231,7.33336114 C2.30769231,9.41642292 4.0675897,11.1111111 6.23076923,11.1111111 C8.39394876,11.1111111 10.1538462,9.41642292 10.1538462,7.33336114 C10.1538462,5.25027156 8.39394876,3.55555556 6.23076923,3.55555556 Z M6.23076923,10.5298996 C5.17534486,10.5298996 4.23341941,10.0530886 3.62497282,9.31143829 C4.19910981,8.80089321 4.94765391,8.47317432 5.75912782,8.38156655 C5.89887877,8.36577019 6.00873879,8.25911693 6.0239875,8.12440289 C6.03923621,7.98971666 5.95577263,7.86276175 5.82286627,7.81837619 C5.32231585,7.65126292 4.98597785,7.19611635 4.98597785,6.68584937 C4.98597785,6.02490512 5.54437517,5.48718918 6.23076923,5.48718918 C6.91716329,5.48718918 7.47556061,6.02490512 7.47556061,6.68584937 C7.47556061,7.19611635 7.13922261,7.6512073 6.63864331,7.81837619 C6.50570807,7.86276175 6.42227338,7.98971666 6.43752208,8.12440289 C6.45277079,8.25908912 6.56263081,8.36577019 6.70238176,8.38156655 C7.51385567,8.47320213 8.26239977,8.80089321 8.83656564,9.31143829 C8.22809017,10.0530886 7.2861936,10.5298996 6.23076923,10.5298996 Z M7.52864229,7.95295118 C7.87306673,7.62545478 8.07909758,7.17256086 8.07909758,6.68587718 C8.07909758,5.70447258 7.24994904,4.90603324 6.23076923,4.90603324 C5.21158943,4.90603324 4.38244088,5.70447258 4.38244088,6.68587718 C4.38244088,7.17253305 4.58852949,7.62542697 4.93289617,7.95295118 C4.32170597,8.12640525 3.76094047,8.42180834 3.29207155,8.81855287 C3.04907414,8.37453049 2.91125815,7.86918597 2.91125815,7.33338895 C2.91125815,5.57075972 4.40040432,4.13679492 6.23076923,4.13679492 C8.06113414,4.13679492 9.55028031,5.57075972 9.55028031,7.33336114 C9.55028031,7.86915816 9.41246432,8.37450268 9.16946691,8.81855287 C8.70059799,8.42178053 8.1398325,8.12640525 7.52864229,7.95295118 Z"
                        id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
)
}
