import React from 'react';
import Analytics from './../../utils/Analytics';

export default function Icon({
  color = 'white',
  height = '24px',
  width = height,
  style = {},
  inverted,
  onClick,
  eventName,
  ...rest
}){
  return (
    <svg
      onClick={e => {
        if (eventName) Analytics.modalview('info/' + eventName);
        if (onClick) onClick(e);
      }}
      height={height}
      width={width}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, cursor: 'pointer' }}
      {...rest}>
      <circle cx="12.5" cy="12.5" r="12" stroke={inverted ? null : color} fill={inverted ? color: null} />
	  <g transform="translate(5,5.5)">
	  	<path fill={inverted ? "white" : color} d="M13.704 0.000867461C13.4516 0.0102427 13.2201 0.150867 13.0865 0.375867L5.93771 11.8884L1.30646 7.67587C1.10756 7.45868 0.809197 7.37743 0.534588 7.46806C0.261463 7.55712 0.058104 7.80087 0.010604 8.09774C-0.036896 8.39618 0.0774007 8.69618 0.308963 8.87587L5.60521 13.7009C5.77146 13.8493 5.99115 13.9149 6.20638 13.8821C6.4231 13.8477 6.61459 13.7181 6.73334 13.5259L14.369 1.25087C14.5322 0.999305 14.5456 0.674305 14.4046 0.40868C14.2636 0.144618 13.9919 -0.0131949 13.704 0.000867461Z" />
	  </g>
    </svg>
  )
}