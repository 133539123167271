import React from "react";
import { Accordion } from "semantic-ui-react";
import './SignupFAQ.css';


const panels = [
	{
		key: "topic-1",
		title: "How much does aurora+ cost?",
		content: (
			<Accordion.Content>				
				<p><em><b>Quick answer</b></em></p>
				<p><em><b>Access to aurora+ is free for all eligible customers.</b></em></p>
				<p><em><b>aurora+ is powered by either our Residential Flat Rate (tariff 31 & 41) plan or our Residential Peak and Off-Peak (tariff 93) plan.</b></em></p>
				<p>With access to aurora+, you're able to see exactly how much power you're using and when, track and manage your usage and costs and make payments when it suits you.</p>
				<p>aurora+ customers receive a monthly statement – which means no more quarterly bills.</p>
				<p>There are two plan options with aurora+, Peak and Off-Peak or Flat Rate.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-2",
		title: "Can renters switch to aurora+?",
		content: (
			<Accordion.Content>				
				<p>If you are the Aurora Energy account holder for the property, it is entirely your choice which energy product you switch to. You may wish to speak with your landlord, just to let them know what is happening.</p>
				<p>If extra work is required before the meter can be exchanged, we will call you to let you know, and you might need to contact your landlord so that they can organise an electrical contractor to attend.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-3",
		title: "Can more than one person have access to the aurora+ app?",
		content: (
			<Accordion.Content>				
				<p>Yes, while you will only have one username and password for the app, you will be able to install the app on multiple devices.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-4",
		title: "Can I have multiple aurora+ log ins?",
		content: (
			<Accordion.Content>				
				<p>No, there is only one username assigned to an account, however you can use this log in on multiple devices.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-5",
		title: "What account notifications will I receive?",
		content: (
			<Accordion.Content>				
				<p>When you have aurora+, you will receive balance notifications letting you know when your account balance is approaching zero dollars. If you want to manage your account on a monthly basis, you can ignore these notifications and pay any amounts due when you receive your monthly statement.</p>
				<p>You may also receive product and service-related notifications from Aurora Energy or relevant third parties.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-6",
		title: "Will I get a bill on aurora+?",
		content: (
			<Accordion.Content>				
				<p>Yes, you'll receive a monthly account statement via email.</p>
				<p>The statement will outline your account balance at the time the statement was created. It will also display an overview of your energy usage for the month.</p>
				<p>If your balance is in debt, you will have 14 days to top up your account balance. Fees may apply for late payments.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-7",
		title: "Will there be app updates on aurora+?",
		content: (
			<Accordion.Content>				
				<p>Yes. From time to time we will make updates to the app to ensure that you are getting the best experience possible.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-8",
		title: "Is aurora+ available outside of Tasmania?",
		content: (
			<Accordion.Content>				
				<p>No, aurora+ is available in Tasmania only. You can access the app anywhere in the world so long as you have connection to the internet.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-9",
		title: "Can I change from aurora+ if it’s not for me?",
		content: (
			<Accordion.Content>				
				<p>Yes, you can stop using aurora+ at any time.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-10",
		title: "I don’t have a credit card, how else can I pay on aurora+?",
		content: (
			<Accordion.Content>				
				<p>You can pay via BPAY, direct debit, Centrepay, over the phone, at Australia Post, Service Tasmania and via cheque in the post.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-11",
		title: "Can I set up direct debit or Centrepay on aurora+?",
		content: (
			<Accordion.Content>				
				<p>Yes, you can set up periodic payments via Centrepay or Direct Debt via Centrelink, your Financial Institution or with us online or over the phone.</p>
			</Accordion.Content>
		)
	},

		
];

export default () => {
	return <div className="signup-faq">
		<h3>Questions about aurora+</h3>
		<Accordion panels={panels} />
	</div>
}

