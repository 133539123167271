import React, {Component} from 'react';
import {Button, Checkbox, Header, Popup, Icon} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import {FORM_STATE} from '../../utils/FormState';
import ValidatedInput from '../../components/Forms/ValidatedInput';
import ValidatedNumberFormatInput from '../../components/Forms/ValidatedNumberFormatInput';
import Analytics from '../../utils/Analytics';
import ConsentFormHelper from './ConsentFormHelper';
import {ID_TYPES, PAGE_NUMBERS} from './constants';
import ReCAPTCHA from 'react-google-recaptcha'

export default class LandingPage extends Component {

  state = {
    recaptcha: '',
    propertyIdLoading: FORM_STATE.DEFAULT,
    errorValidatingPropertyId: false,
    propertyIdValid: '',
    formValid: '',
    hidingPropertyId: false,
    propertyIdHeight: null
  }

  componentDidMount() {
    this.dataHelper = new ConsentFormHelper()
  }

  unknownPropertyId = () => { 
     var hide = !this.state.hidingPropertyId 
     this.setState({hidingPropertyId: hide})

     if(hide) {
       this.setState({propertyIdHeight: 0})
     } else {
       this.setState({propertyIdHeight: null})
     }

     this.props.saveGlobalState({premiseIdIsUnknown: hide})
  }

  submit = () => {
    this.setState({propertyIdLoading: FORM_STATE.LOADING})
    this
      .verifyPropertyId()
      .then(res => {
        this.setState({propertyIdLoading: FORM_STATE.DONE_LOADING})

        if (res) {
          this.setState({errorValidatingPropertyId: false})
          this
            .props
            .next()
        } else {
          this.setState({errorValidatingPropertyId: true})
        }

      })
  }

  verifyPropertyId = () => {
    return new Promise((resolve) => {
      //We now ignore property ID competely
      this.props.saveGlobalState({idTypeOptions: ID_TYPES, typeOfID: ID_TYPES[0].value, unverifiedPremiseId: this.props.context.propertyID})
      Analytics.event({
        category: 'New Consent Form',
        action: `Landing Page - Submission`,
        value: this.props.context.propertyID
      })

      resolve(true)      
      return

      //Remove above and uncomment below to re-enable ID type selection
      /*
      if (this.state.formValid && this.props.context.propertyID !== '') {
        try {

          this
            .dataHelper
            .getValidCustomerIdTypes(this.props.context.propertyID)
            .then(res => {

              let idTypeList = []

              if (!res || res.length === 0) {
                // Record a failed property validation attempt, along with the property ID.
                Analytics.event({
                  category: 'Consent Form',
                  action: `Verify Property ID - Failure`,
                  value: parseInt(this.props.context.propertyID)
                })
                resolve(false)
                return;
              } else {
                // Record a successful property validation attempt.
                Analytics.event({category: 'Consent Form', action: `Verify Property ID - Success`})
                res.forEach(type => {
                  idTypeList.push({
                    value: type.IDTypeCode,
                    text: ID_TYPES
                      .find(idType => {
                      return idType.key === type.IDTypeCode
                    })
                      .text,
                    key: type.IDTypeCode
                  })
                })
              }

              // Default the ID Type to TAS Drivers License if available
              let tasDriversLicenceType = idTypeList.find(i => i.value === 'TASDRV');
              let selectedType = tasDriversLicenceType || idTypeList[0];

              this
                .props
                .saveGlobalState({idTypeOptions: idTypeList, typeOfID: selectedType.value})

              resolve(idTypeList.length > 0)

            })
        } catch (Exception) {
          // Record a failed property validation attempt, along with the property ID.
          Analytics.event({
            category: 'Consent Form',
            action: `Verify Property ID - Failure`,
            value: parseInt(this.props.context.propertyID)
          })
          resolve(false)
        }
      } else {
        this
          .props
          .saveGlobalState({idTypeOptions: ID_TYPES, typeOfID: ID_TYPES[0].value})
        resolve(true)
      }*/

    })
  }

  propertyIdChanged = () => {
    this.setState({errorValidatingPropertyId: false})
  }

  verifyCaptchaCallback = (response) => {
    if (response) {

      this
        .dataHelper
        .getFormToken(response)
        .then(formToken => {

          this
            .props
            .saveGlobalState({captchaToken: response, oneTimeFormToken: formToken})
        })

    }

    this.setState({recaptcha: response})
  }

  canSubmit = () => {
    return (this.state.recaptcha && this.state.formValid)
  }

  onFormValid = () => {
    this.setState({formValid: true})
  }
  onFormInvalid = () => {
    this.setState({formValid: false})
  }

  // One of the fields used to identify the customer has been modified. Stop
  // marking them as invalid.
  identifierChanged = () => {
    this.setState({markIdentificationFieldValid: true})
  }


  render() {
    const {currentStep, propertyID} = this.props.context;

    if (currentStep !== PAGE_NUMBERS.LANDING) {
      return null;
    }
   
    return (
      <React.Fragment>

        <Header textAlign="center" size="large">
          {this.props.context.selectedProduct.text}<br/>
          ({this.props.context.selectedTariff.text})
        </Header>

        <p>
          
         Tell us who you are 
        </p>

        <Form
          noValidate
          onChange={this.props.change}
          onValidSubmit={this.submit}
          onValid={this.onFormValid}
          onInvalid={this.onFormInvalid}
          loading={this.state.propertyIdLoading === FORM_STATE.LOADING}>
          {/* Last Name */}
	  <ValidatedInput
	    required
	    validations={{
	    "maxLength": 100,
	    "isParameterTrue": this.state.markIdentificationFieldValid
	  }}
	    hideErrorMessage
	    name="lastName"
	    placeholder="Last Name"
	    value={this.props.context.lastName}
	    onChange={this.identifierChanged}/>

          {/* Date of Birth */}
	  <ValidatedNumberFormatInput
	    label={'Date of birth'}
	    className={'hide-labels'}
	    required
	    validations={{
	      "birthDate": true,
	      "isParameterTrue": this.state.markIdentificationFieldValid
	    }}
	    value={this.props.context.dateOfBirth}
	    type="tel"
	    format="##/##/####"
	    mask="_"
	    name="dateOfBirth"
	    placeholder="Date of Birth (DD/MM/YYYY)"
	    onChange={this.identifierChanged}/>

          <Checkbox
            style={{
            marginRight: '10px',
            marginBottom: '10px'
          }}
            className='consent'
            name='noPropertyId'
            checked={this.state.hidingPropertyId}
            onChange={this.unknownPropertyId}
            label={{ children: 'I do not know my property ID' }} />

          <Popup
            trigger={< Icon name = 'question circle' size = 'large' floated = 'right' style = {{float: 'right'}}/>}
            content={'You can find your Property ID number on the letter we have sent you.'}
            on='click'/>
          
          <ValidatedInput
            style={{
              transition:'height 1s ease-out',
              overflow: 'hidden',
              height: this.state.propertyIdHeight
            }}
            id="propertyID"
            name="propertyID"
            placeholder="Property ID"
            value={propertyID}
            validations={{
            "minLength": 10,
            "maxLength": 10,
            "isParameterTrue": !this.state.errorValidatingPropertyId
          }}
            validationErrors={{
            minLength: "Property ID must be 10 digits.",
            maxLength: "Property ID must be 10 digits.",
            isParameterTrue: "Sorry, we can't confirm your Property ID. Delete the Property ID and proceed manually."
          }}
            onChange={this.propertyIdChanged}
            type='number'
            max={10}
            errorAlign={'left'}
            maxLength='2'
            loading={this.state.propertyIdLoading === FORM_STATE.LOADING}/> {!this.props.context.oneTimeFormToken && <Form.Field>
            <ReCAPTCHA
              size={(window.innerWidth < 400
              ? 'compact'
              : 'normal')}
              sitekey="6LdRyHUUAAAAAPWBiUkp5arOtIp3xZKc-E9NsBi_"
              onChange={this.verifyCaptchaCallback}/>
          </Form.Field>}

          <Form.Field>
            <Button
              fluid
              secondary
              onClick={this.submitPress}
              loading={this.state.formState === FORM_STATE.LOADING}
              disabled={!this.canSubmit()}>Next</Button>
          </Form.Field>

        </Form>
      </React.Fragment>
    );
  }
}
