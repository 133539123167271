import { useMemo } from "react";

export default ((allPremises) => {
	const [signedUpPremises, inProgressPremises, notSignedUpPremises] = useMemo(() => {

		if (!allPremises) return [[], [], []];

		const signedUp = [];
		const inProgress = [];
		const notSignedUp = [];

		const potentials = allPremises.filter((premises) => {
			if (premises.IsFromCCB) return false;
			if (premises.serviceAgreementStatus !== "Active" && premises.serviceAgreementStatus !== "Pending") return false;
			if (premises.hasOffProductStatus) return false;
			if (!premises.address || premises.address.length == 0) return false;			
			return true;
		});

		potentials.forEach((premises) => {
			if (premises.inAppSignupInProgress && !premises.hasPAYGPlus) inProgress.push(premises);
			else if (premises.hasPAYGPlus) signedUp.push(premises);
			else notSignedUp.push(premises);
		})

		return [
			signedUp,
			inProgress,
			notSignedUp
		]

	}, [allPremises]);

	return [signedUpPremises, inProgressPremises, notSignedUpPremises];
})