import React from "react";
import { Accordion } from "semantic-ui-react";
import './SignupFAQ.css';

const panels = [
	{
		key: "topic-1",
		title: "How long will it take to get a meter exchanged?",
		content: (
			<Accordion.Content>
				<p>Our metering provider will install your new meter and we'll let you know the date, time, and estimated duration of the power interruption required to complete this work.</p>
				<p>You may have received details of this notification from us by email, SMS, or in the mail with at least 4 days’ notice wherever possible.</p>
				<p>Meter exchanges, requested by you, will be connected <b>within 15 business days</b> of processing your request or we will agree on a date that’s convenient for you.</p>
				<p>Sometimes, getting all the people in the same place at the same time (such as if your contractor has to meet the crew on-site), complicated jobs, contractual agreements, access, safety, and impacts on other customers can affect the timeframes and our obligations to you. We will work with you to try and make the process as smooth and efficient as possible.</p>
				<p>If a delay occurs due to any of the above issues, we will let you know, and once the issue is rectified the timeframe will be reset.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-2",
		title: "Is there a cost to upgrade my meter?",
		content: (
			<Accordion.Content>
				<p>There is no upfront cost to exchange your meter to an advanced meter. Sometimes extra work is required before a meter can be safely exchanged (such as asbestos removal, upgrading cotton mains), and this work may be at a cost to you. If we identify extra work is required we won't exchange your meter and we'll contact you to let you know what happens next.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-3",
		title: "Do advanced meters interfere with other devices?",
		content: (
			<Accordion.Content>
				<p>No. Your advanced meter operates at a low frequency and power level.</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-4",
		title: "Is my information protected?",
		content: (
			<Accordion.Content>
				<p>Advanced meters have advanced security features that prevent unauthorised access. Your information is encrypted and sent over secure and private networks.</p>
				<p>The data sent to us only contains your power usage data and meter ID number and does not include your name or address details.</p>
				<p>We take the security of your data very seriously. We are bound by the Personal Information and Protection Act 2004 (Tas.) and the Australian Privacy Principles in the Privacy Act 1988 (Privacy Act).</p>
			</Accordion.Content>
		)
	},
	{
		key: "topic-5",
		title: "Are advanced meters safe?",
		content: (
			<Accordion.Content>
				<p>Yes. Advanced meters are made and installed according to Australian standards.</p>
				<p>There is no scientific evidence to suggest that there are any health effects caused by advanced meters.</p>
				<p>While your energy data is recorded every 15 minutes, your meter only sends us your data once per day, at the end of each day.</p>
			</Accordion.Content>
		)
	},
		
];

export default () => {
	return <div className="signup-faq">
		<h3>Advanced Meter FAQs</h3>
		<Accordion panels={panels} />
	</div>
}

