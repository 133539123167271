import TagManager from 'react-gtm-module'

// .set({ userId })
// .event({ category, action, value? })
// .pageview(location.pathname)
// .modalview(name)
// .enabled
// OutboundLink not implemented

const gtmEnabled = !!((process.env.REACT_APP_GOOGLE_GTM_ENABLED !== false) && process.env.REACT_APP_GOOGLE_GTM_ID && process.env.REACT_APP_GOOGLE_GTM_AUTH);

//console.log(process.env.REACT_APP_GOOGLE_GTM_ID, gtmEnabled);

const Analytics = {
	setCustomerId: (customerId) => {
		//console.log("Analytics customerId:", customerId)
		if (!gtmEnabled) return;
		TagManager.dataLayer({
			dataLayer: {
				application_id: customerId,
				user_id: customerId,
			}
		});
	},
	setPremises: (premises) => {
		//console.log("Analytics setPremises:", premises);
		if (!gtmEnabled) return;
		if (premises) {
			TagManager.dataLayer({
				dataLayer: {
					prod_type: premises.serviceAgreementType.substr(0, 1),
					tou: (!!premises.currentTimeOfUsePeriodEndDate) ? "1" : "0",
					app_state: premises.currentAppExperience,
				},
			});
		} else {
			TagManager.dataLayer({
				dataLayer: {
					prod_type: null,
					tou: null,
					app_state: null,
				},
			});
		}
	},
	setData: (data) => {
		//console.log("Analytics setData:", data);
		if (!gtmEnabled) return;
		TagManager.dataLayer({
			dataLayer: data,
		});
	},
	event: ({ category, action, value }) => {
		//console.log("Analytics event:", category, action, value)
		if (!gtmEnabled) return;
		TagManager.dataLayer({
			dataLayer: {
				event: action,
				event_category: category,
				event_value: value,
			}
		})

	},
	pageview: (pathname) => { /*console.log("Analytics pageview:", pathname)*/ },
	modalview: (name) => { /*console.log("Analytics modalview:", name)*/ },
	enabled: gtmEnabled,
}

export default Analytics

if (gtmEnabled) {
	/*
	console.log("Initialize GTM", {
		gtmId: process.env.REACT_APP_GOOGLE_GTM_ID,
		auth: process.env.REACT_APP_GOOGLE_GTM_AUTH,
		preview: process.env.REACT_APP_GOOGLE_GTM_ENV,
	})
	*/
	TagManager.initialize({
		gtmId: process.env.REACT_APP_GOOGLE_GTM_ID,
		auth: process.env.REACT_APP_GOOGLE_GTM_AUTH,
		preview: process.env.REACT_APP_GOOGLE_GTM_ENV,
	})
}





