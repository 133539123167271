import React from 'react'
import {Image, Segment} from 'semantic-ui-react'
import LandingPage from './LandingPage.js'
import IdentityPage from './IdentityPage.js'
import ProductDetailsPage from './ProductDetailsPage.js'
import ConsentPage from './ConsentPage.js'
import ConfirmationPage from './ConfirmationPage.js'
import FailIdentificationPage from './FailIdentificationPage.js'
import ResidenceQuestions from './ResidenceQuestions.js'
import logoAPAYG from '../../static/logo.svg'
import {withApp} from '../../contexts/App'
import {withCustomer} from '../../contexts/Customer'
import { withRouter } from '../../utils/useRouter.js'
import Layout from '../../layouts/Layout'
import ConsentFormLayout from '../../layouts/ConsentFormLayout'
import Analytics from '../../utils/Analytics'
import {
  PRODUCT_TYPES,
  VALID_TARIFFS,
  MAX_PAGE_STEP,
  MIN_PAGE_STEP,
  TRACKING_SCRIPTS,
  MAILING_ADDRESS_TYPES,
  BACKGROUND_IMAGE,
  PAGE_NAMES
} from './constants'

class ConsentForm extends React.Component {
  state = {
    currentStep: MIN_PAGE_STEP, // Current form page
    oneTimeFormToken: '', // Token for API POST
    captchaToken: '',

    // User Information
    title: '',
    firstName: '',
    givenNames: '',
    lastName: '',
    dateOfBirth: null,
    email: '',
    phoneNumbers: [
      {
        PhoneNumber: '',
        PhoneType: 'MOP'
      }
    ],
    propertyID: '', // Property or Service Agreement ID
    customerID: '', // Customer ID loaded from CC&B
    typeOfID: '', // The identification type used to validate the user
    typeOfIDNumber: '', // The identification number used to validate the user
    idTypeOptions: [], // List of identification types

    // Address Information
    address: '', // Users address in string format
    addressSelection: '', // Collection of addresses (if CC&B returns multiple)
    premise: '', // Users premise object
    multipleAddresses: false,

    /*
     * customerIsUnverified used to be the flag for the dumb/smart form.
     * the form defaults to the dumb form now, so we just set this true
     */
    customerIsUnverified: true,

    // Residence Information
    hasInternalSwitches: false,
    hasSpecialInstructions: false,
    specialInstructionsPhone: '',
    specialInstructions: false,
    hasDog: false,
    useAddressForPostedCorrespondence: true,
    isLivingAtAddress: true,
    hasLifeSupport: '',

    // Mailing Address - Used when mailing address is different from address being
    // signed up
    mailingAddressType: MAILING_ADDRESS_TYPES[0].value,
    mailingUnitNumber: '',
    mailingStreetNumber: '',
    mailingStreetName: '',
    mailingStreetType: '',
    mailingSuburb: '',
    mailingPostcode: '',
    mailingState: '',
    
    //Unverified details
    premiseIdIsUnknown: false,
    unverifiedPremiseId: '',
    customerIdIsUnknown: false,
    unverifiedCustomerId: '',


    // Request a callback details
    callbackFirstname: '',
    callbackLastName: '',
    callbackPhoneNumber: '',
    callbackReason: '',
    callbackFormSubmitted: false,

    // Error page messages
    failureMessage: '',
    contactMessage: '',

    // Consent Information
    hasOptedInForMarketing: false,
    agreeConsentForInstallation: false,

    startTime: new Date(),

    // Product ID passed in URL parameter
    selectedProduct: PRODUCT_TYPES.find(product => {
      return (product.value === this.props.router.params.product && product.tariffs.includes(this.props.router.params.tariff))
    }),

    // Tariff Number passed in URL parameter
    selectedTariff: VALID_TARIFFS.find(tariff => {
      return tariff.value === this.props.router.params.tariff
    })
  }

  /**
   * Setup Consent Form page background.
   */
  componentDidMount() {
    if (BACKGROUND_IMAGE) 
      this.props.app.setBackgroundImage(BACKGROUND_IMAGE)

    Analytics.event({category: 'New Consent Form', action: `Loaded Form`})
  }

  /**
   * Allow child pages to manually share state by saving back to general Consent Form state.
   */
  saveGlobalState = context => {
    this.setState(context)
  }

  /**
   * Progress to the next page of the form.
   */
  next = () => {
    let currentStep = this.state.currentStep >= MAX_PAGE_STEP - 1
      ? MAX_PAGE_STEP
      : this.state.currentStep + 1

    this.setState({currentStep: currentStep})
    let authenticationStatus = ''
    if(currentStep > 2)
    {
      authenticationStatus = '(Authenticated) '
      if(this.state.customerIsUnverified)
      {
        authenticationStatus = '(Not Authenticated) '
      }
    }

    Analytics.event({category: 'New Consent Form', action: `Next Page`, label: `Step: ${currentStep}, Page: ${authenticationStatus}${PAGE_NAMES[currentStep]}`})
    
  }

  /**
   * Go back to the previous page of the form.
   */
  prev = () => {
    let currentStep = this.state.currentStep <= MIN_PAGE_STEP
      ? 1
      : this.state.currentStep - 1

    this.setState({currentStep: currentStep})
  }

  /**
   * To be called by each forms' OnChange, to automatically update state.
   */
  change = (values, isChanged) => {
    if (isChanged) {
      this.setState({
        ...values
      })
    }
  }

  /**
   * Returns the tracking script for the currently selected Product + Tariff combination.
   */
  trackingScript = () => {
    let tmp = TRACKING_SCRIPTS.find(item => {
      return (item.product === this.state.selectedProduct.value && item.tariff === this.state.selectedTariff.value)
    })

    return tmp && tmp.script;
  }

  render() {
    if (this.state.selectedProduct !== undefined && this.state.selectedTariff !== undefined) {
      return (
        <Layout accountPicker={false} layout={ConsentFormLayout}>
          <img
            height='1'
            width='1'
            style={{
            borderStyle: 'none'
          }}
            alt=''
            src={this.trackingScript()}/> {/* Logo */}
          <Segment
            basic
            style={{
            marginBottom: '2rem',
            paddingLeft: '2.5em',
            paddingRight: '2.5em'
          }}>
            <Image centered size='medium' src={logoAPAYG}/>
          </Segment>

          <Segment>
            <LandingPage
              context={this.state}
              prev={this.prev}
              next={this.next}
              change={this.change}
              saveGlobalState={this.saveGlobalState}/>

            <IdentityPage
              context={this.state}
              prev={this.prev}
              next={this.next}
              change={this.change}
              changeDate={this.changeDate}
              saveGlobalState={this.saveGlobalState}/>

            <ProductDetailsPage
              context={this.state}
              prev={this.prev}
              next={this.next}
              change={this.change}
              saveGlobalState={this.saveGlobalState}/>

            <ConsentPage
              context={this.state}
              prev={this.prev}
              next={this.next}
              change={this.change}
              saveGlobalState={this.saveGlobalState}/>

            <ResidenceQuestions
              context={this.state}
              prev={this.prev}
              next={this.next}
              change={this.change}
              saveGlobalState={this.saveGlobalState}/>

            <ConfirmationPage context={this.state}/>

            <FailIdentificationPage
              context={this.state}
              saveGlobalState={this.saveGlobalState}/>
          </Segment>

        </Layout>
      )
    } else {
      return null
    }
  }
}

export default withRouter(withApp(withCustomer(ConsentForm)))
