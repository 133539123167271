import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '16px',
  width = '24px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 42 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path fill={color} d="M5.5 0C2.48023 0 0 2.48023 0 5.5V20.5C0 23.5198 2.48023 26 5.5 26H31.5C34.5198 26 37 23.5198 37 20.5V5.5C37 2.48023 34.5198 0 31.5 0H5.5ZM5.5 3H31.5C32.8982 3 34 4.10177 34 5.5V7H3V5.5C3 4.10177 4.10177 3 5.5 3ZM39 5.0293V20.5C39 24.64 35.64 28 31.5 28H6.0293C7.1893 29.8 9.21 31 11.5 31H31.5C37.29 31 42 26.29 42 20.5V10.5C42 8.21 40.8 6.1893 39 5.0293ZM3 10H34V20.5C34 21.8982 32.8982 23 31.5 23H5.5C4.10177 23 3 21.8982 3 20.5V10ZM24.5 17C24.3012 16.9972 24.1039 17.0339 23.9194 17.108C23.735 17.1822 23.5671 17.2922 23.4255 17.4318C23.284 17.5713 23.1716 17.7376 23.0949 17.921C23.0181 18.1044 22.9786 18.3012 22.9786 18.5C22.9786 18.6988 23.0181 18.8956 23.0949 19.079C23.1716 19.2624 23.284 19.4287 23.4255 19.5682C23.5671 19.7078 23.735 19.8178 23.9194 19.892C24.1039 19.9661 24.3012 20.0028 24.5 20H29.5C29.6988 20.0028 29.8961 19.9661 30.0806 19.892C30.265 19.8178 30.4329 19.7078 30.5745 19.5682C30.716 19.4287 30.8284 19.2624 30.9051 19.079C30.9819 18.8956 31.0214 18.6988 31.0214 18.5C31.0214 18.3012 30.9819 18.1044 30.9051 17.921C30.8284 17.7376 30.716 17.5713 30.5745 17.4318C30.4329 17.2922 30.265 17.1822 30.0806 17.108C29.8961 17.0339 29.6988 16.9972 29.5 17H24.5Z" />
    </svg>
  );
}
