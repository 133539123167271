import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Loader } from "semantic-ui-react";
import { Navigate } from "react-router-dom";
import { Buffer } from "buffer";

import Layout from '../../layouts/Layout';
import FullWidthLayout from '../../layouts/FullWidthLayout';
import ColumnLayout from '../../layouts/ColumnLayout';
import Modal from '../../components/Modal';
import ExternalLink from '../../components/ExternalLink';
import Urls from '../../utils/Urls';
import { FORM_STATE } from '../../utils/FormState';

import { withCustomer } from '../../contexts/Customer'
import { withTheme } from '../../contexts/Theme'
import { withRouter } from '../../utils/useRouter';
import { RequestHelp, AlreadyHasHelp, LandingPage } from './components/HelpLandingPage';
import PlanForm from './components/PlanForm';
import ExtensionForm from './components/ExtensionForm';
import Analytics from '../../utils/Analytics';


export class PaymentHelp extends React.Component {

  state = {
    formState: FORM_STATE.DEFAULT,
    pageLoading: false,
    alreadyHasExtension: false,
    modalExtensionDetails: false,
    modalTermsAndConditions: false,
    paymentExtension: null,
    paymentPlan: null,
    modalExtensionError: false,
    modalExtensionCreditError: false,
    modalExtensionSuccess: false,
    modalPlanSubmit: false,
    modalPlanSubmitted: false,
    modalUnexpectedError: false
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerPremise } = this.props.customer;

    if (!customerPremise) return;

    const { customerPremise: prevCustomerPremise } = prevProps.customer;

    if (!prevCustomerPremise || prevCustomerPremise.serviceAgreementID !== customerPremise.serviceAgreementID) {
      this.handleCloseModal();
      this.setState({
        pageLoading: false,
        alreadyHasExtension: false,
        modalExtensionDetails: false,
        modalTermsAndConditions: false,
        modalPlanSubmit: false,
        modalPlanSubmitted: false,
        modalUnexpectedError: false,
        paymentExtension: null,
        paymentPlan: null
      });

      // check if current customer already has an Extension
      this.hasExtension();
    }
  }

  handleCloseModal = () => {
    this.setState({
      modalExtensionDetails: false,
      modalExtensionError: false,
      modalExtensionCreditError: false,
      modalExtensionSuccess: false,
      modalTermsAndConditions: false,
      modalPlanSubmit: false,
      modalPlanSubmitted: false,
      modalUnexpectedError: false,
    });
  }

  newPaymentPlan = () => {
    this.setState({
      paymentPlan: {
        frequency: null,
        amountPerInstalment: null,
        heatingSource: null,
        startDate: null,
        canSubmit: false,
        formState: FORM_STATE.DEFAULT
      }
    });
  }

  openModal = (modalId) => {
    this.setState({
      [modalId]: true
    });
  }

  hasExtension = () => {
    const { customer, customerPremise, pageConfig } = this.props.customer;

    if (!customerPremise
      || !pageConfig.functionality.paymentExtension
      || !customerPremise.hasActivePaymentExtension) {
      
      if (this.state.paymentExtension) {
        this.setState({ alreadyHasExtension: false });
      }
      return;
    }

    this.setState({ pageLoading: true });

    axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${customerPremise.parentAccountID}/extensions`)
      .then(res => {
        const data = res.data;
        //console.log(data);

        // a paymentExtension will still exist even when it's paid off
        // so if the amount is $0, it's no longer relevant
        this.setState({
          alreadyHasExtension: true,
          pageLoading: false
        });

      })
      .catch(err => {
        this.setState({
          alreadyHasExtension: false,
          pageLoading: false
        });
      })
  }

  onExtensionRequest = () => {

    const { customerPremise } = this.props.customer;

    this.setState({ pageLoading: true });

    axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${customerPremise.parentAccountID}/extension-eligibility`)
      .then(res => {
        const data = res.data;
        //console.log(data);

        if (data.Eligible) {
          this.setState({
            paymentExtension: {
              id: data.Id,
              accountId: data.AccountId,
              amountOutstanding: data.OutstandingAmount,
              dateDue: data.DueDate,
              isOverdue: moment(data.DueDate).diff(moment(), "days") < 0,
              canSubmit: false,
              formState: FORM_STATE.DEFAULT
            },
            pageLoading: false
          });

          return;
        }

        this.setState({
          paymentExtension: null,
          pageLoading: false
        });
    
        if (data.Reason === "IN_CREDIT")// || data.Reason.startsWith("PAYMENT_PLAN"))
          this.openModal('modalExtensionCreditError');
        else
          this.openModal('modalExtensionError');

      })
      .catch(err => {
        this.setState({ paymentExtension: null });
        this.openModal('modalExtensionError');
      })

  }

  onSubmitExtension = () => {
    const { customerPremise } = this.props.customer;

    this.setState({
      paymentExtension: { ...this.state.paymentExtension, formState: FORM_STATE.LOADING }
    });

    axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${customerPremise.parentAccountID}/extension-create`)
      .then(res => {
        const data = res.data;
        //console.log(data);

        this.setState({
          paymentExtension: { ...this.state.paymentExtension, formState: FORM_STATE.DONE_LOADING }
        })
        this.openModal('modalExtensionSuccess');
        Analytics.event({category: 'Payment', action: 'Payment Extension successful'})

      })
      .catch(err => {
        this.setState({
          paymentExtension: { ...this.state.paymentExtension, formState: FORM_STATE.DONE_LOADING }
        })
        this.openModal('modalExtensionError');
      })

  }

  onSubmitPlan = (payload, resetForm) => {

    this.setState({
      paymentPlan: { ...this.state.paymentPlan, formState: FORM_STATE.LOADING }
    });
    this.handleCloseModal();

    payload = this.formatPayload(payload);

    axios
      .post(process.env.REACT_APP_API_BASE_URI + 'feedback', payload)
      .then((res) => {

        this.setState({
          paymentPlan: { ...this.state.paymentPlan, formState: FORM_STATE.DONE_LOADING }
        })
        this.openModal('modalPlanSubmitted');
        Analytics.event({category: 'Payment', action: 'Payment Plan requested'})
      })
      .catch((error) => {
        this.setState({
          paymentPlan: { ...this.state.paymentPlan, formState: FORM_STATE.DONE_LOADING }
        })
        this.openModal('modalUnexpectedError');
      });
  }

  // return new payload with customer details added
  formatPayload = initialPayload => {
    let payload = {
      ...initialPayload
    };
    const { customer, customerPremise } = this.props.customer;
    const { amountPerInstalment, frequency, heatingSource, startDate } = this.state.paymentPlan;

    payload.feedbackCategory = 'payments-help';
    payload.message =
      `[PPA] Payment plan application. Amount = $${amountPerInstalment}, frequency = ${frequency}, main heating source = ${heatingSource}, start date = ${startDate}.`;
    payload.message = Buffer.from(payload.message).toString('base64')

    payload.email = customer.emailAddress;
    payload.name = `${customer.firstName} ${customer.lastName}`;
    payload.personId = customer.customerID;
    payload.serviceAgreementId = customerPremise.serviceAgreementID;
    payload.accountId = customerPremise.parentAccountID;

    return payload;
  }

  onFrequencyChange = (e, { value }) => {
    this.setState({
      paymentPlan: { ...this.state.paymentPlan, frequency: value }
    });
  }

  onInstalmentAmountChange = (e, { value }) => {
    this.setState({
      paymentPlan: { ...this.state.paymentPlan, amountPerInstalment: value }
    });
  }

  onStartDateChange = (e, { value }) => {
    this.setState({
      paymentPlan: { ...this.state.paymentPlan, startDate: value }
    });
  }

  onHeatingSourceChange = (e, { value }) => {
    this.setState({
      paymentPlan: { ...this.state.paymentPlan, heatingSource: value }
    });
  }

  onExtFormValid = () => {
    this.setState({
      paymentExtension: { ...this.state.paymentExtension, canSubmit: true }
    });
  }

  onExtFormInvalid = () => {
    this.setState({
      paymentExtension: { ...this.state.paymentExtension, canSubmit: false }
    });
  }

  onPlanFormValid = () => {
    this.setState({
      paymentPlan: { ...this.state.paymentPlan, canSubmit: true }
    });
  }

  onPlanFormInvalid = () => {
    this.setState({
      paymentPlan: { ...this.state.paymentPlan, canSubmit: false }
    });
  }

  sharedModals() {
    const { theme } = this.props.theme;

    return (
      <React.Fragment>

        <Modal
          name="paymenthelp/extension-error"
          size="tiny"
          open={this.state.modalExtensionCreditError}
          onClose={this.handleCloseModal}>
          <Modal.Header>No billed amount outstanding</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>Good news! You don’t currently have any billed amounts due.</p>
              <p>You can only apply for an extension when you have an amount outstanding.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="paymenthelp/extension-error"
          size="tiny"
          open={this.state.modalExtensionError}
          onClose={this.handleCloseModal}>
          <Modal.Header>Payment extension not available</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>Sorry, we are unable to approve you for an automatic payment extension. If you would like more time to pay please submit a request on the contact us page.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              fluid
              secondary
              onClick={() => {
                this.handleCloseModal();
                this
                  .props
                  .router
                  .navigate('/contact/payments-help')
              }}>Contact us</Button>
            <Button fluid onClick={this.handleCloseModal}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="paymenthelp/extension"
          size="tiny"
          open={this.state.modalExtensionDetails}
          onClose={this.handleCloseModal}>
          <Modal.Header>Payment Extension</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>Your new payment due date will be extended by 10 business days from the original due date of your bill.</p>
              <p>If you're approved for your extension before the due date you won't get charged a late fee!</p>
              <p>A small amount of interest will be charged to your next bill every day there is an outstanding amount after the due date.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="paymenthelp/termsandconditions"
          size="tiny"
          open={this.state.modalTermsAndConditions}
          onClose={this.handleCloseModal}>
          <Modal.Header>Terms & conditions and privacy statement</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>By submitting this online form you are agreeing to the fees that apply and acknowledge and understand the way Aurora Energy collects, uses and discloses your personal information as set out in our privacy collection statement.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              fluid as={ExternalLink}
              href={Urls.DATA_COLLECTION_STATEMENT}>Privacy collection statement</Button>

            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="paymenthelp/extension-success"
          size="tiny"
          open={this.state.modalExtensionSuccess}
          onClose={this.handleCloseModal}>
          <Modal.Header>Extension approved</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>Your payment extension has been approved! You will see the new payment due date and amount owing on your home screen.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.handleCloseModal();
                this
                  .props
                  .router
                  .navigate('/');
              }}>Return to home</Button>
          </Modal.Actions>
        </Modal>

      </React.Fragment>
    );
  }

  planModals() {
    const { theme } = this.props.theme;

    return (
      <React.Fragment>
        <Modal
          name="paymenthelp/plan-submit"
          size="tiny"
          open={this.state.modalPlanSubmit}
          onClose={this.handleCloseModal}>
          <Modal.Header>Confirm details</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>You are applying for a payment plan with <b>{this.state.paymentPlan.frequency}</b> payments of
                <b> ${this.state.paymentPlan.amountPerInstalment}</b> and your main heating source is
                <b> {this.state.paymentPlan.heatingSource}</b>.
              </p>
              <p>The start date of the payment plan is <b>{this.state.paymentPlan.startDate}</b>.</p>
              <p>Tap Ok to send your application to our team.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              fluid
              secondary
              onClick={() => this.onSubmitPlan()}>Ok</Button>
            <Button fluid onClick={this.handleCloseModal}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="paymenthelp/plan-submitted"
          size="tiny"
          open={this.state.modalPlanSubmitted}
          onClose={this.handleCloseModal}>
          <Modal.Header>Application submitted</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>Your payment plan application has been submitted.  We will review your application
                  within two business days and you will be notified when the payment plan is active.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              fluid
              secondary
              onClick={() => {
                this.handleCloseModal();
                this
                  .props
                  .router
                  .navigate('/');
              }}>Ok</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="paymenthelp/plan-submission-error"
          size="tiny"
          open={this.state.modalUnexpectedError}
          onClose={this.handleCloseModal}>
          <Modal.Header>Oh No! Something went wrong</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: "left" }}>
              <p>Please try again.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              fluid
              secondary
              onClick={this.handleCloseModal}>Ok</Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }

  render() {
    const { customerPremise, pageConfig } = this.props.customer;

    if (!customerPremise) return null;

    // switching between account types, redirect to Home page when needed
    if (!pageConfig.functionality.paymentHelp) {
      return (<Navigate to="/" />);
    }

    const { theme } = this.props.theme;
    const isLoading = this.state.formState === FORM_STATE.LOADING || this.state.pageLoading;

    if (this.state.paymentExtension) {
      return (
        <Layout
          pageTitle="Payment extension"
          layout={FullWidthLayout}>

          <Loader
            active={isLoading}
            inverted
            size="medium"
            style={{ alignSelf: 'center' }} />

          {this.sharedModals()}

          <ExtensionForm
            {...this.props.theme}
            {...this.state.paymentExtension}
            openModal={this.openModal}
            onFormValid={this.onExtFormValid}
            onFormInvalid={this.onExtFormInvalid}
            onSubmitExtension={this.onSubmitExtension}
          />
        </Layout>
      );
    }

    if (this.state.paymentPlan) {
      return (
        <Layout
          pageTitle="Payment plan"
          layout={ColumnLayout}
        >
          <Loader
            active={isLoading}
            inverted
            size="medium"
            style={{ alignSelf: 'center' }} />

          {this.planModals()}

          <PlanForm
            {...this.props.theme}
            {...this.state.paymentPlan}
            openModal={this.openModal}
            onFormValid={this.onPlanFormValid}
            onFormInvalid={this.onPlanFormInvalid}
            onHeatingSourceChange={this.onHeatingSourceChange}
            onFrequencyChange={this.onFrequencyChange}
            onInstalmentAmountChange={this.onInstalmentAmountChange}
            onStartDateChange={this.onStartDateChange}
          />
        </Layout>
      );
    }

    return (
      <Layout
        pageTitle="Payment extension"
        layout={ColumnLayout}
      >

        <LandingPage
          {...this.props.theme}
          onNewPaymentPlan={this.newPaymentPlan}
        >
          <React.Fragment>
            <Loader
              active={isLoading}
              inverted
              size="medium"
              style={{ alignSelf: 'center' }} />
      
            {this.sharedModals()}

            {customerPremise && customerPremise.hasActivePaymentExtension
              ? <AlreadyHasHelp
                  {...this.props.theme}
                  history={this.props.router}
                />
              : <RequestHelp
                  {...this.props.theme}
                  onExtensionRequest={this.onExtensionRequest}
                  disabled={isLoading}
                />
            }
          </React.Fragment>
        </LandingPage>

      </Layout>
    );
  }
}

export default withRouter(withCustomer(withTheme(PaymentHelp)))
