import React from 'react';
import {Segment} from 'semantic-ui-react';

import ExternalLink from '../../components/ExternalLink';

class OffProductView extends React.PureComponent {
  link = 'https://www.auroraenergy.com.au/aurorapaygplus';
  title = 'Nothing to see here';
  message = (
    <p>Unfortunately you are no longer registered to use this product. Please
      contact us to sign up&nbsp;
      <ExternalLink
        href={this.link}
        style={{
        color: 'white',
        textDecoration: 'underline'
      }}>here.</ExternalLink>
    </p>
  );

  render() {

    let title = this.props.title
      ? this.props.title
      : this.title;
    let message = this.props.message
      ? <p>{this.props.message}</p>
      : this.message;

    return (
      <Segment basic textAlign="center" style={{
        color: 'white'
      }}>
        <p style={{
          fontSize: '20px'
        }}>{title}</p>
        {message}
        <Segment padded basic>
          {this.props.icon}
        </Segment>
      </Segment>
    );
  }
}

export default OffProductView;
