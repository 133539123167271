import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '27px',
  width = '27px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path fill={color} d="M30.5 0C25.2595 0 21.1328 4.32206 21.1328 9.5C21.1328 10.6648 21.4661 12.0092 22.127 13.6172L20.2227 16.7148C20.0831 16.9421 20.0067 17.2025 20.0012 17.4691C19.9957 17.7357 20.0614 17.999 20.1916 18.2318C20.3217 18.4646 20.5115 18.6585 20.7415 18.7935C20.9715 18.9285 21.2333 18.9998 21.5 19H30.2539C30.4425 19.0317 30.6354 19.0271 30.8223 18.9863C32.9624 18.9149 35.1568 18.1387 36.8984 16.6152C38.7274 15.0154 40 12.5474 40 9.5C40 4.21078 35.9439 0 30.5 0ZM30.5 3C34.4901 3 37 5.64722 37 9.5C37 11.7466 36.1527 13.2788 34.9219 14.3555C34.0886 15.0844 33.0534 15.5742 32 15.8184V11.5C32.0029 11.2992 31.9654 11.0999 31.8899 10.9139C31.8143 10.7279 31.7022 10.5589 31.5601 10.417C31.418 10.2751 31.2489 10.1632 31.0628 10.0879C30.8767 10.0126 30.6773 9.97538 30.4766 9.97852C30.0791 9.98473 29.7003 10.1485 29.4235 10.4337C29.1466 10.719 28.9943 11.1025 29 11.5V16H24.1816L25.0605 14.5723C25.1907 14.3605 25.2661 14.1196 25.2801 13.8714C25.294 13.6232 25.2461 13.3755 25.1406 13.1504C24.3065 11.3701 24.1328 10.4182 24.1328 9.5C24.1328 5.88794 26.8885 3 30.5 3ZM30.5 5C30.1022 5 29.7206 5.15804 29.4393 5.43934C29.158 5.72064 29 6.10218 29 6.5C29 6.89782 29.158 7.27936 29.4393 7.56066C29.7206 7.84196 30.1022 8 30.5 8C30.8978 8 31.2794 7.84196 31.5607 7.56066C31.842 7.27936 32 6.89782 32 6.5C32 6.10218 31.842 5.72064 31.5607 5.43934C31.2794 5.15804 30.8978 5 30.5 5ZM11 6C7.15177 6 4 9.15177 4 13C4 16.8482 7.15177 20 11 20C14.8482 20 18 16.8482 18 13C18 9.15177 14.8482 6 11 6ZM11 9C13.2269 9 15 10.7731 15 13C15 15.2269 13.2269 17 11 17C8.77309 17 7 15.2269 7 13C7 10.7731 8.77309 9 11 9ZM3.73438 22C1.68885 22 0 23.69 0 25.7344V29C0 35.0576 4.94244 40 11 40C17.0576 40 22 35.0576 22 29V25.7344C22 23.6888 20.31 22 18.2656 22H3.73438ZM3.73438 25H18.2656C18.6893 25 19 25.3099 19 25.7344V29C19 33.4364 15.4364 37 11 37C6.56356 37 3 33.4364 3 29V25.7344C3 25.3107 3.3099 25 3.73438 25Z" />
    </svg>
  );
}
