import { createContext, useContext, useReducer } from 'react'

const initialState = {
  isApp: false,
  isAppleDevice: false,
  isGoogleDevice: false,
  isMirroring: false,
  isSideMenuOpen: false,
  appDemoOpen: false,
  showBackButton: true,
  deviceInfo: undefined,
  pageTitle: false,
  backgroundImage: undefined,
  setShowBackButton: () => null,
  setPageTitle: () => null,
  openAppDemo: () => null,
  openSideMenu: () => null,
  closeSideMenu: () => null,
  setBackgroundImage: () => null,
  set: () => null,
  loginUser: null,
  clearAllUserData: () => null,
  isSolarFeatureEnabled: false,
  isOutOfDate: false, // Force an app wrapper store update depending on deviceInfo version number
  isLoadingCustom: false,
}

function appReducer(state, payload) {
  return { ...state, ...payload }
}

export const AppContext = createContext(initialState)

export function AppProvider({ children }) {
  const [state, setState] = useReducer(appReducer, initialState)

  const app = {
    ...state,
    setState,
    openAppDemo: () => setState({ appDemoOpen: false }),
    setPageTitle: pageTitle => setState({ pageTitle }),
    setShowBackButton: showBackButton => setState({ showBackButton }),
    openSideMenu: () => setState({ isSideMenuOpen: true }),
    closeSideMenu: () => setState({ isSideMenuOpen: false }),
    setBackgroundImage: (backgroundImage, showBackgroundImageOnMobile) => setState({ backgroundImage, showBackgroundImageOnMobile }),
    set: props => setState(props)
  }

  return (
    <AppContext.Provider value={app}>
      {children}
    </AppContext.Provider>
  )
}

export const useApp = () => useContext(AppContext)

export function withApp(Component) {
  return function WrappedComponent(props) {
    return (
      <AppContext.Consumer>
        {context => <Component {...props} app={context} />}
      </AppContext.Consumer>
    )
  }
}
