
export default class PaymentAccount {

  constructor(customerData) {

    let token = customerData.singleUseToken;
    if (token) {
      this.tokenType = 'SingleUse';

      if (token.accountType === 'CREDIT_CARD') {
        customerData = token.creditCard

        this.accountToken = token.singleUseTokenId;
        this.cardholderName = customerData.cardholderName;
        this.cardNumber = customerData.cardNumber;
        this.cardScheme = customerData.cardScheme;
        this.cardType = customerData.cardType;
        this.expiryDateMonth = customerData.expiryDateMonth;
        this.expiryDateYear = customerData.expiryDateYear;
      }
      
      return;
    }

    this.accountToken = customerData.accountToken;
    this.cardholderName = customerData.cardholderName;
    this.cardNumber = customerData.cardNumber;
    this.cardScheme = customerData.cardScheme;
    this.cardType = customerData.cardType;
    this.customerId = customerData.customerId;
    this.expiryDateMonth = customerData.expiryDateMonth;
    this.expiryDateYear = customerData.expiryDateYear;
    this.default = customerData.default;
    this.tokenType = 'Account';
  }

}
