import background from '../../../static/backgrounds/bg-desktop-unauthenticated.jpg';

/**
 * -- Form Pages
 * 1 - Landing Page
 * 2 - Identification Page
 * 3 - Product Details
 * 4 - Residence Questions
 * 5 - Consent Page
 * 6 - Confirmation Page
 * 7 - Failure Page
 */
export const MIN_PAGE_STEP = 1;
export const MAX_PAGE_STEP = 7;

export const PAGE_NUMBERS = {
  LANDING: 1,
  IDENTITY: 2,
  PRODUCT_DETAILS: 3,
  RESIDENCE_QUESTIONS: 4,
  CONSENT_PAGE: 5,
  CONFIRMATION_PAGE: 6,
  IDENTIFICATION_FAIL: 7,
}

export const PAGE_NAMES = [
  "Landing Page",
  "Identification Page",
  "Product Details Page",
  "Residence Questions Page",
  "Consent Page",
  "Confirmation Page",
  "Failure Page"
]

/**
 * How many validation attempts we allow before sending the user
 * to the failure state.
 */
export const MAX_VALIDATION_ATTEMPTS = 3;

export const PRODUCT_TYPES = [
  {
    value: 'TAS_RES_PI',
    tariffs: ['93'],
    text: 'Aurora PAYG+'
  }, {
    value: 'TAS_RES_E',
    tariffs: [
      '31', '31-41'
    ],
    text: 'Residential Standing Offer'
  }, {
    value: 'TAS_RES_E',
    tariffs: ['93'],
    text: 'Time of Use'
  }
]

export const VALID_TARIFFS = [
  {
    value: '31',
    text: 'Tariff 31',
    tariffList: ["TAS31"]
  }, {
    value: '31-41',
    text: 'Tariff 31 + Tariff 41',
    tariffList: ["TAS31", "TAS41"]
  }, {
    value: '93',
    text: 'Tariff 93',
    tariffList: ["TAS93"]
  }
]

export const ID_TYPES = [
  {
    value: 'TASDRV',
    key: 'TASDRV',
    text: 'Driver’s Licence Number'
  }, {
    value: 'PHONEPW',
    key: 'PHONEPW',
    text: 'Phone Password'
  }, {
    value: 'BIRTHCER',
    key: 'BIRTHCER',
    text: 'Birth Certificate'
  },
  /*
  {
    value: 'INTDRV',
    key: 'INTDRV',
    text: 'Interstate Drivers licence'
  },
  */
  {
    value: 'PASSPORT',
    key: 'PASSPORT',
    text: 'Passport Number'
  }
]

export const PHONE_TYPES = [
  {
    text: 'Mobile',
    value: 'MOP'
  }, {
    text: 'Home',
    value: 'HMP'
  }, {
    text: 'Work',
    value: 'BSP'
  }, {
    text: 'Silent Home',
    value: 'SPH'
  }, {
    text: 'Fax',
    value: 'FX'
  }, {
    text: 'Message Mobile',
    value: 'MSGM'
  }, {
    text: 'Message Landline',
    value: 'MSGL'
  }, {
    text: 'Silent Mobile',
    value: 'SMP'
  }, {
    text: 'International',
    value: 'INT'
  }
]

export const PHONE_TYPES_SPECIAL_INSTRUCTIONS = [
  {
    text: 'Mobile',
    value: 'MOP'
  }, {
    text: 'Home',
    value: 'HMP'
  }, {
    text: 'Work',
    value: 'BSP'
  }, {
    text: 'Silent Home',
    value: 'SPH'
  }, {
    text: 'Fax',
    value: 'FX'
  }, {
    text: 'Message Mobile',
    value: 'MSGM'
  }, {
    text: 'Message Landline',
    value: 'MSGL'
  }, {
    text: 'Silent Mobile',
    value: 'SMP'
  }
]

export const MAILING_ADDRESS_TYPES = [
  {
    key: "ST",
    value: 'ST',
    text: 'Street',
    showUnitNumber: true,
    showStreetAddress: true,
    showStreetType: true,
    numberText: 'Street Number',
    numberPrompt: 'Enter Street Number'
  }, {
    key: "CPO",
    value: "CPO",
    text: "Care PO",
    showUnitNumber: false,
    showStreetAddress: false,
    showStreetType: false,
    numberText: 'Care PO Number',
    numberPrompt: 'Enter Care PO Number'
  }, {
    key: "LOCK",
    value: "LOCK",
    text: "Locked Bag",
    showUnitNumber: false,
    showStreetAddress: false,
    showStreetType: false,
    numberText: 'Locked Bag Number',
    numberPrompt: 'Enter Locked Bag Number'
  }, {
    key: "POBX",
    value: "POBX",
    text: "GPO/PO Box",
    showUnitNumber: false,
    showStreetAddress: false,
    showStreetType: false,
    numberText: 'PO Box Number',
    numberPrompt: 'Enter PO Box Number'
  }, {
    key: "RSD",
    value: "RSD",
    text: "Road side delivery",
    showUnitNumber: false,
    showStreetAddress: false,
    showStreetType: false,
    numberText: 'Road Side Number',
    numberPrompt: 'Enter Road Side Number'
  }
]

export const STATE_PROVINCES = [
  {
    text: 'Tasmania',
    value: 'TAS'
  }, {
    text: 'Victoria',
    value: 'VIC'
  }, {
    text: 'New South Wales',
    value: 'NSW'
  }, {
    text: 'Queensland',
    value: 'QLD'
  }, {
    text: 'Western Australia',
    value: 'WA'
  }, {
    text: 'ACT',
    value: 'ACT'
  }, {
    text: 'South Australia',
    value: 'SA'
  }, {
    text: 'Northern Territory',
    value: 'NT'
  }
]

export const TRACKING_SCRIPTS = [
  {
    product: 'TAS_RES_E',
    tariff: '31',
    script: '//pixel.roymorgan.com/stats_v2/Tress.php?u=uj6k4rf3tf&ca=20003004&a=labphjq8'
  }, {
    product: 'TAS_RES_E',
    tariff: '31-41',
    script: '//pixel.roymorgan.com/stats_v2/Tress.php?u=uj6k4rf3tf&ca=20003005&a=labphjq8'
  }, {
    product: 'TAS_RES_PI',
    tariff: '93',
    script: '//pixel.roymorgan.com/stats_v2/Tress.php?u=uj6k4rf3tf&ca=20003006&a=labphjq8'
  }
]

export const BACKGROUND_IMAGE = background;

export const PHONE_REGEX = /^\D*(?:\d\D*){6,21}$/g;
export const AURORA_CONTACT_PHONE_NUMBER = `1300 132 030`;

export const CALLBACK_REASON_MORE_INFO = 'MORE_INFO';
export const CALLBACK_REASON_OTHER = 'OTHER';

export const MESSAGE_UNABLE_TO_IDENTIFY = `We're having trouble identifying you - did you enter the right details and method of identification? Please retry with updated information or call us on ${AURORA_CONTACT_PHONE_NUMBER}`;
export const MESSAGE_ALREADY_SUBMITTED = `A consent form has already been submitted for this user.`;

export const FAILURE_MESSAGE_UNABLE_TO_IDENTIFY = `Sorry, we're having trouble recognising those details.`;
export const FAILURE_MESSAGE_NO_PAYG_PREMISES = `Our records show that you are not currently registered as an existing Aurora PAYG customer.`
export const FAILURE_MESSAGE_NOT_LIVING_AT_ADDRESS = `Because you aren't living at $ADDRESS, we'll need you to get in touch with us at a time that suits you to complete your request.`;
export const CONTACT_STRING = `Email us at aurorapaygplus@auroraenergy.com.au or call us at ${AURORA_CONTACT_PHONE_NUMBER}.`;
export const CONTACT_STRING_INCORRECT = `Please call us on ${AURORA_CONTACT_PHONE_NUMBER} if you believe this is incorrect.`;

export const CALLBACK_MODAL_HEADER = `Would you like our team to give you a call?`;
export const CALLBACK_MODAL_HEADER_SUCCESS = `Thank you!`;

export const CALLBACK_MODAL_BODY = `Fill in the details below and someone from our team will be in touch!`;
export const CALLBACK_MODAL_BODY_SUCCESS = `Thank you for your request, our team will be in contact within 24 hours between 8am and 6pm business days.`;

export const TERMS_AND_CONDITIONS_LINK = `https://www.auroraenergy.com.au/Aurora/media/pdf/Aurora_payg_plus/Terms-and-conditions.pdf`
