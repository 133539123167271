import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import { useSignup } from "../../../contexts/Signup.js";
import { Progress } from "semantic-ui-react";
import PasswordValidationInfo from "../../AccountSettings/PasswordValidationInfo.js";
import PasswordField from "../../../components/Forms/PasswordField.js";
import { getPasswordStrengthScore, getPasswordValidationsPassed, passwordThresholds } from "../../../utils/PasswordStrength.js";
import { withAuth } from "../../../contexts/Auth.js";
import { withApp } from "../../../contexts/App.js";
import Analytics from "../../../utils/Analytics.js";


export default withAuth(withApp(({ auth, app }) => {

	const navigate = useNavigate();
	useScrollToTop();

	const signup = useSignup();
	if (!signup.emailAddress) navigate('/signup/residential');

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);
	const [passwordStrength, setPasswordStrength] = useState("");
	const [passwordValidationsPassed, setPasswordValidationsPassed] = useState([]);


	function updatePasswordStrength({ password }) {
		const validationsPassed = getPasswordValidationsPassed(password);

		setPasswordStrength(validationsPassed.length);
		setPasswordValidationsPassed(validationsPassed);
	}

	function onFormChange(fields) {
		updatePasswordStrength(fields);
	}
	
	const { deviceInfo, isApp } = app;

	function submit(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/set-password";

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		axios
			.post(endpoint, {
				password: payload.password,
				emailAddress: signup.emailAddress,
				deviceInfo: deviceInfo || null,
				marketing: false, //signup.offers,
				marketingPreference: null, //signup.offers ? "E" : null,
			}, {
				headers: {
					common: {
						'authorization': 'bearer ' + signup.token
					}
				},
				ignoreGlobalErrors: true
			})
			.then(response => {
				setFormState(FORM_STATE.DONE_LOADING);

				Analytics.event({ category: "Signup", action: "CompleteAccountCreation" });

				auth.updateToken(
					response.data.accessToken,
					isApp ? true : false, //payload.remember
					false
				)

				navigate('/signup/property')
				
			})
			.catch(error => {
				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				//Analytics.event({ category: "User", action: "Failed " });

				switch(error.response ? error.response.status : error.code) {
					case 404:
						//navigate('/signup/external/residential');
						navigate('/signup/residential/signup-error');
						break;
					case 409:
						navigate('/signup/residential/login-exists');
						break;
					case 500:
						//setErrorModal(GENERIC_ERROR);
						navigate('/signup/residential/signup-error');
						break;
					default:
						//setErrorModal(CONNECTIVITY_ERROR);
						navigate('/signup/residential/signup-error');
						break;

				}
				
			});
	}

	return (		
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Choose your password", journeyName: "signup", currentStep: 3 }}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
				onChange={onFormChange}
			>
				<PasswordValidationInfo
					validationsPassed={passwordValidationsPassed}
				/>

				<PasswordField
					validations={{
						minLength: 8,
						maxLength: 256,
						passwordStrength: passwordThresholds.WEAK,
						matchRegexp: /^[A-Za-z0-9@#$%^&*\-_!+=\[\]{}|:',.?\/`~"();]+$/
					}}
					validationErrors={{
						minLength: 'The new password field must be at least 8 characters.',
						maxLength: 'The new password field may not be greater than 256 characters.',
						matchRegexp: 'The new password field must only contain letters, numbers and symbols'
					}}
					required
					autoComplete="new-password"
					fluid
					name="password"
					placeholder="Set Password"
					hideErrorMessage
				/>
				<PasswordField
					validations={{
						equalsField: 'password'
					}}
					validationErrors={{
						equalsField: 'The confirm new password must match.'
					}}
					required
					autoComplete="new-password"
					fluid
					name="confirmPassword"
					placeholder="Confirm Password"
					textColor = "white"
				/>

				<div>
					<small>Password Strength
						<span style={{
							float: 'right'
						}}>{getPasswordStrengthScore(passwordStrength)}</span>
					</small>
					<Progress
						percent={passwordStrength * 25}
						size="small"
						error={passwordStrength <= passwordThresholds.WEAK}
						warning={passwordStrength <= passwordThresholds.STRONG}
						success={passwordStrength <= passwordThresholds.EXCELLENT}
					/>
				</div>



				<SignupButton
					disabled={!canSubmit}
					loading={formState === FORM_STATE.LOADING}
				>
					Continue
				</SignupButton>

			</Form>

			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

}))


