export class ActivatePropertyData {


	/*
	Email 1 - Advanced meter, an eligible tariff, NMI in a billable state
	Email 2 - Advanced meter, an eligible tariff, NMI not in a billable state
	Email 3 - Basic meter, requires meter exchange, life support
	Email 4 - Basic meter, requires meter exchange, hazards
	Email 5 - Basic meter, requires meter exchange, T61/T62/T63
	Email 6 - Basic meter, requires meter exchange
	Email 7 - Basic meter, requires meter exchange, NMI not in a billable state
	Email 8 - Customer confirmation of Submitted Request
	Email 9 - Advanced Meter, T31 Only
	Email 10 - Basic meter, requires meter exchange, T31 Only
	*/

	// Convert tariff to chosenTariff, which will be null and overwritten for advanced meter customers

	payload = null;

	constructor(advancedMeter, customer, premises, tariffData, data, signup) {

		const currentContact = (data.use_current_contact_details === "yes");
		const lifeSupport = (data.life_support === "yes" || customer.customer.lifeSupport);
		const issuesAccessingProperty = (data.issues_accessing_property === "yes");

		let emailScenario = null;
		let subject = null;

		if (advancedMeter) {
			if (tariffData.hasOnlyT31) {
				// Email 9 - Advanced Meter, T31 Only
				emailScenario = 9;
				subject = "a+ in-app signup (MANUAL) - HAS advanced meter, On T31 Only" + (lifeSupport ? ", LIFE SUPPORT" : "");
			} else if (premises.isActive) {
				// Email 1 - Advanced meter, an eligible tariff, NMI in a billable state
				emailScenario = 1;
				subject = "a+ in-app signup (ROBOT) - HAS advanced meter";
			} else {
				// Email 2 - Advanced meter, an eligible tariff, NMI not in a billable state
				emailScenario = 2;
				subject = "a+ in-app signup (MANUAL) - HAS advanced meter, NMI not in a billable state" + (lifeSupport ? ", LIFE SUPPORT" : "");
			}
		} else {
			
			if (tariffData.hasOnlyT31) {
				// Email 10 - Basic meter, requires meter exchange, T31 Only
				emailScenario = 10;
				subject = "a+ in-app signup (MANUAL) - REQUIRES advanced meter, On T31 Only" + (lifeSupport ? ", LIFE SUPPORT" : "");
			} else if (!premises.isActive) {
				// Email 7 - Basic meter, requires meter exchange, NMI not in a billable state
				emailScenario = 7;
				subject = "a+ in-app signup (MANUAL) - REQUIRES advanced meter, NMI not in a billable state" + (lifeSupport ? ", LIFE SUPPORT" : "");
			} else if (tariffData.hasIneligible) {
				// Email 5 - Basic meter, requires meter exchange, T61/T62/T63
				emailScenario = 5;
				subject = "a+ in-app signup (MANUAL) - REQUIRES advanced meter, T61/T62/T63" + (lifeSupport ? ", LIFE SUPPORT" : "");
			} else if (data.issues_accessing_property === "yes") {
				// Email 4 - Basic meter, requires meter exchange, hazards
				emailScenario = 4;
				subject = "a+ in-app signup (MANUAL) - REQUIRES advanced meter, HAZARDS" + (lifeSupport ? ", LIFE SUPPORT" : "");
			} else if (lifeSupport) {
				// Email 3 - Basic meter, requires meter exchange, life support
				emailScenario = 3;
				subject = "a+ in-app signup (MANUAL) - REQUIRES advanced meter, LIFE SUPPORT";
			} else {
				// Email 6 - Basic meter, requires meter exchange
				emailScenario = 6;
				subject = "a+ in-app signup (ROBOT) - REQUIRES advanced meter";
			}
		}


		const payload = {
			parentAccountID: premises.parentAccountID,
			Tariff: data.selectedTariff || tariffData.tariff,
			lifeSupport: lifeSupport,
			typeOfLifeSupport: data.type_of_life_support,
			IssuesAccessingProperty: issuesAccessingProperty,
			Dog: data.dog,
			PanelInside: data.panel_inside,
			LockedGate: data.locked_gate,
			Other: data.other,
			OtherAccessIssueDescription: data.other_access_issue_description,
			ContactFirstNameOnTheDay: currentContact ? customer.customer.firstName : data.contact_first_name_on_the_day,
			ContactLastNameOnTheDay: currentContact ? customer.customer.lastName : data.contact_last_name_on_the_day,
			ContactTitleOnTheDay: currentContact ? customer.customer.title : data.contact_title_on_the_day,
			ContactNumberOnTheDay: currentContact ? customer.customer.mobileNumber : data.contact_number_on_the_day,
			subject: subject,
			campaign: signup.campaign,
		}
		
		this.payload = payload;
	}

	getPayload() {
		return this.payload;
	}

}

/*
		const currentContact = (data.use_current_contact_details === "yes");

{
				parentAccountID: premises.parentAccountID,
				Tariff: data.tariff,
				lifeSupport: data.life_support,
				typeOfLifeSupport: data.type_of_life_support,
				IssuesAccessingProperty: data.issues_accessing_property,
				Dog: data.dog,
				PanelInside: data.panel_inside,
				LockedGate: data.locked_gate,
				Other: data.other,
				OtherAccessIssueDescription: data.other_access_issue_description,
				ContactFirstNameOnTheDay: currentContact ? customer.customer.firstName : data.contact_first_name_on_the_day,
				ContactLastNameOnTheDay: currentContact ? customer.customer.lastName : data.contact_last_name_on_the_day,
				ContactTitleOnTheDay: currentContact ? customer.customer.title : data.contact_title_on_the_day,
				ContactNumberOnTheDay: currentContact ? customer.customer.mobileNumber : data.contact_number_on_the_day,
			}
		*/