import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { withTheme } from '../../contexts/Theme';
import './Footer.css';

function Footer({ theme }) {
  const footerStyle = theme?.footer || { background: 'defaultBackground', color: 'defaultColor' };

  return (
    <Segment
      
      style={{
        backgroundColor: footerStyle.background,
        color: footerStyle.color,
      }}
    >
      <Container
         style={{
          backgroundColor: footerStyle.background,
          color: footerStyle.color,
        }}>
        <p style={{ margin: '0.5em 1em' }}>
          &copy; 2024 Aurora Energy Pty Ltd ABN 85 082 464 622
        </p>
      </Container>
    </Segment>
  );
}

export default withTheme(Footer);