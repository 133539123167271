import React, { useRef, useState } from "react";
import Layout from "../../../../layouts/Layout.js";
import SignupFormLayout from "../../../../layouts/SignupFormLayout.js";
import { Checkbox, Form } from 'formsy-semantic-ui-react';
import SignupButton from "../../components/SignupButton/SignupButton.js";
import IconRadioGroup from "../../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../../components/IconRadio/IconRadio.js";
import ValidatedInput from "../../../../components/Forms/ValidatedInput.js";
import useScrollToTop from "../../../../utils/useScrollToTop.js";
import IconCheckBox from "../../../../components/IconRadio/IconCheckbox.js";
import IconCheckBoxGroup from "../../../../components/IconRadio/IconCheckboxGroup.js";

export default ({ initialFormData, journey, onSubmit }) => {

	useScrollToTop();

	const [canSubmit, setCanSubmit] = useState(false);

	const formRef = useRef();
	const [formData, setFormData] = useState(initialFormData);


	function submit(payload) {
		payload = {
			issues_accessing_property: null,
			dog: null,
			panel_inside: null,
			locked_gate: null,
			other: null,
			other_access_issue_description: null,			
			...payload
		}

		onSubmit(payload);
	}

	const issueRequired = (formData.issues_accessing_property === "yes");
	const issueSelected = (formData.dog || formData.panel_inside || formData.locked_gate || formData.other);


	return <Layout
		layout={SignupFormLayout}
		journey={journey}
		whitePage
		accountPicker={false}
	>
		<Form
			ref={formRef}
			noValidate
			onValidSubmit={submit}
			onValid={() => {setCanSubmit(true)}}
			onInvalid={() => {setCanSubmit(false)}}
			onChange={(() => {
				setFormData(formRef.current ? formRef.current.getModel() : { });
			})}
		>

			<p>Will there be any issues accessing the property or your meter on the day?</p>

			<IconRadioGroup
				name="issues_accessing_property"
				value={formData.issues_accessing_property}
				required
			>
				<IconRadio name="issues_accessing_property" value="yes" label="Yes" />
				<IconRadio name="issues_accessing_property" value="no" label="No" />
			</IconRadioGroup>

			{ formData.issues_accessing_property === "yes" ? <>
				<p>Please select the access issue(s)</p>
				<IconCheckBoxGroup>
					<IconCheckBox checked={formData.dog} name="dog" label="Dog" />
					<IconCheckBox checked={formData.panel_inside} name="panel_inside" label="Meter/Sub board inside" />
					<IconCheckBox checked={formData.locked_gate} name="locked_gate" label="Locked Gate" />
					<IconCheckBox checked={formData.other} name="other" label="Other " />
				</IconCheckBoxGroup>

				{ formData.other ? <>
					<ValidatedInput
						name="other_access_issue_description"
						value={formData.other_access_issue_description}
						hideErrorMessage
						type="text"
						placeholder="Enter the issue"
						required
						validations={{
							"maxLength": 240,
						}}
					/>
				</> : null }

			</> : null }



			<SignupButton disabled={!canSubmit || (issueRequired && !issueSelected)}>Continue</SignupButton>

		</Form>
	</Layout>	


}