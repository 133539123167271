import React from 'react';
import {Dropdown, Message} from 'semantic-ui-react';
import Analytics from '../../utils/Analytics';

import { withCustomer } from '../../contexts/Customer'
import { withTheme } from '../../contexts/Theme';

import './AccountPicker.css'
import HomeIcon from '../Icons/HomeIcon';
import BusinessIcon from '../Icons/BusinessIcon';

export class AccountPicker extends React.Component {

  state = {
    open: false
  }

  formatAddress = (address, isFromCCB) => {
    // Convert the address to uppercase for consistency
    address = address.toUpperCase();
  
    // Define a regex to match the start of the address up to and including the street name
    const streetNameRegex = /^[\dA-Z\s]+\s+\D*?\b(STREET|ST|AVE|AVENUE|CRES|CRESENT|ROAD|RD|TERRACE|TER|TCE|LANE|LN|DRIVE|DR)\b/i;
  
    // Apply the regex to extract the desired part of the address
    const match = address.match(streetNameRegex);
    const filteredAddress = match ? match[0] : address;
  
    // Format the address based on the source (CC&B or HubCX)
    if (!isFromCCB) {
      address = filteredAddress.split(" ").map(a => a.charAt(0) + a.slice(1).toLowerCase()).join(" ");
    } else {
      address = filteredAddress;
    }
    return address;
  }

  setClose = () => {
    this.setState({open: false});
    Analytics.event({category: 'Account', action: 'Swap Accounts'})
  }

  setOpen = () => {
    this.setState({open: true});
  }
  DropdownItem = (text) => {
    const { theme } = this.props.theme;
    return (
      <span>{text}</span>
    )
  }

  render() {
    const { theme } = this.props.theme;
	
	if (!this.props.visible) 
      return null


    let { customerPremise, hasLoaded, setCustomerAndPremise, pickablePremises: premises, isFromHubCX } = this.props.customer
    //let premises = this.props.customer.filteredPremises;
    //handle the scenario - hubCX interim user without HubCX premise returned but has Old CCB premises
    if(premises.filter(c => c.serviceAgreementStatus == "Old_CCB").length != premises.length && this.props.customer.isFromHubCX){
        // do not display old CCB accounts for new HubCX users in some circumstances
      if (!this.props.oldCCBAccountsDisplayed) {
        // change dropdown to use any other account
        if (customerPremise && customerPremise.serviceAgreementStatus == "Old_CCB") {
			setCustomerAndPremise(premises.filter(p => p.serviceAgreementStatus !== "Old_CCB")[0].identifier);
        }

        // then remove old CCB accounts from Premise list
        premises = premises.filter(p => p.serviceAgreementStatus !== "Old_CCB");
      }
    }
    
    let value = customerPremise
      ? customerPremise.identifier
      : '';
	let text = <>
		<HomeIcon color={theme.color} height={26} />
		<span class="text">
			{
				customerPremise
					? this.formatAddress(customerPremise.address, (!isFromHubCX || customerPremise.serviceAgreementStatus=="Old_CCB"))
					: ''
			}
		</span>
    </>
    

    premises = premises.map(p => ({ 
      text: this.formatAddress(p.address,(!isFromHubCX || p.serviceAgreementStatus=="Old_CCB")),
      value: p.identifier,
      content: this.DropdownItem(this.formatAddress(p.address,(!isFromHubCX || p.serviceAgreementStatus=="Old_CCB"))),
	  image: p.isResidential ? <HomeIcon color={theme.color} height={26} /> : <BusinessIcon color={theme.color} height={26} />,
    }))
    
    return ((hasLoaded && premises.length === 0) ?
      	<Message error color='red'>
          <Message.Header>
            Oh no! Something went wrong!
          </Message.Header>
          We are unable to get your data. We will fix this as soon as possible.
        </Message>
    : (hasLoaded && (premises.length > 1 || !this.props.hideSingleAccountPicker)) ?
		<div style={{
			position: 'relative'
		}}>
			<Dropdown
			basic
			fluid
			selection
			className='account-picker'
			disabled={!hasLoaded || this.props.disabled || premises.length <= 1}
			options={premises}
			onChange={(ev, data) => setCustomerAndPremise(data.value)}
			selectOnBlur={false}
			icon={premises.length > 1
				? "chevron down"
				: null}
			onOpen={this.setOpen}
			onClose={this.setClose}
			value={value}
			style={{
				background: theme.background,
			}}
			/>
		</div>
	:
		null
	);
  }
}

export default withCustomer(withTheme(AccountPicker))
