const Urls = {
  USAGE_LEARN_MORE_PDF: 'https://www.auroraenergy.com.au/aurora-plus-info/aurora-plus-pricing', //'https://www.auroraenergy.com.au/sites/default/files/2019-07/Aurora-PAYG%2B-Product-Brochure-A4-DL-July19.pdf',
  BUSINESS_USAGE_LEARN_MORE: "https://auroraenergy.com.au/business/products/business-all-pricing",
  PAYMENT_OPTIONS: 'https://www.auroraenergy.com.au/residential/payments/payment-options',
  SOLAR_LEARN_MORE_LINK: 'https://www.auroraenergy.com.au/residential/connections/solar/how-does-solar-energy-work',
  SOLAR_TRACKER_LEARN_MORE: 'https://www.auroraenergy.com.au/sites/default/files/2019-07/Aurora-PAYG%2B-Product-Brochure-A4-DL-July19.pdf',
  DATA_COLLECTION_STATEMENT: 'https://www.auroraenergy.com.au/sites/default/files/2019-07/Aurora%20Energy%20Collection_Statement%202019.pdf',
  TERMSANDCONDITIONS_DIRECTDEBIT: 'https://www.auroraenergy.com.au/sites/default/files/2019-04/DIRECT%20DEBIT%20REQUEST%20SERVICE%20AGREEMENT.pdf',
  BUSINESS_PRODUCTS: 'https://auroraenergy.com.au/business/products/business-electricity-products'
}

export default Urls;
