import React, { useState } from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import ValidatedInput from "../../../components/Forms/ValidatedInput.js";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR, WRONG_ACC_NUMBER } from "../components/SignupErrorModal/SignupErrorModal.js";
import { useSignup } from "../../../contexts/Signup";
import { NavLink } from "react-router-dom";
import accountNumberImage from '../../../static/account-no.jpeg'
import { Image } from "semantic-ui-react";
import Analytics from "../../../utils/Analytics.js";
import { useExternalLink } from "../../../components/ExternalLink.js";


export default () => {
	const navigate = useNavigate();
	const externalLink = useExternalLink();

	useScrollToTop();	

	const signup = useSignup();
	
	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);
	const [attempts, setAttempts] = useState(0);
	const [errorAccMsg, setErrAccMsg] = useState("");
	const handleErrAccMsg = () => {
		setErrAccMsg("");
	}

	function submit(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/search";
		
		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);
		setErrAccMsg(WRONG_ACC_NUMBER);

		if (payload.accountNo.length > 9) {
			payload.accountNo = payload.accountNo.substring(0, 9);
		}
		axios
			.get(endpoint, {
				params: {
					accountNo: "" + payload.accountNo,
					emailAddress: '',
					dateOfBirth: '',
				}
			}, { ignoreGlobalErrors: true })
			.then(response => {
				setFormState(FORM_STATE.DONE_LOADING);
				switch (response.data.ErrorCode) {
					case "CustomerWithoutEmailAddress":
					case "CustomerNotAPrimaryContact":
						navigate('/signup/external/residential');
						return;
				}
				signup.set({
					accountNo: payload.accountNo,
					emailAddress: '',
					dateOfBirth: '',
					maskedEmailAddress: response.data.MaskedEmailAddress,
					maskedPhoneNumber: response.data.MaskedPhoneNumber,
					token: response.data.Token,
				})
				setTimeout(() => {
					if (response.data.IsCustomerInAzureAd) {
						Analytics.event({ category: "Signup", action: "ExistingCustomer" });
						navigate('/signup/residential/login-exists');
					} else {
						navigate('/signup/residential/select-verification');
					}
				}, 1);
			})
			.catch(error => {
				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);
				switch (error.response ? error.response.status : error.code) {
					case 404:
						if (attempts >= 2) {
							// On third failure, redirect
							navigate('/signup/external/residential');
						} else {
							setAttempts(attempts + 1);
							setNotFoundError(true);
						}
						break;
					case 409:
						setErrorModal({
							title: 'Account Error',
							message: 'Your email has been found on more than one account. Please contact us to resolve this problem.',
							buttonLabel: 'Contact Us',
							action: () => {
								externalLink('https://www.auroraenergy.com.au/residential/contact/general-enquiries-form?utm_source=sms&utm_medium=sms&utm_campaign=app_error&utm_id=app_email&referrer=app_duplicate_email')
							}
						});
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;
				}
			});
	}

	return (
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Find your account", journeyName: "signup", journeyName: "signup", currentStep: 1 }}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				<p>
					Let's try another way. Enter your Aurora Energy account number:
				</p>
				{attempts > 0 && (
					<p style={{ color: 'red', marginTop: '5px', fontSize: 15 }}>
						{errorAccMsg}
					</p>
				)}
				<ValidatedInput
					required
					type="number"
					name="accountNo"
					placeholder="Account number"
					validations={{
						"minLength": 9,
						"maxLength": 10
					}}
					validationErrors={{
						"minLength": "Account numbers must be at least 9 characters.",
						"maxLength": "Account numbers can’t be any longer than 10 characters."
					}}
					onChange={handleErrAccMsg}
				/>


				<SignupButton disabled={!canSubmit} loading={formState === FORM_STATE.LOADING}>
					Find my account
				</SignupButton>

				<p style={{ marginTop: '50px' }}>
					Your account number is found here:
				</p>

				<Image src={accountNumberImage}  />

				<NavLink
					to="/signup/residential/find-your-account"
					tabIndex="-1"
					style={{ display: 'inline-block', marginTop: '30px' }}
				>
					Find your account with your email address
				</NavLink>

			</Form>

			<SignupErrorModal
				open={!!errorModal}
				{...((typeof errorModal === 'string') ? { message: errorModal } : errorModal)}
				onClose={() => {
					if (errorModal.action) errorModal.action();
					setErrorModal(null);
				}}
			/>

		</Layout>
	);

}
