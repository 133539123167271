import React, {Component} from 'react';
import {Header} from 'semantic-ui-react';
import {PAGE_NUMBERS} from './constants';

export default class ConfirmationPage extends Component {

  /*
   * if the user is on TAS_RES_E an email is not required, so there is a gap in the logic there,
   * but as it's always a dumb form now that logic will never be hit
   */
  renderMessage() {
    const { context } = this.props;
    const { success, email, customerIsUnverified } = context;

    if (!success) {
      return (<div>There was an error processing your request.</div>)
    }

    if (customerIsUnverified) {
      return (<div>Thank You! We will be in touch soon.</div>);
    }

    const emailIsValid = (email != null && email.length > 0);

    if (emailIsValid && !customerIsUnverified) {
      return (<div>We will send you a confirmation email shortly.</div>);
    }

    return null;
  }

  render() {
    const { context } = this.props;
    const { currentStep } = context;

    if (currentStep !== PAGE_NUMBERS.CONFIRMATION_PAGE) {
      return null
    }

    return (
      <div className="form-content">
        <Header textAlign="center" size="large">
          <h3>All done!</h3>
        </Header>
        <p>
          { this.renderMessage() }
        </p>
      </div>
    );
  }
}
