import axios from 'axios'

export default class ConsentFormHelper {

    /**
     * Returns a list of identification types held in CC&B that are linked to the
     * provided service agreement ID.
     * @param {number} serviceAgreementId
     */
    getValidCustomerIdTypes(serviceAgreementId) {
        let url = process.env.REACT_APP_API_BASE_URI + `consentForm/CustomerIDTypes/${serviceAgreementId}`;
        return axios
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return false;
            })
    }

    /**
     * Returns a one time form token
     */
    getFormToken(recaptchaToken) {
        let url = process.env.REACT_APP_API_BASE_URI + `consentForm/getToken`;
        return axios.post(url, JSON.stringify(recaptchaToken), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            return res.data.token
        }).catch(() => {
            return false;
        })
    }
    /**
     * Returns a list of street types.
     */
    getStreetTypes() {
        let url = process.env.REACT_APP_API_BASE_URI + `premises/contact/dropdowns`;
        return axios
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return false;
            })
    }

    /**
     * Validates a customer based on the provided details.
     * Optionally, if a service agreement ID id provided, only a single address - the
     * address linked to that service agreement ID - will be returned.
     * @param {string} surname
     * @param {date} dateOfBirth
     * @param {number} idTypeCode
     * @param {number} idNumber
     * @param {number} serviceAgreementId
     */
    validateCustomer(surname, dateOfBirth, idTypeCode, idNumber, serviceAgreementId = '') {
        let url = process.env.REACT_APP_API_BASE_URI + `consentForm/CustomerDetails?surname=${surname}&dateOfBirth=${dateOfBirth}&idTypeCode=${idTypeCode}&idNumber=${idNumber}&serviceAgreementID=${serviceAgreementId}`
        return axios
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return false;
            })
    }

    /**
     * Returns a list of callback reasons for the user to select.
     */
    getCallbackReasons() {
        let url = process.env.REACT_APP_API_BASE_URI + `consentForm/WebCallback/Reasons`
        return axios
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return false;
            })
    }

    /**
     * Sends a callback request to the API
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phoneNumber
     * @param {string} callbackReason
     * @param {string} callbackOtherReasonDescription
     */
    submitCallbackRequest(firstName, lastName, phoneNumber, callbackReason, callbackOtherReasonDescription, oneTimeFormToken, captchaToken) {
        let url = process.env.REACT_APP_API_BASE_URI + `consentForm/WebCallback`

        let callbackRequestObject = {
            "FirstName": firstName,
            "LastName": lastName,
            "PhoneNumber": phoneNumber,
            "CallbackReason": callbackReason,
            "CallbackOtherReasonDescription": callbackOtherReasonDescription,
            "oneTimeFormToken": oneTimeFormToken,
            "captchaToken": captchaToken
        }

        return axios
            .post(url, callbackRequestObject)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return false;
            })
    }

    /**
     * Creates a new customer contact. This call will also update
     * existing contact information, and send any necessary emails.
     * @param {object} premise
     * @param {object} product
     * @param {object} tariff
     * @param {boolean} useAddressForPostedCorrespondence
     * @param {boolean} hasDog
     * @param {boolean} hasOptedInForMarketing
     * @param {boolean} hasInternalSwitches
     * @param {boolean} hasSpecialInstructions
     * @param {string} specialInstructionsComment
     * @param {string} specialInstructionsPhone
     * @param {string} email
     * @param {object} phoneNumbers
     * @param {string} oneTimeFormToken
     * @param {number} customerID
     * @param {object} mailingAddress
     * @param {object} hasLifeSupport
     * @param {boolean} customerIsUnverified
     * @param {string} customerUnverifiedAddress
     * @param {boolean} premiseIdIsUnknown,
     * @param {string} unverifiedPremiseId
     * @param {string} unverifiedCustomerIdType,
     * @param {boolean} customerIDIsUnknown
     * @param {string} unverifiedCustomerID
     * @param {DateTime} dateOfBirth
     */
  createCustomerContact(
    premise,
    product,
    tariff,
    useAddressForPostedCorrespondence,
    hasDog,
    hasOptedInForMarketing,
    hasInternalSwitches,
    hasSpecialInstructions,
    specialInstructionsComment,
    specialInstructionsPhone,
    email,
    phoneNumbers,
    oneTimeFormToken,
    captchaToken,
    customerID,
    mailingAddress,
    hasLifeSupport,
    customerIsUnverified,
    customerUnverifiedAddress,
    premiseIdIsUnknown,
    unverifiedPremiseId,
    customerIdIsUnknown,
    unverifiedCustomerId,
    unverifiedCustomerIdType,
    dateOfBirth,
    unverifiedLastName,
    unverifiedFirstName
  ) {

        let url = process.env.REACT_APP_API_BASE_URI + `consentForm/ProcessOrder`

        // Remove any empty phone numbers.
        let updatedPhoneNumbers = [...phoneNumbers].filter((phoneNumber) => {
            return phoneNumber.PhoneNumber !== ""
        })

        let consentObj = {

            oneTimeFormToken,
            captchaToken,
            Order: {
                PersonID: customerID,
                PremiseID: premise.ParentPremiseID,
                CampaignCode: product.value,
                HasDifferentMailingAddress: !useAddressForPostedCorrespondence,
                HasDogHazardAtProperty: hasDog,
                ReceiveMarketingMaterial: hasOptedInForMarketing,
                MailingAddress: !useAddressForPostedCorrespondence
                    ? {
                        AddressType: mailingAddress.addressType,
                        Suburb: mailingAddress.suburb,
                        State: mailingAddress.state,
                        Postcode: mailingAddress.postcode,
                        StreetType: mailingAddress.streetType,
                        StreetName: mailingAddress.streetName,
                        StreetNumber: mailingAddress.streetNumber,
                        UnitNumber: mailingAddress.unitNumber
                    }
                    : null,
                CustomerClass: 'RES',
                BillRouteType: 'POSTAL',
                HasInternalPanelOfSwitches: hasInternalSwitches,
                HasSpecialMeterAccessInstructions: hasSpecialInstructions,
                HasLifeSupport: hasLifeSupport,
                SpecialMeterAccessInstructionsPhoneNumber: specialInstructionsPhone,
                SelectedTariffs: tariff.tariffList,
		CustomerIsUnverified: customerIsUnverified,
		CustomerUnverifiedAddress: customerUnverifiedAddress,
		PremiseIdIsUnknown: premiseIdIsUnknown,
		UnverifiedPremiseId: unverifiedPremiseId,
		CustomerIdIsUnknown: customerIdIsUnknown,
		UnverifiedCustomerId: unverifiedCustomerId,
		UnverifiedCustomerIdType: unverifiedCustomerIdType,
		DateOfBirth: dateOfBirth
            },

            Contact: {
                AccountID: premise.ParentAccountID,
                PremiseID: premise.ParentPremiseID,
                ServiceAgreementID: premise.ServiceAgreementID,
                SelectedProductTypeCode: product.value,
                SelectedTariffs: tariff.tariffList
            },

            Details: {
                UnverifiedFirstName: unverifiedFirstName,
		UnverifiedLastName: unverifiedLastName,
                EmailAddress: email,
                PhoneNumbers: updatedPhoneNumbers
            }

        }

        return axios
            .post(url, consentObj)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return false;
            })

    }

}
