import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon" 
        className="weather-icon" 
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M326.53,80A118.33,118.33,0,0,0,112.07,68.69a71.14,71.14,0,0,0-12.69-1.15A72.12,72.12,0,0,0,29.66,158a58.63,58.63,0,0,0,37.8,103.48H320.35A90.87,90.87,0,0,0,326.53,80ZM320.35,237H67.46a34.2,34.2,0,0,1-17.18-63.79l9.24-5.39L55.4,158a47.56,47.56,0,0,1,59.42-63.37l10.84,3.73,4.42-10.57a93.86,93.86,0,0,1,176.14,8l2.77,8.82,11.36-.38a66.42,66.42,0,0,1,0,132.84Z" transform="translate(-8.29 -5.15)" />
    <line x1="67.53" y1="314.57" x2="88.29" y2="286.11" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="96.91" y1="353.26" x2="123.38" y2="316.96" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="183.98" y1="315.34" x2="205.3" y2="286.11" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="209.73" y1="361.27" x2="264.54" y2="286.11" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="271.5" y1="361.65" x2="304.44" y2="316.48" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="122.73" y1="399.21" x2="162.73" y2="344.36" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="324.87" y1="286.11" x2="324.87" y2="286.11" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="188.26" y1="389.64" x2="188.26" y2="389.64" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="141.43" y1="286.77" x2="141.73" y2="285.87" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <path d="M132.91,335.05" transform="translate(-8.29 -5.15)" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
    <line x1="46.61" y1="343.26" x2="46.61" y2="343.26" style={{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"22px"}}/>
  </g>
</svg>

)
}