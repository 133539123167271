import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {Form} from 'formsy-semantic-ui-react';

import Layout from "../../layouts/Layout";

import { useExternalLink } from "../../components/ExternalLink";
import IconRadio from "../../components/IconRadio/IconRadio";
import { useState } from "react";
import SignupButton from "./components/SignupButton/SignupButton";

import IconRadioGroup from "../../components/IconRadio/IconRadioGroup";
import SignupBusinessIcon from '../../components/Icons/signup/SignupBusinessIcon';
import SignupResidentialIcon from '../../components/Icons/signup/SignupResidentialIcon';
import SignupNewConnectionIcon from '../../components/Icons/signup/SignupNewConnectionIcon';
import SignupMovingHouseIcon from '../../components/Icons/signup/SignupMovingHouseIcon';
import useScrollToTop from "../../utils/useScrollToTop";
import SignupFormLayout from "../../layouts/SignupFormLayout";
import { useSignup } from "../../contexts/Signup";


const NEW_CONNECTION_URL = `${process.env.REACT_APP_AURORA_WEBSITE}/residential/connections/electricity-connections/connect-now`;

export default function Signup() {

	const navigate = useNavigate();
	const externalLink = useExternalLink();

	useScrollToTop();

	const [searchParams] = useSearchParams();
	const campaign = searchParams.get("campaign");

	const signup = useSignup();

	const [canSubmit, setCanSubmit] = useState(false);


	useEffect(() => {
		signup.set({ campaign: campaign });
	}, [campaign])


	function submit(payload) {
		switch(payload.situation) {
			case 'sign-up':
				navigate('/signup/type');
				break;
			case 'new-connection':
				externalLink(NEW_CONNECTION_URL);
				break;
			case 'moving-house':
				externalLink(NEW_CONNECTION_URL);
				break;
		}
	}


	return <>
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "What best describes you?" }}
			showSideContent={false}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => {setCanSubmit(true)}}
				onInvalid={() => {setCanSubmit(false)}}
				style={{
					marginTop: '20px'
				}}
			>

				<IconRadioGroup
					name="situation"
					formRadioGroup={true}
					required
					wide
				>
					<legend>I’m an Aurora Energy Customer</legend>
					<IconRadio name="situation" value="sign-up" label="I want to sign up to aurora+ at my property" icon={SignupResidentialIcon} iconHeight={63} />						
					<IconRadio name="situation" value="moving-house" label="I’m moving house" icon={SignupMovingHouseIcon} iconHeight={63} />
					<legend>I’m not an Aurora Energy customer</legend>						
					<IconRadio name="situation" value="new-connection" label="I want to move my electricity account to Aurora Energy" icon={SignupNewConnectionIcon} iconHeight={63} />						
				</IconRadioGroup>

				<SignupButton
					disabled={!canSubmit}
				>
					Continue
				</SignupButton>

			</Form>

		</Layout>
    </>
}