import { useState } from 'react';
import { createContext, useContext } from 'react'
import { useCustomer } from './Customer';
import { useEffect } from 'react';
import UsageDataHelper from '../screens/Usage/UsageDataHelper';
import { useMemo } from 'react';
import { useApp } from './App';

export const TariffContext = createContext({ })

export function TariffProvider({ children }) {
  const [state, setState] = useState(null)
  const [tariff, setTariff] = useState(null)

  return (
    <TariffContext.Provider value={tariff}>
      {children}
    </TariffContext.Provider>
  )
}

// Should be placed inside AppProvider and CustomerProvider
export const useTariff = (premises) => {
	useContext(TariffContext)

	const { customer } = useCustomer();
	const app = useApp();
	const [state, setState] = useState({ loaded: false });

	const dataHelper = useMemo(() => {
		return new UsageDataHelper();
	})

	useEffect(() => {
		return () => {
			if (dataHelper) dataHelper.dispose()
		}
	}, [])

	useEffect(() => {

		if (customer && premises) {
			app.set({ isLoadingCustom: true });
			
			const timeRange = UsageDataHelper.YEAR;
			const dataIndex = -1;

			// In lieu of a direct "current tariffs" API, check the last year's usage and see what they have
			dataHelper.getTariffData(
				premises.serviceAgreementID,
				customer.customerID,
			)			
				.then(data => {
					const tariffs = [...data.TariffTypes];
					data.NonMeteredUsageRecords.forEach(record => {
						let description = record.Description;
						if (description.indexOf("Tariff 93") >= 0 && tariffs.indexOf('T93') == -1) tariffs.push('T93');
						if (description.indexOf("Tariff 31") >= 0 && tariffs.indexOf('T31') == -1) tariffs.push('T31');
						if (description.indexOf("Tariff 41") >= 0 && tariffs.indexOf('T41') == -1) tariffs.push('T41');
						if (description.indexOf("Tariff 61") >= 0 && tariffs.indexOf('T61') == -1) tariffs.push('T61');
						if (description.indexOf("Tariff 62") >= 0 && tariffs.indexOf('T62') == -1) tariffs.push('T62');
						if (description.indexOf("Tariff 63") >= 0 && tariffs.indexOf('T63') == -1) tariffs.push('T63');
					})
						
					const hasT93 = (tariffs.indexOf('T93') >= 0);
					const hasT31 = (tariffs.indexOf('T31') >= 0);
					const hasT41 = (tariffs.indexOf('T41') >= 0);
					const hasT61 = (tariffs.indexOf('T61') >= 0);
					const hasT62 = (tariffs.indexOf('T62') >= 0);
					const hasT63 = (tariffs.indexOf('T63') >= 0);
					
					const hasIneligible = hasT61 || hasT62 || hasT63;
					const hasOnlyT31 = hasT31 && !hasT41;
					const hasPeakOffpeak = hasT93;
					const hasFlatRate = hasT31 && hasT41;

					let tariff = "Unknown";
					if (hasIneligible) tariff = "Ineligible";
					else if (hasOnlyT31) tariff = "Tariff31 Only";
					else if (hasPeakOffpeak) tariff = "Residential Peak and Offpeak";
					else if (hasFlatRate) tariff = "Residential Flat Rate";

					setState({
						loaded: true,
						tariffs,
						hasIneligible,
						hasOnlyT31,
						hasFlatRate,
						hasPeakOffpeak,
						tariff,
					});
				})
				.catch(err => console.log(err))
				.finally(() => {
					app.set({ isLoadingCustom: false });
				})

		}

	}, [customer, premises])

	return state;

}