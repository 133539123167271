import React from "react";
import axios from "axios";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { Form } from "formsy-semantic-ui-react";
import { Card, Header, Button, Segment, Image } from "semantic-ui-react";
import { withRouter } from "../../utils/useRouter";
import { withApp } from "../../contexts/App";
import CustomerContext, { withCustomer } from "../../contexts/Customer";
import { withTheme } from "../../contexts/Theme";

import Layout from "../../layouts/Layout";
import FullWidthLayout from "../../layouts/FullWidthLayout";
import { FORM_STATE } from "../../utils/FormState";
import { BILLING_TYPE } from "../../utils/Types";

import logoAPAYG from "../../static/aurora_plus_logo.svg";
import Carousel from "../../components/Carousel/Carousel";
import "./ExperienceSettings.css";

// desktop images
import DesktopAutoPayment_Img from "../AppDemo/assets/Desktop-Payment.png"; // 2c (autopayment)
import DesktopPostpayHomepage_Img from "../AppDemo/assets/Desktop-PostpayHomepage.png"; // 1b
import DesktopPostpayPayment_Img from "../AppDemo/assets/Desktop-PostpayPayment.png"; // 2b
import DesktopPostpayUsageWeek_Img from "../AppDemo/assets/Desktop-PostpayUsageWeek.png"; // 3@
import DesktopKeyInsightsAndEnergyTips from "../AppDemo/assets/Desktop-KeyInsightsAndEnergyTips.png"; // 5b
import DesktopAuroraInsights from "../AppDemo/assets/Desktop-AuroraInsights.png";
import DesktopPostpaySwitch from "../AppDemo/assets/Desktop-PostpaySwitch.png";
import DesktopPrepaySwitch from "../AppDemo/assets/Desktop-PrepaySwitch.png";
import DesktopPostpaySwitchWithAuroraInsights from "../AppDemo/assets/Desktop-PostpaySwitchWithAuroraInsights.png";
import DesktopPrepaySwitchWithAuroraInsights from "../AppDemo/assets/Desktop-PrepaySwitchWithAuroraInsights.png";

// mobile images
import MobileAutoPayment_Img from "../AppDemo/assets/Mobile-Payment.png"; // 2a
import MobilePostpayHomepage_Img from "../AppDemo/assets/Mobile-PostpayHomepage.png"; // 1
import MobilePostpayPayment_Img from "../AppDemo/assets/Mobile-PostpayPayment.png"; // 2
import MobilePostpaySwitch_Img from "../AppDemo/assets/Mobile-PostpaySwitch.png"; // 6
import MobilePrepaySwitch_Img from "../AppDemo/assets/Mobile-PrepaySwitch.png"; // 6a
import MobileViewUsage_Img from "../AppDemo/assets/Mobile-ViewUsage.png"; // 3#
import MobileAuroraInsights from "../AppDemo/assets/Mobile-AuroraInsights.png";
import MobileKeyInsightsAndEnergyTips from "../AppDemo/assets/Mobile-KeyInsightsAndEnergyTips.png"; // 5b

// old images
import DesktopSolarExport from "../AppDemo/assets/onboard-3-desktop-solar.png";
import MobileSolarExport from "../AppDemo/assets/onboard-3-solar.png";
// import MobileInsightsAndTips from "../AppDemo/assets/onboard-5.png";
import DesktopBalance from "../AppDemo/assets/onboard-1-desktop.png";
import MobileBalance from "../AppDemo/assets/onboard-1.png";
import DesktopNotifications from "../AppDemo/assets/onboard-4-desktop.png";
import MobileNotifications from "../AppDemo/assets/onboard-4.png";

// smb-specific:
import DesktopSwitchProductType from "../AppDemo/assets/onboard-6-desktop.png";
import MobileSwitchProductType from "../AppDemo/assets/onboard-6.png";
import DesktopBalanceSMB from "../AppDemo/assets/onboard-7-desktop.png";
import MobileBalanceSMB from "../AppDemo/assets/onboard-7.png";
import DesktopPaymentSMB from "../AppDemo/assets/onboard-8-desktop.png";
import MobilePaymentSMB from "../AppDemo/assets/onboard-8.png";
import DesktopUsageSMB from "../AppDemo/assets/onboard-9-desktop.png";
import MobileUsageSMB from "../AppDemo/assets/onboard-9.png";
import DesktopPredictABill from "../AppDemo/assets/onboard-10-desktop.png";
import MobilePredictABill from "../AppDemo/assets/onboard-10.png";

import Analytics from "../../utils/Analytics";

const Slides = {
  residential: {
    PAYG: [
      {
        title: "View balance",
        description:
          "The home screen is where you can view your account balance. We estimate a real-time balance based on your recent usage, along with the number of prepaid days remaining before you need to top up.",
        image: {
          mobile: MobileBalance,
          desktop: DesktopBalance
        }
      },
      {
        title: "Convenient payment options",
        description:
          "When you want to top up your account balance, you can make in-app payments with your saved credit or debit card, or setup an auto payment to automatically make a preset payment when your balance reaches a minimum amount you set.",
        image: {
          mobile: MobileAutoPayment_Img,
          desktop: DesktopAutoPayment_Img
        }
      },
      {
        title: "View usage",
        description:
          "Check on your usage with our easy to understand graphs. Usage is shown in dollars and kWh based on your energy plan’s rates, and you can also view your usage by day, week, month, quarter and year.",
        image: {
          mobile: MobileViewUsage_Img,
          desktop: DesktopPostpayUsageWeek_Img
        }
      },
      {
        title: "View solar export",
        description:
          "If you have solar, you’ll also see the solar power you’ve exported back to the grid. This is the power you receive a rebate for. You can view your exported solar in kWh or dollar views.",
        image: {
          mobile: MobileSolarExport,
          desktop: DesktopSolarExport
        }
      },
      {
        title: "An easy reminder",
        description:
          "You will receive notifications to help you manage your account balance.",
        image: {
          mobile: MobileNotifications,
          desktop: DesktopNotifications
        }
      }
    ],
    POSTPAID: [
      {
        title: "View bill information",
        description:
          "The home screen shows you details of any payments due. You can also get a predicted amount for your next bill and when it will be issued.",
        image: {
          mobile: MobilePostpayHomepage_Img,
          desktop: DesktopPostpayHomepage_Img
        }
      },
      {
        title: "Convenient payment options",
        description:
          "When you have a payment due, you can make in-app payments with your credit or debit card, or setup direct debit instructions to automatically pay any bill amounts due.",
        image: {
          mobile: MobilePostpayPayment_Img,
          desktop: DesktopPostpayPayment_Img
        }
      },
      {
        title: "View usage",
        description:
          "Check on your usage with our easy to understand graphs. Usage is shown in dollars and kWh based on your energy plan’s rates, and you can also view your usage by day, week, month, quarter and year.",
        image: {
          mobile: MobileViewUsage_Img,
          desktop: DesktopPostpayUsageWeek_Img
        }
      },
      {
        title: "View solar export",
        description:
          "If you have solar, you’ll also see the solar power you’ve exported back to the grid. This is the power you receive a rebate for. You can view your exported solar in kWh or dollar views.",
        image: {
          mobile: MobileSolarExport,
          desktop: DesktopSolarExport
        }
      }
    ]
  },
  business: {
    POSTPAID: [
      {
        title: "Switch between Residential and Business",
        description:
          "If you have eligible aurora+ products, use single sign up and an easy transition between Residential and Business products.",
        image: {
          mobile: MobileSwitchProductType,
          desktop: DesktopSwitchProductType
        }
      },
      {
        title: "View balance",
        description:
          "The home screen is where you can make payments when they are due and see when your next bill is due.",
        image: {
          mobile: MobileBalanceSMB,
          desktop: DesktopBalanceSMB
        }
      },
      {
        title: "Payment",
        description:
          "When your bill has been issued, payments in the app can be made with your credit or debit card.",
        image: {
          mobile: MobilePaymentSMB,
          desktop: DesktopPaymentSMB
        }
      },
      {
        title: "View usage",
        description: "You can view your usage in kWh or dollar views.",
        image: {
          mobile: MobileUsageSMB,
          desktop: DesktopUsageSMB
        }
      },
      {
        title: "Predict-a-bill",
        description:
          "You can see an estimated bill amount for your next bill based on your current usage during the current billing period.",
        image: {
          mobile: MobilePredictABill,
          desktop: DesktopPredictABill
        }
      }
    ]
  }
};

const AURORA_INSIGHTS_SCREENS = {
  residential: {
    PAYG: {
      enabled: [
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePrepaySwitch_Img,
            desktop: DesktopPrepaySwitchWithAuroraInsights
          }
        }
      ],
      disabled: [
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePrepaySwitch_Img,
            desktop: DesktopPrepaySwitch
          }
        }
      ]
    },
    POSTPAID: {
      enabled: [
        {
          title: "aurora+ insights",
          description:
            "Get personalised energy usage insights, useful energy tips, and even see how your usage compares to similar households in your suburb.",
          dependsOn: "auroraInsights",
          image: {
            mobile: MobileAuroraInsights,
            desktop: DesktopAuroraInsights
          }
        },
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePostpaySwitch_Img,
            desktop: DesktopPostpaySwitchWithAuroraInsights
          }
        }
      ],
      disabled: [
        {
          title: "View Key Insights and Energy Tips",
          description:
            "Know how you’re tracking compared to previous weeks with Key Insights, and learn how you can lower your power use with helpful energy tips.",
          image: {
            mobile: MobileKeyInsightsAndEnergyTips,
            desktop: DesktopKeyInsightsAndEnergyTips
          }
        },
        {
          title: "Manage how you pay",
          description:
            "Easily change how you want to manage your energy account and the notifications you receive. You can switch between prepay and postpay options at any time.",
          image: {
            mobile: MobilePostpaySwitch_Img,
            desktop: DesktopPostpaySwitch
          }
        }
      ]
    }
  },
  business: {
    PAYG: {
      enabled: [],
      disabled: []
    },
    POSTPAID: {
      enabled: [],
      disabled: []
    }
  }
};

class ExperienceSettings extends React.Component {
  state = {
    formState: FORM_STATE.DEFAULT,
    displayTour: false,
    isLoading: false,
    activeSlide: 0
  };

  onSwitchClicked = () => {
    this.setState({
      displayTour: false
    });
  };

  onSubmit = (elem, form, value) => {
    const { customerPremise, refresh } = this.props.customer;
    const url =
      process.env.REACT_APP_API_BASE_URI +
      `premises/${customerPremise.parentAccountID}/experience`;

    const newExperience = customerPremise.hasPrePayBilling
      ? BILLING_TYPE.POSTPAID
      : BILLING_TYPE.PAYG;

    // submit and go to home page
    axios.post(url, { CurrentAppExperience: newExperience }).then(res => {
      this.setState({ isLoading: true });

      refresh().then(() => this.props.router.navigate("/"));
    });

    if (newExperience == "POSTPAID") {
      Analytics.event({ category: "Switch", action: "Postpay UX successful" });
    } else {
      Analytics.event({ category: "Switch", action: "Prepay UX successful" });
    }
  };

  setSlide = index => {
    this.setState({ activeSlide: index });
  };

  LandingPage = () => {
    const { theme } = this.props.theme;
    const { customerPremise } = this.props.customer;
    const { formState } = this.state;

    const newExperienceButtonText = customerPremise.hasPrePayBilling
      ? "postpay"
      : "prepay";

    const newExperienceText = customerPremise.hasPrePayBilling
      ? "paying your account monthly (postpay)"
      : "paying in advance (prepay)";
    const currentExperienceText = customerPremise.hasPrePayBilling
      ? "by paying in advance (prepay)"
      : "monthly (postpay)";

    return (
      <div className="experience-settings-form wrapper" style={{ top: "15vh" }}>
        <Segment basic>
          <Image centered size="medium" src={logoAPAYG} />
        </Segment>

        <Segment basic>
          <Header>
            You are currently managing your account {currentExperienceText}. Tap
            below to find out more about {newExperienceText}.
          </Header>
        </Segment>

        <Segment basic>
          <Form noValidate onValidSubmit={this.onSwitchClicked}>
            <div className="field button-group">
              <Button
                className="submit-btn"
                style={{ backgroundColor: theme.primary.backgroundColor }}
                type="submit"
                secondary
              >
                Switch to {newExperienceButtonText}
              </Button>

              <Button
                className="cancel-btn"
                onClick={e => this.props.router.navigate("/")}
                basic
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Segment>
      </div>
    );
  };

  CarouselForm = () => {
    const { theme } = this.props.theme;
    const { formState } = this.state;
    const { customerPremise, pageConfig } = this.props.customer;

    // get business or residential slides, filtered by billing type (PAYG or POSTPAID)
    const carouselSliderImages =
      customerPremise.billingType == "PAYG" ? "POSTPAID" : "PAYG";
    const auroraInsightsEnabled = pageConfig.functionality.auroraInsights
      ? "enabled"
      : "disabled";

    // const carouselData = Slides[pageConfig.productName][carouselSliderImages];

    const carouselData = [
      ...Slides[pageConfig.productName][carouselSliderImages],
      ...AURORA_INSIGHTS_SCREENS[pageConfig.productName][carouselSliderImages][
        auroraInsightsEnabled
      ]
    ];

    const newExperienceTitleText = customerPremise.hasPrePayBilling
      ? "postpay"
      : "prepay";

    return (
      <div className="experience-settings-form wrapper" style={{ top: "5vh" }}>
        <Segment basic>
          <Header as="h2">
            Using {newExperienceTitleText} experience
          </Header>
        </Segment>

        <Segment className="carousel-segment">
          <Carousel
            className="experience-carousel"
            //settings={{ showDescription: false, showTitle: false }}
            activeSlide={this.state.activeSlide}
            onSlideChange={this.setSlide}
            slides={carouselData}
          />
        </Segment>

        <Segment basic>
          <Form noValidate onValidSubmit={this.onSubmit}>
            <div className="field button-group">
              <Button
                className="submit-btn"
                type="submit"
                secondary
                loading={formState === FORM_STATE.LOADING}
              >
                Confirm
              </Button>

              <Button
                className="cancel-btn"
                onClick={e => this.props.router.navigate("/")}
                basic
              >
                Back
              </Button>
            </div>
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    const { CarouselForm, LandingPage } = this
    const { customerPremise, pageConfig } = this.props.customer;

    // switching between residential and business, redirect to Home page when needed
    //if (pageConfig && !pageConfig.functionality.manageExperience) {
    //  return (<Redirect to="/" />);
    //}

    if (!customerPremise) return null;

    return (
      <Layout
        pageTitle="Experience Settings"
        layout={FullWidthLayout}
        accountPicker={false}
        isRefreshing={this.state.isLoading}
      >
        {this.state.displayTour ? <CarouselForm /> : <LandingPage />}
      </Layout>
    );
  }
}

export default withRouter(withApp(withCustomer(withTheme(ExperienceSettings))));
