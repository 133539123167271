import React from "react";

export default function Icon({
  color = "currentColor",
  height = "32px",
  width = height
}) { return (
  <svg
    height={height}
    width={width}
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
	<path d="M18.0001 0C17.2776 0 16.5498 0.260313 15.9813 0.78625C15.9734 0.794219 15.9681 0.799531 15.9601 0.8075L1.80758 15.0238C0.723833 16.1075 0.734458 17.8792 1.78633 19.0188C1.7943 19.0267 1.79961 19.032 1.80758 19.04L15.9601 33.1925C17.0438 34.2762 18.8156 34.2656 19.9551 33.2137C19.9631 33.2058 19.9684 33.2005 19.9763 33.1925L34.1288 19.04C35.2604 17.9084 35.2763 16.1075 34.1926 15.0238L19.9763 0.8075C19.4345 0.265625 18.7226 0 18.0001 0ZM17.9788 1.36C18.3613 1.36 18.7465 1.49016 19.0201 1.76375L33.2363 15.98C33.7862 16.5298 33.7888 17.4463 33.1513 18.0837L19.0413 32.215C19.0334 32.223 19.0281 32.2309 19.0201 32.2363C18.3906 32.7967 17.4582 32.7781 16.9163 32.2363L2.78508 18.105C2.77711 18.097 2.7718 18.0917 2.76383 18.0837C2.48493 17.7703 2.35743 17.3798 2.36008 17C2.36008 16.9044 2.36274 16.8167 2.38133 16.7238C2.43446 16.4475 2.5593 16.1845 2.76383 15.98L16.8951 1.785L16.9163 1.76375C17.2271 1.4875 17.6043 1.36 17.9788 1.36ZM17.1926 9.52C16.9881 9.52 16.9163 9.66078 16.9163 9.79625V19.6562C16.9163 19.8608 17.0571 19.9325 17.1926 19.9325H18.7438C18.9484 19.9325 19.0201 19.7917 19.0201 19.6562V9.79625C19.0201 9.59172 18.8793 9.52 18.7438 9.52H17.1926ZM17.1926 22.1637C16.9881 22.1637 16.9163 22.3045 16.9163 22.44V24.2675C16.9163 24.472 17.0571 24.5438 17.1926 24.5438H18.8076C19.0121 24.5438 19.0838 24.403 19.0838 24.2675V22.5037C19.0838 22.2992 18.9431 22.2275 18.8076 22.2275V22.1637H17.1926Z"/>
  </svg>
)
};

