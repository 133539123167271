import { useEffect, useRef, useState } from "react";


export default ({ children }) => {

	const ref = useRef();
	const [expandedHeight, setExpandedHeight] = useState(null);

	useEffect(() => {
		if (ref.current) {
			let h = ref.current.offsetHeight;			
			ref.current.style.height = 0;
			ref.current.style.transition = 'height 0.5s';
			setExpandedHeight(h);
		}

	}, [ref])

	return (
		<p
			className="error-message"
			ref={ref}
			style={{
				color: '#E10000',
				marginTop: 0,
				height: expandedHeight || 'auto',
				overflow: 'hidden',
			}}
			onTransitionEnd={() => {
				if (ref.current) {
					ref.current.style.removeProperty('height');
					setExpandedHeight(null);
				}
			}}
		>{children}</p>
	);

}