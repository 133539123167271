import axios from 'axios'
import moment from 'moment-timezone'
import _ from 'lodash'

import { TARIFFS as tariffList, solarCheck } from '../../utils/Tariffs'

const memorize = (fn) => {
  let cache = {},
    key

  return (...args) => {
    key = JSON.stringify(args);
    return cache[key] || (cache[key] = fn(...args))
  }

}

export default class UsageDataHelper {

  static DAY = 'day';
  static WEEK = 'week';
  static MONTH = 'month';
  static QUARTER = 'quarter';
  static YEAR = 'year';
  static TARIFFS = Object.keys(tariffList)

  dispose() {
    this
      .source
      .cancel('Page was changed')
    this.getDay = null
    this.getMonth = null
    this.getWeek = null
    this.getQuarter = null
    this.getYear = null
  }

  constructor() {
    this.source = axios
      .CancelToken
      .source();
  }

  /*
   * Offset values for calculating the week index
   * So that we can always get a Mon-Sun region from the API
   */
  weekDayOffsets = [
    0,  // SUN
    -1, // MON
    5,  // TUE
    4,  // WED
    3,  // THU
    2,  // FRI
    1,  // SAT
  ];

  /*
   * The index on the front end can always start at -1 and increment/decrement by 1
   * and the index we send to the backend will be offset accordingly
   */
  getCalibratedIndexForTimePeriod(timeRange, index) {
    switch (timeRange) {
      /*
       * The API expects a day amount as the index, so we multly the page by 7
       * This then has to be offset so the week always starts at monday
       */
      case UsageDataHelper.WEEK:
        return ((index + 1) * 7) + this.weekDayOffsets[moment().days()]
      case UsageDataHelper.QUARTER:
        return index + 1;
      case UsageDataHelper.YEAR:
        return index + 1;
      case UsageDataHelper.MONTH:
        const startOfMonth = moment().date() === 1;
        const indexOffset = startOfMonth
          ? 0
          : 1;
        return index + indexOffset;
      default:
        return index;
    }
  }

  getUsageUrl(timeUnit, serviceAgreementID, index = -1, customerId = "") {

    index = this.getCalibratedIndexForTimePeriod(timeUnit, index);

    let url = process.env.REACT_APP_API_BASE_URI + 'usage/' + timeUnit;
    let query = `?serviceAgreementID=${serviceAgreementID}`

    if (customerId) {
      query += `&customerId=${customerId}`;
    }

    if (timeUnit === UsageDataHelper.WEEK) {
      query += `&dayIndex=${index}`;
    } else {
      query += `&index=${index}`;
    }

    return url + query;
  }

  getDataFormatFunction(timeRange) {
    switch (timeRange) {
      case UsageDataHelper.DAY:
        return this.formatDayData;
      case UsageDataHelper.WEEK:
        return this.formatWeekData;
      case UsageDataHelper.MONTH:
        return this.formatMonthData;
      case UsageDataHelper.QUARTER:
        return this.formatQuarterData;
      case UsageDataHelper.YEAR:
        return this.formatYearData;
      default:
        return () => null;
    }
  };

  getDataForIndex = memorize((timeRange, index, serviceAgreementID, customerId) => {
    const url = this.getUsageUrl(timeRange, serviceAgreementID, index, customerId);
    let dataFormatFunction = this.getDataFormatFunction(timeRange);

    return (
      axios.get(url, {cancelToken: this.source.token})
        .then(res => this.scrubData(dataFormatFunction(res.data)))
        .catch(err => console.log(err))
      );
  })

  getTariffData = memorize((serviceAgreementID, customerId) => {
    let url = process.env.REACT_APP_API_BASE_URI + `usage/tariff?serviceAgreementID=${serviceAgreementID}&customerId=${customerId}`;

    return (
      axios.get(url, {cancelToken: this.source.token})
        .then(res => UsageDataHelper.formatSummaryData(res.data))
        .catch(err => console.log(err))
      );
  })

  getBillingPeriodData = memorize((serviceAgreementID, customerId) => {
    let url = process.env.REACT_APP_API_BASE_URI + `usage/billing-period?serviceAgreementID=${serviceAgreementID}&customerId=${customerId}`;

    return (
      axios.get(url, {cancelToken: this.source.token})
        .then(res => UsageDataHelper.formatSummaryData(res.data))
        .catch(err => console.log(err))
      );
  })

  scrubData(data) {
	if (data) {
		if (data.MeteredUsageRecords) {
			data.MeteredUsageRecords.forEach((record) => {
				if (record.DollarValueUsage) {
					delete record.DollarValueUsage["T22"];
				}
			});
		}
		/* Card is hidden for now
		if (data.summaryDetailsDollarsValue) {
			let t22 = data.summaryDetailsDollarsValue["T22"];
			if (t22) {
				delete data.summaryDetailsDollarsValue["T22"];
				// TODO: adjust totals? Should be server side
				//data.summaryDetailsDollarsTotal -= t22;
				//data.summaryDetailsDollarsTotalMinusSolar -= t22;
			}
		}
		*/
	}
	return data;
  }
  

  getDataForInsights = memorize((serviceAgreementID, customerId = "") => {
    let url = process.env.REACT_APP_API_BASE_URI + `usage/weeklycomparison?serviceAgreementID=${serviceAgreementID}`;

    if (customerId) {
      url += `&customerId=${customerId}`;
    }

    return (
      axios
        .get(url, { cancelToken: this.source.token })
        .then(comparisonData => {
          var insightTotal = this.calculateInsightTotals(comparisonData.data);
          
          return {
            comparisons: comparisonData.data || {},
            comparisonTotals: insightTotal
          };
        })
        .catch(err => console.log(err))
    );
  })

  getDataForTips = memorize((serviceAgreementID) => {
    let url = process.env.REACT_APP_API_BASE_URI + `advice`;

    return (
      axios
        .get(url, { cancelToken: this.source.token })
        .then(response => {
          var singleTip = this.getRandomTip(response.data);
          
          return {
            tips: response.data || {},
            randomTip: singleTip
          };
        })
        .catch(err => console.log(err))
    );
  })

  getDataForWeather = memorize((suburb, index) => {
    let timePeriod = this.getCalibratedIndexForTimePeriod("week", index);
    let url = process.env.REACT_APP_API_BASE_URI + `weather/week?suburb=${suburb}&dayIndex=${timePeriod}`
       
    return (
      axios
        .get(url, { cancelToken: this.source.token })
        .then(weatherData => {
          return {
            weatherData: weatherData.data || {}
          }
        })
        .catch(err => console.log(err))
    )
  })

  getRandomTip(data) {
    if (!data) return "";

    return _.sample(data);
  }

  calculateInsightTotals(data) {
    let tariffTotals = {
      solar: 0,
      elec: 0
    };

    if (!data.TotalDollarDifference || data.TotalDollarDifference.length === 0)
      return tariffTotals;
    
    // take a look at all the tariffs
    _.forEach(data.TotalDollarDifference, function (value, key) {

      if (solarCheck(key)) {
        tariffTotals.solar += value;
      }
      else {
        tariffTotals.elec += value;
      }
    });

    return tariffTotals;
  }

  static getInitialPickerSelectedValues = timeRange => {
    const now = moment().subtract(1, 'days'); // can only get usage data for yesterday
    const day = now.date();
    const month = now.month();
    const year = now.year();
    const week = now.isoWeek();
    const quarter = now.quarter();

    switch (timeRange) {
      case (UsageDataHelper.DAY):
        return [day, month, year];
      case (UsageDataHelper.WEEK):
        return [week, year];
      case (UsageDataHelper.MONTH):
        return [month, year];
      case (UsageDataHelper.QUARTER):
        return [quarter, year];
      case (UsageDataHelper.YEAR):
        return [year];
      default:
        return;
    }
  }

  formatDayData(data) {
    const setLabels = i => {

      let graphLabel = '';
      let tooltipLabel = '';
      const start = moment(i.StartTime);

      if (i.TimeMeasureUnit === "Hour") {
        graphLabel = start.format('ha');
      } else if (start.isSame(moment().subtract(1, 'days').startOf('day'), 'd')) {
        graphLabel = 'Yesterday';
      } else {
        graphLabel = start.format('dddd');
      }

      if (i.TimeMeasureUnit === 'Hour') {
        tooltipLabel = `${start.format('dddd ha')} - ${start
          .add(1, 'hours')
          .format('ha')}`;
      } else {
        tooltipLabel = `${start.format('dddd D MMM')}`;
      }

      return {
        ...i,
        StartTimeLabel: graphLabel,
        TooltipLabel: tooltipLabel
      };
    }

    const combineRecord = (toCombine, matchingRecord, newRecord) => {
      const mergedEntries = Object.entries(newRecord[toCombine] ? newRecord[toCombine] : []).map(([key, value]) => [key, (matchingRecord[toCombine][key] ? matchingRecord[toCombine][key] : 0) + value])
      return Object.fromEntries([...Object.entries(matchingRecord[toCombine] ? matchingRecord[toCombine] : []), ...mergedEntries])
    };

    const mergeRecords = (records, newRecord) => {
      const matchingRecord = records.filter(r => r.StartTimeLabel == newRecord.StartTimeLabel)[0]
      if (matchingRecord) {
        newRecord.DollarValueUsage = combineRecord("DollarValueUsage", matchingRecord, newRecord)
        newRecord.KilowattHourUsage = combineRecord("KilowattHourUsage", matchingRecord, newRecord)
        newRecord.KilowattHourUsageAEST = combineRecord("KilowattHourUsageAEST", matchingRecord, newRecord)
        newRecord.HasSubstitutedData = newRecord.HasSubstitutedData || matchingRecord.HasSubstitutedData
      }
      return [...records.filter(r => r.StartTimeLabel != newRecord.StartTimeLabel), newRecord]
    }

    data.MeteredUsageRecords = data
      .MeteredUsageRecords
      .map(setLabels)
      .reduce(mergeRecords, []);
    const summaryDate = moment(data.StartDate);
    data.SummaryLabel = summaryDate.format('dddd D MMM');
    data.SummaryLabelStartDate = summaryDate
    data.SummaryLabelEndDate = summaryDate

    return UsageDataHelper.formatSummaryData(data);
  }

  formatMonthData(data) {
    const setLabels = i => {
      const start = moment(i.StartTime);
      let end = moment(i.EndTime).subtract(1, 'days');

      if (i.KilowattHourUsage) {
        i.KilowattHourUsage = {
          ...i.KilowattHourUsage,
        }
      }

      return {
        ...i,
        StartTimeLabel: `${start.date()} - ${end.date()}`,
        TooltipLabel: `${start.format('ddd D')} to ${end.format('ddd D')}`
      };
    }

    data.MeteredUsageRecords = data
      .MeteredUsageRecords
      .map(setLabels);

    const summaryDate = moment(data.StartDate);
    data.SummaryLabel = summaryDate.format('MMMM YYYY');
    data.SummaryLabelStartDate = summaryDate
    data.SummaryLabelEndDate = summaryDate

    return UsageDataHelper.formatSummaryData(data);
  }

  formatWeekData(data) {
    const setLabels = i => {
      const start = moment(i.StartTime);

      return {
        ...i,
        StartTimeLabel: start.format('ddd'),
        TooltipLabel: start.format('dddd D MMMM')
      };
    }

    data.MeteredUsageRecords = data
      .MeteredUsageRecords
      .map(setLabels);

    const start = moment(data.StartDate);
    const end = moment(data.EndDate).subtract(1, 'days');

    data.SummaryLabel = `${start.format('ddd D MMM')} to ${end.format('ddd D MMM')}`;
    data.SummaryLabelStartDate = start
    data.SummaryLabelEndDate = end

    return UsageDataHelper.formatSummaryData(data);
  }

  formatQuarterData(data) {
    const setLabels = i => {
      const start = moment(i.StartTime);

      if (i.KilowattHourUsage) {
        i.KilowattHourUsage = {
          ...i.KilowattHourUsage,
        }
      }

      return {
        ...i,
        StartTimeLabel: start.format('MMM'),
        TooltipLabel: start.format('MMMM YYYY')
      };
    }

    data.MeteredUsageRecords = data
      .MeteredUsageRecords
      .map(setLabels);

    const start = moment(data.StartDate);
    const end = moment(data.EndDate).subtract(1, 'months');

    data.SummaryLabel = `${start.format('MMMM')} to ${end.format('MMMM YYYY')}`;

    data.SummaryLabelStartDate = start
    data.SummaryLabelEndDate = end

    return UsageDataHelper.formatSummaryData(data);
  }

  formatYearData(data) {
    const setLabels = i => {
      const start = moment(i.StartTime);

      if (i.KilowattHourUsage) {
        i.KilowattHourUsage = {
          ...i.KilowattHourUsage,
        }
      }

      return {
        ...i,
        StartTimeLabel: start.format('MMM'),
        TooltipLabel: start.format('MMMM YYYY')
      };
    }

    data.MeteredUsageRecords = data
      .MeteredUsageRecords
      .map(setLabels);

    const start = moment(data.StartDate);
    data.SummaryLabel = `${start.format('YYYY')}`;
    data.SummaryLabelStartDate = start
    data.SummaryLabelEndDate = start

    return UsageDataHelper.formatSummaryData(data);
  }

  static formatSummaryData(data) {
    const toDollarString = val => {
      var valTemp = '$' + val.toLocaleString('en-AU', { currency: 'AUD', minimumFractionDigits: 2 });
      if(valTemp.includes("$-")) valTemp  = valTemp.replace("$-", "- $");
      return valTemp;   
  };
    const toKwhString = val => (val.toLocaleString('en-AU', { minimumFractionDigits: 2 }) + ' kWh');
    
    let {} = data;

    //APAYGAPP-1348 As there was a potential difference in rounded tooltip values and the summed total,
    //the solution was to round all the individual kilowatt values first, then sum them to get the
    //total. This way all values are correctly rounded/summed
    data.MeteredUsageRecords = data.MeteredUsageRecords.map(i => {
      let kwhs = i.KilowattHourUsage;
      if (!kwhs) return i;


      for (let tariff in kwhs) {
        kwhs[tariff] = kwhs[tariff] ? Number((kwhs[tariff]).toFixed(2)) : null
      }

      return ({ ...i, KilowattHourUsage: kwhs });
    });

    data.summaryDetailsKilowattValue = {}
    data.summaryDetailsDollarsValue = {}
    data.summaryDetailsDayContainsSubstitutedUsage = data.SummaryTotals.DayContainsSubstitutedUsage ? true : false;

    let tempTariffTypes = []
    let tempKwhTotal = 0

    for (let i = 0; i < UsageDataHelper.TARIFFS.length; i++) {
      for (let t = 0; t < data.TariffTypes.length; t++) {
        let tar = UsageDataHelper.TARIFFS[i]

        if (UsageDataHelper.TARIFFS[i] === data.TariffTypes[t]) {
          tempTariffTypes.push(data.TariffTypes[t])
          data.summaryDetailsKilowattValue[tar] = toKwhString(Number(parseFloat(data.SummaryTotals.KilowattHourUsage[tar].toFixed(2))))
          data.summaryDetailsDollarsValue[tar] = toDollarString(Number(parseFloat(data.SummaryTotals.DollarValueUsage[tar]).toFixed(2)))

          if (!solarCheck(tar)) {
            tempKwhTotal = tempKwhTotal + Number(data.SummaryTotals.KilowattHourUsage[tar].toFixed(2))
          }
        }
      }
    }

    data.summaryDetailsKilowattCombinedRoundedTotal = toKwhString(tempKwhTotal)

    let kwhTotal = Number(data.SummaryTotals.KilowattHourUsage.Total.toFixed(2)),
      dollarTotal = data.SummaryTotals.DollarValueUsage.Total,
      totalKwhSolar = 0,
      totalDollarSolar = 0;

    data.summaryDetailsKilowattTotal = toKwhString(Number(parseFloat(kwhTotal).toFixed(2)))
    data.summaryDetailsDollarsTotal = toDollarString(Number(parseFloat(dollarTotal).toFixed(2)))
    
    for (let t = 0; t < data.TariffTypes.length; t++)
    {
      if (solarCheck(data.TariffTypes[t])) {
        totalKwhSolar += Number(data.SummaryTotals.KilowattHourUsage[data.TariffTypes[t]]);
        totalDollarSolar += Number(data.SummaryTotals.DollarValueUsage[data.TariffTypes[t]]);
      }
    }

    data.summaryDetailsKilowattSolarTotal = toKwhString(Number(totalKwhSolar.toFixed(2)))
    data.summaryDetailsDollarsSolarTotal = toDollarString(Number(parseFloat(totalDollarSolar).toFixed(2)))
    data.summaryDetailsOtherTotal = toDollarString(Number(parseFloat(data.SummaryTotals.DollarValueUsage.Other).toFixed(2)))

    data.summaryDetailsKilowattTotalMinusSolar = toKwhString(kwhTotal - Number(totalKwhSolar.toFixed(2)))
    data.summaryDetailsDollarsTotalMinusSolar = toDollarString(Number(parseFloat(dollarTotal - totalDollarSolar).toFixed(2)))

    data.TariffTypes = tempTariffTypes

    return data;
  }
}
