import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon"
        className="weather-icon"  
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M210.35,286.5a76.43,76.43,0,1,1,76.44-76.42,76.51,76.51,0,0,1-76.44,76.42m0-178.4a102,102,0,1,0,102,102,102.09,102.09,0,0,0-102-102" transform="translate(-0.42)" />
    <path d="M210.35,336a12.76,12.76,0,0,0-12.76,12.77v58a12.77,12.77,0,0,0,25.53,0v-58A12.77,12.77,0,0,0,210.35,336" transform="translate(-0.42)" />
    <path d="M407.23,196.88h-58a12.77,12.77,0,1,0,0,25.54h58a12.77,12.77,0,1,0,0-25.54" transform="translate(-0.42)" />
    <path d="M210.06,83.55a12.77,12.77,0,0,0,12.77-12.77v-58a12.77,12.77,0,1,0-25.53,0v58a12.76,12.76,0,0,0,12.76,12.77" transform="translate(-0.42)" />
    <path d="M84,209.93a12.76,12.76,0,0,0-12.77-12.76h-58a12.77,12.77,0,0,0,0,25.53h58A12.77,12.77,0,0,0,84,209.93" transform="translate(-0.42)" />
    <path d="M290.89,323.72a12.76,12.76,0,0,0-22.11,12.76l20.75,35.94a12.76,12.76,0,1,0,22.11-12.76Z" transform="translate(-0.42)" />
    <path d="M330.53,152.93a12.7,12.7,0,0,0,6.37-1.71l36-20.74a12.77,12.77,0,0,0-12.77-22.12L324.14,129.1a12.77,12.77,0,0,0,6.39,23.83" transform="translate(-0.42)" />
    <path d="M129.52,95.86a12.78,12.78,0,0,0,11.07,6.39,12.78,12.78,0,0,0,11-19.15L130.88,47.16a12.76,12.76,0,1,0-22.11,12.76Z" transform="translate(-0.42)" />
    <path d="M83.5,268.37,47.57,289.11a12.77,12.77,0,0,0,12.77,22.12l35.93-20.74A12.77,12.77,0,0,0,83.5,268.37" transform="translate(-0.42)" />
    <path d="M373,288.86l-35.94-20.75a12.77,12.77,0,0,0-12.77,22.12l36,20.75A12.77,12.77,0,0,0,373,288.86" transform="translate(-0.42)" />
    <path d="M273.2,100.4a12.77,12.77,0,0,0,17.44-4.67l20.75-35.95A12.77,12.77,0,1,0,289.28,47L268.53,83a12.78,12.78,0,0,0,4.67,17.45" transform="translate(-0.42)" />
    <path d="M47.43,130.72l35.93,20.75a12.63,12.63,0,0,0,6.38,1.71,12.76,12.76,0,0,0,6.39-23.82L60.2,108.61a12.77,12.77,0,1,0-12.77,22.11" transform="translate(-0.42)" />
    <path d="M147.21,319.21a12.77,12.77,0,0,0-17.44,4.67L109,359.81a12.77,12.77,0,1,0,22.12,12.76l20.74-35.92a12.77,12.77,0,0,0-4.67-17.44" transform="translate(-0.42)" />
  </g>
</svg>

)
}