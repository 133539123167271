import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

export const useRouter = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  return { location, navigate, params }
}

// TODO: Remove once app is refactored to functional components
export const withRouter = (Component) => (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  
  return (
    <Component
      {...props}
      router={{ location, navigate, params }}
    />
  )
}