import _ from "lodash";
import residentialHubData from "./residential-hubcx.json";
import residentialCcbData from "./residential-ccb.json";
import businessData from "./business.json";

// menus:
import payg from "./menu/payg.json";
import postpaid from "./menu/postpaid.json";

const configData = {
  //default: defaultData,
  residentialccb: residentialCcbData,
  residentialhubcx: residentialHubData,
  businesshubcx: businessData
};

const menu = {
  payg: payg,
  postpaid: postpaid
};

const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    // filter array by "order" - any duplicates from original array are removed
    let filteredArray = _.filter(objValue, (value, key) => {
      return !_.find(srcValue, obj => obj.order === value.order);
    });

    // then new array is added on top of filteredArray
    return filteredArray.concat(srcValue);
  }
};

export const Config = (
  configName,
  systemType,
  billing,
  isAuroraInsightsEnabled = false
) => {
  if (!configName || !systemType) {
    return {
      menuItems: null,
      functionality: {}
    };
  }

  // merge the config json we want with the default.json
  // default.json values will get overriden
  const filename = configName + systemType;
  const billingType = billing ? billing.toLowerCase() : config.menu;
  const config = configData[filename];
  config.functionality.auroraInsights = isAuroraInsightsEnabled;
  config.billingType = billingType;

  // add the menu items from payg.json or postpaid.json
  const menuItems = _.cloneDeep(menu[billingType]);

  const filteredSideMenu = _.reduce(
    menuItems.sideMenu,
    (acc, curr) => {
      const sideMenuItem = _.cloneDeep(curr);
      // Use the "dependsOn" attribute to decide whether the side-menu item should be shown
      // This applies to both side-menu item(as a link itself) and the links in side-menu item
      if (
        sideMenuItem.dependsOn &&
        sideMenuItem.dependsOn != "" &&
        !config.functionality[sideMenuItem.dependsOn]
      ) {
        return acc;
      }

      if (sideMenuItem.links && sideMenuItem.links.length > 0) {
        sideMenuItem.links = sideMenuItem.links
          .filter(
            link =>
              !(
                link.dependsOn &&
                link.dependsOn != "" &&
                !config.functionality[link.dependsOn]
              )
          )
          .map(el => {
            return { ...el }; // To prevent mutating the original links
          });
      }

      return acc.concat(sideMenuItem);
    },
    []
  );

  menuItems.sideMenu = filteredSideMenu;
  config.menuItems = menuItems;

  return config;
};
