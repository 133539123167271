import React from 'react';
import {Form} from 'formsy-semantic-ui-react';
import {Form as SUForm} from 'semantic-ui-react';

export default(props) => {
  let errorLabel = (
    <small
      style={{
        textAlign: props.errorAlign ? props.errorAlign : 'right',
        color: props.textColor ? 'white' :'#fb6461',
        'padding': '0.25em 0',
        display: 'block'
      }}
    ></small>
  )

  let className = props.className || '';

  if (props.hideErrorMessage) {
    className += ' hide-errors';
    errorLabel = null;
  }

  if (props.hideLabel || !props.label) {
    className += ' hide-labels';
  }

  return (<Form.Input
    inputAs={props.as || SUForm.Input}
    className={className}
    name={props.name}
    label={props.label}
    action={props.action}
    errorLabel={errorLabel}
    validations={props.validations}
    validationErrors={props.validationErrors}
    validationError={props.validationError || "Field is invalid"}
    required={props.required}
    autoComplete={props.autoComplete}
    type={props.type}
    fluid={props.fluid}
    disabled={props.disabled}
    onChange={props.onChange}
    onKeyPress={props.onKeyPress}
    icon={props.icon}
    iconPosition={props.iconPosition}
    inline={props.inline}
    value={props.value}
    min={props.min}
    max={props.max}
    step={props.step}
    placeholder={props.placeholder}
    style={props.style}
    loading={props.loading}
    instantValidation/>)
}
