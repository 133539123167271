import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

/**
 * This differs to the standard Route in that a logged in user cannot visit this route
 */
const UnProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  return !isAuthenticated ? children : (
    <Navigate
      to={{
        pathname: '/',
        state: {
          from: location.pathname
        }
      }}
    />
  )
}

export default UnProtectedRoute;
