import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import ValidatedInput from "../../../components/Forms/ValidatedInput.js";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import {
	generateDatePickerContent,
	DesktopDatePicker,
	InputDatePicker,
	MobileDatePicker
} from "../../../components/DatePicker/DatePicker";
import UsageDataHelper from "../../Usage/UsageDataHelper";
import DateContextHelper from "../../Usage/DateContextHelper";
import Responsive from "../../../components/Responsive/Responsive.js";
import { withTheme } from "../../../contexts/Theme.js";
import moment from "moment-timezone";
import { useSignup } from "../../../contexts/Signup.js";
import SignupError from "../components/SignupError/SignupError.js";
import { NavLink } from "react-router-dom";
import Analytics from "../../../utils/Analytics.js";
import { useExternalLink } from "../../../components/ExternalLink.js";
  

export default withTheme((props) => {

	const navigate = useNavigate();
	useScrollToTop();

	const externalLink = useExternalLink()

	const signup = useSignup();
	let { theme } = props.theme;

	const [dob, setDob] = useState(null);

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);
	const [attempts, setAttempts] = useState(0);
	const [notFoundError, setNotFoundError] = useState(false);

	let initialDate = new Date();
	initialDate.setFullYear(initialDate.getFullYear() - 18);

	function submit(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/search";

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		let dateOfBirth = new moment(dob).format("YYYY-MM-DD");

		setNotFoundError(false);

		axios
			.get(endpoint, {
				params: {
					accountNo: '',
					emailAddress: payload.emailAddress,
					dateOfBirth: dateOfBirth, // dob state is not part of formsy
				}
			}, { ignoreGlobalErrors: true})
			.then(response => {
				setFormState(FORM_STATE.DONE_LOADING);

				//Analytics.event({ category: "User", action: "Successful Login" });		
				
				switch(response.data.ErrorCode) {
					case "CustomerWithoutEmailAddress":
					case "CustomerNotAPrimaryContact":
						navigate('/signup/external/residential');
						return;
						break;
				}
				
				signup.set({
					accountNo: '',
					emailAddress: payload.emailAddress,
					dateOfBirth: dateOfBirth, // dob state is not part of formsy
					maskedEmailAddress: response.data.MaskedEmailAddress,
					maskedPhoneNumber: response.data.MaskedPhoneNumber,
					token: response.data.Token,
				})

				setTimeout(() => {
					if (response.data.IsCustomerInAzureAd) {
						Analytics.event({ category: "Signup", action: "ExistingCustomer" });
						navigate('/signup/residential/login-exists');
					} else {
						navigate('/signup/residential/select-verification');
					}
				}, 1);
			
			})
			.catch(error => {
				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				//Analytics.event({ category: "User", action: "Failed " });

				switch(error.response ? error.response.status : error.code) {
					case 404:
						if (attempts >= 2) {
							// On third failure, redirect
							navigate('/signup/residential/find-account-number');
						} else {
							setAttempts(attempts + 1);
							setNotFoundError(true);
						}
						break;
					case 409:
						setErrorModal({
							title: 'Account Error',
							message: 'Your email has been found on more than one account. Please contact us to resolve this problem.',
							buttonLabel: 'Contact Us',
							action: () => {
								externalLink('https://www.auroraenergy.com.au/residential/contact/general-enquiries-form?utm_source=sms&utm_medium=sms&utm_campaign=app_error&utm_id=app_email&referrer=app_duplicate_email')
							}
						});
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;
	
				}
				
			});
	}

	  
	return (
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Find your account", journeyName: "signup", currentStep: 1 }}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				<p>
					Enter details for your existing Aurora Energy account:
				</p>

				{ notFoundError && <SignupError>
					Account not found, please try again	
				</SignupError>}

				<ValidatedInput
					hideErrorMessage
					required
					type="email"
					name="emailAddress"
					placeholder="Email Address"
					validations={{
						"maxLength": 320
					}}
				/>				

				{/* Not a formsy component, so state handled separately */}
				<InputDatePicker
					placeholder="Date of birth"
					initialDate={initialDate}
					date={dob}
					onChange={value => setDob(value)}
					color={theme.primary.color}
				/>

				<SignupButton disabled={!canSubmit || (dob === null)} loading={formState === FORM_STATE.LOADING}>
					Find my account
				</SignupButton>
				
				<NavLink
					to="/signup/residential/find-account-number"
					tabIndex="-1"
					style={{
						display: 'inline-block',
						marginTop: '50px'
					}}
				>
					Find your account another way
				</NavLink>

			</Form>

			<SignupErrorModal
				open={!!errorModal}
				{...((typeof errorModal === 'string') ? { message: errorModal } : errorModal)}
				onClose={() => {
					if (errorModal.action) errorModal.action();
					setErrorModal(null);
				}}
			/>

		</Layout>
	);

});
