import React, { useRef, useState } from "react";
import Layout from "../../../../layouts/Layout.js";
import SignupFormLayout from "../../../../layouts/SignupFormLayout.js";
import { Form } from 'formsy-semantic-ui-react';
import SignupButton from "../../components/SignupButton/SignupButton.js";
import IconRadioGroup from "../../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../../components/IconRadio/IconRadio.js";
import ValidatedInput from "../../../../components/Forms/ValidatedInput.js";
import useScrollToTop from "../../../../utils/useScrollToTop.js";

export default ({ initialFormData, journey, onSubmit }) => {

	useScrollToTop();

	const [canSubmit, setCanSubmit] = useState(false);

	const formRef = useRef();
	const [formData, setFormData] = useState(initialFormData);


	function submit(payload) {
		payload = {
			life_support: null,
			type_of_life_support: null,
			...payload
		}
		onSubmit(payload);
	}


	return <Layout
		layout={SignupFormLayout}
		journey={journey}
		whitePage
		accountPicker={false}
	>
		<Form
			ref={formRef}
			noValidate
			onValidSubmit={submit}
			onValid={() => {setCanSubmit(true)}}
			onInvalid={() => {setCanSubmit(false)}}
			onChange={(() => {
				setFormData(formRef.current ? formRef.current.getModel() : { });
			})}
		>

			<p>Do you have any life support machinery at the property?</p>

			<IconRadioGroup
				name="life_support"
				value={formData.life_support}
				required
			>
				<IconRadio name="life_support" value="yes" label="Yes" />
				<IconRadio name="life_support" value="no" label="No" />
			</IconRadioGroup>

			{ formData.life_support === "yes" ? <>
				<p>What type of life support?</p>
				<ValidatedInput
					name="type_of_life_support"
					value={formData.type_of_life_support}
					hideErrorMessage
					type="text"
					placeholder="Please specify"
					required
					validations={{
						"maxLength": 256,
					}}
				/>
			</> : null }

			<SignupButton disabled={!canSubmit}>Continue</SignupButton>

		</Form>
	</Layout>	


}