import React, { useEffect } from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import SignupHR from '../components/SignupHR/SignupHR.js';
import { useNavigate } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import SignupTip from "../components/SignupTip/SignupTip.js";
import SignupTipButton from "../components/SignupTip/SignupTipButton.js";
import Property from "../../../components/Property/Property.js";
import { useMemo } from "react";
import PropertyRadioGroup from "../../../components/Property/PropertyRadioGroup.js";
import PropertyRadio from "../../../components/Property/PropertyRadio.js";
import { withCustomer } from "../../../contexts/Customer.js";
import useClassifiedPremises from "../../../hooks/useClassifiedPremises.js";
import axios from "axios";
import { useExternalLink } from "../../../components/ExternalLink.js";
import Analytics from "../../../utils/Analytics.js";
import PropertyLink from "../../../components/Property/PropertyLink.js";


export default withCustomer(({ customer }) => {
	

	const navigate = useNavigate();
	const externalLink = useExternalLink();
	useScrollToTop();

	const [canSubmit, setCanSubmit] = useState(false);

	const [signedUpPremises, inProgressPremises, notSignedUpPremises] = useClassifiedPremises(customer.allPremises);

	const allSignedUp = (notSignedUpPremises.length === 0);

	
	function submit(payload) {

		if (!payload.premises) return;

		const premises = customer.allPremises.find(premises => (premises.identifier === payload.premises));
		if (!premises) return;

		if (premises.hasAdvancedMeter) {
			Analytics.event({ category: "Signup", action: "ConnectAdvanced" });
			navigate('/signup/property/' + payload.premises);
		} else {
			Analytics.event({ category: "Signup", action: "ConnectBasic" });
			navigate("/signup/property/" + payload.premises + "/meter-exchange-required");
		}

	}



	if (!customer.allPremises || !customer.customer || !notSignedUpPremises || !signedUpPremises || !inProgressPremises) {
		return null;
	}


	return (		
		<Layout
			layout={SignupFormLayout}
			journey={{ message: "Select your property", journeyName: "signup", currentStep: allSignedUp ? 6 : 4 }}
			whitePage
			accountPicker={false}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>				
				{
					allSignedUp ? <>
						{ ((signedUpPremises.length + inProgressPremises.length) > 0) &&
							<p>Congratulations!<br/>All your properties are already using aurora+</p>
						}
					</> : <>
						<p>The following properties listed on your account do not have aurora+. Please select the property you would like to connect to aurora+</p>
						<PropertyRadioGroup
							name="premises"
							formRadioGroup={true}
							required
							style={{marginTop: '30px', marginBottom: '30px'}}
						>
							{ notSignedUpPremises.map(premises => {
								return <PropertyRadio
									key={premises.identifier}
									name="premises"
									value={premises.identifier}
									premises={premises}
									advancedMeterRequired={!premises.hasAdvancedMeter}
									advancedMeter={premises.hasAdvancedMeter}
								/>
							})}
						</PropertyRadioGroup>
					</>
				}

				{ (inProgressPremises.length + signedUpPremises.length > 0) && <>

					{ !allSignedUp && <>
						<p>These properties are already connected to aurora+</p>
					</>}

					<SignupHR light />

					{ inProgressPremises.map(premises => {
						return <PropertyLink
							key={premises.identifier}
							premises={premises}
							inProgress={true}
						/>
					})}
					{ signedUpPremises.map(premises => {
						return <PropertyLink
							key={premises.identifier}
							premises={premises}
							enterPrompt={true}
							onClick={() => {
								customer.setCustomerPremise(premises.identifier);
								setTimeout(() => {
									navigate("/");	
								}, 1)
							}}
						/>
					})}
					<SignupHR light />
				</>}

				{ !allSignedUp &&
					<SignupButton disabled={!canSubmit}>
						Continue
					</SignupButton>
				}

			</Form>

			<SignupTip>
				<p>Don’t see the property you were looking for? Call us on 1300 13 2003 or </p>
				<SignupTipButton onClick={() => {
					externalLink('https://www.auroraenergy.com.au/general-enquiries-form');
				}}>Contact us here</SignupTipButton>
			</SignupTip>

		</Layout>		
	);

})
