import React, { useEffect, useRef, useState } from 'react';
import {Form} from 'formsy-semantic-ui-react';
import {Form as SUForm} from 'semantic-ui-react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import './Forms.css';
import { addValidationRule } from 'formsy-react';

const INVALID_ID = "~invalid~";

addValidationRule('matchesId', (values, value, items) => {
	return (value !== INVALID_ID);
});

export default (props) => {

	const [value, setValue] = useState("");
	const wrapperRef = useRef(null);

   	let errorLabel = (
		<small
		style={{
			textAlign: props.errorAlign ? props.errorAlign : 'right',
			color: props.textColor ? 'white' :'red',
			'padding': '0.25em 0',
			display: 'block'
		}}
		></small>
  	)


	function handleInputChange(event) {
		const val = event.currentTarget.value.length;
		setTimeout(() => {
			setValue((val > 0) ? INVALID_ID : "");
		}, 0);
		//return true;
	}
	function handleInputBlur(event) {
		/*
		const val = event.currentTarget.value.length;
		setTimeout(() => {
			setValue((val > 0) ? INVALID_ID : "");
		}, 0);
		*/
		//return true;
	}

	useEffect(() => {

		if (wrapperRef.current) {
			const input = wrapperRef.current.querySelector('input');
			input.addEventListener('input', handleInputChange);
			input.addEventListener('blur', handleInputBlur);			
		}

		return () => {
			if (wrapperRef.current) {
				const input = wrapperRef.current.querySelector('input');
				input.removeEventListener('input', handleInputChange);
				input.removeEventListener('blur', handleInputBlur);			
			}	
		}
	}, []);

	let className = props.className || '';
	
	if (props.hideErrorMessage) {
		className += ' hide-errors';
		errorLabel = null;
	}

	if (props.hideLabel || !props.label) {
		className += ' hide-labels';
	}

	const autocompleteInput = (<ReactSearchAutocomplete
		value={props.value}
		items={props.items}
		onSelect={(a) => {
			setValue(a.id);
		}}
		style={{...props.style, background: 'red'}}
		showIcon={false}
		showClear={false}
		styling={{
			backgroundColor: 'white',
			boxShadow: 'none',
			border: 'none',
			borderRadius: 0,
			height: 40,
			zIndex: 1,
		}}
	/>);

	const autocompleteWrapper = (
		autocompleteInput
	);

	return (<div className="field" ref={wrapperRef}>
		<Form.Input		
			inputAs={autocompleteWrapper}
			className={[className, 'autocomplete-input'].join(' ')}
			name={props.name}
			label={props.label}
			action={props.action}
			errorLabel={errorLabel}
			validations={props.validations}
			validationErrors={props.validationErrors}
			validationError={props.validationError || "Field is invalid"}
			required={props.required}
			type={props.type}
			fluid={props.fluid}
			inline={props.inline}
			value={value}
			placeholder={props.placeholder}
			loading={props.loading}
		/>
	</div>);
  
	


}
