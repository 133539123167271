import React, {Component} from 'react';
import {Form} from 'formsy-semantic-ui-react';
import {Button} from 'semantic-ui-react';
import Modal from './../../components/Modal';

export default class MultipleAddressesModal extends Component {

  handleOpen = () => this.setState({modalOpen: true, centered: true});
  handleClose = () => this.setState({modalOpen: false});

  submit = () => {
    this
      .props
      .addressSaved();
  }

  render() {
    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '320px'
      }
    };

    return (
      <Modal
        name="multiple-addresses"
        style={inlineStyle.modal}
        open={this.props.modalOpen}
        onClose={this.handleClose}>
        <Modal.Header>Oops.</Modal.Header>
        <Modal.Content>
          <Form
            noValidate
            onChange={this.change}
            onValidSubmit={this.submit}
            onValid={this.onFormValid}
            onInvalid={this.onFormInvalid}>

            <p>
              We've found more than one Aurora PAYG property.
            </p>

            <div className="form-group">

              <label htmlFor="address">Please select one of the addresses below:</label><br/> {Object
                .keys(this.props.context.addressSelection)
                .map((item, i) => {
                  return (
                    <div key={i}>
                      <label>
                        <input
                          name="address"
                          type="radio"
                          key={i}
                          value={this.props.context.addressSelection[item].Address}
                          checked={this.props.context.address === this.props.context.addressSelection[item].Address}
                          onChange={e => {
                          this
                            .props
                            .addressChanged(e.target.value)
                        }}/> {this.props.context.addressSelection[item].Address}
                      </label>
                    </div>
                  )
                })
}

            </div>

            <br/>

            <Modal.Actions>
              <Button type="submit" fluid secondary onClick={this.submit}>
                Continue
              </Button>
            </Modal.Actions>

          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
