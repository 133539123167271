import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '28px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 80 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M68.7581 0C63.0364 0 58.3333 4.70714 58.3333 10.4289V72.9167C58.3333 78.6384 63.0364 83.3455 68.7581 83.3455C74.4799 83.3455 79.187 78.6384 79.187 72.9167V10.4289C79.187 4.70714 74.4799 0 68.7581 0ZM68.7581 6.25C71.1031 6.25 72.937 8.08394 72.937 10.4289V72.9167C72.937 75.2616 71.1031 77.0955 68.7581 77.0955C66.4132 77.0955 64.5833 75.2616 64.5833 72.9167V10.4289C64.5833 8.08394 66.4132 6.25 68.7581 6.25ZM10.4248 18.7622C4.70307 18.7622 0 23.4653 0 29.187V72.9167C0 78.6384 4.70307 83.3455 10.4248 83.3455C16.1465 83.3455 20.8537 78.6384 20.8537 72.9167V29.187C20.8537 23.4653 16.1465 18.7622 10.4248 18.7622ZM10.4248 25.0122C12.7697 25.0122 14.6037 26.8421 14.6037 29.187V72.9167C14.6037 75.2616 12.7697 77.0955 10.4248 77.0955C8.07987 77.0955 6.25 75.2616 6.25 72.9167V29.187C6.25 26.8421 8.07987 25.0122 10.4248 25.0122ZM39.6037 37.5C33.8819 37.5 29.1748 42.2071 29.1748 47.9289V72.9167C29.1748 78.6384 33.8819 83.3455 39.6037 83.3455C45.3254 83.3455 50.0326 78.6384 50.0326 72.9167V47.9289C50.0326 42.2071 45.3254 37.5 39.6037 37.5ZM39.6037 43.75C41.9486 43.75 43.7826 45.5839 43.7826 47.9289V72.9167C43.7826 75.2616 41.9486 77.0955 39.6037 77.0955C37.2587 77.0955 35.4248 75.2616 35.4248 72.9167V47.9289C35.4248 45.5839 37.2587 43.75 39.6037 43.75Z" />
      </svg>

)
}
