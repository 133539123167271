import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

function UsingTopupModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Making a top up</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'left' }}>
          <p>You can make account balance top ups at any time by selecting:</p>
          <ul>
            <li>an amount greater than $10, or</li>
            <li>selecting the pre-filled button that shows the total amount due, which includes all outstanding bill amounts (either due or overdue).</li>
              </ul>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor, color: 'white' }}
          onClick={() => closeModal()}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}


export {
  UsingTopupModal
};
