import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    style = {}
}) { return (
    <svg 
        id="SolarIcon" 
        className="weather-icon" 
        data-name="SolarIcon"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 566.93 566.93">
  <g fill={color}>
    <path d="M387.34,296.56l-44.08-25.44,43.4-24.68A13.48,13.48,0,0,0,373.34,223L316.21,255.5,237.34,210l78.86-45.53,57.14,32.48a13.36,13.36,0,0,0,6.65,1.76,13.47,13.47,0,0,0,6.67-25.19l-43.4-24.67,44.08-25.45A13.48,13.48,0,0,0,373.86,100l-44.08,25.45.33-49.92A13.49,13.49,0,0,0,316.72,62c-7.28-.33-13.51,5.95-13.56,13.39l-.43,65.72-78.85,45.52V95.57l56.7-33.24A13.48,13.48,0,0,0,267,39.08L223.88,64.34V13.47a13.48,13.48,0,0,0-26.95,0V64.34L153.85,39.08a13.48,13.48,0,0,0-13.63,23.25l56.71,33.24v91.06l-78.86-45.52-.44-65.75A13.47,13.47,0,0,0,104.16,62h-.1A13.47,13.47,0,0,0,90.68,75.54L91,125.49,46.94,100a13.48,13.48,0,1,0-13.48,23.34l44.07,25.44L34.14,173.49a13.48,13.48,0,0,0,13.32,23.43l57.13-32.48L183.44,210l-78.86,45.52L47.44,223a13.47,13.47,0,1,0-13.31,23.43l43.4,24.68L33.46,296.56A13.47,13.47,0,0,0,46.93,319.9L91,294.45l-.33,49.93a13.48,13.48,0,0,0,13.39,13.56h.09a13.47,13.47,0,0,0,13.47-13.38l.44-65.73,78.86-45.53v91.06l-56.71,33.25a13.48,13.48,0,0,0,13.63,23.25l43.08-25.26v50.93a13.48,13.48,0,0,0,26.95,0V355.6L267,380.86a13.48,13.48,0,0,0,13.63-23.25l-56.7-33.25V233.31l78.85,45.52.45,65.73a13.46,13.46,0,0,0,13.47,13.38h.09a13.47,13.47,0,0,0,13.38-13.56l-.33-49.93,44.07,25.45a13.48,13.48,0,0,0,13.48-23.34Z" transform="translate(-26.71)" />
  </g>
</svg>

)
}