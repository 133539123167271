import moment from 'moment-timezone';
import {passwordStrength} from './PasswordStrength';

import {addValidationRule} from 'formsy-react';

addValidationRule('passwordStrength', (values, value, score = 3) => {
  return passwordStrength(value) >= score;
})

addValidationRule('min', function (values, value, otherValue) {
  return Number(value) >= Number(otherValue);
});

addValidationRule('max', function (values, value, otherValue) {
  return Number(value) <= Number(otherValue);
});

addValidationRule('birthDate', (values, value, otherValue) => {
  let start = moment('31/12/1890', 'DD/MM/YYYY');
  let end = moment().subtract(18, 'years');

  return moment(value, 'DD/MM/YYYY').isValid() && moment(value, 'DD/MM/YYYY').isBetween(start, end);
});

addValidationRule('isParameterTrue', (values, value, otherValue) => {
  return otherValue === true;
})

addValidationRule('validPhoneNumberOrEmpty', (values, value, otherValue) => {
  if (value === '') {
    return true;
  }
  let phoneRegex = /^\D*(?:\d\D*){10}$/g;
  return (value.match(phoneRegex) != null);
})

addValidationRule('validInternationlPhoneNumberOrEmpty', (values, value, otherValue) => {
  if (value === '') {
    return true;
  }
  let phoneRegex = /^\D*(?:\d\D*){6,21}$/g;
  return (value.match(phoneRegex) != null);
})