import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import { useSignup } from "../../../contexts/Signup.js";
import IconRadioGroup from "../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../components/IconRadio/IconRadio.js";
import SignupPhoneIcon from '../../../components/Icons/signup/SignupPhoneIcon.js';
import SignupEmailIcon from '../../../components/Icons/signup/SignupEmailIcon.js';
import SignupTip from "../components/SignupTip/SignupTip.js";
import SignupTipButton from "../components/SignupTip/SignupTipButton.js";


export default () => {

	const navigate = useNavigate();
	useScrollToTop();

	const signup = useSignup();
	if (!signup.token || (!signup.maskedEmailAddress && !signup.maskedPhoneNumber)) navigate('/signup/residential');

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	const hasMobile = (signup.maskedPhoneNumber !== "");

	
	function submit(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/verify/send";

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		axios
			.post(endpoint, {
				accountNo: signup.accountNo,
				emailAddress: signup.emailAddress,
				dateOfBirth: signup.dateOfBirth,
				verificationType: payload.verificationType,
			}, {
				headers: {
					common: {
						'authorization': 'bearer ' + signup.token
					}
				},
				ignoreGlobalErrors: true
			})
			.then(response => {
				setFormState(FORM_STATE.DONE_LOADING);

				signup.set({
					verificationType: payload.verificationType
				});

				//Analytics.event({ category: "User", action: "Successful Login" });		
				
				setTimeout(() => {
					navigate('/signup/residential/verify');
				}, 1);

			})
			.catch(error => {
				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				//Analytics.event({ category: "User", action: "Failed " });

				switch(error.response ? error.response.status : error.code) {
					case 404:
						navigate('/signup/external/residential');
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;

					// TODO: 401 not verified if bad token
				}
				
			});
	}

	return (		
		<Layout
			whitePage
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "We’ve found you!", journeyName: "signup", currentStep: 2 }}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				<p>
					To verify who you are we will send a 6 digit code to the details we have recorded for the primary account holder. Where would you like us to send it?
				</p>

				<IconRadioGroup
					name="verificationType"
					formRadioGroup={true}
					required
					style={{marginTop: '30px'}}
					value={ !hasMobile ? "Email" : null }
				>
					{ hasMobile ? <IconRadio name="verificationType" value="Phone" label={<span>Mobile<br />Ending in {signup.maskedPhoneNumber}</span>} icon={SignupPhoneIcon} iconHeight={40} /> : null }
					<IconRadio name="verificationType" value="Email" label={<span>Email<br />{signup.maskedEmailAddress}</span>} icon={SignupEmailIcon} iconHeight={40} />
				</IconRadioGroup>

				<SignupButton disabled={!canSubmit} loading={formState === FORM_STATE.LOADING}>
					Continue
				</SignupButton>

			</Form>

			<SignupTip>
				<p>If you don’t have access to the above, or the details are no longer current, we won’t be able to verify you. Please click below:</p>
				<SignupTipButton onClick={() => {
					navigate('/signup/external/residential');
				}}>Update my details and<br/>request aurora+</SignupTipButton>
			</SignupTip>


			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

}


