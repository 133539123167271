import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '11px',
  width = '18px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 47 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={color} d="M23.4646 17.2146L7.54375 1.29375C5.81875 -0.43125 3.01875 -0.43125 1.29375 1.29375C-0.43125 3.01875 -0.43125 5.81875 1.29375 7.54375L20.5188 26.7688C22.1479 28.3979 24.7854 28.3979 26.4104 26.7688L45.6354 7.54375C47.3604 5.81875 47.3604 3.01875 45.6354 1.29375C43.9104 -0.43125 41.1104 -0.43125 39.3854 1.29375L23.4646 17.2146Z" />
    </svg>
  );
}
