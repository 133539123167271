import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
        height={height}
        width={width}
        viewBox="0 0 15 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Screens-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Hamburger-Menu"
                transform="translate(-32.000000, -419.000000)"
                fill={color}
                fillRule="nonzero">
                <g id="help" transform="translate(32.000000, 419.000000)">
                    <path
                        d="M7.01262353,9.33696639 C6.59985171,9.33696639 6.26566416,9.672025 6.26566416,10.0740953 C6.26566416,10.4665808 6.59001106,10.8112242 7.01262353,10.8112242 C7.43523601,10.8112242 7.76942356,10.4666082 7.76942356,10.0740953 C7.76942356,9.672025 7.42545174,9.33696639 7.01262353,9.33696639 Z"
                        id="Shape"></path>
                    <path
                        d="M6.93928729,3.93135427 C5.61891455,3.93135427 5.01253133,4.69618838 5.01253133,5.21246854 C5.01253133,5.58535431 5.33526604,5.75743855 5.59935742,5.75743855 C6.12748407,5.75743855 5.9123276,5.02126687 6.90993756,5.02126687 C7.39897801,5.02126687 7.79020476,5.23161339 7.79020476,5.67139643 C7.79020476,6.18770402 7.24249293,6.48406524 6.91973016,6.75176403 C6.63610971,6.99077298 6.26444009,7.38277617 6.26444009,8.20498998 C6.26444009,8.70212529 6.40133999,8.84554711 6.80235933,8.84554711 C7.28160718,8.84554711 7.37939283,8.63522802 7.37939283,8.45357135 C7.37939283,7.95640861 7.38921348,7.66959239 7.92713272,7.2584992 C8.19119604,7.0577251 9.02255639,6.40759554 9.02255639,5.50888461 C9.02255639,4.61017368 8.19119604,3.93135427 6.93928729,3.93135427 Z"
                        id="Shape"></path>
                    <path
                        d="M7.13571429,0 C3.23937757,0 0.0857142857,3.09902764 0.0857142857,6.9290119 L0.0857142857,13.3166948 C0.0857142857,13.6156708 0.332299051,13.8580238 0.636495536,13.8580238 L7.13571429,13.8580238 C11.0320235,13.8580238 14.1857143,10.7589962 14.1857143,6.9290119 C14.1857143,3.0995419 11.0325742,0 7.13571429,0 Z M7.22857143,12.9112738 L1.20178572,12.9112738 L1.20178572,7.0027248 C1.20178572,3.73723934 3.89729353,1.09417575 7.22857143,1.09417575 C10.5594029,1.09417575 13.2553571,3.73680167 13.2553571,7.0027248 C13.2553571,10.2682103 10.5598493,12.9112738 7.22857143,12.9112738 Z"
                        id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
)
}
