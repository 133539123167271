import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

function AutoTopupConfirmModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, topUpAmount, threshold, theme } = props;
  
  return (
    <Modal style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Confirm auto payment details</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>
            Your account will automatically top up by <b>${topUpAmount}</b> when your balance gets below <b>${threshold}</b>.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => confirmModal()}>
          Confirm
        </Button>
        <Button
          fluid
          type="button"
          onClick={() => closeModal()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function AutoTopupSavedModal(props) {

  const { openModal, closeModal, isOpen, theme } = props;

  return (
    <Modal style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Auto payment details saved</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>
            Your auto payment details have been saved. You can update or stop auto payments at any time on the payment screen.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => closeModal()}>
          Return to payment
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function AutoTopupStopModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Stopping auto payments</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>Are you sure you want to stop your auto payment?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => confirmModal()}>
          Confirm
        </Button>
        <Button
          className="state-button"
          fluid
          onClick={() => closeModal()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function UsingAutoTopupModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Using auto payments</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>Auto payments will use the saved card that is selected as your primary card.
            If another saved card is selected to be the primary card, auto payments will automatically use the new primary card from that point onwards.
            You can update or stop your auto payment at any time.</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor, color: 'white' }}
          onClick={() => closeModal()}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function AutoTopupAddCardModal(props) {

    const { openModal, closeModal, confirmModal, isOpen, theme } = props;

      return (
        <Modal
          style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
          onClose={() => closeModal()}
          onOpen={() => openModal()}
          size='mini'
          open={isOpen}>
          <Modal.Header>Update auto payment card</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: 'center' }}>
          <p>You can save new card details or update your primary card for auto payments on the payment details page.</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => confirmModal()}>
          Go to payment details
        </Button>
        <Button
          className="state-button"
          fluid
          onClick={() => closeModal()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export {
  AutoTopupSavedModal,
  AutoTopupConfirmModal,
  AutoTopupStopModal,
  UsingAutoTopupModal,
  AutoTopupAddCardModal
};
