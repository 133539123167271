export default function GlobalIcon({
    height = '32px',
    width = height,
    style = {}
}) {
    return (
    <svg
        aria-hidden="true"
        height={height}
        width={width}
        style={{ ...style, cursor: 'pointer' }}
        focusable="false"
        data-prefix="fas"
        data-icon="info"
        className="svg-inline--fa fa-info fa-w-6"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 612">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M262.36,21.58c-107.91,0-195.7,87.8-195.7,195.71,0,76.25,43.84,142.45,107.64,174.74V508.6a51.09,51.09,0,0,0,51,51H299.4a51.09,51.09,0,0,0,51-51V392c63.8-32.29,107.64-98.49,107.64-174.74C458.07,109.38,370.28,21.58,262.36,21.58ZM277.59,379.1c-5,.47-10.1.72-15.23.72s-10.21-.25-15.22-.72c1.13-22.7.58-66.08-15.51-104.22H293.1C277,313,276.46,356.4,277.59,379.1Zm39.67,95.63H207.48V405.15a195.83,195.83,0,0,0,109.78,0Zm0,33.87a17.88,17.88,0,0,1-17.86,17.86H225.34a17.88,17.88,0,0,1-17.86-17.86v-.69H317.26Zm-6.69-136.08c-.77-25.57,1.36-72.85,24-104.59a16.59,16.59,0,0,0-13.51-26.22H203.64a16.58,16.58,0,0,0-13.49,26.22c22.65,31.74,24.78,79,24,104.59C148,351.93,99.83,290.13,99.83,217.29c0-89.63,72.91-162.53,162.53-162.53S424.9,127.66,424.9,217.29C424.9,290.13,376.73,351.94,310.57,372.52ZM48,71.47,4.86,28.32A16.59,16.59,0,0,1,28.32,4.86L71.47,48A16.59,16.59,0,1,1,48,71.47Zm417,4.86A16.59,16.59,0,0,1,453.26,48L496.41,4.86a16.59,16.59,0,0,1,23.45,23.46L476.72,71.47A16.55,16.55,0,0,1,465,76.33ZM476.72,363.1l43.14,43.15a16.59,16.59,0,1,1-23.45,23.46l-43.15-43.15a16.59,16.59,0,0,1,23.46-23.46ZM48,363.1a16.59,16.59,0,0,1,23.46,23.46L28.32,429.71A16.59,16.59,0,1,1,4.86,406.25Z" />
            </g>
        </g>
    </svg>
    )
}