import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
	<svg
		height={height}
		width={width}
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		viewBox="0 0 20 25"
	>
		<path fill={color} d="M16.7171 1.70424L13.6597 8.3837L13.2926 9.18697C12.9483 9.9391 13.5019 10.7935 14.3334 10.7935H15.221H17.3085L7.71186 21.003L9.50766 14.0577L9.69067 13.3487C9.87711 12.6295 9.33036 11.9296 8.58231 11.9296H7.84683H5.9378L9.23886 2.0928C9.31664 1.86103 9.53511 1.70424 9.78103 1.70424H16.7171ZM17.9387 0H9.78217C8.79734 0 7.92232 0.626023 7.6112 1.55426L4.05965 12.1387C3.81258 12.8738 4.3639 13.6339 5.14399 13.6339H7.84683L5.16686 23.9956C5.02389 24.5649 5.48256 25 5.96411 25C6.17571 25 6.39189 24.9159 6.56347 24.7216L19.7586 10.6844C20.3064 10.0629 19.8615 9.08926 19.0299 9.08926H15.2222L18.784 1.30431C19.0643 0.694192 18.6147 0 17.9387 0Z" />
        <path fill={color} d="M4.5 1C6.98531 1 9 3.01469 9 5.5C9 7.98531 6.98531 10 4.5 10C2.01469 10 0 7.98531 0 5.5C0 3.01469 2.01469 1 4.5 1ZM5.0625 5.5C5.0625 5.39706 5.0625 3.54044 5.0625 3.4375C5.0625 3.12681 4.81069 2.875 4.5 2.875C4.18931 2.875 3.9375 3.12681 3.9375 3.4375C3.9375 3.50594 3.9375 4.3495 3.9375 4.9375C3.51488 4.9375 2.88319 4.9375 2.8125 4.9375C2.50181 4.9375 2.25 5.18931 2.25 5.5C2.25 5.81069 2.50181 6.0625 2.8125 6.0625C2.91544 6.0625 4.46794 6.0625 4.5 6.0625C4.81069 6.0625 5.0625 5.81069 5.0625 5.5Z" />
	</svg>
)
}


