import React from 'react';
import {Segment} from 'semantic-ui-react'
import ColumnLayout from './ColumnLayout';
import { useTheme } from '../contexts/Theme';

const DefaultLayout = ({ children, dimmer, whiteBackground, ...rest }) => {
  const { theme } = useTheme();

  const columnContainer = {
    mobile: 16,
    tablet: 10,
    computer: 6
  };

  // Use theme values for styling
  const layoutStyle = {
    backgroundColor: whiteBackground ? '#FFFFFF' : (theme.layout?.backgroundColor || theme.background),
    color: theme.layout?.color || theme.color
  };

  return (
    <Segment 
      padded 
      basic 
      {...rest} 
      className="main-wrapper layout-default"
      style={layoutStyle}
    >
      {dimmer}
      <ColumnLayout column={columnContainer}>
        {children}
      </ColumnLayout>
    </Segment>
  );
};

export default DefaultLayout;