import React, {Component} from 'react';
import {Button, Header, List} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import {FORM_STATE} from '../../utils/FormState';
// eslint-disable-next-line
import Log from '../../utils/Log';
import ConsentFormHelper from './ConsentFormHelper';
import ValidatedCheckbox from '../../components/Forms/ValidatedCheckbox';
import ExternalLink from '../../components/ExternalLink';
import Analytics from '../../utils/Analytics';
import {
  CONTACT_STRING,
  AURORA_CONTACT_PHONE_NUMBER,
  TERMS_AND_CONDITIONS_LINK,
  PAGE_NUMBERS,
  ID_TYPES
} from './constants';

export default class ConsentPage extends Component {

  state = {
    formValid: '',
    submittingConsent: FORM_STATE.DEFAULT
  }

  componentDidMount() {
    this.dataHelper = new ConsentFormHelper()
  }

  submit = () => {
    this.setState({submittingConsent: FORM_STATE.LOADING})
    this
      .submitConsentForm()
      .then(res => {
        if (res) {
          this
            .props
            .next()
        }
      })
  }

  canSubmit = () => {
    return (this.state.formValid)
  }
  onFormValid = () => {
    this.setState({formValid: true})
  }
  onFormInvalid = () => {
    this.setState({formValid: false})
  }

  submitConsentForm = () => {
    return new Promise(resolve => {
      const { context } = this.props;

      this.mailingAddress = {
        addressType: context.mailingAddressType,
        postcode: context.mailingPostcode,
        state: context.mailingState,
        streetName: context.mailingStreetName,
        streetNumber: context.mailingStreetNumber,
        streetType: context.mailingStreetType,
        suburb: context.mailingSuburb,
        unitNumber: context.mailingUnitNumber
      }

      let idType = ID_TYPES.find(i => i.value === context.typeOfID);
      const unverifiedCustomerIdType = idType ? idType.text : "";

      this
        .dataHelper
        .createCustomerContact(
          context.premise,
          context.selectedProduct,
          context.selectedTariff,
          context.useAddressForPostedCorrespondence,
          context.hasDog,
          context.hasOptedInForMarketing,
          context.hasInternalSwitches,
          context.hasSpecialInstructions,
          context.specialInstructions,
          context.specialInstructionsPhone.PhoneNumber,
          context.email,
          context.phoneNumbers,
          context.oneTimeFormToken,
          context.captchaToken,
          context.customerID,
          this.mailingAddress,
          context.hasLifeSupport,
          context.customerIsUnverified,
          context.address,
          context.premiseIdIsUnknown,
          context.unverifiedPremiseId,
          context.customerIdIsUnknown,
          context.unverifiedCustomerId,
          unverifiedCustomerIdType,
          context.dateOfBirth,
          context.lastName,
          context.firstName
        )
        .then(res => {

          this.setState({submittingConsent: FORM_STATE.DONE_LOADING})

          if (res) {
            const success = (res && res.CMAddNewOrder && res.CMAddNewOrder.orderIdField) || (res && res.UnverifiedOrderSentSuccessfully)
            if(res.UnverifiedOrderSentSuccessfully)
            {
              this
                .props
                .saveGlobalState({success})
            }else{
              this.props
                .saveGlobalState({success, orderId: res.CMAddNewOrder.orderIdField})
            }

            if (success) {
              resolve(true);

              // Log an event indicating a successful form submit, along with the number of
              // seconds spent on the form.
              let timeSpent = new Date(new Date().getTime() - this.props.context.startTime.getTime())
              Analytics.event({
                category: 'New Consent Form',
                action: `Submit Form Success`,
                value: Math.round(timeSpent / 1000)
              })

              return;
            } else {
              Analytics.event({category: 'New Consent Form', action: `Submit Form Failure`})
              resolve(false)
              this
                .props
                .saveGlobalState({currentStep: 7, failureMessage: 'Error creating your request', contactMessage: CONTACT_STRING})
            }
          } else {
            Analytics.event({category: 'New Consent Form', action: `Submit Form Failure`})
            resolve(false)
            this
              .props
              .saveGlobalState({currentStep: 7, failureMessage: 'Error creating your request', contactMessage: CONTACT_STRING})
          }
        })
    })
  }

  render() {
    const {currentStep, selectedProduct, selectedTariff} = this.props.context;

    const agreeConsentForInstallationLabel = <label>I agree and have read and understood the&nbsp;
      <ExternalLink href={TERMS_AND_CONDITIONS_LINK}>terms and conditions</ExternalLink>.</label>

    if (currentStep !== PAGE_NUMBERS.CONSENT_PAGE) {
      return null;
    }

    return (
      <Form
        noValidate
        onChange={this.props.change}
        onValidSubmit={this.submit}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}
        loading={this.state.submittingConsent === FORM_STATE.LOADING}>

        <Header textAlign="center" size="large">
          {this.props.context.selectedProduct.text}<br/>
          ({this.props.context.selectedTariff.text})
        </Header>

        { !this.props.context.customerIsUnverified ? 
          <React.Fragment>
            <p>
              Installation will take place at this property:
              <strong> {this.props.context.address || 'No address specified'}</strong>
            </p>

	    {(selectedProduct.value === 'TAS_RES_PI' && selectedTariff.value === '93')
	      ? (
		<p>
		  We will send your welcome pack and monthly statements here:
		  <strong> {this.props.context.email}</strong>
		</p>
	      )
	      : (
		<p>
		  You will receive a welcome pack and quarterly statements.
		</p>
	      )}

	    <Form.Field>
	      <p>
		Clicking the checkbox below confirms you understand and agree to the following:
	      </p>
	      <List bulleted>
		<List.Item>Consent for your property to be transferred to the new product.</List.Item>
		<List.Item>You understand that installation will take place before December 2019.</List.Item>
		{selectedProduct.value === 'TAS_RES_PI' && (
		  <List.Item>If there is poor quality mobile coverage we may not be able to
		    install the product.</List.Item>
		)}
		{selectedProduct.value === 'TAS_RES_PI' && (
		  <List.Item>There will be a short delay between when we install your new meter
		    and you receive your email with a link to activate your new online account.</List.Item>
		)}
	      </List>
	    </Form.Field>

	    <ValidatedCheckbox
	      validations="isTrue"
	      name="agreeConsentForInstallation"
	      value={this.props.context.agreeConsentForInstallation}
	      validationErrors={{
	      isTrue: "This field is required."
	    }}
	      label={agreeConsentForInstallationLabel}/>
          </React.Fragment> 
        :
          <React.Fragment>
            <p>You have completed a request for the above product to be installed at this property: <strong> {this.props.context.address || 'No address specified'}</strong></p>
            <p>Press submit to confirm.</p>
          </React.Fragment>
        }

        <ValidatedCheckbox
          name="hasOptedInForMarketing"
          value={this.props.context.hasOptedInForMarketing}
          label="I'm happy to receive marketing updates!"/>

        <Form.Field>
          <Button fluid type="submit" secondary disabled={!this.canSubmit()}>Submit</Button>
        </Form.Field>

        <Button fluid basic onClick={this.props.prev}>Previous</Button>

        <p>
          Still have questions? Email us at <a href="mailto:aurorapaygplus@auroraenergy.com.au">aurorapaygplus@auroraenergy.com.au</a> or call us at {AURORA_CONTACT_PHONE_NUMBER}. 
        </p>

        {/* Removed web callback for release3.2 */}
        {/*
          <CallbackModal
            context={this.props.context}
          />
          */}

      </Form>
    );
  }
}
