import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Image,
  Container,
} from "semantic-ui-react";

import Layout from "../../layouts/Layout";
import BareLayout from "../../layouts/BareLayout";

import banner from "../../static/home-banner.jpg";
import logo from "../../static/logo.svg";

import LoggedOutHomeLayout from "../../layouts/LoggedOutHomeLayout";
import Responsive from "../../components/Responsive/Responsive";
import { withContexts } from "../../contexts";

import './Login.css';
import Analytics from "../../utils/Analytics";
import { useLoginReturn } from "../../contexts/LoginReturn";
import MobileAppComms from "../../utils/MobileAppComms";


export default withContexts(({ auth }) => {

	const navigate = useNavigate();
	const loginReturn = useLoginReturn();

	useEffect(() => {
		// Only initiate login if the loginReturn was requested this session. Discard stale ones
		if (loginReturn.loginRequired) {
			auth.initiateLogin();
		} else {
			// Only clear if needed, or else this useEffect will retrigger infinitely
			if (loginReturn.returnUrl) {
				loginReturn.clear();
			}
		}
	}, [loginReturn])

	
	function loginClick() {
		auth.initiateLogin();
	}

	function requestNotifiicationPermissions() {
		MobileAppComms.postMessage(
			JSON.stringify({
				REQUEST_NOTIFICATION_PERMISSIONS: true
			})
		);
	}
	
	return loginReturn.loginRequired ? null : <>
		<Layout
			whitePage
			className="login-page"
			hideBackButton
			requiresCustomer={false}
			accountPicker={false}
			layout={BareLayout}
			noContainer={true}
		>
			<div className="image-wrapper" onLoad={requestNotifiicationPermissions}>
				<Image src={banner} alt="Aurora APAYG+ logo" />
			</div>

			<div className="login-wrapper">
				<Container>
					<LoggedOutHomeLayout>
						<p>Log in to aurora+</p>

						<Button
							fluid
							secondary
							size="large"
							style={{ fontWeight: '400' }}
							onClick={loginClick}
						>
							Log in
						</Button>

						<Link style={{color:"#0060ae"}}
							className="forgot-password"
							onClick={() => {
								auth.initiateForgotPassword()
							}}
						>Forgotten Password</Link>

					</LoggedOutHomeLayout>
				</Container>
			</div>

			<div className="signup-wrapper">
				<Container>
					<LoggedOutHomeLayout>
						<p>Don’t have aurora+ yet?<br />Sign up here</p>
						<Button
							fluid
							inverted
							size="large"
							onClick={() => {
								Analytics.event({ category: "Signup", action: "Start" });
								navigate('/signup')
							}}
						>
							Sign me up
						</Button>
					</LoggedOutHomeLayout>
				</Container>

				<div className="logo">
					<Image src={logo} alt="Aurora Energy logo" />
				</div>

			</div>


		</Layout>
	</>
})