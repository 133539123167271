import { createContext, useContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { loginRequest, msalConfig, signUpSignInAcr } from '../AuthConfig';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

const initialState = {
  remember: false,
  msalToken: '',
  msalForgotPassword: false,
  msalCancelled: false,
  msalLoggedOut: false,
  msalResetComplete: false,
  msalMaxRetries: false,
  msalError: null,
  accessToken: getAccessToken() || '',
  isAuthenticated: !!getAccessToken(),
  unauthenticate: false,
  clearToken: () => {},
  updateToken: () => {}
}






const AuthContext = createContext(initialState)

const authReducer = (state = initialState, payload) => {
  return { ...state, ...payload }
}

export function clearToken() {
  localStorage.removeItem('accessToken')
  sessionStorage.removeItem('accessToken')
  axios.defaults.headers.common['Authorization'] = '';
  delete axios.defaults.headers.common["Authorization"];
}

export function getAccessToken() {
  return localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
}

export const AuthProvider = ({ children, app }) => {
  const [state, setState] = useReducer(authReducer, initialState)

  const { instance: msalInstance } = useMsal();

  useEffect(() => {

	const callback = (event) => {
		console.log("msal event", JSON.stringify(event));

		switch(event.eventType) {
			case EventType.LOGIN_SUCCESS:
			case EventType.ACQUIRE_TOKEN_SUCCESS:

					
				if (event.payload.account) {
					//console.log("login success", event.payload.idToken, state);					
					const account = event.payload.account;
					//msalInstance.setActiveAccount(account);
			
					if (account.idTokenClaims.acr === signUpSignInAcr) {

						let token = event.payload.idToken;
						let remember = account.idTokenClaims.kmsi; // keep me signed in						
				
						setState({
							msalToken: token,
							remember: remember,
						});

					} else {
						// Another flow eg. forgot password has completed. Direct to login
						setState({
							msalResetComplete: true,
						});
					}
				}

				break;			
			
			case EventType.LOGOUT_SUCCESS:
				//console.log("logout success");
				setState({
					msalToken: '',
					msalLoggedOut: true,
				});
				break;


			case EventType.LOGIN_FAILURE:

				if (event.error.errorMessage.indexOf("AADB2C90118") >= 0) {
					setState({ msalForgotPassword: true });
				} else if (event.error.errorMessage.indexOf("AADB2C90091") >= 0) {
					setState({ msalCancelled: true });
				} else if (event.error.errorMessage.indexOf("AADB2C90157") >= 0) {
					setState({ msalMaxRetries: true });
				} else {
					console.log("Unhandled MSAL error", event.error.errorMessage, event.error);
					setState({ msalError: event.error.errorMessage });
				}
				
				break;
		}

	}

	// Listen for sign-in event and set active account
	msalInstance.addEventCallback(callback);			
	return () => {
		msalInstance.removeEventCallback(callback);			
	}
	
  }, [msalInstance]);

  function clearMsalFlags() {
	setState({
		msalForgotPassword: false,
		msalCancelled: false,
		msalLoggedOut: false,
		msalResetComplete: false,
		msalMaxRetries: false,
		msalError: null,
	})
  }


  // Called by the login button. Redirects to MS to do the actual log in
  function initiateLogin() {		
	msalInstance.loginRedirect(loginRequest).catch((error) => console.log(error));
  }

  // Called by the redirect page if the login flow fails with a forgot password code
  function initiateForgotPassword() {		
	msalInstance.loginRedirect(msalConfig.authorities.forgotPassword);
  }

  // Called hby the ResetMfaPhone page when landed on
  function initiateResetMfaPhone() {		
	msalInstance.loginRedirect(msalConfig.authorities.resetMfaPhone);
  }

  // Upon receiving a valid token from the server, store it for future requests
  function updateToken(accessToken, remember, doMsalLogout = true) {
	clearToken(doMsalLogout);

	remember
		? localStorage.setItem('accessToken', accessToken)
		: sessionStorage.setItem('accessToken', accessToken)
	axios.defaults.headers.common['Authorization'] = accessToken

	setState({
		accessToken,
		isAuthenticated: accessToken ? accessToken.length > 0 : false
	})
  }

  // Upon logout or error, clear the token so it cannot be used for further requests
  function clearToken(doMsalLogout = true) {
	//let msalAccount = msalInstance.getActiveAccount() || (msalInstance.getAllAccounts().length > 0 ? msalInstance.getAllAccounts()[0] : null);
	localStorage.removeItem('accessToken')
	sessionStorage.removeItem('accessToken')
	axios.defaults.headers.common['Authorization'] = ''
  
	setState({
		msalToken: '',
		accessToken: '',
		isAuthenticated: false,
	})
	
	if (doMsalLogout) {
		msalInstance.logoutRedirect().then(() => {
			//console.log("logoutRedirect promise");
		});
	}
  }


  const auth = {
	...state,
	initiateLogin,
	initiateForgotPassword,
	initiateResetMfaPhone,
	clearMsalFlags,
	clearToken,
    updateToken,
  }

  return (
	<AuthContext.Provider value={auth}>
		{children}
	</AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export function withAuth(Component) {
	return function WrappedComponent(props) {
	  return (
		<AuthContext.Consumer>
		  {auth => <Component {...props} auth={auth}/>}
		</AuthContext.Consumer>
	  )
	}
  }
  