import moment from 'moment-timezone';
import { TARIFFS } from '../utils/Tariffs';

export default class PredictBill {

    constructor(data, customerPremise) {

        var totals = data.SummaryTotals.DollarValueUsage;
        var importSoFar = 0;
        var solarSoFar = 0;
        for (var key in totals) {					
            const value = totals[key];
            var tariff = TARIFFS[key];
            if (tariff) {
                if (tariff.isSolar) solarSoFar += value;
                else importSoFar += value;
            } else if (key == "Other") {
                importSoFar += value;
            }
        }

        
        var periodStart = moment(data.StartDate);
        var periodEnd = moment(data.EndDate);
        var today = moment().startOf("day");

        var totalDays = periodEnd.diff(periodStart, "days");
        var daysRemaining = periodEnd.diff(today, "days");
        var daysSoFar = totalDays - Math.max(0, daysRemaining); // Days remaining can be negative if the period has finished

        var dailyImport = importSoFar / daysSoFar;
        var predictedImport = dailyImport * totalDays;
        var dailySolar = solarSoFar / daysSoFar;
        var predictedSolar = dailySolar * totalDays;
        
        var chargesSoFar = importSoFar + solarSoFar;
        var dailyCost = dailyImport + dailySolar;
        var predictedAmount = predictedImport + predictedSolar


        this.noData = data.NoDataFlag;
        this.startDate = periodStart;
        this.billTo = customerPremise.billTo;
        this.billNextTo = customerPremise.billNextTo;
        this.billNextRun = customerPremise.billNextRun;
        this.InvoiceID = customerPremise.InvoiceID;        
        this.totalDays = totalDays;
        this.daysRemaining = daysRemaining;
        this.chargesSoFar = chargesSoFar;
        this.solarSoFar = solarSoFar;
        this.dailyCost = dailyCost;
        this.predictedAmount = predictedAmount;
        this.predictedSolar = predictedSolar;
		this.tariffs = data.TariffTypes;

    }

}
