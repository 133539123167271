export default class Device {

  static isMobileDevice = () => {
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  static isAndroidDevice = () => {
    return /Android/i.test(navigator.userAgent);
  }

  static isAppleDevice = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

}
