import React from 'react';
import { Table } from 'semantic-ui-react';

const OtherChargesTooltip = props => {

  const { data } = props;

  if (!data.NonMeteredUsageRecords)
    return;

  const otherItems = data
    .NonMeteredUsageRecords
    .map((i, d) => {
      const absDollar = Math
        .abs(i.DollarAmount)
        .toLocaleString('en-AU', {
          currency: 'AUD',
          minimumFractionDigits: 2
        });
      const isNegative = i.DollarAmount < 0
        ? '-'
        : '';
      const dollarDisplay = isNegative + ' $' + Number(absDollar).toFixed(2);

      return (
        <Table.Row key={"other-charge" + d}>
          <Table.Cell width={12}>{i.Description}</Table.Cell>
          <Table.Cell
            textAlign="right"
            style={{
            color: '#0060ae',
            whiteSpace: 'nowrap'
          }}>{dollarDisplay}</Table.Cell>
        </Table.Row>
      )
    });

  return (
    <Table basic unstackable className="other-charges-tooltip">
      <Table.Body>
        {otherItems}
      </Table.Body>
    </Table>
  );
}

export default OtherChargesTooltip;
