import React from 'react'
import axios from 'axios';
import Script from 'react-load-script'

import './WestpacPayment.css';

import { Message, Icon, Dimmer, Loader } from 'semantic-ui-react';

export default class WestpacPayment extends React.Component {

  trustedFrame
  state = {
    loading: true,
    loaded: false,
    error: false,
    endpoint: false
  }

  handleScriptCreate() {
    this.setState({ loading: true, loaded: false, error: false })
  }

  handleScriptError(message) {
    this.setState({ loading: false, loaded: false, error: message })

    if (this.props.onError) {
      this
        .props
        .onError(message)
    }
  }

  handleScriptLoad() {
    this.setState({ loaded: true, error: false })

    if (!window.QuickstreamAPI) {
      return this.handleScriptError('There was a problem loading the payment gateway. Please try again later.')
    }

    window
      .QuickstreamAPI
      .init({ publishableApiKey: this.state.endpoint.publishableKey });

    var inputStyle = {
      'font-family': "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      'padding': '0.25em 0.875em',
      'font-size': '18px',
      'height': 'auto',
      'border-radius': '8px',
      'color': 'rgba(0,0,0,.87)',
      'font-weight': 500
    }

    const { customerPremise, isFromHubCX } = this.props.customer;

    var options = {
      config: {
        // This is a required config option
        supplierBusinessCode: isFromHubCX
          ? this.state.endpoint.HubSupplierBusinessCode
          : this.state.endpoint.CCBSupplierBusinessCode
      },
      iframe: {
        width: '100%',
        height: '420px'
      },
      labels: {
        style: {
          'display': 'block',
          'margin': '0 0 .25rem 0',
          'color': 'rgba(0,0,0,.87)',
          'font-size': '15px',
          'font-weight': 'normal',
          'text-transform': 'none'
        }
      },
      cardholderName: {
        style: inputStyle
      },
      cardNumber: {
        label: (customerPremise && customerPremise.hasPrePayBilling) ? "Credit Card number" : "Card number",
        style: inputStyle
      },
      cvn: {
        style: inputStyle
      },
      expiryDateMonth: {
        style: inputStyle
      },
      expiryDateYear: {
        style: inputStyle
      }
    };

    window
      .QuickstreamAPI
      .creditCards
      .createTrustedFrame(options, (errors, data) => {
        if (errors) {
          return this.handleScriptError(errors[0])
        }

        this.trustedFrame = data.trustedFrame

        if (this.props.onChanged) {
          this
            .trustedFrame
            .setEventHandler('cardholderName', 'change', this.props.onChanged)
          this
            .trustedFrame
            .setEventHandler('cardNumber', 'change', this.props.onChanged)
          this
            .trustedFrame
            .setEventHandler('expiryDateMonth', 'change', this.props.onChanged)
          this
            .trustedFrame
            .setEventHandler('expiryDateYear', 'change', this.props.onChanged)
          this
            .trustedFrame
            .setEventHandler('cvn', 'change', this.props.onChanged)
        }

        if (this.props.onFocused) {
          this
            .trustedFrame
            .setEventHandler('cardholderName', 'focus', this.props.onFocused)
          this
            .trustedFrame
            .setEventHandler('cardNumber', 'focus', this.props.onFocused)
          this
            .trustedFrame
            .setEventHandler('expiryDateMonth', 'focus', this.props.onFocused)
          this
            .trustedFrame
            .setEventHandler('expiryDateYear', 'focus', this.props.onFocused)
          this
            .trustedFrame
            .setEventHandler('cvn', 'focus', this.props.onFocused)
        }

        this.setState({ loading: false })
      });
  }

  componentWillUnmount() {
    if (this.trustedFrame) {
      this.trustedFrame.teardown()
    }
  }

  submit(callback) {
    if (this.trustedFrame) {
      this.trustedFrame.submitForm(callback);
    }
  }

  async componentDidMount() {

    try {
      let endpoint = await axios.get(process.env.REACT_APP_API_BASE_URI + 'payment/endpoint')

      this.setState({ endpoint: endpoint.data })
    } catch (e) {
      this.handleScriptError('There was a problem loading the payment gateway. Please try again later')
    }
  }

  render() {

    if (this.state.error) {
      return (
        <Message icon negative attached="bottom">
          <Icon name="exclamation triangle" />
          <Message.Content>

            <Message.Header>An error occured.</Message.Header>
            {this.state.error}
          </Message.Content>
        </Message>
      )
    }

    return (
      <div>
        <Dimmer inverted active={this.state.loading}>
          <Loader />
        </Dimmer>

        {this.state.endpoint &&
          <Script
            url={this.state.endpoint.url + 'quickstream-api-1.0.min.js'}
            onCreate={this.handleScriptCreate.bind(this)}
            onError={this.handleScriptError.bind(this)}
            onLoad={this.handleScriptLoad.bind(this)} />
        }

        <div className="frame-container" data-quickstream-api="creditCardContainer"></div>

        {this.props.children}
      </div>
    )
  }

}
