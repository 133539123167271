import React from "react";
import axios from "axios";
import qs from "query-string";
import { Image, Dimmer, Loader } from "semantic-ui-react";

import Analytics from "../../utils/Analytics";
import { withRouter } from '../../utils/useRouter';
import { withCustomer } from "../../contexts/Customer";
import logo from "../../static/logo.svg";
import "./SamlSSO.css";

const { REACT_APP_API_BASE_URI } = process.env;

const STATUS = {
  DEFAULT: "default",
  AUTHENTICATED: "authorized",
  SUCCEED: "succeed",
  FAILED: "failed"
};

const STATUS_TEXT = {
  DEFAULT: "Redirecting you to aurora+ insights.",
  FAILED: errorMsg => `Something went wrong\n${errorMsg}`
};

class SamlSSO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      premise: null,
      status: STATUS.DEFAULT,
      statusText: STATUS_TEXT.DEFAULT,
      samlRequest: null,
      relayState: null,
      samlResponse: null,
      endpoint: null
    };
    this.authenticate = this.authenticate.bind(this);
    this.getSamlResponse = this.getSamlResponse.bind(this);
    this.postSamlResponse = this.postSamlResponse.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const queryString = qs.parse(location.search);
    const samlRequest = queryString.SAMLRequest;
    const relayState = queryString.RelayState;

    // If SAMLRequest is not present in the url, throw error message
    if (!samlRequest) {
      this.setState({
        status: STATUS.FAILED,
        statusText: STATUS_TEXT.FAILED("We cannot locate the query string")
      });

      Analytics.event({
        category: "Bidgely SSO",
        action: "SAML request is not present"
      });

      return;
    }

    // Save request details
    this.setState({ samlRequest, relayState });
  }

  componentDidUpdate(_, prevState) {
    const { status, isLoading } = this.state;

    if (isLoading) return;

    switch (status) {
      case STATUS.FAILED:
        break;
      case STATUS.DEFAULT:
        this.authenticate();
        break;
      case STATUS.AUTHENTICATED:
        this.getSamlResponse();
        break;
      case STATUS.SUCCEED:
        this.postSamlResponse();
        break;
      default:
        console.log("Undefined status: ", status);
        break;
    }
  }

  authenticate = () => {
    const { hasLoaded, customerPremise } = this.props.customer;

    // Wait for the app to load
    if (!hasLoaded) return;

    this.setState({ isLoading: true });

    // If the app has loaded but there's no valid premise, throw auth error and redirect to login
    if (!customerPremise) {
      this.setState({
        status: STATUS.FAILED,
        statusText:
          "Authentication failed\nWe're redirecting you to the login page",
        isLoading: false
      });

      Analytics.event({
        category: "Bidgely SSO",
        action: "User authentication failed"
      });

      setTimeout(() => {
        this.props.router.navigate("/login");
      }, 3000);
      return;
    }

    this.setState({
      status: STATUS.AUTHENTICATED,
      isLoading: false,
      premise: customerPremise
    });
  };

  // get SAML response from Aurora+ backend
  getSamlResponse = () => {
    const {
      premise: { parentAccountID: accountId, identifier: premiseId },
      samlRequest
    } = this.state;

    this.setState({ isLoading: true });

    axios
      .get(REACT_APP_API_BASE_URI + "identity/GetBidgelyDetails")
      .then(res => {
        if (res.data) {
          this.setState({
            endpoint: res.data.SpEndpoint
          });

          return axios.get(
            REACT_APP_API_BASE_URI +
              `identity/SAMLLogin?samlRequest=${encodeURIComponent(
                decodeURIComponent(samlRequest)
              )}&accountId=${encodeURIComponent(
                accountId
              )}&premiseId=${encodeURIComponent(premiseId)}`
          );
        }
      })
      .then(res => {
        if (res.data) {
          this.setState({
            status: STATUS.SUCCEED,
            samlResponse: res.data,
            isLoading: false
          });

          Analytics.event({
            category: "Bidgely SSO",
            action: "SAML response ready"
          });
        }
      })
      .catch(err => {
        this.setState({
          status: STATUS.FAILED,
          statusText: STATUS_TEXT.FAILED(err),
          isLoading: false
        });

        Analytics.event({
          category: "Bidgely SSO",
          action: "SAML response error"
        });
      });
  };

  // Post SAML response to Bidgely ACS endpoint
  postSamlResponse = () => {
    document.getElementById("saml-form").submit();
  };

  render() {
    const {
      statusText,
      isLoading,
      samlResponse,
      relayState,
      endpoint
    } = this.state;

    return (
      <Dimmer className="page-samlsso" active page>
        <Image src={logo} alt="Aurora APAYG+ logo" />
        <h3 className="text">{statusText}</h3>
        {isLoading && <Loader inverted size="medium" inline />}
        <form action={endpoint} method="post" id="saml-form">
          <input type="hidden" name="SAMLResponse" value={samlResponse} />
          <input type="hidden" name="RelayState" value={relayState} />
        </form>
      </Dimmer>
    );
  }
}

export default withRouter(withCustomer(SamlSSO));
