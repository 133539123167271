import React from 'react';
import {Icon, Visibility} from 'semantic-ui-react'
import {Gesture} from 'react-with-gesture';
import {Spring, animated} from 'react-spring';

class PullToRefresh extends React.Component {
  static defaultProps = {
    refreshThreshold: 40,
    maxThreshold: 100
  }
  state = {
    paused: false,
    isRefreshing: false
  }

  handleUp = (props) => {
    if (this.calculateY(props.yDelta) >= this.props.refreshThreshold) {
      this.props.onRefresh && this
        .props
        .onRefresh(props)
    }

    return props
  }

  handleVisibilityUpdate = (e, {calculations}) => {
    this.setState({
      paused: !calculations.topVisible
    })
  }

  //simulate spring like pulldown
  calculateY = y => {
    return y - (y / 1.5);
  }

  render() {
    let {refreshThreshold, maxThreshold, children, isRefreshing} = this.props;

    return (this.props.active
      ? <Gesture onUp={this.handleUp} mouse={false}>
          {({
            down,
            x,
            y,
            xDelta,
            yDelta,
            xInitial,
            yInitial
          }) => <Visibility onUpdate={this.handleVisibilityUpdate}>
            <Spring
              native
              to={{
              y: down
                ? yDelta
                : 0
            }}
              immediate={name => down && name === 'y'}>
              {({y}) => (
                <React.Fragment>
                  {(isRefreshing || (!this.state.paused && down && yDelta !== 0 && yDelta > refreshThreshold)) && <Spring
                    from={{
                    opacity: 0
                  }}
                    to={{
                    opacity: isRefreshing
                      ? 1
                      : (yDelta / maxThreshold)
                  }}>
                    {props => (
                      <div
                        style={{
                        position: 'absolute',
                        width: '100%',
                        marginTop: '1em',
                        left: 0,
                        height: maxThreshold + 'px',
                        zIndex: '-1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        fontSize: '1.125rem',
                        color: 'white',
                        ...props
                      }}>
                        <Icon name="refresh" size="big" loading/> {!isRefreshing && <p>Release to refresh</p>}
                      </div>
                    )}
                  </Spring>
}
                  <animated.div
                    style={this.state.paused
                    ? undefined
                    : {
                      transform: y.to(y => `translate3d(0, ${Math.max(0, isRefreshing
                        ? 80
                        : this.calculateY(y))}px,0)`)
                    }}>
                    {children}
                  </animated.div>
                </React.Fragment>
              )}
            </Spring>
          </Visibility>
}
        </Gesture>
      : children)
  }
}

export default PullToRefresh
