import SignupSearchIcon from '../components/Icons/signup/SignupSearchIcon';
import SignupVerifyIcon from '../components/Icons/signup/SignupVerifyIcon';
import SignupEmailIcon from '../components/Icons/signup/SignupEmailIcon';
import SignupResidentialIcon from '../components/Icons/signup/SignupResidentialIcon';
import SignupNewConnectionIcon from '../components/Icons/signup/SignupNewConnectionIcon';

export default {

	'signup': {
		steps: [
			{
				icon: SignupSearchIcon,
				short: "1.",
				title: '1. Find your account',
				text: 'You will need an existing Aurora Energy account'
			  },
			  {
				icon: SignupVerifyIcon,
				short: "2.",
				title: '2. Verify your identity',
				text: 'You will need access to your email or mobile phone'
			  },
			  {
				icon: SignupEmailIcon,
				short: "3.",
				title: '3. Confirm your email address',
				text: 'You will need access to your email'
			  },
			  {
				icon: SignupResidentialIcon,
				short: "4.",
				title: '4. Select your property',
				text: 'You’ll need to select the property you want connected'
			  },
			  {
				icon: SignupNewConnectionIcon,
				short: "5.",
				title: '5. Connect you to aurora+',
				text: 'We’ll give you an estimate on a target date for completion'
			  }
		],
	}


}