import './SignupEstimation.css';

export default ({ premises }) => {

	if (!premises) return null;

	const { progress, date } = premises.signupEstimate;

	return <div className="signup-estimation">
		<p>We are creating your aurora+ account</p>
		<div className="signup-estimation-progress-bar">
			<div
				style={{
					width: (5 + progress * 95) + "%",
				}}
			/>
		</div>
		<p>Estimated completion date:</p>
		<p className="signup-estimation-date">{date.format("D MMMM YYYY")}</p>
	</div>


}