import { Button, Modal } from "semantic-ui-react";
import { withApp } from "../../contexts/App"
import { useEffect, useMemo, useState } from "react";
import "./SplashScreenModal.css";
import ExternalLink from "../ExternalLink";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { DATE_FORMAT } from "../../model/SplashScreen";
import { withCustomer } from "../../contexts/Customer";
import Analytics from "../../utils/Analytics";

export default withCustomer(withApp(({ app, customer: customerWrapper }) => {

	const { customer, powerHours, customers } = customerWrapper

	const navigate = useNavigate();

	const { splashScreens: unfilteredSplashScreens } = app;
	const [status, setStatus] = useState(JSON.parse(window.localStorage.getItem("splashScreenStatus")) || { })

	const [totalToShow, setTotalToShow] = useState(0);
	const [numClosed, setNumClosed] = useState(0);

	const customerId = customer && customer.customerID;


	useEffect(() => {
		if (status) {
			window.localStorage.setItem("splashScreenStatus", JSON.stringify(status));
		}
	}, [status]);

	const customersToCheck = [...customers].sort((a, b) => {
		if (a.CustomerID === customerId) return -1;
		if (b.CustomerID === customerId) return 1;
		return 0;
	})

	const splashScreens = useMemo(() => {		
		let matchedCustomerIds = { }

		return unfilteredSplashScreens.filter(splashScreen => {
			if (splashScreen.filter) {

				let strippedFilter = splashScreen.filter.replace(/[^\w\d,]/ig, '').toLowerCase();
				let filterParts = strippedFilter.split(",");

				customersToCheck.forEach(c => {

					if (matchedCustomerIds[splashScreen.id]) return;

					for (var i = 0; i < filterParts.length; i++) {
						let filterPart = filterParts[i];

						switch(filterPart) {
							case "resi":
							case "residential":
								if (c.CustomerType !== "Residential") return;
								break;
							case "business":
								if (c.CustomerType !== "Business") return;
								break;
							case "solar":
								if (!c.HasSolarProduct) return;
								break;
							case "powerhours":
								// Must be residential	
								// Must be from HubCX
								// Must be a currently active power hours invitation that has not been accepted
								if (c.CustomerType !== "Residential") return;
								if (c.IsFromCCB) return;
								if (powerHours) {
									let hasInvitation = false;
									powerHours.forEach(event => {			
										if (event.isValid) {
											if (event.isInvitation) {
												if (!event.invitationHasExpired) {
													hasInvitation = true;
												}
											}
										}
									});
									if (!hasInvitation) return;
								} else {
									return;
								}
								break;
						}
					}

					matchedCustomerIds[splashScreen.id] = c.CustomerID;

				})

				return !!matchedCustomerIds[splashScreen.id];

			} else {
				return true;
			}

		}).sort((a, b) => {
			// Oldest (end of the list) are displayed first, so send other customer matches to the front
			var aIsCurrentCustomer = matchedCustomerIds[a.id] === customerId || !!matchedCustomerIds[a.id]
			var bIsCurrentCustomer = matchedCustomerIds[b.id] === customerId || !!matchedCustomerIds[b.id]
			if (aIsCurrentCustomer == bIsCurrentCustomer) return 0;
			if (aIsCurrentCustomer) return 1;
			if (bIsCurrentCustomer) return -1;
			return 0;
		});
	}, [unfilteredSplashScreens, status, customerId, customersToCheck]);



	const splashScreensToShow = useMemo(() => {

		if (!splashScreens || !status || !customerId) return null;

		const customerStatus = status[customerId] || { };

		const toShow = [];

		splashScreens.forEach(splashScreen => {
			
			let show = true;

			if (!splashScreen.isCurrent) show = false;

			let screenStatus = customerStatus[splashScreen.id];
			if (screenStatus) {

				if (screenStatus.dismissed) {
					show = false;
				} else if (screenStatus.remind) {
					const now = new moment();
					const remind = new moment(screenStatus.remind, DATE_FORMAT);
					if (remind.isAfter(now)) show = false;
				}

			}

			if (show) toShow.unshift(splashScreen);
			
		});

		return toShow;

	}, [splashScreens, status, customerId])

	useEffect(() => {

		if (!splashScreens || !status || !customerId) return;

		const customerStatus = status[customerId] || { };

		const presentIds = [];
		splashScreens.forEach(splashScreen => {
			presentIds.push(splashScreen.id);
		});

		const newCustomerStatus = { ...customerStatus };
		let statusChanged = false;
		for (var key in customerStatus) {
			if (presentIds.indexOf(key) === -1) {
				newCustomerStatus[key] = undefined;
				delete newCustomerStatus[key];
				statusChanged = true;
			}
		}

		if (statusChanged) {
			const newStatus = { ...status }
			newStatus[customerId] = newCustomerStatus,
			setStatus(newStatus);
		}

	}, [splashScreens, status, customerId])
	


	useEffect(() => {

		if (splashScreensToShow && (splashScreensToShow.length > totalToShow)) setTotalToShow(splashScreensToShow.length);

	}, [totalToShow, splashScreensToShow && splashScreensToShow.length]);


	if (!splashScreensToShow || splashScreensToShow.length === 0) return null;

	// Go oldest first
	const current = splashScreensToShow[splashScreensToShow.length - 1];


	function dismissSplashScreen(splashScreen) {
		const newStatus = { ...status };
		if (!newStatus[customerId]) newStatus[customerId] = { }
		newStatus[customerId][splashScreen.id] = {
			dismissed: true,
		}
		setStatus(newStatus);
		setNumClosed(numClosed + 1);
	}
	function remindSplashScreen(splashScreen) {
		const newStatus = { ...status };
		if (!newStatus[customerId]) newStatus[customerId] = { }
		const midnight = (new moment()).endOf('day');
		newStatus[customerId][splashScreen.id] = {
			remind: midnight.format(DATE_FORMAT),
		}
		setStatus(newStatus);
		setNumClosed(numClosed + 1);
	}


	function handleRemindClick() {
		remindSplashScreen(current);
	}

	function handleConfirmClick() {

		// Analytics for different filters
		if (current.filter) {
			switch(current.filter.replace(/ /ig, '').toLowerCase()) {
				case "powerhours":
					Analytics.event({
						category: "Power Hours",
						action: "Splash Screen",
					});																						
					break;
			}
		}


		dismissSplashScreen(current);

		switch(current.confirmAction) {
			case "deeplink":
				setTimeout(() => {
					navigate(current.confirmDeepLink);
				}, 0);
				break;
		}
	}

	const canRemind = true;


	return (
		<Modal 
			className={`splash-screen-modal template-${current.template}`}
			name="home/splash-screens"
			size="mini"
			open={true}
		>
			<Modal.Content>
				
				<div className="splash-screen-modal-icon">
					<img src={ process.env.REACT_APP_AURORA_WEBSITE + current.icon } />
				</div>
				<h2>{ current.title }</h2>

				<Modal.Description style={{textAlign: 'left'}}>

					{ current.text && current.text.split("<br />").map(((line, index) => <p key={index}>{line}</p>)) }

					{ current.linkUrl && (
						<ExternalLink href={current.linkUrl}>{current.linkLabel}</ExternalLink>
					)}
					
				</Modal.Description>


			</Modal.Content>

			<Modal.Actions>
				<Button
					secondary
					fluid
					onClick={handleConfirmClick}
				>{ current.confirmText || "Got it" }</Button>
				{ canRemind && 
					<a
						className="splash-screen-remind-me"
						href="#"
						onClick={handleRemindClick}
					>Remind me later</a>
				}
			</Modal.Actions>
			
			<div className="splash-screen-viewed-count">{(numClosed + 1)} of {totalToShow}</div>

		</Modal>
	)

}));