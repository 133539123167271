import _ from 'lodash';
import moment from 'moment';
import Bill from './Bill';


export default class BillingDetails {

  constructor(data, isFromCCB = false) {
    this.bills = _.map(data, (val) => new Bill(val));

    // we need to know if the billing details are coming from a particular system,
    // as the bills come back differently for CCB versus Hub. CCB cannot reconcile
    // bill amounts to specific bills in a timely manner, so amountOutstanding = null
    this.isFromCCB = isFromCCB; 
  }

  get totalAmountDue() {
    return _.sumBy(this.bills, (val) => (val.isValid && val.amountOutstanding) ? val.amountOutstanding : 0);
  }

  get outStanding() {
    return _.filter(this.bills, (val) => val.isValid && (this.isFromCCB || val.amountOutstanding > 0));
  }

  get overdue() {
    return _.filter(this.outStanding, (val) => val.isOverdue);
  }

  get due() {
    return _.filter(this.outStanding, (val) => !val.isOverdue);
  }

  static sortByDate(arrayOfBills) {
    if (!arrayOfBills) return null;

    // note: array must be of type: Bills
    arrayOfBills.sort((a, b) => moment(a.dateDue) - moment(b.dateDue));

    return arrayOfBills;
  }
}
