import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 25 24"
      
      xmlns="http://www.w3.org/2000/svg">
	  <path
	  	d="M12.4171 20.8847C14.8788 20.8847 17.2398 19.9068 18.9805 18.1661C20.7213 16.4253 21.6992 14.0644 21.6992 11.6026C21.6992 9.14086 20.7213 6.77992 18.9805 5.03919C17.2398 3.29846 14.8788 2.32053 12.4171 2.32053C9.95531 2.32053 7.59438 3.29846 5.85364 5.03919C4.11291 6.77992 3.13498 9.14086 3.13498 11.6026C3.13498 14.0644 4.11291 16.4253 5.85364 18.1661C7.59438 19.9068 9.95531 20.8847 12.4171 20.8847ZM12.4171 0C13.9408 0 15.4495 0.300111 16.8572 0.883198C18.2649 1.46628 19.544 2.32093 20.6214 3.39833C21.6988 4.47573 22.5534 5.7548 23.1365 7.16249C23.7196 8.57019 24.0197 10.0789 24.0197 11.6026C24.0197 14.6798 22.7973 17.631 20.6214 19.8069C18.4455 21.9828 15.4943 23.2053 12.4171 23.2053C6.00083 23.2053 0.814453 17.9841 0.814453 11.6026C0.814453 8.52542 2.03687 5.57425 4.21278 3.39833C6.3887 1.22242 9.33987 0 12.4171 0ZM12.9972 5.80131V11.8927L18.2184 14.9906L17.3482 16.4177L11.2568 12.7629V5.80131H12.9972Z"
		fill={color}
	  />
    </svg>
)
}
