import './IconSteps.css';

export default ({ children, separators, fullWidth, ...props }) => {
	return <div
		className={
			"icon-steps"
			+ (separators ? " separators" : "")
			+ (fullWidth ? " full-width" : "")
		}
		{...props}
	>
		{children}
	</div>
}