//utility class for WebView -> Mobile App communications
class MobileAppComms {

  /*
   * provides a method to call window.postMessage correctly
   * react-native-webview v6 requires window.ReactNativeWebView.postMessage(data) to be
   * used instead of window.postMessage
   *
   * As MobileApp versions will differ, this will allow backwards compatability across all
   * versions of the PAYG+ mobile app
   */
  static postMessage(data) {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.postMessage(data, '*');
    }
  }
}

export default MobileAppComms;
