import React from 'react';

export default({
    color = 'currentColor',
    height = '24px',
    width = height,
    style,
    onClick,
    ...rest
}) => {
    style = {
        ...style,
        cursor: 'pointer'
    }
    return (
        <svg
            onClick={e => {
            onClick && onClick(e);
        }}
            height={height}
            width={width}
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            {...rest}>
			<g
                id="Screens-v2"
                stroke="none"
                strokeWidth="1"
                fill={color}
                fillRule="nonzero">
				<g
					fill={color}
					id="info-icon"
					transform="translate(8, 8) rotate(45)">
					<g id="Group">
						<rect
							id="Rectangle-path"
							fillRule="nonzero"
							x="-0.63"
							y="-10"
							width="1.26"
							height="20"></rect>
						<rect
							id="Rectangle-path"
							fillRule="nonzero"
							x="-10"
							y="-0.63"
							width="20"
							height="1.26"></rect>
					</g>
				</g>
			</g>
        </svg>
    )
}
