import React from 'react';
import { Header, Button } from "semantic-ui-react";


export const LandingPage = props => {
  const { theme, onNewPaymentPlan, children } = props;

  return (
    <React.Fragment>
      {children}

      <span className='divider' />

      <div className="payment-help">
        <Header size='large'>Need longer?</Header>
        <p style={{ marginBottom: '1.5em' }}>If two weeks isn’t quite enough we can help you with a long term <b>payment plan</b>.</p>
        <Button
          secondary
          onClick={() => onNewPaymentPlan()}
        >Apply for payment plan</Button>
      </div>

    </React.Fragment>
  );
}

export const RequestHelp = props => {
  const { theme, onExtensionRequest, disabled } = props;

  return (
    <div className="payment-help">
      <Header size='large'>Would an extra couple of weeks help?</Header>
      <p style={{ marginBottom: '1.5em' }}>
        You can request a short term payment extension to help you stay on top of your usage.
      </p>
      <Button
        onClick={() => onExtensionRequest()}
        secondary
        disabled={disabled}
      >Request payment extension</Button>
    </div>
  );
}

export const AlreadyHasHelp = props => {
  const { theme, history } = props;

  return (
    <div className="payment-help">
      <Header size='large'>You’ve already applied for an extension.</Header>
      <p style={{ marginBottom: '1.5em' }}>
        If the payment extension wasn’t quite long enough please get in touch with us to make other arrangements.
      </p>
      <Button
        secondary
        onClick={() => history.navigate('/contact/payments-help')}
      >Contact us</Button>
    </div>
  );
}
