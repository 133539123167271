import React, { useState } from 'react';

import Popup from 'rmc-picker/lib/Popup'
import Picker from 'rmc-picker/lib/Picker'

import { Button, Icon, Input, } from 'semantic-ui-react';

import 'rmc-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';

import Modal from '../Modal';
import DateContextHelper from '../../screens/Usage/DateContextHelper';
import UsageDataHelper from '../../screens/Usage/UsageDataHelper';
import Responsive from '../Responsive/Responsive';
import moment from 'moment-timezone';




const generateDatePickerContent = ({ data, timeRange, pickerValues, onValueChange, getClosestSelection }) => {
	
  const startDate = data && data.StartDate;
  const endDate = data && data.EndDate;
  const startYear = startDate.getFullYear();

  const title = 'Select Day';
  const options = ['calculateDayOptions', 'calculateMonthOptions', 'calculateYearOptions'];

  let selectedValue = []

  const pickers = options.map((fn, i) => ( //APAYGDEV-360, onWheel and "multipicker" weren't playing nice together, so something else needed to be worked out
    <div
	  key={fn}
	  onWheel={(e) => {
        const newSelection = getClosestSelection(pickerValues);        
        var d = new Date();

        if(e.deltaY < 0) { // Scroll down the dates
          if(pickerValues[2] == startYear && i == 2) { //don't let us scroll before startYear
            return startYear;
          } else {
            newSelection[i]--;
          }
        } else if (e.deltaY > 0) { // scroll up the dates
          if(pickerValues[2] == d.getFullYear() && i == 2) { //don't let us scroll beyond the current year
            return d.getFullYear();
          } else {
            newSelection[i]++;
          }
        }

        onValueChange(getClosestSelection(newSelection))
      }
    } className="rmc-picker">
      
      <Picker
	  	key={i}
		selectedValue={pickerValues[i]}
		onValueChange={(v) => {
			const newSelection = getClosestSelection(pickerValues);
			newSelection[i] = v;
			onValueChange(newSelection)
        }}
	  >

        {DateContextHelper[fn](pickerValues, timeRange, startDate, endDate).map((opt, i2) => {
          if (opt.selected) {
            selectedValue[i] = opt.value
          }

          return <Picker.Item
            key={i2}
            value={opt.value}
            style={opt.header
            ? {
              float: 'left',
              textAlign: 'left',
              fontWeight: 'bold',
              width: 'auto',
              display: 'inline-block'
            }
            : {}}>{opt.text}</Picker.Item>
        })}
      </Picker>
      
      </div>
  ))

  var pickersDivStyle = //Since we aren't using the "multipicker" for desktop, we need to fix the styling of the 3 individual pickers
  {
    display: 'flex'
  };

  const picker = (
    <div style={pickersDivStyle}>
      {pickers}
    </div>
  )

  return {title, picker}
}

const MobileDatePicker = props => {
  const { children, content, title, onOk, data, color } = props;

  return (
    <Popup content={content} title={title} onOk={onOk}>
		{ children }
    </Popup>
  )
}

const DesktopDatePicker = props => {
  const { children, content, title, onOk, toggleVisible, visible, data, color } = props;

  return (
    <React.Fragment>
      <Modal size="tiny" name={title} open={visible}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {content}
        </Modal.Content>
        <Modal.Actions>

          <Button
            color="grey"
            onClick={() => toggleVisible(false)}>Cancel</Button>
          <Button
            color="orange"
            onClick={() => {
            onOk();
            toggleVisible(false);
          }}>Ok</Button>
        </Modal.Actions>
      </Modal>
      
	  { children }
    </React.Fragment>
  )
}



const InputDatePicker = props => {
	const { color, date, initialDate, onChange, placeholder } = props;

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	let startDate = new Date("1900-01-01T14:00:00Z");
	let endDate = yesterday;
	let data = {
		StartDate: startDate,
		EndDate: endDate,
	};
	let timeRange = UsageDataHelper.DAY;

	if (!initialDate) initialDate = yesterday;
	let m = new moment(initialDate);
	const initialPickerValues = [m.date(), m.month(), m.year()];

	const [label, setLabel] = useState("");
	const [pickerVisible, setPickerVisible] = useState(false);
	const [pickerValues, setPickerValues] = useState(initialPickerValues);



	const getClosestSelection = (values) => {
		const newValues = [].concat(values);
	
		// Validate Month Selection
		const months = DateContextHelper.calculateMonthOptions(newValues, timeRange, startDate, endDate);
	
		// find the closest
		newValues[1] = months.reduce((prev, curr) => {
			return (Math.abs(curr.value - newValues[1]) < Math.abs(Math.abs(prev.value - newValues[1])) ) ? curr : prev;
		}, months[0]).value;
	
		// Validate Day Selection
		const days = DateContextHelper.calculateDayOptions(newValues, timeRange, startDate, endDate);
	
		// If the current day selection is not valid find the closest
		newValues[0] = days.reduce((prev, curr) => {
			return (Math.abs(curr.value - newValues[0]) < Math.abs(Math.abs(prev.value - newValues[0]))) ? curr : prev;
		}, days[0]).value;
	
		return newValues;
   }   
   
	const onValueChange = vals => {
		const newSelection = getClosestSelection(vals);
		setPickerValues(newSelection);
	};

	const { title, picker } = generateDatePickerContent({
		data,
		timeRange,
		pickerValues,
		onValueChange,
		getClosestSelection: (...args) => getClosestSelection(...args) //APAYGDEV-360
	});

	const onOk = () => {
		let d = new Date();
		d.setDate(1); // Get rid of any potential wrapping if today's date is higher than the days in selected month
		d.setYear(pickerValues[2]);
		d.setMonth(pickerValues[1]);
		d.setDate(pickerValues[0]);
		let m = new moment(d);
		let formatted = m.format("Do MMMM YYYY");
		setLabel(formatted);
		if (onChange) onChange(d);
	};
	
	const input = <Input
		readOnly
		fluid
		value={label}
		placeholder={placeholder}
		onFocus={() => {
			setPickerVisible(true);
		}}
		onClick={() => {
			setPickerVisible(true);
		}}
	/>

	return (
	  <>
		<Responsive lessThan="computer">
		  <MobileDatePicker
			title={title}
			content={picker}
			onOk={onOk}
			data={data}
			color={color}
		  >
			{ input }
		  </MobileDatePicker>
		</Responsive>
		<Responsive greaterThan="tablet">
		  <DesktopDatePicker
			title={title}
			content={picker}
			visible={pickerVisible}
			toggleVisible={vis => setPickerVisible(vis)}
			onOk={onOk}
			data={data}
			color={color}
		  >
			{ input }
		  </DesktopDatePicker>
		</Responsive>
	  </>
	);
};
  

export { DesktopDatePicker, MobileDatePicker, InputDatePicker, generateDatePickerContent };
