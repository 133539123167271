import React from "react";
import { Loader } from "semantic-ui-react";
import './IframeContainer.css';

class IframeContainer extends React.Component {

  state = {
	iframeLoading: true,
  };
	
  render() {	

	let {
		title,
		src
	} = this.props;

	if (!title) title = "iframe"

	return (
		<div className="iframe-container">
          	{ this.state.iframeLoading && <Loader active /> }
			<iframe
				title={title}
				src={src}
				onLoad={() => {this.setState({ iframeLoading: false })}}
			/>
		</div>			  
	  );
	}
}

export default IframeContainer;
