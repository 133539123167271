import moment from 'moment-timezone';

const diffTolerance = 2 * 60 * 1000; // 2 minutes in ms

export class PowerHoursEvent {

	constructor(data) {
		
		this.id = data.PowerHourEventId;
		this.name = data.EventName;

		// Expiry of the overall event should equal the last expiring timeslot
		this.expiry = data.OfferExpiryDateTime ? new moment(data.OfferExpiryDateTime) : null;

		this.acceptedTimeSlot = data.TimeslotAccepted && new PowerHoursTimeSlot(data.TimeslotAccepted);
		this.availableTimeSlots = data.TimeslotAll?.map(ts => new PowerHoursTimeSlot(ts)) || [];
		this.availableTimeSlots.sort((a, b) => {
			return a.startDateTime.valueOf() - b.startDateTime.valueOf();
		});
		this.startDateTime = data.StartDateTime ? new moment(data.StartDateTime) : null;

		if (data.Customer) {
			this.accountId = data.Customer.AccountId;
			this.nmi = data.Customer.Nmi;
			this.usage = data.Customer.Usage;
			this.cost = data.Customer.Cost;
			this.calculatedDateTime = data.Customer.CalculatedDateTime ? new moment(data.Customer.CalculatedDateTime) : null;
			this.acceptedDateTime = data.Customer.AcceptedDateTime ? new moment(data.Customer.AcceptedDateTime) : null;
			this.hasSubstitute = /[SEN]/.test(data.Customer.DataQuality || "");
			this.isFlagged = data.Customer.IsFlagged;
		}


		let now = getNowEpoch();		

		// Do some checks to validate and categorize
		this.isPreloaded = !!(this.startDateTime && (this.startDateTime > now)); // Should not appear yet
		this.invitationHasExpired = !!(this.expiry && (this.expiry <= now));
		this.isInvitation = !!(!this.acceptedTimeSlot && !this.acceptedDateTime && (this.availableTimeSlots.length > 0));
		this.isAccepted = !!(this.accountId && this.acceptedTimeSlot);// && this.acceptedDateTime); // acceptedDateTime not being populated properly?
		this.allTimeSlotsFinished = true;
		if (this.availableTimeSlots) {
			this.availableTimeSlots.forEach((ts) => {
				if (ts.endDateTime > now) this.allTimeSlotsFinished = false;
			});
		}
		this.isExpired = this.isInvitation && this.invitationHasExpired && !this.isAccepted;


		this.isValid = this.isExpired || !!(((this.isInvitation && !this.allTimeSlotsFinished) || this.isAccepted) && !this.isPreloaded);

		//console.log("Valid?", this.isValid, this.name, this.isInProgress(), this.hasFinished(), this.isPreloaded)

	}

	isInProgress() {
		let now = getNowEpoch();
		return !!(this.isAccepted && (this.acceptedTimeSlot.startDateTime <= now) && (now <= this.acceptedTimeSlot.endDateTime));
	}
	hasFinished() {
		let now = getNowEpoch();
		return !!(this.acceptedTimeSlot && this.acceptedTimeSlot.endDateTime < now);
	}


	renderCost() {
		if (!this.cost) return null;
		let cost = parseFloat(this.cost);
		if (isNaN(cost)) return null;

		return <span className="power-hours-cost">
			<span className="dollars">$</span>
			{cost.toFixed(2)}
		</span>

	}

	getFormattedExpiry() {
		return this.expiry ? this.expiry.format("D/MM/YYYY") : "";
	}
	
	getCondensedStartDate() {		
		return this.acceptedTimeSlot?.getCondensedStartDate() || "-"
	}
	getFormattedStartDate() {		
		return this.acceptedTimeSlot?.getFormattedStartDate() || "-"
	}
	getFormattedEndDate() {		
		return this.acceptedTimeSlot?.getFormattedEndDate() || "-"
	}

	getTimeRemaining() {
		return this.acceptedTimeSlot?.getTimeRemaining() || "-"
	}
	
	renderStartTime() {
		return this.acceptedTimeSlot?.renderStartTime() || "-"
	}
	renderEndTime() {
		return this.acceptedTimeSlot?.renderEndTime() || "-"
	}
	renderTime(dateTime) {
		return this.acceptedTimeSlot?.renderTime() || "-"
	}

	getLabel() {
		return this.acceptedTimeSlot?.getLabel() || "-";
	}
	hasTimePeriodLabel() {
		return this.acceptedTimeSlot?.hasTimePeriodLabel() || false;
	}
	renderDuration() {
		return this.acceptedTimeSlot?.renderDuration() || "-";
	}
	hasDuration() {
		return this.acceptedTimeSlot?.hasDuration() || false;
	}
	isMultiDay() {
		return this.acceptedTimeSlot?.isMultiDay() || false;
	}

}
 

export class PowerHoursTimeSlot {

	constructor(data) {
		this.id = data.PowerHourTimeSlotId;
		this.startDateTime = data.StartDateTime ? new moment(data.StartDateTime) : null;
		this.endDateTime = data.EndDateTime ? new moment(data.EndDateTime) : null;
		this.expiry = data.ExpiryDateTime ? new moment(data.ExpiryDateTime) : null;

		this.multiDay = this.startDateTime && this.endDateTime && (this.startDateTime.format("DD") !== this.endDateTime.format("DD"));


		var startsAtMidnight = dateIsCloseToMidnight(this.startDateTime);
		var endsAtMidnight = dateIsCloseToMidnight(this.endDateTime);
		var diff = this.endDateTime.diff(this.startDateTime);
		var days = Math.round(diff / (24 * 60 * 60 * 1000));
		var start = roundToMidnight(this.startDateTime)
		var end = roundToMidnight(this.endDateTime)
		if (startsAtMidnight && endsAtMidnight) {
			if (days > 20) {
				// Check if it fits nicely to a month
				var monthStart = moment(start).startOf('month');
				var monthEnd = moment(start).endOf('month');
				if (datesAreSimilar(start, monthStart) && datesAreSimilar(end, monthEnd)) {
					this.label = "All month during " + monthStart.format("MMMM") + " " + monthStart.format("YYYY");
					this.duration = "All month"
				}
			} else if (days === 7) {
				var lastDayOfWeek = moment(end).subtract(1, 'minutes');
				this.label = "All week (7 days) " + start.format("dddd D MMMM") + " - " + lastDayOfWeek.format("dddd D MMMM YYYY");
				this.duration = <>All week <span style={{ whiteSpace: "nowrap" }}>(7 days)</span></>
			} else if (days === 1) {
				this.label = "All day (24 hours) " + start.format("dddd D MMMM YYYY");
				this.duration = <>All day <span style={{ whiteSpace: "nowrap" }}>(24 hours)</span></>
			}
		}

		if (this.multiDay && !this.duration) {
			// DPS-1050 only assign a generic multi-day duration label if it's more than a day
			// This prevents time slots that end at midnight or shortly after from being considered multi-day
			var hours = Math.round(diff / (60 * 60 * 1000));
			if (hours >= 24) {
				this.duration = days + " day" + (days == 1 ? "" : "s") + ", " + start.format("dddd D MMMM YYYY");;
			}
		}

	}

	isMultiDay() {
		return !!this.multiDay;
	}
	hasTimePeriodLabel() {
		return !!this.label;
	}
	hasDuration() {
		return !!this.duration;
	}
	


	getLabel() {
		// If we have predetermined a label that matches a time period, use that
		if (this.label) return this.label;

		let start = this.startDateTime;
		let end = this.endDateTime;

		let startDate = this.getFormattedStartDate();
		let endDate = this.getFormattedEndDate();

		return start.format("h:mma") + " " + (startDate !== endDate ? startDate + " " : "") + " - " + end.format("h:mma") + " " + endDate;
	}
	renderDuration() {
		return this.duration
	}

	getCondensedStartDate() {		
		return this.startDateTime.format("ddd D MMM");
	}
	getFormattedStartDate() {		
		return this.startDateTime.format("dddd D MMMM YYYY");
	}
	getFormattedEndDate() {		
		return this.endDateTime.format("dddd D MMMM YYYY");
	}

	getTimeRemaining() {		
		let diff = this.endDateTime.diff(new moment());
		if (diff < 0) return "0:00:00";
		let t = Math.floor(diff / 1000);
		let seconds = ("" + (t % 60)).padStart(2, '0');
		t = Math.floor(t / 60);
		let minutes = ("" + (t % 60)).padStart(2, '0');
		t = Math.floor(t / 60);
		let hours = t;
		if (hours >= 24) {
			let days = Math.max(1, Math.floor(hours / 24));
			return days + " day" + (days == 1 ? "" : "s");
		} else {
			return hours + ":" + minutes + ":" + seconds;
		}
	}

	renderStartTime() {
		return this.renderTime(this.startDateTime);
	}
	renderEndTime() {
		return this.renderTime(this.endDateTime);
	}
	renderTime(dateTime) {
		return <span className="power-hours-time">
			{dateTime.format("h:mm")}
			<span className="period">{dateTime.format("A")}</span>
		</span>
	}



}



export const sortPowerHoursEvents = (events) => {
	
	let sorted = events.concat();
	sorted.sort((a, b) => {
		return b.startDateTime.valueOf() - a.startDateTime.valueOf();
	});

	let inProgressEvents = [];
	let invitations = [];
	let acceptedEvents = [];
	let historicEvents = [];

	let expiredAdded = false;

	sorted.forEach((event) => {
		if (!event.isValid) return;

		if (event.isExpired) {
			// Only show a single expired card
			if (!expiredAdded) {
				historicEvents.push(event);
				expiredAdded = true;
			}
		} else if (event.isInvitation) {
			if (!event.invitationHasExpired) invitations.push(event);
		} else if (event.hasFinished()) {
			historicEvents.push(event);
		} else if (event.isInProgress()) {
			inProgressEvents.push(event);
		} else {
			acceptedEvents.push(event);
		}
	})

	return [].concat(inProgressEvents, invitations, acceptedEvents, historicEvents);

}


export const prioritiseHomePagePowerHoursEvents = (events, premise) => {
	// return array containing invitation then accepted

	if (!events || !premise) return [null, null];

	let invitedEvents = [];
	let acceptedEvents = [];

	events.forEach(event => {			
		if (event.isValid) {
			if (event.isInvitation && !event.invitationHasExpired) {
				invitedEvents.push(event);
			}
			if ((event.accountId === premise.identifier) && event.isAccepted && !event.hasFinished()) {
				acceptedEvents.push(event);
			}
		}
	});

	invitedEvents.sort((a, b) => {
		return a.expiry - b.expiry;
	});

	acceptedEvents.sort((a, b) => {
		if (a.isInProgress() != b.isInProgress()) {
			// Only one in progress - prioritise that
			return a.isInProgress() ? -1 : 1;
		} else {
			// Either both in progress, or neither are
			return a.acceptedTimeSlot.startDateTime - b.acceptedTimeSlot.startDateTime
		}
	});

	return [
		(invitedEvents.length > 0) ? invitedEvents[0] : null,
		(acceptedEvents.length > 0) ? acceptedEvents[0] : null,		
	];
}


// Returns whether a given moment is within the tolerance (2 minutes) of midnight
function dateIsCloseToMidnight(m) {
	var midnight = roundToMidnight(m);
	return datesAreSimilar(m, midnight);
}

function roundToMidnight(m) {
	return moment(m).add(12, 'hours').startOf('day');;
}

function datesAreSimilar(m1, m2) {
	var diff = Math.abs(m2.diff(m1));
	return (diff <= diffTolerance);
}

// Current time in epoch time. Can be manipulated for testing different card states
function getNowEpoch() {
	let now = moment.now();

	//now += 3 * (24 * 60 * 60 * 1000)

	return now
}		

