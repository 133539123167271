import React from 'react';

export default function Icon({
    color = 'currentColor',
    height = '28px',
    width = height
}) {
    return (
        <svg
            height={height} // Set the SVG height
            width={width}   // Set the SVG width
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M12.5 0C5.61148 0 0 5.61148 0 12.5C0 19.3885 5.61148 25 12.5 25C19.3885 25 25 19.3885 25 12.5C25 5.61148 19.3885 0 12.5 0ZM12.5 1.92308C18.3519 1.92308 23.0769 6.64814 23.0769 12.5C23.0769 15.0766 22.1605 17.4279 20.643 19.2608C19.8167 16.9171 18.0288 15.0316 15.7452 14.1001C16.6992 13.2212 17.3077 11.9666 17.3077 10.5769C17.3077 7.93269 15.1442 5.76923 12.5 5.76923C9.85577 5.76923 7.69231 7.93269 7.69231 10.5769C7.69231 11.9666 8.30078 13.2212 9.25481 14.1001C6.97115 15.0316 5.19081 16.9171 4.36448 19.2608C2.83954 17.4279 1.92308 15.0766 1.92308 12.5C1.92308 6.64814 6.64814 1.92308 12.5 1.92308ZM12.5 7.69231C14.1076 7.69231 15.3846 8.96935 15.3846 10.5769C15.3846 12.1845 14.1076 13.4615 12.5 13.4615C10.8924 13.4615 9.61538 12.1845 9.61538 10.5769C9.61538 8.96935 10.8924 7.69231 12.5 7.69231ZM12.5 15.3846C15.7677 15.3846 18.4495 17.7058 19.073 20.7858C17.2626 22.2206 14.9865 23.0769 12.5 23.0769C10.0135 23.0769 7.73738 22.2206 5.9345 20.7858C6.55048 17.7058 9.23227 15.3846 12.5 15.3846Z" 
                fill={color} // Fill the path with the color prop
            />
        </svg>
    );
}
