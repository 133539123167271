import React from 'react';
import FullStory from 'react-fullstory';

const APP_KEY = process.env.REACT_APP_FULLSTORY_KEY;
const PRODUCTION_HOSTNAME = 'my.auroraenergy.com.au';

class FullStoryView extends React.Component {

  isProduction = window.location.hostname === PRODUCTION_HOSTNAME;

  render() {
    if (this.isProduction) {
      return <FullStory org={APP_KEY} />
    }

    return null;
  }
}

export default FullStoryView;
