import moment from 'moment-timezone';

import UsageDataHelper from './UsageDataHelper';

class DateContextHelper {


  static calculateYearOptions = (selection, timeRange, startDate, endDate, limitToUsageDates) => {
    let year = moment(startDate).year();
	let startYear = limitToUsageDates ? 2018 : moment(startDate).year();
	let endYear = moment().year();
	let options = [];
    for (var i = startYear; i <= endYear; i++) {
      options.push({
        text: i,
        value: i,
        active: i === year,
        selected: i === year,
      });
    }
    return options
  }

  static calculateQuarterOptions = (selection, timeRange, startDate, endDate, limitToUsageDates) => {
    let options = [];

    let current = moment().year(selection[1]);
    let now = moment().subtract(1, 'days');
    let until = 5;
    let start = 1;

    if (now.year() === current.year()) {
      until = now.quarter() + 1;
    }

	if (limitToUsageDates && current.year() === 2018) {
	  start = 4;
	}

    for (var i = start; i < until; i++) {
      let quarter = moment().quarter(i);
      let start = quarter
        .clone()
        .startOf('quarter');
      let end = quarter
        .clone()
        .endOf('quarter');
      options.push({
        text: `${start.format('MMMM')} to ${end.format('MMMM')}`,
        value: i,
        description: `Q${i}`,
        active: i === current.quarter(),
        selected: i === current.quarter(),
      });
    }
    return options;
  }

  static calculateMonthOptions = (pickerValues, timeRange, startDate, endDate, limitToUsageDates) => {
    let currentDataSourceDate = moment(startDate)
    let now = moment()
    let selectedYear = moment()

    if (pickerValues) {

      if (timeRange === UsageDataHelper.DAY) {
        selectedYear.year(pickerValues[2]).month(pickerValues[1]);
      }
      else if (timeRange === UsageDataHelper.MONTH) {
        selectedYear.year(pickerValues[1]).month(pickerValues[0]);
      }
    }

    let selected = currentDataSourceDate.format('MMMM')
    let options = moment
      .months()
      .map((month, i) => {
        return {
          value: i,
          text: month,
          active: month === selected,
          selected: month === selected,
        }
      })

    // filter out all months ahead of now
    // also filter current month if day is 1
    if (selectedYear.year() === now.year()) {
      options = options.filter(option => {
        if (now.date() === 1 && option.value === now.month()) {
          return false;
        } else {
          return option.value <= now.month()
        }
      })
    }

    // filter out 2018 months before october
    if (limitToUsageDates && selectedYear.year() === 2018) {
      options = options.filter(option => option.value >= 9);
    }

    // if after the filtering there are no options selected
    // just select the first/last item in the list
    if (!options.find(i => i.selected)) {
      //console.log('picker no selection');
    }

    return options
  }

  static calculateWeekOptions = (pickerValues, timeRange, startDate, endDate, limitToUsageDates) => {
    let options = [];

    let currentMonth = moment().month();
    let currentYear = moment().year();
    let endWeek = 53;
    let startWeek = 0;

    if (pickerValues && pickerValues[1]) {
      if (pickerValues[1] === currentYear) {
        const isStartOfMonth = moment().date() === 1;
        endWeek = moment().isoWeek() - (isStartOfMonth ? 1 : 0);
      }

      currentYear = pickerValues[1];
    }

    // dates are hardcoded to start at Oct 2018
    if (limitToUsageDates && pickerValues && pickerValues[1] === 2018) {
      const firstIsoWeekOfOctober = 40;

      startWeek = firstIsoWeekOfOctober;
    }

    for (var i = startWeek; i <= endWeek; i++) {

      let week = moment().year((pickerValues && pickerValues[1]) || moment().year()).isoWeek(i);
      let start = week.clone().startOf('isoWeek');
      let end = week.clone().endOf('isoWeek');

      //filter out weeks that should not be in the list
      if (week.startOf('isoWeek').year() !== currentYear) {
        continue;
      }

      if (currentMonth !== start.month()) {
        options.push({
          text: `${start.format('MMMM')}`,
          value: i  * -1,
          header: true
        });
        currentMonth = start.month();
      }

      let endWeek = moment(endDate);

      options.push({
        value: i,
        text: `${start.format('MMM Do')} to ${end.format('MMM Do')}`,
        description: `W${i}`,
        active: i === endWeek.isoWeek(),
        selected: i === endWeek.isoWeek(),
      });
    }

    return options;
  }

  static calculateDayOptions = (pickerValues, timeRange, startDate, endDate, limitToUsageDates) => {
    let current = moment()

    if (pickerValues) {
      current.date(pickerValues[0]);
      current.month(pickerValues[1]);
      current.year(pickerValues[2]);
    }

    let today = moment()
    let startDay = current.clone().startOf('month').date()
    let m = current.clone().startOf('month')
    let options = []

    // set until to be the end of the month
    let until = current.clone().endOf('month').date();

    // unless its the current month, then set to the current day
    if (today.month() === current.month() && today.year() === current.year()) {
      until = today.subtract(1, 'days').date()
    }

    for (var day = startDay; day <= until; day++) {
      options.push({
        value: day,
        text: m.format('Do'),
        active: day === current.date(),
        selected: day === current.date(),
      })
      m = m.add(1, 'day')
    }

    return options
  }

  static recalculateDataIndex = (currentIndex, fromTimeRange, toTimeRange) => {
    let now = moment();

    switch (fromTimeRange) {
      case UsageDataHelper.DAY:
        now.add(currentIndex, 'days')

        if (toTimeRange === UsageDataHelper.MONTH) {
          now.date(1);

          return now.diff(moment().subtract(1, 'days'), 'months') - 1;
        }

        if (toTimeRange === UsageDataHelper.WEEK) {
          now.isoWeekday(1);

          if (moment().day() === 1) {
            return now.diff(moment().isoWeekday(1), 'weeks');
          }

          return now.diff(moment().isoWeekday(8), 'weeks');
        }

        if (toTimeRange === UsageDataHelper.QUARTER) {
          now.startOf('quarter');
          return now.diff(moment(), 'quarters') - 1;
        }

        if (toTimeRange === UsageDataHelper.YEAR) {
          now.startOf('year');
          return now.diff(moment(), 'years') - 1;
        }

        break;
      case UsageDataHelper.WEEK:
        if (toTimeRange === UsageDataHelper.DAY) {

          if (now.day() === 1) {
            now.add(currentIndex, 'weeks').day(1);
          } else {
            now.add(currentIndex + 1, 'weeks').day(1);
          }

          return now.diff(moment(), 'days');
        }

        if (moment().day() === 1) {
          now.day(1).add(currentIndex, 'weeks')
        } else {
          now.day(1).add(currentIndex + 1, 'weeks')
        }

        if (toTimeRange === UsageDataHelper.MONTH) {
          now.startOf('month');
          return now.diff(moment().subtract(1, 'days'), 'months') - 1;
        }

        if (toTimeRange === UsageDataHelper.QUARTER) {
          now.startOf('quarter');
          return now.diff(moment(), 'quarters') - 1;
        }

        if (toTimeRange === UsageDataHelper.YEAR) {
          now.startOf('year');
          return now.diff(moment().subtract(1, 'days'), 'years') - 1;
        }

        break;
      case UsageDataHelper.MONTH:

        if (toTimeRange === UsageDataHelper.DAY) {
          now.subtract(1, 'days').date(1).add(currentIndex + 1, 'months');
          return now.diff(moment(), 'days');
        }

        if (toTimeRange === UsageDataHelper.WEEK) {
          now.subtract(1, 'days').date(1).add(currentIndex + 1, 'months');
          now.isoWeekday(8);

          if (now.date() === 8) {
            now = now.isoWeekday(-6)
          }

          if (moment().day() === 1) {
            return now.diff(moment().isoWeekday(1), 'weeks');
          } else {
            return now.diff(moment().isoWeekday(8), 'weeks');
          }
        }

        if (toTimeRange === UsageDataHelper.YEAR) {
          now.add(currentIndex + 1, 'months').startOf('year');

          return now.diff(moment().startOf('year'), 'years') - 1;
        }

        if (toTimeRange === UsageDataHelper.QUARTER) {
          now.add(currentIndex + 1, 'months').startOf('quarter');

          return now.diff(moment().startOf('quarter'), 'quarters') - 1;
        }

        break;
      case UsageDataHelper.QUARTER:

        if (toTimeRange === UsageDataHelper.DAY) {
          now.add(currentIndex + 1, 'quarter').startOf('quarter');
          return now.diff(moment(), 'days');
        }

        if (toTimeRange === UsageDataHelper.WEEK) {
          now.add(currentIndex + 1, 'quarters').startOf('quarter');
          now.isoWeekday(8);

          if (now.date() === 8) {
            now = now.isoWeekday(-6)
          }

          if (moment().day() === 1) {
            return now.diff(moment().isoWeekday(1), 'weeks');
          } else {
            return now.diff(moment().isoWeekday(8), 'weeks');
          }
        }

        if (toTimeRange === UsageDataHelper.MONTH) {
          now.add(currentIndex + 1, 'quarter').startOf('quarter');
          return now.diff(moment().subtract(1, 'days'), 'months') - 1;
        }

        if (toTimeRange === UsageDataHelper.YEAR) {
          now.add(currentIndex + 1, 'quarter').startOf('year');

          return now.diff(moment().subtract(1, 'days').startOf('year'), 'years') - 1;
        }

        break;
      case UsageDataHelper.YEAR:
        now.add(currentIndex + 1, 'years').startOf('year')

        if (toTimeRange === UsageDataHelper.DAY) {
          return now.diff(moment(), 'days');
        }

        if (toTimeRange === UsageDataHelper.WEEK) {
          now.isoWeekday(8);

          if (now.date() === 8) {
            now = now.isoWeekday(-6)
          }

          if (moment().day() === 1) {
            return now.diff(moment().isoWeekday(1), 'weeks');
          } else {
            return now.diff(moment().isoWeekday(8), 'weeks');
          }
        }

        if (toTimeRange === UsageDataHelper.MONTH) {
          return now.diff(moment().subtract(1, 'days'), 'months') - 1;
        }

        if (toTimeRange === UsageDataHelper.QUARTER) {
          return now.diff(moment(), 'quarters') - 1;
        }

        break;
      default:
        return -1;
    }
  }
}

export default DateContextHelper;
