import { SYSTEM_TYPE } from '../utils/Types';

export default class Customer {

  constructor(customerData) {
    this.customerID = customerData.CustomerID;
    this.customerType = customerData.CustomerType ? customerData.CustomerType.toLowerCase() : "";
    this.businessName = customerData.BusinessName;
    this.firstName = customerData.FirstName;
    this.lastName = customerData.LastName;
    this.givenNames = customerData.GivenNames;
    this.title = customerData.Title;
    this.emailAddress = customerData.EmailAddress;
    this.mobileNumber = customerData.MobileNumber;
    this.unreadNotificationCount = customerData.UnreadNotificationsCount;
    this.HasSolarProduct =  customerData.HasSolarProduct;
    this.isFromHubCX = customerData.IsFromHubCX;
    this.isFromCCB = customerData.IsFromCCB;
    this.abn = customerData.ABN;
    this.hasPaymentExtension = customerData.HasPaymentExtension;
    this.productLevel = customerData.ProductLevel;
    this.ConcessionStatus = customerData.ConcessionStatus;
    this.lifeSupport = customerData.lifeSupport;
	this.signedUpThroughInAppSignup = customerData.IsNewlyCreated;
  }

  get systemType() {
    return this.isFromHubCX
      ? SYSTEM_TYPE.HUB
      : SYSTEM_TYPE.CCB
  }
}