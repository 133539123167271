
import React from 'react';

import './IconRadio.css';

export default ({ label, icon, iconHeight, name, value, checked, onChange }) => {
	const Icon = icon;
	const id = name + "-" + value + "-input";
	return <div className="ui icon-radio">
		<input id={id} name={name} type="radio" value={value} checked={checked} onChange={
			(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (onChange) {
					setTimeout(() => {
						onChange(e, { value: value, checked: true });
					}, 0);
				}
			}
		}/>
		<label htmlFor={id}>
			{Icon && <div className="icon-radio-icon">{<Icon height={iconHeight}/>}</div>}
			<span className="icon-radio-label">
				<span>{label}</span>
			</span>
		</label>
	</div>
}
