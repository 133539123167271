import React, {Component} from "react";
import { scaleLinear, scaleBand } from "d3-scale";
import { selectAll } from "d3-selection";
import { format } from "d3-format";
import { drag } from 'd3-drag';
import { cloneDeep, isEqual } from 'lodash'

import 'd3-zoom';

import {Popup} from 'semantic-ui-react';
import { tariffSearch, solarCheck } from '../../utils/Tariffs'

// sub-components
import { Axis, AxisLabel } from '../BarChart/Axis';
import DataBars from '../BarChart/DataBars';
import WeatherOverlay from './WeatherOverlay'

import './GroupedBarChart.css';
import CloseIcon from "../Icons/CloseIcon";

class GroupedBarChart extends Component {
  errors = {
    dataProcessingErrorMessage: "Sorry, your usage data isn't available yet, please check back later.",
    noDataForCurrentPremise: "Your current address was not active at this time",
    genericNoDataError: "There seems to be a problem getting your energy usage data. We are looking into " +
    "it and will update your data as soon as it becomes available.",
    substitutedDataMessage: 'Energy costs will be available once your usage data has been finalised.'
  };

  margin = { top: 40, right: 25, bottom: 20, left: 25 };

  constructor(props) {
    super(props);

    this.chartAreaRef = React.createRef();
    this.nodeRef = React.createRef();
    this.popupRef = React.createRef();

    this.state = {
      popupOpen: false,
      popupTitle: 'Title',
      popUpValues: {},
      popupNode: null,
      panX: (this.margin.left / 2),
      barWidth: null,
      showDataBars: true,
      showSubstitutedUsageMessage: false,
	  warningBoxDismissed: false
    };
  }

  // close popup if we clicked anywhere other than the bar that the current popup is attached to
  handleDocumentMouseDown = (e) => {
    if (this.state.popupOpen && e && e.target.classList && !e.target.classList.contains("hasPopup")) {
      this.setState({
        popupOpen: false,
      })
    }
  }

  componentDidMount() {

    window.addEventListener('resize', this.renderChart());
    document.addEventListener('mousedown', (e) => this.handleDocumentMouseDown(e));

    // add drag event handler to entire svg element, for X axis scrolling
    selectAll(".draggable")
      .call(drag().on('drag', this.onDragEvent));

    this.barWidthCalculator()
    this.panValueCalculator()

    if (this.props.unitOfMeasurement == "DollarValueUsage" && this.props.timeRange === 'day' && this.props.hasDaySubstituted)
    {
      this.state.showSubstitutedUsageMessage = true;
    }
    else
    {
      this.state.showSubstitutedUsageMessage = false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.loadingData && prevState.popup === this.state.popup) {
      this.renderChart();
    }

    if (prevProps.loadingData !== this.props.loadingData && this.props.loadingData) {
      this.setState({showDataBars: false})
    } else if (prevProps.loadingData !== this.props.loadingData && !this.props.loadingData) {
      this.setState({showDataBars: true})
    }

    if (prevProps.timeRange !== this.props.timeRange) {
      this.barWidthCalculator()
      this.panValueCalculator()
    }

    if (prevProps.timeRange !== this.props.timeRange
		|| prevProps.unitOfMeasurement !== this.props.unitOfMeasurement
		|| !isEqual(prevProps.data, this.props.data)) {
		this.setState({warningBoxDismissed: false});
	}

    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (isIE11 && prevState.popupNode && (prevState.popupNode !== this.state.popupNode)) {
      this.setState({popupOpen: false})
    }

    if (this.props.unitOfMeasurement == "DollarValueUsage" && this.props.timeRange === 'day' && this.props.hasDaySubstituted)
    {
      this.state.showSubstitutedUsageMessage = true;
    }
    else
    {
      this.state.showSubstitutedUsageMessage = false;
    }
    if (this.props.unitOfMeasurement == "KilowattHourUsage")
    {
      this.state.showSubstitutedUsageMessage = false;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.renderChart());
    document.removeEventListener('mousedown', this.handleDocumentMouseDown());
  }

  // returns the largest value of all the objects in the 'data' array.
  // the fields used in the calculation are from the 'keys'
  maxValue(data, unitOfMeasurement, keys) {
    
    let values = cloneDeep(data)
    values.map((time, index) => {
      if (time[unitOfMeasurement]) {
        for (let key in time[unitOfMeasurement]) {
          if (time[unitOfMeasurement][key] < 0) {
            values[index][unitOfMeasurement][key] = Math.abs(time[unitOfMeasurement][key])
          }
        }
      }
    })

    let processedKeys = []
    
    for (let key in keys) {
      if (this.props.solar) {
        solarCheck(keys[key]) ? processedKeys.push(keys[key]) : null
      } else {
        processedKeys.push(keys[key])
      }
    }
    
    const max = values.map(segment => Math.max(...processedKeys.map(key => {
      if (segment[unitOfMeasurement] && typeof segment[unitOfMeasurement][key] === 'number') {
        return segment[unitOfMeasurement][key];
      } else {
        return 0;
      }
    }))).reduce((a, b) => Math.max(a, b), 0);

    // round to 5 when dataMax under 100
    // round to 50 when dataMax under 1000
    // else round to 100
    const roundUpTo = max < 100 ? 5
      : max < 1000 ? 50
        : 100;

    let maxRounded = Math.ceil(max / roundUpTo) * roundUpTo;
    
    return (maxRounded !== 0) 
      ? maxRounded 
      : roundUpTo; 
  }

  panValueCalculator() {
    let panX = this.margin.left / 2

    if (this.props.timeRange === 'day' && this.props.unitOfMeasurement === 'KilowattHourUsage') {
      panX = -240 // arbitrary value to get to 6am
    }

    this.setState({panX: panX})
  }

  barWidthCalculator() {

    // This solves the graphs not moving problem, but we don't know why

    let barWidth = 52

    if (this.props.timeRange === 'day') {
      barWidth = 47
    } 
    
    if (this.props.timeRange === "week") {
      barWidth = 65
    }

    this.setState({barWidth: barWidth})
  }

  renderChart() {
    const unitOfMeasurement = this.props.unitOfMeasurement || 'DollarValueUsage';
    const data = this.props.data;
    const timeRange = this.props.timeRange;
    const contentHeight = this.props.size.height - this.margin.top - this.margin.bottom + 5;
    let contentWidth = this.props.size.width - this.margin.left - this.margin.right;

    // Bars are designed to render at 12px each, bar width values need to be divisible by 2,3 & 4 in order to accommodate tariff combinations and bar padding.

    let clipMarginLeft = 0;
    let clipAdjustment = 0
    let barWidth = 48;
    let barGroupPadding = 0.5;
	
    let keys = this.props.tariffs || []
	let nonSolarKeys = this.props.nonSolarTariffs || keys;
    let hasDaySubstituted = this.props.hasDaySubstituted;

    let dataMax = this.maxValue(data, unitOfMeasurement, keys);

    if ((timeRange === 'day' && unitOfMeasurement === 'KilowattHourUsage') || timeRange === 'year') {
      barGroupPadding = 0.4;
      barWidth = 42;
    }

    if (timeRange === 'year') {
      clipAdjustment = unitOfMeasurement === 'KilowattHourUsage' ? 10 : 5;
    }

    if (timeRange === 'quarter') {
      clipAdjustment = ( unitOfMeasurement === 'KilowattHourUsage' || unitOfMeasurement === 'DollarValueUsage') ? 20 : 5 ;
    }
  

    if (this.props.tariffs) {

      if (this.props.solar) {
        keys = this.props.tariffs.filter(item => solarCheck(item))
      }

    }

    // adjust size of svg to fit entire area, when browser scales
    if (this.chartAreaRef.current){
       contentWidth = this.chartAreaRef.current.offsetWidth;
    }
      
    const adjustDataWidth = 84 // when comparing to content width this is needed to account for axis lable
    let dataWidth = (data.length * barWidth);
    if (dataWidth + adjustDataWidth < contentWidth) {
      clipMarginLeft = (contentWidth / 2) - (dataWidth / 2) - (barWidth / 2) + (timeRange === "month" ? 25 : clipAdjustment);
    }

    const yScale = scaleLinear()
      .domain(this.props.hasSolar && !this.props.solar ? [-Math.abs(dataMax), dataMax] : [0, dataMax]) // domain = inputs between 0 and dataMax
      .rangeRound([contentHeight, 0]); // map to range of contentHeight to 0

    const xScale0 = scaleBand() // takes into account padding between bars on bar chart - splits range into N bands
      .domain(data.map(i => i.StartTime))
      .range([0, dataWidth])
      .paddingInner(barGroupPadding); // calling .bandwidth() gets width of each bar in barchart

    const xScale1 = scaleBand()
      .domain(keys)
      .range([0, xScale0.bandwidth()]);

      return {
        yScale: yScale,
        xScale0: xScale0,
        xScale1: xScale1,
        contentHeight: contentHeight,
        contentWidth: contentWidth,
        clipMarginLeft: clipMarginLeft,
        timeRange: timeRange,
        keys: keys,
		nonSolarKeys: nonSolarKeys,
        data: data,
        barWidth: barWidth,
        dataMax: dataMax,
        unitOfMeasurement: unitOfMeasurement,
        moreData: dataWidth + adjustDataWidth >= contentWidth // when data goes past the clip width, display the "show more data" indicator
      };
  }

  // clicking bars on graph => show tooltip
  onClickEvent = (model, data, ref) => {

    const UsagetoString = (obj) => {
      let totalUsage = {};
      if (!obj) return totalUsage;

      // if the usageUnit says the value is in dollars, then format the string as AU money
      var func = model.units === "DollarValueUsage"
        ? (val) => ('$' + (Number(parseFloat(val).toFixed(2)).toLocaleString('en-AU', { currency: 'AUD', minimumFractionDigits: 2 }))).replace("$-","- $")
        : (val) => val.toLocaleString('en-AU', { minimumFractionDigits: 2 }) + ' kWh';

      for (let tariff in obj) {
        if (obj[tariff]) {
          totalUsage[tariff] = tariff ? func(obj[tariff]) : 0
        }
      }

      return totalUsage;
    };

    let usage = UsagetoString(data[model.units]);

    // special case here for day view - can click on individual peak/off-peak bars and show separate stats
    if (model.units !== "Dollar" && this.props.timeRange === 'day') {
      usage.peak = data.isPeak ? usage.peak : 0;
      usage.offPeak = data.isPeak ? 0 : usage.offPeak;
    }
    
    this.setState({
      popup: Date.now(),
      popupNode: ref.current,
      popupOpen: true,
      popupTitle: data.TooltipLabel,
      popUpValues: usage,
      showDataBars: true
    })
  }

  onDragEvent = (event) => {
    let currentWidth = this.chartAreaRef.current ? this.chartAreaRef.current.offsetWidth : 540;
    let xPanAdjustment = (event) ? event.dx : 0;
    let xTotalPan = this.state.panX + xPanAdjustment;

    let rightMostPan = (this.margin.left / 2);
    let leftMostPan = -((this.props.data.length * this.state.barWidth) - currentWidth - (this.margin.right) - (this.margin.left));
    
    // clamp pan value between start of graph and end
    if (xTotalPan > rightMostPan)
      xTotalPan = rightMostPan;
    else if (xTotalPan < leftMostPan)
      xTotalPan = leftMostPan;

    this.setState({
      panX: xTotalPan,
      popupOpen: false
    });
  }

  Spinner = (props) => {
    // 15 == (size of the spinner) / 2
    const spinnerPosition = {
      x: ((props.width - this.margin.right - this.margin.left) / 2 ) + 15,
      y: (props.height / 2) + (this.props.timeRange === "week" && this.props.weather && this.props.weather.length > 0 ? 65 : 15)
    };

    return (
      <g className="spinner" transform={`translate(${spinnerPosition.x}, ${spinnerPosition.y})`}>
        <path opacity="0.2" fill="#003"
          d={"M20.201,5.169c-8.254,0-14.946,6.692-14.946,14" +
            ".946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.86" +
            "1,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634" +
            "c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26" +
            ".541,26.626,31.749,20.201,31.749z"} />
        <path
          fill="#0060ae"
          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20" to="360 20 20"
            dur="0.5s"
            repeatCount="indefinite" />
        </path>
      </g>
    );
  }

  MoreDataIndicator = (props) => {
    let leftMostPan = -((this.props.data.length * this.state.barWidth) - props.width - (this.margin.right * 2) - (this.margin.left));
    let isShowing = this.state.panX > leftMostPan ? 'showing' : '';
    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    return (
      <g className={`more-data-right-arrow ${isShowing}`} transform={isIE11 ? `translate(${props.width - (this.margin.right * 2) - (this.margin.left) - 46}, 46)` : ""}>
        <g className="animated-transform">
          <path d="M11,4l6,6l-6,6"></path>
          <path d="M5,5l5,5l-5,5"></path>
        </g>
      </g>
    );
  }

  ErrorMessage = data => {
    return (
      <div className="errorMessage" style={{bottom: this.props.timeRange === "week" ? "40%" : "50%"}}>
        {
         !this.state.showSubstitutedUsageMessage ? (data.flags.NoDataDayThresholdExceededFlag
            ? this.props.noDataReason
            : this.errors.dataProcessingErrorMessage) : this.errors.substitutedDataMessage
        }
      </div>
    );
  }
  
  WarningBox = data => {
    return (
      <div className="warningBox" style={{bottom: this.props.timeRange === "week" ? "40%" : "50%"}}>
        {
          data.content
        }
		<CloseIcon className="warningBox-close-icon" onClick={() => {
			this.setState({warningBoxDismissed: true})
		}}/>
      </div>
    );
  }
  
  tickFormat = (d) => {
    const item = this.props.data.find(i => i.StartTime === d);
    return (item)
      ? item.StartTimeLabel
      : '';
  }

  yAxisTicksPosition = () => {
    let maxValue = this.maxValue(this.props.data, this.props.unitOfMeasurement, this.props.tariffs)
    if (maxValue < 100 ) {
      return 25
    } else if (maxValue < 1000) {
      return 35
    } else {
      return 40
    }
  }

  closePopup = () => {
    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (!isIE11) {
      this.setState({
        popupOpen: false,
      })
    }
  }

  render() {
    const { ErrorMessage, MoreDataIndicator, Spinner } = this
    const { height } = this.props.size;
    const theme = this.props.theme;
    const chart = this.renderChart();
    const panX = chart.moreData ? this.state.panX : -(this.margin.left / 2);
    const yAxisOffset = 3;
    const showWeather = true;
    const displayWeather = this.props.weatherInfo && this.props.timeRange === "week" && this.props.weather && this.props.weather.length > 0;

    return (
      <div className="grouped-bar-chart" ref={this.chartAreaRef}>

        <svg
          ref={node => (this.nodeRef = node)}
          height={displayWeather ? height + 65 : height + 15}
          width="100%"
          onClick={this.handleDocumentMouseDown}
        >
          <g
            className="content-container draggable"
            transform={`translate(${this.margin.left},${displayWeather ? this.margin.top + 50 : this.margin.top})`}
            opacity={this.props.loadingData ? 0.5 : 1}>

            <Axis
              name="y-axis"
              orientation="left"
              contentHeight={chart.contentHeight}
              dynamicStrokeWidth
              position={{ x: -this.margin.left, y: 0 }}
              textOffset={{ x: this.yAxisTicksPosition() - yAxisOffset, y: 0 }}
              length={chart.contentWidth}
              scale={chart.yScale}
              ticks={this.props.hasSolar && !this.props.solar ? 10 : 5}
              tickFormat={format(",")}
              dataMax={{
                min: this.props.hasSolar && !this.props.solar ? chart.dataMax : 0,
                max: chart.dataMax
              }}
			  axisColor={theme.chart.yAxis.color}
              >
                <AxisLabel
                  name="axis-data-type"
                  position={{ x: (this.margin.left / 2) + yAxisOffset, y: -18 }}
                  text={chart.unitOfMeasurement === 'DollarValueUsage' ? '$' : 'kWh'}
				  color={theme.chart.yAxis.color}
                  visible
                />
            </Axis>

            <Axis
              name="x-axis"
              className="dragged"
              tickFormat={this.tickFormat}
              orientation="bottom"
              position={{ x: chart.clipMarginLeft + panX, y: chart.contentHeight + 10 }}
              scale={chart.xScale0}
              ticks={this.props.hasSolar ? (this.props.solar ? 5 : 10) : 5}
			  axisColor={theme.chart.xAxis.color}
            />

            {this.state.showDataBars &&
              <DataBars
                onClick={this.onClickEvent}
                solar={this.props.solar}
                hasSolar={this.props.hasSolar}
                hasDaySubstituted={this.props.hasDaySubstituted}
                {...{ ...chart, panX: chart.clipMarginLeft + panX, margins: this.margin }} /> 
            }

            {displayWeather &&
              <WeatherOverlay {...{ ...chart, panX: chart.clipMarginLeft + panX, margins: this.margin }} weatherData={this.props.weather}/>
            }

            {chart.moreData &&
              <MoreDataIndicator width={chart.contentWidth} />
            }
          </g>

          {this.props.loadingData &&
            <Spinner height={chart.contentHeight} width={chart.contentWidth} />
          }
        </svg>

        <Popup
          ref={this.popupRef}
          context={this.state.popupNode}
          open={this.state.popupOpen}          
          horizontalOffset={13}
          position="right center"
          on={['click', 'focus']}
          onClose={() => this.closePopup()}
          className={this.props.solar ? "solar" : null}
        >
          <Popup.Header >{this.state.popupTitle}</Popup.Header>
          <Popup.Content as='dl'>
            {this.props.tariffs && this.props.tariffs.length > 0 &&
              this.props.tariffs.map((item, index) => (
                this.props.solar ? 
                  solarCheck(item) ? 
                  <React.Fragment key={index}>
                    <dt>{this.props.mobile ? tariffSearch(item).DisplayNameMobile : tariffSearch(item).DisplayName}</dt>
                    <dd>{this.state.popUpValues[item] ? this.state.popUpValues[item] : 0}</dd>
                  </React.Fragment> : null :
                  <React.Fragment key={index}>
                    <dt>{this.props.mobile ? tariffSearch(item).DisplayNameMobile : tariffSearch(item).DisplayName}</dt>
                    <dd>{this.state.popUpValues[item] ? this.state.popUpValues[item] : 0}</dd>
                  </React.Fragment>
              ))
            }
          </Popup.Content>
        </Popup>

        {
          this.props.errorFlags.NoDataFlag &&
          <ErrorMessage flags={this.props.errorFlags}/>
        }
        {
          this.state.showSubstitutedUsageMessage &&
          <ErrorMessage flags={this.errors.substitutedDataMessage}/>
        }
		{
		  !this.state.warningBoxDismissed && this.props.graphWarningContent &&
		  <this.WarningBox content={this.props.graphWarningContent}/>
		}
      </div>
    );
  }
}

export default GroupedBarChart;
