import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg 
		height={height}
		width={width}
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		viewBox="0 0 25 25"
	>
		<path
			d="M12.5 0C5.61148 0 0 5.61148 0 12.5C0 19.3885 5.61148 25 12.5 25C19.3885 25 25 19.3885 25 12.5C25 5.61148 19.3885 0 12.5 0ZM12.5 1.92308C18.3519 1.92308 23.0769 6.64814 23.0769 12.5C23.0769 18.3519 18.3519 23.0769 12.5 23.0769C6.64814 23.0769 1.92308 18.3519 1.92308 12.5C1.92308 6.64814 6.64814 1.92308 12.5 1.92308ZM12.6127 5.76923C10.284 5.90445 8.38341 7.85757 8.38341 10.2314H10.3065C10.3065 8.66887 11.6587 7.44441 13.3113 7.72987C14.4907 7.93269 15.4222 9.08954 15.3846 10.3065C15.3546 11.3732 14.7311 12.0493 13.8146 12.4549C13.2437 12.7103 12.7028 12.9507 12.2446 13.4315C11.7864 13.9123 11.5385 14.6259 11.5385 15.3846H13.4615C13.4615 14.9489 13.5216 14.8813 13.6418 14.7536C13.7545 14.6334 14.07 14.4456 14.5883 14.2127C16.0231 13.5892 17.2551 12.2145 17.3077 10.3666C17.3753 8.18059 15.8053 6.20493 13.6343 5.82933C13.2888 5.77674 12.9432 5.75421 12.6127 5.76923ZM11.5385 17.3077V19.2308H13.4615V17.3077H11.5385Z"
			fill={color}
		/>
	</svg>
)
}


