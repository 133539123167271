
export default class AutoPaymentDetails {

  constructor(data) {
    this.accountId = data.AccountId;
    this.topUpAmount = data.TopUpAmount;
    this.threshold = data.Threshold;
    this.trigger = data.Trigger;
  }

  get isActive() {
    return this.trigger !== 'DISABLED';
  }
}
