import { createContext, useContext, useReducer } from 'react'

const loadedState = JSON.parse(sessionStorage.getItem("login-return"));

/*
  loginRequired should not be persisted in session storage
  It is used as a way of ensuring the login page will only
  launch into the login flow if the request happened this
  session, otherwise it will clear the returnUrl.
*/
const initialState = {
  returnUrl: null,
  ...loadedState,
  loginRequired: false,
}

function loginReturnReducer(state, payload) {
  const newState = { ...state, ...payload }
  sessionStorage.setItem("login-return", JSON.stringify({
    // Be selective about what is stored. We don't want to persist loginRequired
    returnUrl: newState.returnUrl,
  }));
  return newState;
}

export const LoginReturnContext = createContext(initialState)

export function LoginReturnProvider({ children }) {
  const [state, setState] = useReducer(loginReturnReducer, initialState)

  const loginReturn = {
    ...state,

    set: (url) => {
      setState({
        returnUrl: url,
        loginRequired: true
      })
    },

    clear: () => {
      setState({
        returnUrl: null,
        loginRequired: false,
      })      
    }
  }

  return (
    <LoginReturnContext.Provider value={loginReturn}>
      {children}
    </LoginReturnContext.Provider>
  )
}

export const useLoginReturn = () => useContext(LoginReturnContext)

export function withLoginReturn(Component) {
  return function WrappedComponent(props) {
    return (
      <LoginReturnContext.Consumer>
        {context => <Component {...props} loginReturn={context} />}
      </LoginReturnContext.Consumer>
    )
  }
}