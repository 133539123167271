import moment from 'moment-timezone';

export const DATE_FORMAT = "DD/MM/YYYY - hh:mm a";

export default class SplashScreen {

	constructor(data) {
		this.id = data.Id;
		this.title = htmlDecode(data.Title);
		this.text = htmlDecode(data.Text);
		this.template = data.Template;
		this.icon = data.Icon;
		this.link = data.Link;
		this.confirmAction = data.ConfirmButtonAction;
		this.confirmText = data.ConfirmButtonText;
		this.confirmDeepLink = data.ConfirmButtonDeepLink;
		this.startTime = this.parseMoment(data.StartTime);
		this.endTime = this.parseMoment(data.EndTime);
		this.filter = data.Filter;
	}

	parseMoment(time) {
		if (!time || time.length === 0) return null;
		return new moment(time, DATE_FORMAT);
	}

	get isCurrent() {
		const now = new moment();
		if (this.startTime && this.startTime.isAfter(now)) return false;
		if (this.endTime && this.endTime.isBefore(now)) return false;
		return true;
	}

	get linkLabel() {
		if (!this.link || this.link.length === 0) return null;
		const parts = this.link.split('\n');
		if (parts.length > 1) return parts[0];
		else return this.link;
	}
	get linkUrl() {
		if (!this.link || this.link.length === 0) return null;
		const parts = this.link.split('\n');
		if (parts.length > 1) return parts[1].trim();
		else return this.link;
	}

}

function htmlDecode(input) {
	// Decode html entities and strip tags, but leave <br> included for formatting
	return input.split("<br />").map(text => {
		var doc = new DOMParser().parseFromString(text, "text/html");
		return doc.documentElement.textContent;
	}).join("<br />");
 }