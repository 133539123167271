import React from "react";

export default function Icon({
  color = "currentColor",
  height = "32px",
  width = height
}) { return (
  <svg
    height={height}
    width={width}
    viewBox="0 0 15 21"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
    <path d="M8.54469 6.39786L13.6738 1.02637L8.01568 5.87381C7.78567 5.83014 7.50966 5.87381 7.32566 6.04849C7.04965 6.31052 7.04965 6.76906 7.32566 7.03108C7.60167 7.29311 8.08468 7.29311 8.36069 7.03108C8.5447 6.8564 8.6137 6.61621 8.54469 6.39786Z" />
    <path d="M6.24512 19.7826C6.24512 20.1974 6.61313 20.525 7.05014 20.525H8.63719C9.0742 20.525 9.44221 20.1756 9.44221 19.7826V19.7607H6.24512V19.7826Z" />
    <path d="M13.3291 2.51106L12.6161 3.2753C13.3061 4.19238 13.6971 5.26231 13.6971 6.39775C13.6971 7.83888 13.0991 9.19267 11.995 10.2189C10.753 11.3762 9.97097 12.8392 9.74097 14.455H5.99185C5.76185 12.8392 4.97982 11.3762 3.73778 10.2189C2.63375 9.19267 2.03573 7.83888 2.03573 6.39775C2.03573 4.95662 2.63375 3.60283 3.73778 2.57657C4.84182 1.55031 6.29086 0.98259 7.85491 0.98259C9.09695 0.98259 10.293 1.35379 11.282 2.00885L12.087 1.35379C10.868 0.458542 9.34995 0 7.85491 0C6.08385 0 4.3358 0.633225 2.97876 1.87784C0.287679 4.36707 0.287679 8.42844 2.97876 10.9177C4.10579 11.9658 4.77281 13.2759 4.93382 14.6515C4.97982 14.9135 5.27883 15.4376 5.80785 15.4376H9.85597C10.385 15.4376 10.707 14.9135 10.73 14.6515C10.914 13.2977 11.558 11.9658 12.6851 10.9177C15.2151 8.62496 15.3991 5.00029 13.3291 2.51106Z" />
    <path d="M5.53183 17.3594C7.23388 17.3594 8.42992 17.3594 10.155 17.3594C10.937 17.3594 10.937 16.2021 10.155 16.2021C8.45292 16.2021 7.25688 16.2021 5.53183 16.2021C4.74981 16.2021 4.74981 17.3594 5.53183 17.3594Z" />
    <path d="M5.53183 19.128C7.23388 19.128 8.42992 19.128 10.155 19.128C10.937 19.128 10.937 17.9707 10.155 17.9707C8.45292 17.9707 7.25688 17.9707 5.53183 17.9707C4.74981 17.9707 4.74981 19.128 5.53183 19.128Z" />
    <path d="M3.73801 6.5507C3.71501 6.44153 3.71501 6.31052 3.71501 6.1795C3.71501 5.93932 3.73801 5.72096 3.78401 5.48077L3.07099 5.21875C2.97898 5.65546 2.93298 6.09216 2.97898 6.52887H3.73801V6.5507Z" />
    <path d="M4.0827 4.62811C4.28971 4.21324 4.56572 3.82021 4.93373 3.49268C4.97973 3.44901 5.00273 3.42717 5.04873 3.3835L4.63472 2.79395C4.54272 2.85945 4.47371 2.92496 4.38171 3.0123C3.9217 3.449 3.57669 3.92938 3.34668 4.45343L4.0827 4.62811Z" />
    <path d="M5.34766 2.336L5.80767 2.88188C6.26768 2.64169 6.7967 2.46701 7.34871 2.42334L7.39472 1.72461C6.6817 1.76828 5.96867 1.98663 5.34766 2.336Z" />
    <path d="M10.2932 2.336C9.64913 1.98663 8.95911 1.79012 8.24609 1.72461L8.2921 2.42334C8.84411 2.48885 9.35013 2.64169 9.83314 2.88188L10.2932 2.336Z" />
  </svg>
)
};
