import React, { Component, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import "./static/fonts/graphik.css";
import "./App.css";

import {
  AppDownload,
  AppDemo,
  Contact,
  ConsentForm,
  Home,
  Login,
  LoginRedirect,
  ResetMfaPhone,
  Signup,
  SignupType,
  ResidentialSignup,
  ResidentialExternalSignup,
  BusinessExternalSignup,
  Notifications,
  BillPrediction,
  PaymentHelp,
  Payment,
  Usage,
  SetEmail,
  SetPassword,
  ChangePassword,
  RequestDeletion,
  TransactionHistory,
  AdminLogin,
  AccountSettings,
  AccountDetailsEdit,
  ExperienceSettings,
  SamlSSO,
  Redirect,
  PowerHours,
  FindEmailDoB,
  FindAccountNumber,
  AccountExists,
  SelectVerification,
  Verify,
  ConfirmEmail,
  UpdateEmail,
  VerifyUpdate,
  ChoosePassword,
  SignupErrorScreen,
  SelectProperty,
  PropertySelected,
  PropertyIneligible,
  MeterExchangeRequired,
  MeterExchangeBooking,
  PropertyComplete,
} from "./screens/index";
import {
  Header,
  Navigation,
  ProtectedRoute,
  UnProtectedRoute,
  SideMenu,
  FullStory
} from "./components/index";
import {
  Container,
  Dimmer,
  Loader,
  Image
} from "semantic-ui-react";
import Responsive from "./components/Responsive/Responsive";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment-timezone";
import AppRouterContainer from "./AppRouterContainer";
import Customer from "./model/Customer";
import Premise from "./model/Premise";
import Footer from "./components/Footer/Footer";
import "./utils/Validators";
import PaymentSettingsAdd from "./screens/AccountSettings/Payments/PaymentSettingsAdd";
import PaymentSettingsList from "./screens/AccountSettings/Payments/PaymentSettingsList";
import PaymentSettingsEdit from "./screens/AccountSettings/Payments/PaymentSettingsEdit";
import { withContexts } from './contexts'
import { Config } from "./config/configData";
import logo from "./static/logo.svg";
import Help from "./screens/StaticHelp";
import Analytics from "./utils/Analytics";
import MobileAppComms from "./utils/MobileAppComms";
import Log from "./utils/Log";
import Interim from "./screens/Interim/Interim";
import { PRODUCT_TYPE, SYSTEM_TYPE } from "./utils/Types";
import NoProduct from "./screens/NoProduct/NoProduct";
import SplashScreen from "./model/SplashScreen";
import { mockAPICalls } from "./Mock";
import { PowerHoursEvent, sortPowerHoursEvents } from "./model/PowerHours";
import semver from "semver";
import ForceUpdate from "./screens/ForceUpdate/ForceUpdate";
import DeepLinkLandingPage from "./screens/DeepLinkLandingPage/DeepLinkLandingPage";
import JourneyProgress from "./components/JourneyProgress/JourneyProgress";
import PendingInAppSignup from "./screens/Signup/PendingInAppSignup/PendingInAppSignup";
import { defaultTheme } from "./contexts/Theme";

const RefreshRoute = ({ path = '/' }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === path) {
      navigate(location.pathname.slice(path.length));
    }
  }, [navigate, location.pathname, path]);

  return null;
};

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      notificationKey: ''
    }

    moment.tz.setDefault("Australia/Hobart");

    //Global Error Handling
    axios.interceptors.response.use(undefined, error => {
      const ignoreGlobalErrors = error.config && error.config.ignoreGlobalErrors;
      const statusCode = error.response && error.response.status;

      if (
        !ignoreGlobalErrors &&
        statusCode === 401 &&
        !this.props.app.isMirroring
      ) {
        this.clearAllUserData();
      }

      return Promise.reject(error);
    });

	if (process.env.REACT_APP_MOCK_API_CALLS === "true") {
		mockAPICalls();
	}

  }

  componentDidMount() {
    document.addEventListener("message", this.mobileAppEventListener);

    this.props.app.setState({
      loginUser: this.loginUser.bind(this),
      clearAllUserData: this.clearAllUserData.bind(this)
    })

    this.props.customer.setState({
      customer: JSON.parse(localStorage.getItem("override_customer") || "null"),
      premises: JSON.parse(localStorage.getItem("override_premises") || "[]"),
      pageConfig: Config(),
      refresh: this.refreshData.bind(this),
      updateUnreadNotificationCount: this.updateUnreadNotificationCount.bind(this),
      setPremiseType: this.setPremiseType.bind(this),
      setCustomerPremise: this.setCustomerPremise.bind(this),
      setCustomerAndPremise: this.setCustomerAndPremise.bind(this),
      setConfig: this.setConfig.bind(this)
    })

  }

  componentDidUpdate(prevProps, prevState) {
    const { isAuthenticated } = this.props.auth
    const prevStateIsAuthenticated = prevState.isAuthenticated

    if (isAuthenticated !== prevStateIsAuthenticated) {
      this.setState({ isAuthenticated }, () => {
		if (isAuthenticated) this.refreshData();
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("message", this.mobileAppEventListener);
  }



  checkVersion() {
	let { deviceInfo, isAppleDevice, isGoogleDevice } = this.props.app;

	if (!deviceInfo || !deviceInfo.appVersion) return;
	let version = "" + deviceInfo.appVersion;

	let requiredVersion = undefined;
	if (isAppleDevice) requiredVersion = process.env.REACT_APP_REQUIRED_IOS_VERSION;
	else if (isGoogleDevice) requiredVersion = process.env.REACT_APP_REQUIRED_ANDROID_VERSION;

	if (!requiredVersion) return;

	let outOfDate = semver.lt(version, requiredVersion);

	this.props.app.setState({ isOutOfDate: outOfDate });

  }


  setPremiseType(type) {
    if (!type) return;

	// set the theme type, component configuration, and currently selected Premise

    // if switching from business to residential or vice-versa
    if (this.props.customer.customer.customerType !== type) {

      const newCust = this.props.customer.customers.find(
        x => x.CustomerType.toLowerCase() === type
      );

      const customer = this.createCustomerModel(newCust);
      const premises = this.createPremisesModel(newCust);
      const defaultPremise = premises[0];
	  
	  var pickablePremises = premises || [];
	  this.props.customer.customers.forEach(cust => {
		if (cust.CustomerType !== newCust.CustomerType) {
			pickablePremises = pickablePremises.concat(this.createPremisesModel(cust));
		}
	  });

      this.setConfig({
        configName: type,
        systemType: customer.systemType,
        billingType: defaultPremise.billingType,
        premiseId: defaultPremise.identifier,
        hasSolar: defaultPremise.hasSolar
      });

      Log.log("Account changed, id:", defaultPremise.identifier);

      this.props.customer.setState({
        customer,
        premises,
		pickablePremises,
        customerPremise: defaultPremise
      })
	  Analytics.setPremises(defaultPremise);

      window.sessionStorage.setItem(
        "customerPremise",
        JSON.stringify(defaultPremise)
      );
      window.sessionStorage.setItem(
        "customerId",
        JSON.stringify(customer.customerID)
      );
      window.sessionStorage.setItem(
        "customerType",
        JSON.stringify(customer.customerType)
      );

      this.props.theme.setCustomerType(customer.customerType);
    }
  }

  setCustomerPremise(premiseId, providedPremises = null) {
  // select a new Premise from the dropdown
  // does not trigger a theme
  // trigger configuration update
  // optionally provide a list of premises to get around async state updates

  let { premises, pageConfig } = this.props.customer;
  if (providedPremises) premises = providedPremises;

  const customerPremise = premises.find(
    i => i.identifier === premiseId
  );

  if (customerPremise) {
    Log.log("Account changed, id:", premiseId);

    // Update config to check whether aurora+ insights is enabled
    this.setConfig({
      configName: pageConfig.productName,
      systemType: pageConfig.system,
      billingType: customerPremise.billingType,
      premiseId: customerPremise.identifier,
      hasSolar: customerPremise.hasSolar
    });

    this.props.customer.setState({ customerPremise })
	Analytics.setPremises(customerPremise);

    window.sessionStorage.setItem(
      "customerPremise",
      JSON.stringify(customerPremise)
    );

    if (customerPremise) {
      Log.log("Account changed, id:", premiseId);

      // Update config to check whether aurora+ insights is enabled
      this.setConfig({
        configName: pageConfig.productName,
        systemType: pageConfig.system,
        billingType: customerPremise.billingType,
        premiseId: customerPremise.identifier,
        hasSolar: customerPremise.hasSolar
      });

      this.props.customer.setState({ customerPremise })
	  Analytics.setPremises(customerPremise);

      window.sessionStorage.setItem(
        "customerPremise",
        JSON.stringify(customerPremise)
      );
    }
  }
}

setCustomerAndPremise(premiseId, providedPremises = null) {
	// select a new Premise regardless of whether it belongs to the resi or business customer

	let { pickablePremises, pageConfig } = this.props.customer;
	if (providedPremises) pickablePremises = providedPremises;

	const customerPremise = pickablePremises.find(
		i => i.identifier === premiseId
	);

	if (!customerPremise) return;
	var type = customerPremise.serviceAgreementType;

	this.setPremiseType(type);
	this.setCustomerPremise(premiseId);

}

  async setConfig({
    configName,
    systemType,
    billingType,
    premiseId,
    hasSolar
  }) {
    if (!configName || !systemType) return;
    const current = this.props.customer.pageConfig;

    // const isAuroraInsightsEnabled =
    //     this.props.customer.featureEnabled.findIndex(
    //         i => i.AccountId === premiseId && i.FeatureEnabledState
    //     ) >= 0;

    const isBidgelyFeatureEnabled =
      (await this.getBidgelyFeatureEnabledState(premiseId)) || false;

    const isSolarFeatureEnabled =
      this.props.app.isSolarFeatureEnabled || !hasSolar;

    const isAuroraInsightsEnabled =
      isBidgelyFeatureEnabled && isSolarFeatureEnabled;

    if (
      configName !== current.productName ||
      systemType !== current.system ||
      billingType.toLowerCase() !== current.billingType ||
      isAuroraInsightsEnabled !== current.functionality.auroraInsights
    ) {
      console.log(
        `Refresh page config with following params
        configName: ${configName}
        systemType: ${systemType}
        billingType: ${billingType.toLowerCase()}
        accountId: ${premiseId}
        Bidgely-01-SSO: ${isBidgelyFeatureEnabled}
        Bidgely-02-Solar: ${this.props.app.isSolarFeatureEnabled}
        hasSolar: ${hasSolar}`
      );

      this.props.customer.setState({
        pageConfig: Config(
          configName,
          systemType,
          billingType,
          isAuroraInsightsEnabled
        )
      })
    }
  }

  mobileAppEventListener = event => {
    if (!event.data) return;

    let result = JSON.parse(event.data);

    let contextStateValues = {};

    Object.keys(result).forEach(key => {
      const payload = result[key];

      switch (key) {
        case "IS_APP":
          if (payload === true) {
            //running on mobile app.
            contextStateValues.isApp = true;
          }
          break;
        case "SET_DEVICE_INFO":
          if (payload) {
            contextStateValues.deviceInfo = payload;

			if (payload) {
				// Double coverage in case of an edge device that doesn't report the same
				let manufacturer = payload.manufacturer;
				let systemName = payload.systemName;
			
				switch(manufacturer) {
					case "Apple":
						contextStateValues.isAppleDevice = true;
						break;
					case "Google":
						contextStateValues.isGoogleDevice = true;
						break;
				}
				switch(systemName) {
					case "iOS":
					case "iPhone OS":
					case "iPhone iPadOS":
						contextStateValues.isAppleDevice = true;
						break;
					case "Android":
						contextStateValues.isGoogleDevice = true;
						break;
				}
			}

			setTimeout(this.checkVersion.bind(this), 1);
          }
          break;
        case "REGISTER_NOTIFICATION_KEY":
          if (payload) {
            //send notification key to API
            axios.post(
              process.env.REACT_APP_API_BASE_URI + "Notifications/register",
              payload
            );

            this.setState({ notificationKey: payload.NotificationKey });
            contextStateValues.notificationKey = payload.NotificationKey;
          }
          break;
        case "NOTIFICATION_KEY":
          if (payload) {
            this.setState({ notificationKey: payload });
            contextStateValues.notificationKey = payload;
          }
          break;
        default:
          break;
      }
    });

    this.props.app.setState(contextStateValues);
  };

  createCustomerModel = data => new Customer(data);
  createPremisesModel = data =>
    data.Premises.map(p => new Premise({
		IsFromCCB: (data.IsFromCCB || p.ServiceAgreementStatus === "Old_CCB"),
		...p
	}))
      .sort((a, b) => a.postCodeCompare(b))
      .sort((a, b) => a.serviceAgreementStatusCompare(b));

  hasNoPremises = premises => {
    if (premises && premises.length === 0) return true;
    return false;
  };

  hasMultipleProducts = data => {
    const customers = data || this.props.customer.customers;

    const hasResiProduct = customers.some(
      val => val.CustomerType.toLowerCase() === PRODUCT_TYPE.RESIDENTIAL
    );

    if (!hasResiProduct) return false;

    return customers.some(
      val => val.CustomerType.toLowerCase() === PRODUCT_TYPE.BUSINESS
    );
  };

  // get the global flag of whether to consider solar when displaying aurora+ insights features(Bidgely)
  getSolarFeatureEnabledState = async () => {
    try {
      const customerPremise = this.props.customer.customerPremise;
      if (!customerPremise || !customerPremise.parentAccountID) return;

      const { data: result } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URI}identity/FeatureEnabled?featureId=Bidgely-02-Solar&accountId=${customerPremise.parentAccountID}`
      );
      this.props.app.setState({ isSolarFeatureEnabled: result });
    } catch (error) {
      console.log(error);
    }
  };

  // get whether a accountId is available for aurora+ insights features(Bidgely)
  getBidgelyFeatureEnabledState = async accountId => {
    try {
      if (!accountId) return false;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URI}identity/FeatureEnabled?featureId=Bidgely-01-SSO&accountId=${accountId}`
      );
      const { FeatureEnabledState } = JSON.parse(res.data);

      return FeatureEnabledState;
    } catch (error) {
      console.log(error);
    }
  };

  refreshData = () => {
    if (this.isRefreshing) return this.isRefreshing;

    this.props.customer.setState({ hasLoaded: false });

    this.isRefreshing = Promise.all([
		axios
			.get(process.env.REACT_APP_API_BASE_URI + "splashScreen")
			.then(response => {
				if (response.data && response.data.length > 0) {
					this.props.app.setState({ splashScreens: response.data.map(data => new SplashScreen(data)) })
				} else {
					this.props.app.setState({ splashScreens: [] })
				}
				})
				.catch(error => {
					this.props.app.setState({ splashScreens: [] })
				})
	,
		axios
			.get(process.env.REACT_APP_API_BASE_URI + "powerhour/upcoming-active")
			.then(response => {
				const data = response.data;

				const powerHours = sortPowerHoursEvents(data.map(e => new PowerHoursEvent(e)))
				this.props.customer.setState({
					powerHours: powerHours
				})
			})
			.catch(error => {
				console.log("PS error", error);
				this.props.customer.setState({
					powerHours: null,
				})
			})
	,
		axios
			.get(process.env.REACT_APP_API_BASE_URI + "customers/current")
			.then(response => {
				const data = response.data;
				const cachedCustomerId = JSON.parse(
					window.sessionStorage.getItem("customerId")
				);
				const cachedCustomerType = JSON.parse(
					window.sessionStorage.getItem("customerType")
				);

				// need to decide which customer should be used
				// check if currently cached customer is in the returned dataset
				// if not, by default use the first customer in the dataset (which should be residential if it's multi-product)
				var selectedCustomer = cachedCustomerId
					? data.find(
						elem =>
							elem.CustomerID === cachedCustomerId &&
							elem.CustomerType.toLowerCase() === cachedCustomerType
						)
					: data[0];

				if (!selectedCustomer) selectedCustomer = data[0];

				// DPS-516 & DPS-20 When an existing Hub customer transitions from STOF to a+,
				// the Hub API only returns the STOF service agreement but not the a+ which is
				// still in progress. The STOF is then filtered out, resulting in an empty
				// array returned here. Use that as an indicator to show the interim screen
				if (Array.isArray(data) && data.length === 0) {
					this.props.customer.setState({
						isInterimScreenOpen: true,
					});
					return;
				}

				let customer = this.createCustomerModel(selectedCustomer);
				let premises = this.createPremisesModel(selectedCustomer);   

				var pickablePremises = premises || [];
				data.forEach(cust => {
				  if (cust.CustomerType !== selectedCustomer.CustomerType) {
					  pickablePremises = pickablePremises.concat(this.createPremisesModel(cust));
				  }
				});
		  
				MobileAppComms.postMessage(
					JSON.stringify({
						SET_CUSTOMER: customer
					})
				)

				Analytics.setCustomerId(customer.customerID);
				this.props.theme.setCustomerType(customer.customerType);

				// Set Promise to var for return later
				let setConfigPromise = this.setConfig({
					configName: customer.customerType,
					systemType: customer.systemType,
					billingType: premises[0] ? premises[0].billingType : "",
					premiseId: premises[0] ? premises[0].premiseId : "",
					hasSolar: premises[0] ? premises[0].hasSolar : ""
				});

				const noPremises = this.hasNoPremises(premises);
				const isInterimState =
					customer && customer.productLevel
						? noPremises && customer.productLevel == "INTERIMPRODUCT"
						: false;
				const isNoProductState =
					customer && customer.productLevel
						? noPremises && customer.productLevel == "NOPRODUCT"
						: false;

				this.props.customer.setState({
					customers: data,
					customer,
					premises,
					pickablePremises,
					lastRefreshTimeString: moment().format("D/M/YYYY [at] h.mma"),
					hasLoaded: false,
					hasMultipleProducts: this.hasMultipleProducts(data),
					isInterimScreenOpen: isInterimState,
					isNoProductScreenOpen: isNoProductState,
					isFromHubCX: customer.isFromHubCX
				});

				// select a premise if only if they are in a an active customer
				if (!noPremises) {
					//Select premise 0 unless there is a premise saved in session storage
					let selectedPremiseId = premises[0].identifier;

					let sessionPremiseJson = window.sessionStorage.getItem(
						"customerPremise"
					);

					if (sessionPremiseJson) {
						let cachedPremiseId = JSON.parse(sessionPremiseJson).identifier;
						let cachedPremiseFound = premises.find(
							i => i.identifier === cachedPremiseId
						);

						if (cachedPremiseFound) selectedPremiseId = cachedPremiseId;
					}

					this.props.customer.setCustomerPremise(selectedPremiseId, premises);
				}

				this.getSolarFeatureEnabledState();
				// Returning config promise to allow for waiting till config is set
				return setConfigPromise;
			})
			.catch(() => {})
	,
		axios
			.get(process.env.REACT_APP_API_BASE_URI + "customers/premises")
			.then(response => {
				this.props.customer.setState({
					allPremises: this.createPremisesModel(response.data)
				});
			})
			.catch(() => {})
	
	])
	.finally(() => {
        this.isRefreshing = null;
		//if we set hasloaded to true in previous setContextState function, AppDemo will crash the app if first time login.
		//the root cause is that customerPremise object is still null at that stage
		this.props.customer.setState({ hasLoaded: true });
	})

	return this.isRefreshing;
  };

  loginUser = (accessToken = false) => {
    if (accessToken) {
      this.props.auth.updateToken(accessToken)
      Analytics.event({ category: "User", action: "Mirror" });
    }

    if (!accessToken && localStorage.getItem("hasLoggedIn") !== "true") {
      this.props.app.setState({ appDemoOpen: false });
      localStorage.setItem("hasLoggedIn", true);
    }

    //admin portal needs to have empty session storage on login
    sessionStorage.removeItem("customerPremise");

    this.setState({ }, () => {
      // Ensure state containing the user token has updated

      let data = {
        LOGIN_SUCCESS: {
          accessToken
        }
      };
    
      MobileAppComms.postMessage(JSON.stringify(data));
    })
    
  };

  clearAllUserData = () => {
    this.props.auth.clearToken();

    sessionStorage.removeItem("customerPremise");

    this.props.customer.setState({
      customer: null,
      customers: [],
      premises: [],
	  allPremises: [],
      hasLoaded: false,
      hasMultipleProducts: false,
      customerPremise: null,
      isInterimScreenOpen: false,
	  isNoProductScreenOpen: false,
      isFromHubCX: false
    })
  };


  logoutUser = () => {
    const endpoint = process.env.REACT_APP_API_BASE_URI + "identity/logout";
    const endpointNotificationUnregister =
      process.env.REACT_APP_API_BASE_URI + "Notifications/unregister";

    //if mobile, we need to unregister the notification token as well as logout
    if (this.props.app.isApp && this.state.notificationKey) {
      axios
      .post(endpointNotificationUnregister, {
        NotificationKey: this.state.notificationKey
      })
      .then(() => axios.post(endpoint))
      .catch(() => axios.post(endpoint));
    } else {
      axios.post(endpoint);
    }

    Analytics.event({ category: "User", action: "Logged Out" });
	Analytics.setCustomerId(null);
	Analytics.setData(null)

    this.clearAllUserData();
  };

  updateUnreadNotificationCount = () => {
  const url =
    process.env.REACT_APP_API_BASE_URI + "Notifications/unreadCount";

  axios
    .get(url)
    .then(res => {
      const count = res.data;

      //update the field in customer.customer
      this.props.customer.setState({
        customer: {
        ...this.props.customer.customer,
        unreadNotificationCount: count
        }
      });
    })
    .catch(() => {});
  };

  renderRoutes(customer) {
	return (
		<Routes>

			{/* neither Protected or Unprotected since the deeplink could be either */}
			<Route path="/launch/*" element={<DeepLinkLandingPage />} />
			
			<Route path="/login/redirect" element={<UnProtectedRoute><LoginRedirect /></UnProtectedRoute>} />
			<Route path="/login/reset-sms" element={<UnProtectedRoute><ResetMfaPhone /></UnProtectedRoute>} />
			<Route path="/login" element={<UnProtectedRoute><Login /></UnProtectedRoute>} />
			<Route path="/setemail/:forgotPassword?" element={<UnProtectedRoute><SetEmail /></UnProtectedRoute>} />
			<Route path="/setpassword/:id" element={<UnProtectedRoute><SetPassword /></UnProtectedRoute>} />
			<Route path="/appdownload" element={<UnProtectedRoute><AppDownload /></UnProtectedRoute>} />

			<Route path="/saml/sso" element={<SamlSSO />} />
			<Route path="/refresh" element={<RefreshRoute path="/refresh" />} />
			<Route exact path="/redirect/bidgely" element={<Redirect />} />
			<Route path="/consent-form/:product?/:tariff?" element={<ConsentForm />} />
			<Route path="/admin/login/:token" element={<AdminLogin />} />
			<Route path="/appdownload" element={<UnProtectedRoute><AppDownload /></UnProtectedRoute>} />
			<Route path="/contact/:query?" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
			<Route path="/help/:level1?/:level2?" element={<ProtectedRoute><Help /></ProtectedRoute>} />
			<Route exact path="/" element={<ProtectedRoute forgetLocation={true}><Home /></ProtectedRoute>} />
			<Route exact path="/accountSettings" element={<ProtectedRoute><AccountSettings /></ProtectedRoute>} />
			<Route exact path="/accountSettings/experience" element={<ProtectedRoute><ExperienceSettings /></ProtectedRoute>} />
			<Route exact path="/accountSettings/payments" element={<ProtectedRoute><PaymentSettingsList /></ProtectedRoute>} />
			<Route exact path="/accountSettings/payments/add" element={<ProtectedRoute><PaymentSettingsAdd /></ProtectedRoute>} />
			<Route exact path="/accountSettings/payments/:accountToken" element={<ProtectedRoute><PaymentSettingsEdit /></ProtectedRoute>} />
			<Route exact path="/accountSettings/accountDetails/editAddress" element={<ProtectedRoute><AccountDetailsEdit /></ProtectedRoute>} />
			<Route exact path="/accountSettings/accountDetails/editPhoneNumbers" element={<ProtectedRoute><AccountDetailsEdit /></ProtectedRoute>} />
			<Route exact path="/accountSettings/changePassword" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
			<Route exact path="/accountSettings/requestDeletion" element={<ProtectedRoute><RequestDeletion /></ProtectedRoute>} />
			<Route path="/usage" element={<ProtectedRoute><Usage /></ProtectedRoute>} />
			<Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
			<Route path="/billprediction" element={<ProtectedRoute><BillPrediction /></ProtectedRoute>} />
			<Route path="/payment-help" element={<ProtectedRoute><PaymentHelp /></ProtectedRoute>} />
			<Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
			<Route path="/transactionhistory/:tab?" element={<ProtectedRoute><TransactionHistory /></ProtectedRoute>} />
			<Route path="/powerhours" element={<ProtectedRoute><PowerHours /></ProtectedRoute>} />

			<Route exact path="/signup" element={<UnProtectedRoute><Signup /></UnProtectedRoute>} />
			<Route exact path="/signup/type" element={<UnProtectedRoute><SignupType /></UnProtectedRoute>} />
			<Route exact path="/signup/external/residential" element={<UnProtectedRoute><ResidentialExternalSignup /></UnProtectedRoute>} />
			<Route exact path="/signup/external/business" element={<UnProtectedRoute><BusinessExternalSignup /></UnProtectedRoute>} />

			<Route exact path="/signup/property/:premisesId/complete" element={<ProtectedRoute><PropertyComplete /></ProtectedRoute>} />
			<Route exact path="/signup/property/:premisesId/ineligible" element={<ProtectedRoute><PropertyIneligible /></ProtectedRoute>} />
			<Route exact path="/signup/property/:premisesId/meter-exchange-required" element={<ProtectedRoute><MeterExchangeRequired /></ProtectedRoute>} />
			<Route exact path="/signup/property/:premisesId/meter-exchange-booking" element={<ProtectedRoute><MeterExchangeBooking /></ProtectedRoute>} />
			<Route exact path="/signup/property/:premisesId/meter-exchange-booking/:step" element={<ProtectedRoute><MeterExchangeBooking /></ProtectedRoute>} />
			<Route exact path="/signup/property/:premisesId" element={<ProtectedRoute><PropertySelected /></ProtectedRoute>} />
			<Route exact path="/signup/property" element={<ProtectedRoute><SelectProperty /></ProtectedRoute>} />

			<Route exact path="/signup/residential/find-your-account" element={<UnProtectedRoute><FindEmailDoB /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/find-account-number" element={<UnProtectedRoute><FindAccountNumber /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/login-exists" element={<UnProtectedRoute><AccountExists /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/select-verification" element={<UnProtectedRoute><SelectVerification /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/verify" element={<UnProtectedRoute><Verify /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/confirm-email" element={<UnProtectedRoute><ConfirmEmail /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/update-email" element={<UnProtectedRoute><UpdateEmail /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/verify-update" element={<UnProtectedRoute><VerifyUpdate /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/choose-password" element={<UnProtectedRoute><ChoosePassword /></UnProtectedRoute>} />
			<Route exact path="/signup/residential/signup-error" element={<UnProtectedRoute><SignupErrorScreen /></UnProtectedRoute>} />
			<Route exact path="/signup/residential" element={<UnProtectedRoute><ResidentialSignup /></UnProtectedRoute>} />

			<Route exact path="/signup/pending" element={<ProtectedRoute><PendingInAppSignup /></ProtectedRoute>} />

			<Route exact path="/" element={<ProtectedRoute></ProtectedRoute>} />

		</Routes>
	);
  }

  render() {
    const {
      isSideMenuOpen,
      appDemoOpen,
      backgroundImage,
	  showBackgroundImageOnMobile,
	  noContainer,
	  noHeader,
	  noFooter,
	  ignoreTheme,
	  isOutOfDate,
	  isLoadingCustom,	  
    } = this.props.app;
    const {
      isAuthenticated,
      hasLoaded,
      isInterimScreenOpen,
      isNoProductScreenOpen,
      customer,
      customerPremise,
      premises,
      hasMultipleProducts,
      pageConfig
    } = this.props.customer;
	const { whitePage, blueHeader } = this.props.app;
	const theme = whitePage ? defaultTheme : this.props.theme.theme;
    const isNoProductScreenShowing = hasLoaded && isNoProductScreenOpen;
    const isInterimScreenShowing = hasLoaded && isInterimScreenOpen;
	const signedUpThroughInAppSignup = hasLoaded && customer && customer.signedUpThroughInAppSignup;
  const hasSignedUpPremises = premises.length > 0
    const isAppDemoShowing = hasLoaded && appDemoOpen && !isInterimScreenShowing;
    const isLoadingScreenShowing =
      isAuthenticated &&
	  (isLoadingCustom || (!hasLoaded && !isAppDemoShowing));
    const hasSolar =
      hasLoaded && customer && customerPremise
      ? customerPremise.hasSolar
      : false;
    const isMirroring = this.props.app.isMirroring;
    let wrapperStyles = {
      color: theme.color,
    };
    if (isSideMenuOpen) {
      wrapperStyles.maxHeight = "100vh";
    }
    if (backgroundImage) {
      wrapperStyles.backgroundImage = `url(${backgroundImage})`;
	  wrapperStyles.className = "custom-background no-attribution" + ((showBackgroundImageOnMobile === false) ? " hide-custom-background-mobile" : "");
    } else if (theme && (isAuthenticated || isMirroring) && !whitePage) {
      wrapperStyles.background = theme.background;
    }
	wrapperStyles['--alternate-background'] = theme.table.alternateBackground


	let journeyProgress = this.props.app.journey ? <JourneyProgress.Banner {...this.props.app.journey}/> : null

    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <FullStory />
        <Router>
          <AppRouterContainer style={wrapperStyles} whitePage={whitePage}>

			{ isOutOfDate ? <>
                <SideMenu>
                  <ForceUpdate />
                </SideMenu>
			</> : <>          

				{ !signedUpThroughInAppSignup && (
					isNoProductScreenShowing ? (
						<Dimmer
							page
							className="page-noproduct"
							active={isNoProductScreenShowing}
						>
							<NoProduct logoutUser={this.logoutUser} />
						</Dimmer>
					) : (
						<Dimmer
							page
							className="page-interim"
							active={isInterimScreenShowing}
						>
							<Interim logoutUser={this.logoutUser} />
						</Dimmer>
					)
				)}

				{/* <Dimmer
					className="page-load"
					active={isLoadingScreenShowing}
					page
				>
					<Image src={logo} alt="Aurora APAYG+ logo" />
					<Loader
						inverted
						size="medium"
						style={{
						alignSelf: "center",
						marginTop: "70px"
						}}
					/>
				</Dimmer> */}

				<AppDemo
					modalOpen={isAppDemoShowing}
					hasSolar={customer && hasSolar}
					pageConfig={pageConfig}
					theme={theme}
					closeModal={() => {

						this.props.app.setState({ appDemoOpen: false });
					}}
				/>
				<SideMenu logoutUser={this.logoutUser}>
					<Routes>
						<Route path="/*" element={
							<> 
								<Header hasSolar={hasSolar} whitePage={whitePage} blueHeader={blueHeader} />
								
								<Responsive lessThan="computer">
									{journeyProgress}
								</Responsive>

								{noContainer ?
									<>                      
										{this.renderRoutes(this.props.customer)}
									</>
								:
									<Container className="main-container">                        
										{this.renderRoutes(this.props.customer)}
									</Container>
								}

								{this.props.auth.isAuthenticated && (<Responsive as={Footer} greaterThan="tablet" />)}
							</>
						} />
					</Routes>
				</SideMenu>

				{isAuthenticated && !isSideMenuOpen && hasSignedUpPremises && (
					<Responsive lessThan="computer">
						<Navigation hasSolar={hasSolar} />
					</Responsive>
				)}
  			
			</>}

          </AppRouterContainer>
        </Router>
      </AppInsightsContext.Provider>
    );
  }
}

export default withContexts(App)
