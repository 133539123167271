const debug = (ns) => {
  let fn = (...args) => {
    if (process.env.NODE_ENV === 'development') {
      console[ns
          .split(':')
          .pop() || 'log']("%c" + ns, 'color: ' + fn.color, ...args)
    }
  }
  fn.color = 'black'
  return fn;
}

const BASE = 'app';
const COLOURS = {
  trace: 'lightblue',
  info: 'gray',
  warn: 'pink',
  error: 'red',
  log: 'darkgreen'
};

class Log {
  generateMessage(level, ...args) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    createDebug(...args);
  }

  trace(...args) {
    return this.generateMessage('trace', ...args);
  }

  info(...args) {
    return this.generateMessage('info', ...args);
  }

  warn(...args) {
    return this.generateMessage('warn', ...args);
  }

  error(...args) {
    return this.generateMessage('error', ...args);
  }

  log(...args) {
    return this.generateMessage('log', ...args);
  }
}

export default new Log();