import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '21px',
  width = '23px',
  onClick,
  eventName,
  ...rest
}) {
  return (
    <svg
	  onClick={e => {
		if (eventName) 
			Analytics.modalview('info/' + eventName);
		onClick && onClick(e);
	  }}
      height={height}
      width={width}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
		d="M2.54883 0L1.45907 1.13958L5.02501 4.87489C1.5741 6.86916 0.211898 9.98402 0.133193 10.174L0 10.4968L0.133193 10.8197C0.248224 11.1046 3.13003 17.7902 11.497 17.7902C13.4525 17.7902 15.0932 17.4166 16.4796 16.8532L20.4391 21L21.5409 19.8478L17.981 16.1314L16.8186 14.9096L9.36589 7.11607L7.7373 5.41935L6.50829 4.14049L2.54883 0ZM11.497 3.2035C10.6373 3.2035 9.85628 3.29213 9.11161 3.41875L10.5041 4.87489C10.831 4.84956 11.1519 4.82424 11.497 4.82424C17.9084 4.82424 20.6328 9.23063 21.2806 10.4968C20.9779 11.073 20.1848 12.3518 18.8044 13.5484L19.9184 14.7196C21.9405 12.9216 22.8002 10.9717 22.8608 10.8197L23 10.4968L22.8608 10.174C22.7457 9.88906 19.8639 3.2035 11.497 3.2035ZM6.19347 6.09044L8.28823 8.28098C7.8826 8.92041 7.62227 9.6738 7.62227 10.4968C7.62227 12.738 9.35378 14.5487 11.497 14.5487C12.284 14.5487 13.0045 14.2765 13.616 13.8523L15.2445 15.5617C14.1608 15.9289 12.9318 16.1694 11.497 16.1694C5.08555 16.1694 2.36115 11.763 1.71335 10.4968C2.13714 9.68013 3.5054 7.50226 6.19347 6.09044Z"
        fill={color}
      />
    </svg>
  );
}
